import React, { Fragment, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import CambiarContraseña from "./CambiarContrasena";
import AuthContext from '../context/autenticacion/authContext';
// import { loadTree } from "../js/MenuTreeHelper";

// class Header extends Component {
const Header = () => {

    const authContext = useContext(AuthContext);
    const { usuario, usuarioAutenticado, cerrarSesion  } = authContext;

    useEffect(() => {
        // loadTree();
        usuarioAutenticado();

        // eslint-disable-next-line
    }, []);
    // render() {
        return (
           <Fragment>
                <nav className="main-header navbar navbar-expand navbar-white navbar-light">
                    <ul className="navbar-nav">
                    <li className="nav-item">
                        <a className="nav-link" data-widget="pushmenu" href="#"><i className="fas fa-bars" /></a>
                    </li>
                    <li className="nav-item dropdown">
                        <a className="nav-link" data-toggle="dropdown" href="#">
                        <i className="fas fa-plus"></i>
                        </a>
                        <div className="dropdown-menu dropdown-menu-lg dropdown-menu-left">
                        <div className="dropdown-divider" />
                        <Link to="/nuevo-cfdi" className="dropdown-item">
                            <i className="fas fa-file mr-2" /> Facturar
                        </Link>
                        <Link to="/cfdi" className="dropdown-item">
                            <i className="fas fa-search mr-2" /> Consultar CFDI's 3.3
                        </Link>
                        <div className="dropdown-divider" />
                        <Link to="/nuevo-nota-credito3_3" className="dropdown-item">
                            <i className="fas fa-file mr-2" /> Nota de Crédito
                        </Link>
                        <Link to="/cfdi-nota-credito3_3" className="dropdown-item">
                            <i className="fas fa-search mr-2" /> Consultar Notas de Crédito
                        </Link>
                        <div className="dropdown-divider" />
                        <Link to="/cliente" className="dropdown-item">
                            
                            <i className="fas fa-users mr-2" /> Clientes
                        </Link>
                        {/* <div className="dropdown-divider" />
                        <Link to="/dashboard" className="dropdown-item dropdown-footer">See All Notifications</Link> */}
                        </div>
                    </li>
                    <li className="nav-item d-none d-sm-inline-block">
                        <Link to="/dashboard" className="nav-link">Inicio</Link>
                    </li>
                    <li className="nav-item d-none d-sm-inline-block">
                        <Link to="/dashboard" className="nav-link">Contact</Link>
                    </li>
                    </ul>
                    <ul className="navbar-nav ml-auto">
                    <li className="nav-item d-none d-sm-inline-block navbar-text">
                        <a><span>Facturas disponibles:</span>
                        <span className="text-success font-weight-bold">
                            { usuario ? usuario.sucursal_pe_timbres : null}
                        </span>
                        </a>
                    </li>
                    <li className="nav item d-none d-sm-inline-block">
                        <Link to="/comprar-folios" className="nav-link"><i className="fas fa-shopping-cart"></i></Link>
                    </li>

                    <li className="nav-item dropdown">
                        <a className="nav-link" data-toggle="dropdown" href="#">
                        <i className="far fa-bell" />
                        <span className="badge badge-warning navbar-badge">15</span>
                        </a>
                        <div className="dropdown-menu dropdown-menu-lg dropdown-menu-left">
                        <span className="dropdown-item dropdown-header">15 Notifications</span>
                        <div className="dropdown-divider" />
                        <a href="#" className="dropdown-item">
                            <i className="fas fa-envelope mr-2" /> 4 new messages
                            <span className="float-right text-muted text-sm">3 mins</span>
                        </a>
                        <div className="dropdown-divider" />
                        <a href="#" className="dropdown-item">
                            <i className="fas fa-users mr-2" /> 8 friend requests
                            <span className="float-right text-muted text-sm">12 hours</span>
                        </a>
                        <div className="dropdown-divider" />
                        <a href="#" className="dropdown-item">
                            <i className="fas fa-file mr-2" /> 3 new reports
                            <span className="float-right text-muted text-sm">2 days</span>
                        </a>
                        <div className="dropdown-divider" />
                        <a href="#" className="dropdown-item dropdown-footer">See All Notifications</a>
                        </div>
                    </li>
                    <li className="nav-item dropdown">
                    <a className="nav-link" data-toggle="dropdown" href="#">
                        <i className="fas fa-user"></i>
                        </a>
                        <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                        <button type="button" className="btn btn-primary"><span>Razón Social</span>
                        {/* <p> */}
                        {usuario ? <p ><span>{usuario.sucursal_nombre} </span> </p> : null}
                        {/* </p> */}
                        </button>
                        <div className="dropdown-divider" />
                        <Link to="/soporte" className="dropdown-item">
                            <i className="fas fa-headset mr-2" /> Soporte
                        </Link>

                        <div className="dropdown-divider" />
                        <Link to="/cambiar-contrasena" className="dropdown-item">
                            <i className="fas fa-edit mr-2" /> Cambiar Contraseña
                        </Link>

                        {/* <div className="dropdown-divider" />
                        <span className="dropdown-item" data-toggle="modal" data-target="#changepass">
                            <i className="fas fa-edit mr-2" /> Cambiar Contraseña
                        </span>
                        <div className="modal fade" id="changepass" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="changepass">Cambiar Contraseña</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">×</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <CambiarContraseña />
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal"><i className="fas fa-times"></i>Cerrar</button>
                                        <button type="button" className="btn btn-primary"><i className="fas fa-save"></i>Guardar</button>
                                    </div>
                                </div>
                            </div>
                        </div> */}


                        <div className="dropdown-divider" />
                        {/* <Link to="/cliente" className="dropdown-item">
                            
                            <i className="fas fa-sign-out-alt mr-2" /> Cerrar Sesión
                        </Link> */}
                          
                        <button 
                            className="btn btn-blank cerrar-sesion"
                            onClick={() => cerrarSesion() }
                        ><i className="fas fa-sign-out-alt mr-2" />Cerrar Sesión</button>
                        </div>
                    </li>
                    </ul>
                </nav>
            </Fragment>

        );
    // }
}

export default Header;