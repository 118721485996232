import React from 'react';

function NuevaCtaBancaria() {
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12">
                        <div className="card card-titulo">
                            <div className="card-body">
                                <span>
                                Reglas en Num.Cuenta <br></br>
                                Cheque Nominativo = 11 o 18 dígitos <br></br>
                                Transferencia Electronica de Fondos = 10, 16 o 18 dígitos <br></br>
                                Tarjeta de Credito & Tarjeta de Debito = 16 dígitos <br></br>
                                Monedero Electrónico = 10,11,15,16,18 dígitos <br></br>
                                Dinero Electrónico = 10 dígitos <br></br>
                                Tarjeta de Servicios = 15 o 16 dígitos <br></br>
                                </span>
                            </div>
                        </div>
                        <label>Nombre del banco: </label>
                        <div className="input-group">
                            <input className="form-control" type="text" placeholder="Nombre del banco" />
                        </div>
                        
                        <label>RFC del banco: </label>
                        <div className="input-group">
                            <input className="form-control" type="text" readOnly />
                        </div>
                        <label>No. de Cuenta: </label>
                        <div className="input-group">
                            <input className="form-control" type="text"  />
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="checkbox" id="inlineCheckbox1" defaultValue="option1" />
                            <label className="form-check-label" htmlFor="inlineCheckbox1">Activa</label>
                        </div>
                        </div>
                    </div>
                    </div>
    );
}

export default NuevaCtaBancaria;