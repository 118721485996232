import React, { Component } from "react";

class ComplementoComercio extends Component {
  render() {
    return (
      <div>
        <div className="container-fluid">
          <div className="col-md-12">
                  <h4>
                    Complemento Comercio Exterior
                  </h4>
                  <hr></hr>
                    <div className="row">
                      <div className="col-md-3 col-sm-6">
                        <label>Motivo de Traslado:</label>
                        <div className="input-group mb-3">
                          <select
                            className="custom-select"
                            id="inputGroupSelect01"
                          >
                            <option selected>
                              Seleccionar Motivo de Traslado
                            </option>
                            <option>
                              Envío de mercancias facturadas con anterioridad -
                              01
                            </option>
                            <option>
                              Reubicación de mercancias propias - 02
                            </option>
                            <option>
                              Envío de mercancías objeto de contrato de
                              consignación - 03
                            </option>
                            <option>
                              Envío de mercancías para posterior enajenación -
                              04
                            </option>
                            <option>
                              Envío de mercancías propiedad de terceros - 05
                            </option>
                            <option>Otros - 99</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-6">
                        <label>Tipo Operación:</label>
                        <div className="input-group mb-3">
                          <select
                            className="custom-select"
                            id="inputGroupSelect01"
                          >
                            <option selected>Exportación - 2</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-6">
                        <label>Clave De Pedimento:</label>
                        <div className="input-group mb-3">
                          <select
                            className="custom-select"
                            id="inputGroupSelect01"
                          >
                            <option selected>Seleccione</option>
                            <option>
                              IMPORTACION Y EXPORTACION DEFINITIVA - A1
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-3 col-xs-6">
                        <label>Certificado Origen:</label>
                        <div className="input-group mb-3">
                          <select
                            className="custom-select"
                            id="inputGroupSelect01"
                          >
                            <option selected>Seleccione</option>
                            <option>NO</option>
                            <option>SI</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-3 col-xs-6">
                        <div className="form-group">
                          <label>No. Certificado Origen:</label>
                          <input
                            type="email"
                            className="form-control"
                            id="inputEmail4"
                          />
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-6">
                        <div className="form-group">
                          <label>No. Exportador Confiable:</label>
                          <input
                            type="email"
                            className="form-control"
                            id="inputEmail4"
                          />
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-6">
                        <label>INCOTERM:</label>
                        <div className="input-group mb-3">
                          <select
                            className="custom-select"
                            id="inputGroupSelect01"
                          >
                            <option selected>Seleccionar INCOTERM</option>
                            <option>
                              CFR - COSTE Y FLETE (PUERTO DE DESTINO CONVENIDO)
                            </option>
                            <option>
                              CIF - COSTE, SEGURO Y FLETE (PUERTO DE DESTINO
                              CONVENIDO)
                            </option>
                            <option>
                              CPT - TRANSPORTE PAGADO HASTA (EL LUGAR DE DESTINO
                              CONVENIDO)
                            </option>
                            <option>
                              CIP - TRANSPORTE Y SEGURO PAGADOS HASTA (LUGAR DE
                              DESTINO CONVENIDO)
                            </option>
                            <option>
                              DAF - ENTREGADA EN FRONTRERA (LUGAR CONVENIDO)
                            </option>
                            <option>DAP - ENTREGADA EN LUGAR</option>
                            <option>DAT - ENTREGADA EN TERMINAL</option>
                            <option>
                              DES - ENTREGADA SOBRE BUQUE (PUERTO DE DESTINO
                              CONVENIDO)
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-6">
                        <label>Subdivisión:</label>
                        <div className="input-group mb-3">
                          <select
                            className="custom-select"
                            id="inputGroupSelect01"
                          >
                            <option selected>Seleccionar Subdivisión</option>
                            <option>NO</option>
                            <option>SI</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-6">
                        <div className="form-group">
                          <label>Tipo Cambio USD:</label>
                          <input
                            type="email"
                            className="form-control"
                            id="inputEmail4"
                          />
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-6">
                        <div className="form-group">
                          <label>TotalUSD</label>
                          <input
                            type="email"
                            className="form-control"
                            id="inputEmail4"
                          />
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-6">
                        <div className="form-group">
                          <label>Observaciones</label>
                          <textarea
                            className="form-control"
                            id="inputEmail4"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  <div className="col-md-12">
                      <div className="card my-5">
                          <h4 className="card-header card-titulo">
                            Domicilio Emisor |
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="inlineCheckbox1"
                                defaultValue="option1"
                                data-toggle="collapse" data-target="#emisor"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineCheckbox1"
                              >
                                Usar opcionales
                              </label>
                            </div>
                          </h4>
                        <div className="card-body" id="emisor">
                          <div className="col-md-12">
                            <div className="row">
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label>CodigoPostal*</label>
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="inputEmail4"
                                  />
                                </div>
                              </div>
                              <div className="col-md-4 col-sm-6">
                                <div className="form-group">
                                  <label>Estado*:</label>
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="inputEmail4"
                                  />
                                </div>
                              </div>
                              <div className="col-md-4 col-sm-6">
                                <div className="form-group">
                                  <label>Municipio*:</label>
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="inputEmail4"
                                  />
                                </div>
                              </div>
                              <div className="col-md-4 col-sm-6">
                                <div className="form-group">
                                  <label>Localidad*:</label>
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="inputEmail4"
                                  />
                                </div>
                              </div>
                              <div className="col-md-4 col-sm-6">
                                <div className="form-group">
                                  <label>Colonia*:</label>
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="inputEmail4"
                                  />
                                </div>
                              </div>
                              <div className="col-md-4 col-sm-6">
                                <div className="form-group">
                                  <label>Calle*:</label>
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="inputEmail4"
                                  />
                                </div>
                              </div>
                              <div className="col-md-4 col-sm-6">
                                <div className="form-group">
                                  <label>No. Ext:</label>
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="inputEmail4"
                                  />
                                </div>
                              </div>
                              <div className="col-md-4 col-sm-6">
                                <div className="form-group">
                                  <label>No. Int*:</label>
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="inputEmail4"
                                  />
                                </div>
                              </div>
                              <div className="col-md-4 col-sm-6">
                                <div className="form-group">
                                  <label>Referencia*:</label>
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="inputEmail4"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
                  <div className="col-md-12">
                      <div className="card my-5">
                          <h4 className="card-header card-titulo">Propietario</h4>
                        <div className="card-body">
                          <div className="col-md-12">
                            <div className="row">
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label>NumRegIdTrib*</label>
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="inputEmail4"
                                  />
                                </div>
                              </div>
                              <div className="col-md-4 col-sm-6">
                                <label>ResidenciaFiscal*:</label>
                                <div className="input-group mb-3">
                                  <select
                                    className="custom-select"
                                    id="inputGroupSelect01"
                                  >
                                    <option selected>MEX - México</option>
                                    <option value="1">ABW - Aruba</option>
                                    <option value="2">AFG - Afganistán</option>
                                    <option value="3">AGO - ANGOLA</option>
                                    <option value="3">AIA - Anguila</option>
                                    <option value="3">ALA - ISLAS ALAND</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-12 col-sm-12 my-3">
                                <button className="btn btn-primary float-right">
                                  <i className="fas fa-plus"></i>
                                  Agregar Propietario
                                </button>
                              </div>
                              <div className="table-responsive">
                                <table className="table">
                                  <thead className="thead-dark">
                                    <tr>
                                      <th scope="col">ClaveProServ</th>
                                      <th scope="col">Nombre</th>
                                      <th scope="col">Eliminar</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>Uso mixto</td>
                                      <td>Av. Miguel Alemán</td>
                                      <td>
                                        <button className="btn btn-danger" data-toggle="tooltip" data-placement="top" title="Eliminar">
                                          <i className="fas fa-trash-alt"></i>
                                        </button>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Uso mixto</td>
                                      <td>Av. Miguel Alemán</td>
                                      <td>
                                        <button className="btn btn-danger" data-toggle="tooltip" data-placement="top" title="Eliminar">
                                          <i className="fas fa-trash-alt"></i>
                                        </button>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Uso mixto</td>
                                      <td>Av. Miguel Alemán</td>
                                      <td>
                                        <button className="btn btn-danger" data-toggle="tooltip" data-placement="top" title="Eliminar">
                                          <i className="fas fa-trash-alt"></i>
                                        </button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          <div className="col-md-12">
                              <div className="card my-5">
                                  <h4 className="card-header card-titulo">
                                    Domicilio Receptor |
                                    <div className="form-check form-check-inline">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="inlineCheckbox1"
                                        defaultValue="option1"
                                        data-toggle="collapse" data-target="#receptor"
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="inlineCheckbox1"
                                      >
                                        Usar opcionales
                                      </label>
                                    </div>
                                  </h4>
                                <div className="card-body" id="receptor"> 
                                  <div className="col-md-12">
                                    <div className="row">
                                      <div className="col-md-12 col-sm-12">
                                        <label>País*:</label>
                                        <div className="input-group mb-3">
                                          <select
                                            className="custom-select"
                                            id="inputGroupSelect01"
                                          >
                                            <option selected>
                                              MEX - México
                                            </option>
                                            <option>ABW - Aruba</option>
                                            <option>AFG - Afganistan</option>
                                          </select>
                                        </div>
                                      </div>
                                      <div className="col-md-4 col-sm-6">
                                        <div className="form-group">
                                          <label>CodigoPostal*:</label>
                                          <input
                                            type="email"
                                            className="form-control"
                                            id="inputEmail4"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-4 col-sm-6">
                                        <div className="form-group">
                                          <label>Estado*:</label>
                                          <input
                                            type="email"
                                            className="form-control"
                                            id="inputEmail4"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-4 col-sm-6">
                                        <div className="form-group">
                                          <label>Municipio*:</label>
                                          <input
                                            type="email"
                                            className="form-control"
                                            id="inputEmail4"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-4 col-sm-6">
                                        <div className="form-group">
                                          <label>Localidad:</label>
                                          <input
                                            type="email"
                                            className="form-control"
                                            id="inputEmail4"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-4 col-sm-6">
                                        <div className="form-group">
                                          <label>Colonia*:</label>
                                          <input
                                            type="email"
                                            className="form-control"
                                            id="inputEmail4"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-4 col-sm-6">
                                        <div className="form-group">
                                          <label>Calle*:</label>
                                          <input
                                            type="email"
                                            className="form-control"
                                            id="inputEmail4"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-4 col-sm-6">
                                        <div className="form-group">
                                          <label>No Exterior:</label>
                                          <input
                                            type="email"
                                            className="form-control"
                                            id="inputEmail4"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-4 col-sm-6">
                                        <div className="form-group">
                                          <label>No. Interior:</label>
                                          <input
                                            type="email"
                                            className="form-control"
                                            id="inputEmail4"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-4 col-sm-6">
                                        <div className="form-group">
                                          <label>Referencia*:</label>
                                          <input
                                            type="email"
                                            className="form-control"
                                            id="inputEmail4"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-12 col-sm-12">
                                        <button className="btn btn-primary float-right">
                                          <i className="fas fa-plus"></i>
                                          Agregar Destinatario
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                          </div>
                          <div className="col-md-12">
                              <div className="card my-5">
                                  <h4 className="card-header card-titulo">Destinatario</h4>
                                <div className="card-body">
                                  <div className="col-md-12">
                                    <div className="row">
                                      <div className="col-md-4 col-sm-6">
                                        <div className="form-group">
                                          <label>NumRegIdTrib*:</label>
                                          <input
                                            type="email"
                                            className="form-control"
                                            id="inputEmail4"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-4 col-sm-6">
                                        <div className="form-group">
                                          <label>Nombre*:</label>
                                          <input
                                            type="email"
                                            className="form-control"
                                            id="inputEmail4"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                              <div className="card my-5">
                                  <h4 className="card-header card-titulo">
                                    Domicilio Destinatario |
                                    <div className="form-check form-check-inline">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="inlineCheckbox1"
                                        defaultValue="option1"
                                        data-toggle="collapse" data-target="#destinatario"
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="inlineCheckbox1"
                                      >
                                        Usar opcionales
                                      </label>
                                    </div>
                                  </h4>
                                <div className="card-body" id="destinatario">
                                  <div className="col-md-12">
                                    <div className="row">
                                      <div className="col-md-12 col-sm-12">
                                        <label>País*:</label>
                                        <div className="input-group mb-3">
                                          <select
                                            className="custom-select"
                                            id="inputGroupSelect01"
                                          >
                                            <option selected>
                                              MEX - México
                                            </option>
                                            <option>ABW - Aruba</option>
                                            <option>AFG - Afganistan</option>
                                          </select>
                                        </div>
                                      </div>
                                      <div className="col-md-4 col-sm-6">
                                        <div className="form-group">
                                          <label>CodigoPostal*:</label>
                                          <input
                                            type="email"
                                            className="form-control"
                                            id="inputEmail4"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-4 col-sm-6">
                                        <div className="form-group">
                                          <label>Estado*:</label>
                                          <input
                                            type="email"
                                            className="form-control"
                                            id="inputEmail4"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-4 col-sm-6">
                                        <div className="form-group">
                                          <label>Municipio*:</label>
                                          <input
                                            type="email"
                                            className="form-control"
                                            id="inputEmail4"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-4 col-sm-6">
                                        <div className="form-group">
                                          <label>Localidad:</label>
                                          <input
                                            type="email"
                                            className="form-control"
                                            id="inputEmail4"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-4 col-sm-6">
                                        <div className="form-group">
                                          <label>Colonia*:</label>
                                          <input
                                            type="email"
                                            className="form-control"
                                            id="inputEmail4"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-4 col-sm-6">
                                        <div className="form-group">
                                          <label>Calle*:</label>
                                          <input
                                            type="email"
                                            className="form-control"
                                            id="inputEmail4"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-4 col-sm-6">
                                        <div className="form-group">
                                          <label>No Exterior:</label>
                                          <input
                                            type="email"
                                            className="form-control"
                                            id="inputEmail4"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-4 col-sm-6">
                                        <div className="form-group">
                                          <label>No. Interior:</label>
                                          <input
                                            type="email"
                                            className="form-control"
                                            id="inputEmail4"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-4 col-sm-6">
                                        <div className="form-group">
                                          <label>Referencia*:</label>
                                          <input
                                            type="email"
                                            className="form-control"
                                            id="inputEmail4"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-12 col-sm-12">
                                        <button className="btn btn-primary float-right">
                                          <i className="fas fa-plus"></i>
                                          Agregar Destinatario
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                          </div>
                          <div className="table-responsive">
                            <table className="table">
                              <thead className="thead-dark">
                                <tr>
                                  <th scope="col">NumRegIdTrib</th>
                                  <th scope="col">Nombre</th>
                                  <th scope="col">País</th>
                                  <th scope="col">Código Postal</th>
                                  <th scope="col">Estado</th>
                                  <th scope="col">Eliminar</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>342840230</td>
                                  <td>Mariana Rodríguez</td>
                                  <td>México</td>
                                  <td>82130</td>
                                  <td>Sinaloa</td>
                                  <td>
                                    <button className="btn btn-danger" data-toggle="tooltip" data-placement="top" title="Eliminar">
                                      <i className="fas fa-trash-alt"></i>
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <td>342840230</td>
                                  <td>Mariana Rodríguez</td>
                                  <td>México</td>
                                  <td>82130</td>
                                  <td>Sinaloa</td>
                                  <td>
                                    <button className="btn btn-danger" data-toggle="tooltip" data-placement="top" title="Eliminar">
                                      <i className="fas fa-trash-alt"></i>
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="table-responsive">
                            <table className="table">
                              <thead className="thead-dark">
                                <tr>
                                  <th scope="col">NoIdentificación</th>
                                  <th scope="col">FracciónArancelaria</th>
                                  <th scope="col">CantidadAduana</th>
                                  <th scope="col">UnidadAduana</th>
                                  <th scope="col">ValorUnitarioAduana</th>
                                  <th scope="col">Valor Dolares</th>
                                  <th scope="col">Especificaciones</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>342</td>
                                  <td>Mariana Rodríguez</td>
                                  <td>10</td>
                                  <td>1</td>
                                  <td>10</td>
                                  <td>19</td>
                                  <td>Lorem Ipsum</td>
                                  <td>
                                    <button className="btn btn-danger" data-toggle="tooltip" data-placement="top" title="Eliminar">
                                      <i className="fas fa-trash-alt"></i>
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <td>342</td>
                                  <td>Mariana Rodríguez</td>
                                  <td>10</td>
                                  <td>1</td>
                                  <td>10</td>
                                  <td>19</td>
                                  <td>Lorem Ipsum</td>
                                  <td>
                                    <button className="btn btn-danger" data-toggle="tooltip" data-placement="top" title="Eliminar">
                                      <i className="fas fa-trash-alt"></i>
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ComplementoComercio;
