import { 
    FORMULARIO_CLIENTE, 
    OBTENER_CLIENTES,
    AGREGAR_CLIENTE,
    CLIENTE_ERROR,
    VALIDAR_FORMULARIO_CLIENTE,
    CLIENTE_ACTUAL,
    ELIMINAR_CLIENTE,
    ACTUALIZAR_ENVIADA
} from '../../types';


export default (state, action) => {
    switch(action.type) {
        case FORMULARIO_CLIENTE:
            return {
                ...state,
                formulario: true
            }
        case OBTENER_CLIENTES:
            return {
                ...state,
                clientes: action.payload.data,
                paginacion: (action.payload?.meta?.pagination === undefined) ? '' : action.payload.meta.pagination,
                cargando_clientes: false
            }
        case AGREGAR_CLIENTE:
            return {
                ...state,
                clientes: [...state.clientes, action.payload],
                formulario: false,
                errorformulario: false
            }
        case VALIDAR_FORMULARIO_CLIENTE:
            return {
                ...state, 
                errorformulario: true
            }
        case CLIENTE_ACTUAL:
            return {
                ...state,
                cliente: state.cliente.filter(cliente => cliente._id === action.payload )
            }
        case ELIMINAR_CLIENTE:
            return {
                ...state,
                clientes: state.clientes.filter(cliente => cliente._id !== action.payload ),
                cliente: null
            }
        case CLIENTE_ERROR:
            return {
                ...state,
                mensaje: action.payload
            }
        default:
            return state;
    }
}