import React, { Component } from 'react';

class ComplementoLeyendas extends Component {
    render() {
        return (
              <div className="container-fluid">
                <div className="col-md-12">
                              <h4>
                                Complemento Leyendas Fiscales
                              </h4>
                              <hr></hr>
                              <div className="col-md-12">
                                <div className="row">
                                  <div className="col-md-4 col-sm-6">
                                    <div className="form-group">
                                      <label>Disposición Fiscal: </label>
                                      <input
                                        type="email"
                                        className="form-control"
                                        id="inputEmail4"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-4 col-sm-6">
                                    <div className="form-group">
                                      <label>Norma:</label>
                                      <input
                                        type="email"
                                        className="form-control"
                                        id="inputEmail4"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-4 col-sm-12">
                                    <label>Texto leyenda</label>
                                    <div className="form-group">
                                      <textarea className="form-control"></textarea>
                                    </div>
                                  </div>
                                  <div className="col-md-12 col-sm-12 my-3">
                                    <button className="btn btn-primary float-right">
                                      <i className="fas fa-plus"></i>Agregar
                                      Leyenda
                                    </button>
                                  </div>
                                </div>
                              </div>
                            <div className="table-responsive">
                              <table className="table">
                                <thead className="thead-dark">
                                  <tr>
                                    <th scope="col">Disposición Fiscal</th>
                                    <th scope="col">Norma</th>
                                    <th scope="col">Texto Leyenda</th>
                                    <th scope="col"></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>ISR</td>
                                    <td>102</td>
                                    <td>lorem ipsum</td>
                                    <td>
                                      <button className="btn btn-danger" data-toggle="tooltip" data-placement="top" title="Eliminar">
                                        <i className="fas fa-trash-alt"></i>
                                      </button>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>ISR</td>
                                    <td>98</td>
                                    <td>lorem ipsum</td>
                                    <td>
                                      <button className="btn btn-danger" data-toggle="tooltip" data-placement="top" title="Eliminar">
                                        <i className="fas fa-trash-alt"></i>
                                      </button>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>ISR</td>
                                    <td>87</td>
                                    <td>lorem ipsum</td>
                                    <td>
                                      <button className="btn btn-danger" data-toggle="tooltip" data-placement="top" title="Eliminar">
                                        <i className="fas fa-trash-alt"></i>
                                      </button>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                        </div>
              </div>
        );
    }
}

export default ComplementoLeyendas;