import React from 'react';

function NuevoUsuario() {
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12">
                    <div className="form-group">
                        <label>Nombre de Usuario</label>
                        <input type="text" className="form-control" placeholder="Nombre de Usuario"></input>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="form-group">
                        <label>Correo Electrónico</label>
                        <input type="text" className="form-control" placeholder="Correo Electrónico"></input>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="form-group">
                        <label>Contraseña</label>
                        <input type="password" className="form-control" placeholder="Contraseña"></input>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="form-group">
                        <label>Confirmar contraseña</label>
                        <input type="password" className="form-control" placeholder="Confirmar Contraseña"></input>
                    </div>
                </div>
                <div className="col-md-12">
                    <h4>Permisos</h4>
                    <label>Opciones Principales</label>
                </div>
                   <div className="col-md-6">
                    <div className="form-check">
                            <input className="form-check-input" type="checkbox" id="inlineCheckbox1" defaultValue="option1" />
                            <label className="form-check-label" htmlFor="inlineCheckbox1">Crear Facturas</label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" id="inlineCheckbox1" defaultValue="option1" />
                            <label className="form-check-label" htmlFor="inlineCheckbox1">Crear Nóminas</label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" id="inlineCheckbox1" defaultValue="option1" />
                            <label className="form-check-label" htmlFor="inlineCheckbox1">Carrito de compras</label>
                        </div>
                   </div>
                    <div className="col-md-6">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" id="inlineCheckbox1" defaultValue="option1" />
                            <label className="form-check-label" htmlFor="inlineCheckbox1">Consultar</label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" id="inlineCheckbox1" defaultValue="option1" />
                            <label className="form-check-label" htmlFor="inlineCheckbox1">Perfil Fiscal</label>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <label>Menu lateral</label>
                    </div>
                        <div className="col-md-6">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="inlineCheckbox1" defaultValue="option1" />
                                <label className="form-check-label" htmlFor="inlineCheckbox1">Facturas</label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="inlineCheckbox1" defaultValue="option1" />
                                <label className="form-check-label" htmlFor="inlineCheckbox1">Cuentas</label>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="inlineCheckbox1" defaultValue="option1" />
                                <label className="form-check-label" htmlFor="inlineCheckbox1">Catálogos</label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="inlineCheckbox1" defaultValue="option1" />
                                <label className="form-check-label" htmlFor="inlineCheckbox1">Estadisticas</label>
                            </div>
                        </div>

                
            </div>
        </div>
    );
}

export default NuevoUsuario;