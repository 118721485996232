import React from 'react';

function CambiarContrasena() {
    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <h5 className="card-header card-titulo">Cambiar Contraseña</h5>
                        <div className="card-body">
                            <div className="form-group">
                                <label htmlFor="exampleInputPassword1">Contraseña actual*</label>
                                <input type="password" className="form-control" id="exampleInputPassword1" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleInputPassword1">Contraseña nueva*</label>
                                <input type="password" className="form-control" id="exampleInputPassword1" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleInputPassword1">Confirmar contraseña*</label>
                                <input type="password" className="form-control" id="exampleInputPassword1" />
                            </div>
                        </div>
                        <div className="container mb-4">
                        <label>Seguridad de la contraseña:</label>
                        <div className="progress">
                            <div className="progress-bar progress-bar-striped bg-danger" role="progressbar" style={{width: '100%'}} aria-valuenow={100} aria-valuemin={0} aria-valuemax={100} />
                        </div>
                        </div>

                        <div className="card card-titulo mx-2">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h5>Requisitos Minimos</h5>
                                        <span> Minimo 8 Caracteres <br/>
                                        Debe de contener 1 número <br/>
                                        Debe de contener una letra minuscula <br/>
                                        Debe de contener una letra mayúscula <br/>
                                        Debe de contene un caracter especial </span>
                                    </div>

                                    <div className="col-md-6">
                                        <h5>Requisitos Recomendados</h5>
                                        <span> Minimo de 12 caracteres </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card-footer">
                                <div className="float-right">
                                    <button type="button" className="btn btn-danger"><i className="fas fa-times"></i>Cancelar</button>
                                    <button type="button" className="btn btn-primary"><i className="fas fa-save"></i>Guardar</button>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CambiarContrasena;