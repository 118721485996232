import React, { Fragment, useContext } from 'react';
// import productoContext from '../context/facturas/productoContext';
import productoContext from '../../context/productos/productoContext';

import { Link } from "react-router-dom";

const Paginacion = ({parametros, pagina}) => {

    const productosContext = useContext(productoContext);
    // const { cargando_productos, mensaje, facturas, paginacion, obtenerProductos, pagina, setPagina } = productosContext;
    const { cargando_productos, mensaje, productos, paginacion, obtenerProductos } = productosContext;

    let paginas = null;
    let paginas2 =[]
    
    let contador = 1;
    // if((paginacion.current_page%10) === 0){
    //     console.log('Residuo cero')
    // }
    let inicio = paginacion.current_page/10;
    let inicial = parseInt(inicio) * 10;
    if(inicial===0){
        inicial++;
    }

    console.log('Numero inicial '+ inicial);
    
    for (let i = inicial; i <= paginacion.total_pages; i++ ){
        if(contador>10){
            break;
        }    
        paginas2.push(i);
        contador++;
    }
    paginas2.map(pa => {console.log('pagina ' + pa)});
    console.log(paginas2);
        // var paginas = paginas + 'uno' + i + ' ';   
        paginas =  (
            <Fragment>
                {paginas2.map( pagi =>{
                    let activo = null;
                    (paginacion.current_page === pagi) ? activo='active' : activo=''
                    console.log(`${pagi} Esta activo ${activo}`)
                    return (
                        <Fragment>  
                        <li class={`paginate_button page-item ${activo}`} key={ `paginas-${pagi}` }>
                            {/* <a href="#" onClick={(e) => handleClick(e, pagi)} aria-controls="example1" data-dt-idx={pagi} tabindex="0" class="page-link">{pagi}</a> */}
                            <Link to={`/productos/${pagi}`}  aria-controls="example1" data-dt-idx={pagi} tabindex="0" class="page-link">{pagi}</Link>
                        </li>
                        </Fragment>
                    )
                })}

            </Fragment>
            )                                        
    
            const handleClick = (e, pagina1) => {
                e.preventDefault();
                // setParametros({
                //     ...parametros,
                //     pagina
                // });
                // setPagina(pagina1);
                pagina = pagina1;
                console.log('LA PAGINA QUE ESTA EN EL ESTADO ' + pagina )
                console.log( parametros)
                console.log('LA PAGINA QUE SE DIO CLIC # '+pagina1);
                obtenerProductos(pagina, parametros);
            };
            let desactivarRetroceso = null;
            (paginacion.current_page <= 1) ? desactivarRetroceso='disabled' : desactivarRetroceso=''
            let desactivarSiguiente = null;
            (paginacion.current_page >= paginacion.total_pages) ? desactivarSiguiente='disabled' : desactivarSiguiente=''
    return ( 
        <ul class="pagination">
            <li class={`paginate_button page-item previous ${desactivarRetroceso}`} id="example1_previous" key={ 'paginas-pre-11111' } >
                {/* <a href="#" onClick={(e) => handleClick(e)} aria-controls="example1" data-dt-idx="0" tabindex="0" class="page-link"> <i class="fas fa-chevron-left"></i><i class="fas fa-chevron-left"></i> </a> */}
                <Link to={`/productos/1`}  aria-controls="example1" data-dt-idx="1" tabindex="0" class="page-link"><i class="fas fa-chevron-left"></i><i class="fas fa-chevron-left"></i></Link>
            </li>
            <li class={`paginate_button page-item previous ${desactivarRetroceso}`} id="example1_previous" key={ `paginas-pre-12222` }>
                {/* <a href="#" onClick={(e) => handleClick(e, paginacion.current_page-1)} aria-controls="example1" data-dt-idx="0" tabindex="0" class="page-link"> <i class="fas fa-chevron-left"></i> </a> */}
                <Link to={`/productos/${paginacion.current_page-1}`}  aria-controls="example1" data-dt-idx={paginacion.current_page-1} tabindex="0" class="page-link"><i class="fas fa-chevron-left"></i></Link>
            </li>
            {
            paginas
            }
            <li class={`paginate_button page-item next ${desactivarSiguiente}`} id="example1_next" key={ `paginas-next-13333` }>
                {/* <a href="#" onClick={(e) => handleClick(e, paginacion.current_page+1)} aria-controls="example1" data-dt-idx={paginacion.current_page+1} tabindex="0" class="page-link"> <i class="fas fa-chevron-right"></i> </a> */}
                <Link to={`/productos/${paginacion.current_page+1}`}  aria-controls="example1" data-dt-idx={paginacion.current_page+1} tabindex="0" class="page-link"><i class="fas fa-chevron-right"></i></Link>
            </li>
            <li class={`paginate_button page-item next ${desactivarSiguiente}`} id="example1_next" key={ `paginas-next-14444` }>
                {/* <a href="#" onClick={(e) => handleClick(e, paginacion.total_pages)} aria-controls="example1" data-dt-idx={paginacion.total_pages} tabindex="0" class="page-link"> <i class="fas fa-chevron-right"></i><i class="fas fa-chevron-right"></i> </a> */}
                <Link to={`/productos/${paginacion.total_pages}`}  aria-controls="example1" data-dt-idx={paginacion.total_pages} tabindex="0" class="page-link"><i class="fas fa-chevron-right"></i><i class="fas fa-chevron-right"></i></Link>
            </li>
            
        </ul>   
     );
}
 
export default Paginacion;