import React from 'react';

function ConsultarTickets() {
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <h3>Consulta Tickets</h3>
                        <div className="card-body">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>No. de Reporte</label>
                                    <input className="form-control"></input>
                                    <button type="button" className="btn btn-primary"><i className="fas fa-search"></i></button>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Estatus</label>
                                    <select className="form-control">
                                        <option>Sin selección</option>
                                        <option>Pendiente</option>
                                        <option>En Proceso</option>
                                        <option>Cerrado</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-4">
                                    <label>Desde</label>
                                    <div className="input-group">
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    id="datepicker"
                                                    placeholder="Seleccione fecha"
                                                  ></input>
                                                  <div className="input-group-append" data-toggle="tooltip" data-placement="top" title="Seleccione fecha">
                                                    <button
                                                      className="input-group-text"
                                                      id="datepicker"
                                                    >
                                                      <i
                                                        className="fas fa-calendar-alt"
                                                        data-toggle="datepicker"
                                                      ></i>
                                                    </button>
                                                  </div>
                                                </div>
                            </div>
                            <div className="col-md-4">
                                    <label>Hasta</label>
                                    <div className="input-group">
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    id="datepicker"
                                                    placeholder="Seleccione fecha"
                                                  ></input>
                                                  <div className="input-group-append" data-toggle="tooltip" data-placement="top" title="Seleccione fecha">
                                                    <button
                                                      className="input-group-text"
                                                      id="datepicker"
                                                    >
                                                      <i
                                                        className="fas fa-calendar-alt"
                                                        data-toggle="datepicker"
                                                      ></i>
                                                    </button>
                                                  </div>
                                                </div>
                            </div>
                        </div>
                        <div className="card-footer">

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ConsultarTickets;