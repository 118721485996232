import React, { useState, useEffect, Fragment, useContext } from 'react';
// import { Document } from 'react-pdf'
import { useRouteMatch, useParams } from "react-router-dom";

import clienteAxios from '../../config/axios';

import axios from 'axios';

import AuthContext from '../../context/autenticacion/authContext';


const FacturaVerPdf = () => {
    const authContext = useContext(AuthContext);
    const { usuario  } = authContext;
// function FacturaVerPdf({idFactura}) {
    const [cargando, setCargando] = useState(false);
    // Modal enviar email
    const [correos, setCorreos] = useState();
    // const [idFactura, setIdFactura] = useState();
    const [enviando, setEnviado] = useState(1);
    const [paramFormulario, setParamFormulario] = useState({
        // emails: correos,
        emailAsunto: usuario?.sucursal_email_asunto,
        emailMensaje1: usuario?.sucursal_email_mensaje,
    });


    const [cfdiBase64, setCfdiBase64] = useState();
    let { idFactura, uuid, emails } = useParams();

    // let idFactura1 = match.params.idFactura;

    let idFactura1 = idFactura;

    console.log('ID FACTURA');
        console.log(idFactura1);
    
    useEffect(() => {
        
       obtenerPdf(idFactura1);
        
        
       
    }, [idFactura1]);

    async function obtenerPdf(idFactura1){
        console.log('CARGO USE EFFECT id fact');
        console.log(idFactura1);

        try {
            const resultado = await clienteAxios.get(`/v3/facturas-pdf/${idFactura1}`);
            console.log('PDF BASE64');
            console.log(resultado.data.data);
            console.log(resultado.data.code);

            const cfdiBase64_2 = resultado.data.data;
            setCfdiBase64(cfdiBase64_2);
            // return cfdiBase64_2;
            setCargando(true);
            
        } catch (error) {
            const alerta = {
                msg: 'Hubo un error',
                categoria: 'alerta-error'
            }
            console.log(alerta);
            
            // dispatch({
            //     type: FACTURA_ERROR,
            //     payload: alerta
            // })
        } 

    }

    const descargarXml = async (idFact, uuid) => {
        console.log('Descargar xml '+idFact);
        const token ="eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyIiwianRpIjoiZTBmNDBlZTFiYWEzNThkMzYwMDU2MjJiYjU3MTVjNDliNGQ2ODk4YWE1ZmJmZWMyY2EzMmEyNzIyNWJmNDBlZDBlZWQ0ZDIwYzcxOGE1MDkiLCJpYXQiOjE1ODU0NjExNTgsIm5iZiI6MTU4NTQ2MTE1OCwiZXhwIjoxNTg2NzUzNTU4LCJzdWIiOiI2Iiwic2NvcGVzIjpbXX0.q8mYHZrV7rwzQkbqIMQv7pyrXUynnyuZ8s0vjkboXwyJKHOd50qX_Mw7ZMxcHkMCzaxTOCLHp-2ibyHbmMFYoO8EZE-S8gWkHvK7Doyw3oUeROAdgmyKYsuYx1WbWucDhNzIuImrtCbo3v_OEVJWxkXvs-UN3OBTkTpo_qpisFPQjw9SzYKEyjEpkpEcy7hFznjlWJ2y9OOIiAux2QeazkdcxPenmLBuMdYK1oPP2eIVU-6-ACVU2Ni5jTg6hxFhGaHjvp3n2hb5eJBhzJ0DDtgn_pH15SB44r2Fxrnv1WmYExiWpEFiNZRcI7A76gnwtOxzWBw9l_ny-2IMyM5qbNOP9j_AaClgil7xTr4woGxwl3DHT1JkVUp6P6tR6-av9r_1vqJggg6-U3AcenIVBh-LJiKBe4Fu4naCr1yiYOuFk5maN7l4UrrdAHyPRP-oeIGxH7JnJYQtvAKnYkt8tZUavUB1-u9_p3Jq5kZIFXde8Fq56YOZrI5ge_Nzz-1zwqy71sORklu2gKQ2ynBYGTfQvP39r6npd47uT4sKxXxWxBo9v_HSV2Y1vA25a6iS1KPBj7o9Bdh4zmpyVAZMKLkRTjimMAJIFdIMeRWOeqV0iUdydaejAWHXdG4Jw3BoTW_Wkr2bUUYz20VMCPV5WPiCemjgmwGTvToHJnwf470";
        // await axios({
        await clienteAxios({
            //"https://app.facturandoenlinea.mx/v3/facturas-xml/eyJpdiI6IjZiQWc3WVBLREsrdFNhRzBCM3p0UGc9PSIsInZhbHVlIjoiRW1Fd3BncEpObVM5UlI0aDZUQnFLdz09IiwibWFjIjoiMzNjMjk2OTk3ZWExNTA5ZGE3NTY5NGI5NzE1MWE4YTg4ZTA3NDQ1ZDc4NGE1NWJiMjMyMGE1ZWRmNjhlNTUyMCJ9"
            url: `https://app.facturandoenlinea.mx/v3/facturas-xml/${idFact}?forzar_descarga=1`,
            // headers: { Authorization: `Bearer ${token}` },
            method: 'GET',
            responseType: 'blob', // important
          }).then((response) => {
            if (!window.navigator.msSaveOrOpenBlob){
              // BLOB NAVIGATOR
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', `${uuid}.xml`);
              document.body.appendChild(link);
              link.click();
            }else{
              // BLOB FOR EXPLORER 11
              const url = window.navigator.msSaveOrOpenBlob(new Blob([response.data]),`${uuid}.xml`);
            }
          });
    }

    const descargarPdf = async (idFact, uuid) => {
        console.log('Descargar pdf '+idFact);
        const token ="eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyIiwianRpIjoiZTBmNDBlZTFiYWEzNThkMzYwMDU2MjJiYjU3MTVjNDliNGQ2ODk4YWE1ZmJmZWMyY2EzMmEyNzIyNWJmNDBlZDBlZWQ0ZDIwYzcxOGE1MDkiLCJpYXQiOjE1ODU0NjExNTgsIm5iZiI6MTU4NTQ2MTE1OCwiZXhwIjoxNTg2NzUzNTU4LCJzdWIiOiI2Iiwic2NvcGVzIjpbXX0.q8mYHZrV7rwzQkbqIMQv7pyrXUynnyuZ8s0vjkboXwyJKHOd50qX_Mw7ZMxcHkMCzaxTOCLHp-2ibyHbmMFYoO8EZE-S8gWkHvK7Doyw3oUeROAdgmyKYsuYx1WbWucDhNzIuImrtCbo3v_OEVJWxkXvs-UN3OBTkTpo_qpisFPQjw9SzYKEyjEpkpEcy7hFznjlWJ2y9OOIiAux2QeazkdcxPenmLBuMdYK1oPP2eIVU-6-ACVU2Ni5jTg6hxFhGaHjvp3n2hb5eJBhzJ0DDtgn_pH15SB44r2Fxrnv1WmYExiWpEFiNZRcI7A76gnwtOxzWBw9l_ny-2IMyM5qbNOP9j_AaClgil7xTr4woGxwl3DHT1JkVUp6P6tR6-av9r_1vqJggg6-U3AcenIVBh-LJiKBe4Fu4naCr1yiYOuFk5maN7l4UrrdAHyPRP-oeIGxH7JnJYQtvAKnYkt8tZUavUB1-u9_p3Jq5kZIFXde8Fq56YOZrI5ge_Nzz-1zwqy71sORklu2gKQ2ynBYGTfQvP39r6npd47uT4sKxXxWxBo9v_HSV2Y1vA25a6iS1KPBj7o9Bdh4zmpyVAZMKLkRTjimMAJIFdIMeRWOeqV0iUdydaejAWHXdG4Jw3BoTW_Wkr2bUUYz20VMCPV5WPiCemjgmwGTvToHJnwf470";
        // await axios({
        await clienteAxios({
            //"https://app.facturandoenlinea.mx/v3/facturas-xml/eyJpdiI6IjZiQWc3WVBLREsrdFNhRzBCM3p0UGc9PSIsInZhbHVlIjoiRW1Fd3BncEpObVM5UlI0aDZUQnFLdz09IiwibWFjIjoiMzNjMjk2OTk3ZWExNTA5ZGE3NTY5NGI5NzE1MWE4YTg4ZTA3NDQ1ZDc4NGE1NWJiMjMyMGE1ZWRmNjhlNTUyMCJ9"
            url: `https://app.facturandoenlinea.mx/v3/facturas-pdf/${idFact}?forzar_descarga=1`,
            // headers: { Authorization: `Bearer ${token}` },
            method: 'GET',
            responseType: 'blob', // important
          }).then((response) => {
            if (!window.navigator.msSaveOrOpenBlob){
              // BLOB NAVIGATOR
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', `${uuid}.pdf`);
              document.body.appendChild(link);
              link.click();
            }else{
              // BLOB FOR EXPLORER 11
              const url = window.navigator.msSaveOrOpenBlob(new Blob([response.data]),`${uuid}.pdf`);
            }
          });
    }

    //Abrir modal enviar factura
    const abrirModal = ( e, correo, email_asunto, email_mensaje ) => {
        e.preventDefault();
        setEnviado(1);
        setCorreos(correo);
        // setIdFactura(fact.factura_identificador);
        setParamFormulario({
            emailAsunto: email_asunto,
            emailMensaje1: email_mensaje,
        });
        console.log('Correos en variable correo '+ correo);
        console.log('Correos en state '+ correos);
        console.log('Identificador factura en state '+ correos);
        // $(ReactDOM.findDOMNode(this)).modal('show');
        // $(this.getDOMNode()).on('hidden.bs.modal', this.props.handleHideModal);
    }

    //Enviar factura
    async function enviarFactura(){
        setEnviado(2);
        console.log('Se enviara a los correo '+correos);
        console.log('Asunto '+emailAsunto);
        console.log('Mensaje '+emailMensaje1);
        try {
            const resultado = await clienteAxios.post(`/v3/facturas-reenviar/${idFactura}`,  {
                factura_emails: correos,
                factura_email_cuerpo: emailMensaje1,
                factura_email_asunto: emailAsunto
            });
            console.log(resultado.data);
            setEnviado(3);
            // cambiarEnviada(idFactura);
  
        } catch (error) { 
            setEnviado(4);
            console.log('Hubo un error '+error);
        }


    }

    const handleChangeFormSendEmail = e => {
        setParamFormulario({
            ...paramFormulario,
            [e.target.name] : e.target.value
        });
        console.log(paramFormulario);
    }

    const handleChangeFormSendEmailCorreos = e => {
        // actualizar el state
        setCorreos(e.target.value);
        
    }

        const {emailAsunto, emailMensaje1} = paramFormulario;

        let formEnviar = (enviando === 1) ?
             (
                <Fragment>
                <div className="form-group">
                                            <label>Correo</label>
                                            <input 
                                                type="email" 
                                                className="form-control"
                                                name="emails"
                                                value={correos}
                                                onChange={handleChangeFormSendEmailCorreos}
                                            ></input>
                                        </div>
                                        <div className="form-group">
                                            <label>Asunto</label>
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                name="emailAsunto"
                                                value={emailAsunto}
                                                onChange={handleChangeFormSendEmail}
                                            ></input>
                                        </div>
                                        <div className="form-group">
                                            <label>Mensaje</label>
                                            <textarea 
                                                className="form-control"
                                                name="emailMensaje1"
                                                value={emailMensaje1}
                                                onChange={handleChangeFormSendEmail}
                                            >
                                            </textarea>
                                        </div>
                                        </Fragment>
            )
        : (enviando === 2) ?
             'Enviando factura por favor espere'
        : (enviando === 3) ?
            `La factura fue enviada a los correo ${correos}`
        :  'No se pudo enviar la factura por correo'
    
    return (
        <div className="container-fluid">
           <div className="row">
               <div className="col-md-12">
                   <div className="card">
                       <div className="card-header">
                           <h3 className="card-title">CFDI</h3>
                           <div className="btn-group float-right">
                            <button className="btn btn-primary" onClick={() => descargarPdf(idFactura, uuid)}><i className="fas fa-file-download"></i>Descargar PDF</button>
                            <button className="btn btn-primary" onClick={() => descargarXml(idFactura, uuid)}><i className="fas fa-file-download"></i>Descargar XML</button>
                            <button className="btn btn-primary" onClick={(e) => abrirModal(e, emails, paramFormulario.email_asunto, paramFormulario.email_mensaje) } data-toggle="modal" data-target=".addcorreos" ><i className="fas fa-envelope"></i>Enviar por correo</button>
                            <button className="btn btn-danger"><i className="fas fa-times"></i>Cancelar</button>
                           </div>
                       </div>
                        <div className="card-body cardpdf">
                            {/* <iframe src="3AD3FDDA-174C-4BBB-B324-2CDF90602F09.pdf"></iframe> */}
                            {
                                // (cfdiBase64?.length>0) ? 
                                (cargando) ?
                                    (<iframe src={`data:application/pdf;base64,${cfdiBase64}`}></iframe>)
                                :
                                'Cargando PDF por favor espere.....'

                            }
                            
                            
                            {/* <Document file="3AD3FDDA-174C-4BBB-B324-2CDF90602F09.pdf" /> */}
                        </div>
                   </div>
               </div>
           </div>
           {/* inicia modal enviar correo */}
           <div className="modal fade addcorreos" tabIndex={-1} role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalCenterTitle">Enviar Correo</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    {formEnviar}
                          
                                </div>
                            </div>
                        </div>
                          
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" data-dismiss="modal"><i className="fas fa-times"></i>Cancelar</button>
                            <button onClick={enviarFactura} type="button" className="btn btn-primary"><i className="fas fa-envelope"></i>Enviar</button>
                        </div>
                    </div>
                </div>
                </div>
                {/* fin modal enviar correo */}
        </div>
    );
}

export default FacturaVerPdf;