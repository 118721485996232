import React, { Component } from "react";

class ComplementoAerolineas extends Component {
  render() {
    return (
        <div className="container-fluid">
          <div className="col-md-12">
                  <h4>
                    Complemento Aerolíneas
                  </h4>
                  <hr></hr>
                    <div className="row">
                      <div className="col-md-3 col-sm-6">
                        <div className="form-group">
                          <label>TUA* </label>
                          <input
                            type="tua"
                            className="form-control"
                            id="inputEmail4"
                          />
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-6">
                        <div className="form-group">
                          <label>Total Cargos*</label>
                          <input
                            type="email"
                            className="form-control"
                            id="inputEmail4"
                          />
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-6">
                        <label>Código Cargo</label>
                        <div className="input-group mb-3">
                          <select
                            className="custom-select"
                            id="inputGroupSelect01"
                          >
                            <option selected>Seleccione</option>
                            <option>NW</option>
                            <option>BA</option>
                            <option>CO</option>
                            <option>AC</option>
                            <option>AY</option>
                            <option>B1</option>
                            <option>B2</option>
                            <option>B3</option>
                            <option>B4</option>
                            <option>B5</option>
                            <option>B6</option>
                            <option>B7</option>
                            <option>B8</option>
                            <option>BAG</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-6">
                        <div className="form-group">
                          <label>Importe*</label>
                          <input
                            type="importe"
                            className="form-control"
                            id="inputEmail4"
                          />
                        </div>
                      </div>
                      <div className="col-md-12 col-sm-12">
                        <button className="btn btn-primary float-right">
                          <i className="fas fa-plus"></i>Agregar Cargos
                        </button>
                      </div>
                    </div>
                <div className="table-responsive mt-3">
                  <table className="table">
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col"></th>
                        <th scope="col">Código Cargo</th>
                        <th scope="col">Importe</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th></th>
                        <td>B5</td>
                        <td>150</td>
                        <td>
                          <button className="btn btn-danger" data-toggle="tooltip" data-placement="top" title="Eliminar">
                            <i className="fas fa-trash-alt"></i>
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <th></th>
                        <td>AY</td>
                        <td>100</td>
                        <td>
                          <button className="btn btn-danger" data-toggle="tooltip" data-placement="top" title="Eliminar">
                            <i className="fas fa-trash-alt"></i>
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <th></th>
                        <td>B1</td>
                        <td>200</td>
                        <td>
                          <button className="btn btn-danger" data-toggle="tooltip" data-placement="top" title="Eliminar">
                            <i className="fas fa-trash-alt"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
          </div>
        </div>
    );
  }
}

export default ComplementoAerolineas;
