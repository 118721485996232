import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import clienteAxios from "../../config/axios";


const Producto = ({producto}) => {
  return (
    <tr>
      <td></td>
      <td>{producto.producto_clave}</td>
      <td>{producto.producto_descripcion}</td>
      <td>{producto.producto_unidad}</td>
      <td>{producto.producto_precio_venta}</td>
      <td>
        <Link to={`/editar-producto/${producto.producto_identificador}`}>
          <img src="/dist/img/consultar.gif" width="20" />
        </Link>
      </td>
      <td>
        <img src="/dist/img/anular.gif" width="20" />
      </td>
    </tr>
  );
};

export default Producto;
