import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Alumno extends Component {
    render() {
        return (
            <div>
               <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">Busqueda de Alumno</h3>
                      </div>
                      <div className="col-md-12">
                                <div id="example1_filter" className="dataTables_filter">
                                    <label>
                                        <input type="search" className="form-control form-control-sm" placeholder="Buscar" aria-controls="example1" />
                                    </label>
                                </div>
                            </div>
                      <div className="card-body">
                        <table id="example1" className="table table-bordered table-striped">
                          <thead>
                            <tr>
                              <td></td>
                              <th>Nombre</th>
                              <th>CURP</th>
                              <th>Nivel Educativo</th>
                              <th>Clave Trabajo</th>
                              <th>RFC Pago</th>
                              <th></th>
                              <th></th>
                              <th></th>
                          
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td></td>
                              <td>EMPRESA DE TRANSPORTES SA DE CV</td>
                              <td>XEXX010101000
                              </td>
                              <td>354564564</td>
                              <td> 4</td>
                              <td>SINALOA</td>
                              <td><Link to="editar-alumno"><img src="dist/img/consultar.gif" width="20"/></Link></td>
                                <td></td>
                              <td></td>
                            </tr>
                            <tr>
                              <td></td>
                              <td>EMPRESA DE TRANSPORTES SA DE CV</td>
                              <td>XEXX010101000</td>
                              <td>3453435335</td>
                              <td>ventas@facturandoenlinea.mx</td>
                              <td>SINALOA</td>
                              <td><Link to="editar-alumno"><img src="dist/img/consultar.gif" width="20"/></Link></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                              <td></td>
                              <td>EMPRESA DE TRANSPORTES SA DE CV</td>
                              <td>XEXX010101000</td>
                              <td>3453435335</td>
                              <td>ventas@facturandoenlinea.mx</td>
                              <td>SINALOA</td>
                              <td><Link to="editar-alumno"><img src="dist/img/consultar.gif" width="20"/></Link></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                              <td></td>
                              <td>EMPRESA DE TRANSPORTES SA DE CV</td>
                              <td>XEXX010101000</td>
                              <td>5456465565</td>
                              <td>ventas@facturandoenlinea.mx</td>
                              <td>SINALOA</td>
                              <td><Link to="editar-alumno"><img src="dist/img/consultar.gif" width="20"/></Link></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                              <td></td>
                              <td>EMPRESA DE TRANSPORTES SA DE CV</td>
                              <td>XEXX010101000</td>
                              <td>5456465565</td>
                              <td>ventas@facturandoenlinea.mx</td>
                              <td>SINALOA</td>
                              <td><Link to="editar-alumno"><img src="dist/img/consultar.gif" width="20"/></Link></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                              <td></td>
                              <td>EMPRESA DE TRANSPORTES SA DE CV</td>
                              <td>XEXX010101000</td>
                              <td>4564564654</td>
                              <td>ventas@facturandoenlinea.mx</td>
                              <td>SINALOA</td>
                              <td><Link to="editar-alumno"><img src="dist/img/consultar.gif" width="20"/></Link></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                              <td></td>
                              <td>EMPRESA DE TRANSPORTES SA DE CV</td>
                              <td>XEXX010101000</td>
                              <td>654653215</td>
                              <td>ventas@facturandoenlinea.mx</td>
                              <td>SINALOA</td>
                              <td><Link to="editar-alumno"><img src="dist/img/consultar.gif" width="20"/></Link></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                              <td></td>
                              <td>EMPRESA DE TRANSPORTES SA DE CV</td>
                              <td>XEXX010101000</td>
                              <td>635453215</td>
                              <td>ventas@facturandoenlinea.mx</td>
                              <td>SINALOA</td>
                              <td><Link to="editar-alumno"><img src="dist/img/consultar.gif" width="20"/></Link></td>
                                <td></td>
                         
                            </tr>
                            
                            <tr>
                              <td></td>
                              <td>EMPRESA DE TRANSPORTES SA DE CV</td>
                              <td>XEXX010101000</td>
                              <td>989854512</td>
                              <td>ventas@facturandoenlinea.mx</td>
                              <td>SINALOA</td>
                              <td><Link to="editar-alumno"><img src="dist/img/consultar.gif" width="20"/></Link></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                              <td></td>
                              <td>EMPRESA DE TRANSPORTES SA DE CV</td>
                              <td>XEXX010101000</td>
                              <td>654562345</td>
                              <td>ventas@facturandoenlinea.mx</td>
                              <td>SINALOA</td>
                              <td><Link to="editar-alumno"><img src="dist/img/consultar.gif" width="20"/></Link></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                              <td></td>
                              <td>EMPRESA DE TRANSPORTES SA DE CV</td>
                              <td>XEXX010101000</td>
                              <td>54254554</td>
                              <td>ventas@facturandoenlinea.mx</td>
                              <td>SINALOA</td>
                              <td><Link to="editar-alumno"><img src="dist/img/consultar.gif" width="20"/></Link></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                              <td></td>
                              <td>EMPRESA DE TRANSPORTES SA DE CV</td>
                              <td>XEXX010101000</td>
                              <td>6458646564</td>
                              <td>ventas@facturandoenlinea.mx</td>                              
                              <td>SINALOA</td>
                              <td><Link to="editar-alumno"><img src="dist/img/consultar.gif" width="20"/></Link></td>
                              <td></td>
                         
                            </tr>
                          
                            <tr>
                              <td></td>
                              <td>EMPRESA DE TRANSPORTES SA DE CV</td>
                              <td>XEXX010101000</td>
                              <td>4536465464</td>
                              <td>ventas@facturandoenlinea.mx</td>
                              <td>SINALOA</td>
                              <td><Link to="editar-alumno"><img src="dist/img/consultar.gif" width="20"/></Link></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                              <td></td>
                              <td>EMPRESA DE TRANSPORTES SA DE CV</td>
                              <td>XEXX010101000</td>
                              <td>453465454</td>
                              <td>ventas@facturandoenlinea.mx</td>
                              <td>SINALOA</td>
                              <td><Link to="editar-alumno"><img src="dist/img/consultar.gif" width="20"/></Link></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                              <td></td>
                              <td>EMPRESA DE TRANSPORTES SA DE CV</td>
                              <td>XEXX010101000</td>
                              <td>356455564</td>
                              <td>ventas@facturandoenlinea.mx</td>
                              <td>SINALOA</td>
                              <td><Link to="editar-alumno"><img src="dist/img/consultar.gif" width="20"/></Link></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                              <td></td>
                              <td>EMPRESA DE TRANSPORTES SA DE CV</td>
                              <td>XEXX010101000</td>
                              <td>456465466</td>
                              <td>ventas@facturandoenlinea.mx</td>
                              <td>SINALOA</td>
                              <td><Link to="editar-alumno"><img src="dist/img/consultar.gif" width="20"/></Link></td>
                                <td></td>
                                <td></td>
                         
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="float-right">
                  <div class="dataTables_paginate paging_simple_numbers" id="example1_paginate">
                      <ul class="pagination">
                          <li class="paginate_button page-item previous disabled" id="example1_previous">
                              <a href="#" aria-controls="example1" data-dt-idx="0" tabindex="0" class="page-link"> <i class="fas fa-chevron-left"></i> </a></li><li class="paginate_button page-item active"><a href="#" aria-controls="example1" data-dt-idx="1" tabindex="0" class="page-link">1</a>
                            </li>
                            <li class="paginate_button page-item ">
                                <a href="#" aria-controls="example1" data-dt-idx="2" tabindex="0" class="page-link">2</a>
                            </li>
                            <li class="paginate_button page-item ">
                                <a href="#" aria-controls="example1" data-dt-idx="3" tabindex="0" class="page-link">3</a>
                                </li>
                            <li class="paginate_button page-item ">
                                <a href="#" aria-controls="example1" data-dt-idx="4" tabindex="0" class="page-link">4</a>
                            </li>
                            <li class="paginate_button page-item ">
                                <a href="#" aria-controls="example1" data-dt-idx="5" tabindex="0" class="page-link">5</a>
                            </li>
                            <li class="paginate_button page-item ">
                                <a href="#" aria-controls="example1" data-dt-idx="6" tabindex="0" class="page-link">6</a>
                            </li>
                            <li class="paginate_button page-item next" id="example1_next">
                                <a href="#" aria-controls="example1" data-dt-idx="7" tabindex="0" class="page-link"> <i class="fas fa-chevron-right"></i> </a>
                            </li>
                        </ul>
                    </div>
              </div>
            </div>
        );
    }
}

export default Alumno;