import React, { Component } from 'react';

class SubirCertificado extends Component {
    render() {
        return (
            <div>
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-12">
                            <div className="card card-primary">
                                <div className="card-header">
                                <h3 className="card-title">Consulta de datos Empresa</h3>
                                </div>
                                <div className="card-body">
                                    <div class="col-md-12">
                                    <p>Recuerde que si ya coloco un sello la carga de un nuevo certificado reemplazara el anterior.</p>
                                    </div>
                                    <div className="form-group">
                                    <label htmlFor="exampleInputFile">Certificado *.cer</label>
                                    <div className="input-group">
                                        <div className="custom-file">
                                        <input type="file" className="custom-file-input" id="exampleInputFile" />
                                        <label className="custom-file-label" htmlFor="exampleInputFile">Seleccionar archivo</label>
                                        </div>
                                        <div className="input-group-append">
                                        <span className="input-group-text" id>Subir</span>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="form-group">
                                    <label htmlFor="exampleInputFile">key *.key</label>
                                    <div className="input-group">
                                        <div className="custom-file">
                                        <input type="file" className="custom-file-input" id="exampleInputFile"/>
                                        <label className="custom-file-label" htmlFor="exampleInputFile">Seleccionar archivo</label>
                                        </div>
                                        <div className="input-group-append">
                                        <span className="input-group-text" id>Subir</span>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <button type="submit" className="btn btn-primary">Paso 2 de 3 click por favor</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default SubirCertificado;