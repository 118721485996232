import React, { Component } from 'react';

class ComplementoVehiculoUsado extends Component {
    render() {
        return (
                <div className="container-fluid">
                  <div className="row">
                  <h4>
                                Complemento Vehiculo Usado
                              </h4>
                              <hr></hr>
                              <div className="col-md-12">
                                <div className="row">
                                  <div className="col-md-2 col-sm-4 col-xs-6">
                                    <div className="form-group">
                                      <label>Monto Adquisición*:</label>
                                      <input
                                        type="email"
                                        className="form-control"
                                        id="inputEmail4"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-2 col-sm-4 col-xs-6">
                                    <div className="form-group">
                                      <label>Monto Enajenación:</label>
                                      <input
                                        type="email"
                                        className="form-control"
                                        id="inputEmail4"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-2 col-sm-4 col-xs-6">
                                    <div className="form-group">
                                      <label>Clave Vehicular</label>
                                      <input
                                        type="email"
                                        className="form-control"
                                        id="inputEmail4"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-3 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                      <label>Marca*:</label>
                                      <input
                                        type="email"
                                        className="form-control"
                                        id="inputEmail4"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-3 col-sm-6">
                                    <div className="form-group">
                                      <label>Tipo*:</label>
                                      <input
                                        type="email"
                                        className="form-control"
                                        id="inputEmail4"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-3 col-sm-6">
                                    <div className="form-group">
                                      <label>Modelo*:</label>
                                      <input
                                        type="email"
                                        className="form-control"
                                        id="inputEmail4"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-3 col-sm-6">
                                    <div className="form-group">
                                      <label>No. Motor:</label>
                                      <input
                                        type="email"
                                        className="form-control"
                                        id="inputEmail4"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-3 col-sm-6">
                                    <div className="form-group">
                                      <label>No. Serie:</label>
                                      <input
                                        type="email"
                                        className="form-control"
                                        id="inputEmail4"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-3 col-sm-6">
                                    <div className="form-group">
                                      <label>NIV</label>
                                      <input
                                        type="email"
                                        className="form-control"
                                        id="inputEmail4"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-3 col-sm-6">
                                    <div className="form-group">
                                      <label>Valor*</label>
                                      <input
                                        type="email"
                                        className="form-control"
                                        id="inputEmail4"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-3 col-sm-6">
                                    <div className="form-group">
                                      <label>Número:*</label>
                                      <input
                                        type="email"
                                        className="form-control"
                                        id="inputEmail4"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-3 col-sm-6">
                                    <label htmlFor="unidad">
                                      Seleccione fecha:
                                    </label>
                                    <div className="input-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="datepicker"
                                        placeholder="Seleccione fecha"
                                      ></input>
                                      <div className="input-group-append" data-toggle="tooltip" data-placement="top" title="Seleccione fecha">
                                        <button
                                          className="input-group-text"
                                          id="datepicker"
                                        >
                                          <i
                                            className="fas fa-calendar-alt"
                                            data-toggle="datepicker"
                                          ></i>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-3 col-sm-6">
                                    <div className="form-group">
                                      <label>Aduana:*</label>
                                      <input
                                        type="email"
                                        className="form-control"
                                        id="inputEmail4"
                                      />
                                    </div>
                                  </div>
                                </div>
                  </div>             
                </div>
                </div>
        );
    }
}

export default ComplementoVehiculoUsado;