import React, { Component } from "react";

class ComplementoConceptoTerceros extends Component {
  render() {
    return (
      <div>
        <div className="container-fluid">
          <div className="col-md-12">
                  <h4>
                    Complemento Concepto Por Cuenta de Terceros
                  </h4>
                  <hr></hr>
                  <div className="col-md-12">
                          <h4>Datos del Tercero</h4>
                          <div className="col-md-12">
                            <div className="row">
                              <div className="col-md-6 col-sm-6">
                                <div className="form-group">
                                  <label>Nombre:</label>
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="inputEmail4"
                                  />
                                </div>
                              </div>
                              <div className="col-md-6 col-sm-6">
                                <div className="form-group">
                                  <label>RFC*:</label>
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="inputEmail4"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-12">
                                <div className="card my-4">
                                    <h4 className="card-header card-titulo">
                                      Información Fiscal Tercero
                                    </h4>
                                  <div className="card-body">
                                    <div className="row">
                                      <div className="col-md-4 col-sm-6">
                                        <div className="form-group">
                                          <label>Calle*:</label>
                                          <input
                                            type="email"
                                            className="form-control"
                                            id="inputEmail4"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-4 col-sm-6">
                                        <div className="form-group">
                                          <label>No. Exterior:</label>
                                          <input
                                            type="email"
                                            className="form-control"
                                            id="inputEmail4"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-4 col-sm-6">
                                        <div className="form-group">
                                          <label>No. Interior:</label>
                                          <input
                                            type="email"
                                            className="form-control"
                                            id="inputEmail4"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-4 col-sm-6">
                                        <div className="form-group">
                                          <label>Colonia:</label>
                                          <input
                                            type="email"
                                            className="form-control"
                                            id="inputEmail4"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-4 col-sm-6">
                                        <div className="form-group">
                                          <label>Localidad:</label>
                                          <input
                                            type="email"
                                            className="form-control"
                                            id="inputEmail4"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-4 col-sm-6">
                                        <div className="form-group">
                                          <label>Referencia:</label>
                                          <input
                                            type="email"
                                            className="form-control"
                                            id="inputEmail4"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-4 col-sm-6">
                                        <div className="form-group">
                                          <label>Municipio*:</label>
                                          <input
                                            type="email"
                                            className="form-control"
                                            id="inputEmail4"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-4 col-sm-6">
                                        <div className="form-group">
                                          <label>Estado*:</label>
                                          <input
                                            type="email"
                                            className="form-control"
                                            id="inputEmail4"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-4 col-sm-6">
                                        <div className="form-group">
                                          <label>Pais*:</label>
                                          <input
                                            type="email"
                                            className="form-control"
                                            id="inputEmail4"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-4 col-sm-6">
                                        <div className="form-group">
                                          <label>Código Postal:</label>
                                          <input
                                            type="email"
                                            className="form-control"
                                            id="inputEmail4"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="card my-4">
                                    <h4 className="card-titulo card-header">Impuestos</h4>
                                  <div className="card-body">
                                    <div className="col-md-12">
                                      <div className="row">
                                      <div className="col-md-3 col-sm-6">
                                          <div className="form-group">
                                            <label>Tipo Impuesto: </label>
                                            <select className="form-control">
                                              <option>Trasladado</option>
                                              <option>Retenido</option>
                                            </select>
                                          </div>
                                        </div>
                                        <div className="col-md-3 col-sm-6">
                                          <div className="form-group">
                                            <label>Impuesto: </label>
                                            <select className="form-control">
                                              <option>IVA</option>
                                              <option>IEPS</option>
                                            </select>
                                          </div>
                                        </div>
                                        <div className="col-md-3 col-sm-6">
                                          <label>Tasa </label>
                                          <div className="from-group">
                                            <input className="form-control" />
                                          </div>
                                        </div>
                                        <div className="col-md-3 col-sm-6">
                                          <label>Importe </label>
                                          <div className="from-group">
                                            <input className="form-control" />
                                          </div>
                                        </div>
                                        <div className="col-md-12 col-sm-12">
                                          <button className="btn btn-primary float-right">
                                            <i className="fas fa-plus"></i>
                                            Agregar Impuesto
                                          </button>
                                        </div>
                                        <div className="table-responsive">
                                          <table className="table">
                                            <thead className="thead-dark">
                                              <tr>
                                                <th scope="col">Tipo Impuesto</th>
                                                <th scope="col">Impuesto</th>
                                                <th scope="col">Tasa</th>
                                                <th scope="col">Importe</th>
                                                <th scope="col"></th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr>
                                                <td>Trasladado</td>
                                                <td>IVA</td>
                                                <td>0.10</td>
                                                <td>1</td>
                                                <td>
                                                  <button className="btn btn-danger">
                                                    <i className="fas fa-trash-alt"></i>
                                                  </button>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>Trasladado</td>
                                                <td>IVA</td>
                                                <td>0.10</td>
                                                <td>1</td>
                                                <td>
                                                  <button className="btn btn-danger">
                                                    <i className="fas fa-trash-alt"></i>
                                                  </button>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>Trasladado</td>
                                                <td>IVA</td>
                                                <td>0.10</td>
                                                <td>1</td>
                                                <td>
                                                  <button className="btn btn-danger">
                                                    <i className="fas fa-trash-alt"></i>
                                                  </button>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                                  <h4 className="text-center mt-3">
                                    <div className="form-check form-check-inline">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        data-toggle="collapse" data-target="#collapse"
                                        id="inlineCheckbox1"
                                        defaultValue="option1"
                                      />
                                    </div>
                                    Información extra del Complemento
                                  </h4>
                                  <hr></hr>
                                  <div className="row" id="collapse">
                                  <div className="col-md-6">
                                      <div className="card my-4">
                                          <h4 className="card-header card-titulo">
                                            Información Aduanera:
                                          </h4>
                                        <div className="card-body">
                                          <div className="col-md-12">
                                            <div className="row">
                                              <div className="col-md-4 col-sm-6">
                                                <label>Número </label>
                                                <div className="from-group">
                                                  <input className="form-control" />
                                                </div>
                                              </div>
                                              <div className="col-md-4 col-sm-6">
                                                <label htmlFor="unidad">
                                                  Fecha:
                                                </label>
                                                <div className="input-group">
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    id="datepicker"
                                                    placeholder="Seleccione fecha"
                                                  ></input>
                                                  <div className="input-group-append" data-toggle="tooltip" data-placement="top" title="Seleccione fecha">
                                                    <button
                                                      className="input-group-text"
                                                      id="datepicker"
                                                    >
                                                      <i
                                                        className="fas fa-calendar-alt"
                                                        data-toggle="datepicker"
                                                      ></i>
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="col-md-4 col-sm-6">
                                                <label>Aduana</label>
                                                <div className="from-group">
                                                  <input className="form-control" />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                  </div>
                                  <div className="col-md-6">
                                      <div className="card my-4">
                                          <h4 className="card-header card-titulo">
                                            Cuenta Predial:
                                          </h4>
                                        <div className="card-body">
                                          <div className="col-md-12">
                                            <div className="row">
                                              <div className="col-md-12">
                                                <label>Cuenta Predial </label>
                                                <div className="from-group">
                                                  <input className="form-control" />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                  </div>
                                  </div>
                                  <div className="row">
                                  <div className="col-md-12">
                                      <div className="card my-4">
                                          <h4 className="card-titulo card-header">
                                            Elemento Parte para integrar la
                                            totalidad del Concepto
                                          </h4>
                                        <div className="card-body">
                                          <div className="col-md-12">
                                            <div className="row">
                                              <div className="col-md-3 col-sm-6">
                                                <label>Cantidad </label>
                                                <div className="from-group">
                                                  <input className="form-control" />
                                                </div>
                                              </div>
                                              <div className="col-md-3 col-sm-6">
                                                <label>Unidad </label>
                                                <div className="from-group">
                                                  <input className="form-control" />
                                                </div>
                                              </div>
                                              <div className="col-md-3 col-sm-6">
                                                <label>
                                                  No. Identificación
                                                </label>
                                                <div className="from-group">
                                                  <input className="form-control" />
                                                </div>
                                              </div>
                                              <div className="col-md-3 col-sm-6">
                                                <label>Descripción* </label>
                                                <div className="from-group">
                                                  <input className="form-control" />
                                                </div>
                                              </div>
                                              <div className="col-md-4 col-sm-6">
                                                <label>Valor Unitario </label>
                                                <div className="from-group">
                                                  <input className="form-control" />
                                                </div>
                                              </div>
                                              <div className="col-md-4 col-sm-6">
                                                <label>Importe </label>
                                                <div className="from-group">
                                                  <input className="form-control" />
                                                </div>
                                              </div>
                                              <div className="col-md-4 col-sm-6 mt-4">
                                                <button className="btn btn-primary float-right">
                                                  <i className="fas fa-plus"></i>
                                                  Agregar Parte
                                                </button>
                                              </div>
                                              <div className="table-responsive mt-4">
                                                <table className="table">
                                                  <thead className="thead-dark">
                                                    <tr>
                                                      <th scope="col">
                                                        Cantidad
                                                      </th>
                                                      <th scope="col">
                                                        Unidad
                                                      </th>
                                                      <th scope="col">
                                                        No. Identificacion
                                                      </th>
                                                      <th scope="col">
                                                        Descripción
                                                      </th>
                                                      <th scope="col">
                                                        Valor Unitario
                                                      </th>
                                                      <th scope="col">
                                                        Importe
                                                      </th>
                                                      <th scope="col"></th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    <tr>
                                                      <td>1</td>
                                                      <td>1</td>
                                                      <td>235</td>
                                                      <td>Maletin</td>
                                                      <td>1</td>
                                                      <td>20</td>
                                                      <td>
                                                        <button className="btn btn-danger">
                                                          <i className="fas fa-trash-alt"></i>
                                                        </button>
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>1</td>
                                                      <td>1</td>
                                                      <td>235</td>
                                                      <td>Maletin</td>
                                                      <td>1</td>
                                                      <td>20</td>
                                                      <td>
                                                        <button className="btn btn-danger">
                                                          <i className="fas fa-trash-alt"></i>
                                                        </button>
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>1</td>
                                                      <td>1</td>
                                                      <td>235</td>
                                                      <td>Maletin</td>
                                                      <td>1</td>
                                                      <td>20</td>
                                                      <td>
                                                        <button className="btn btn-danger">
                                                          <i className="fas fa-trash-alt"></i>
                                                        </button>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                  </div>
                                  </div>
                          </div>
                        </div>
            </div>
          </div>
        </div>
    );
  }
}

export default ComplementoConceptoTerceros;
