import React, { Component } from "react";

class ComplementoIedu extends Component {
  render() {
    return (
        <div className="container-fluid">
          <div className="row">
            <h4>Complemento IEDU</h4>
            <hr></hr>
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label>Nombre Alumno*:</label>
                    <input
                      type="email"
                      className="form-control"
                      id="inputEmail4"
                    />
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <label>CURP:</label>
                    <input
                      type="email"
                      className="form-control"
                      id="inputEmail4"
                    />
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <label>Nivel Educativo*:</label>
                  <div className="input-group mb-3">
                    <select className="custom-select" id="inputGroupSelect01">
                      <option selected>Seleccionar Nivel Educativo</option>
                      <option value="1">Preescolar</option>
                      <option value="2">Primaria</option>
                      <option value="3">Secundaria</option>
                      <option value="3">Profesional Técnico</option>
                      <option value="3">Bachillerato o su equivalente</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <label>Clave Trabajo*:</label>
                    <input
                      type="email"
                      className="form-control"
                      id="inputEmail4"
                    />
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="form-group">
                    <label>RFC Pago:</label>
                    <input
                      type="email"
                      className="form-control"
                      id="inputEmail4"
                    />
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="inlineCheckbox1"
                      defaultValue="option1"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="inlineCheckbox1"
                    >
                      Habilitar complemento
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

export default ComplementoIedu;
