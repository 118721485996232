import { 
    FORMULARIO_PRODUCTO, 
    OBTENER_PRODUCTOS,
    AGREGAR_PRODUCTO,
    PRODUCTO_ERROR,
    VALIDAR_FORMULARIO_PRODUCTO,
    PRODUCTO_ACTUAL,
    ELIMINAR_PRODUCTO,
    ACTUALIZAR_ENVIADA
} from '../../types';


export default (state, action) => {
    switch(action.type) {
        case FORMULARIO_PRODUCTO:
            return {
                ...state,
                formulario: true
            }
        case OBTENER_PRODUCTOS:
            return {
                ...state,
                productos: action.payload.data,
                paginacion: (action.payload?.meta?.pagination === undefined) ? '' : action.payload.meta.pagination,
                cargando_productos: false
            }
        case AGREGAR_PRODUCTO:
            return {
                ...state,
                productos: [...state.productos, action.payload],
                formulario: false,
                errorformulario: false
            }
        case VALIDAR_FORMULARIO_PRODUCTO:
            return {
                ...state, 
                errorformulario: true
            }
        case PRODUCTO_ACTUAL:
            return {
                ...state,
                producto: state.producto.filter(producto => producto._id === action.payload )
            }
        case ELIMINAR_PRODUCTO:
            return {
                ...state,
                productos: state.productos.filter(producto => producto._id !== action.payload ),
                producto: null
            }
        case PRODUCTO_ERROR:
            return {
                ...state,
                mensaje: action.payload
            }
        // case ACTUALIZAR_ENVIADA:
        //     // console.log("IMPRIMIMOS PAYLOAD")
        //     // console.log(action.payload);
        //     // console.log("FIN IMPRIMIMOS PAYLOAD")
        //     return {
        //         ...state,
        //         // facturas: action.payload
        //     }
        default:
            return state;
    }
}