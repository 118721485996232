import React from 'react';

function ComplementoDetallista() {
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12">
                    <h4>Complemento Detallista</h4>
                    <hr></hr>
                    <div className="card">
                        <h5 className="card-header card-titulo">Tipo de documento</h5>
                        <div className="card-body">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-6 col-sm-12">
                                    <label>Documento </label>
                                        <div className="input-group mb-3">
                                            <select
                                                className="custom-select"
                                                id="inputGroupSelect01"
                                            >
                                                <option selected>INVOICE - Factura</option>
                                                <option>DEBIT_NOTE - Nota de Débito</option>
                                                <option>CREDIT_NOTE - Nota de Crédito</option>
                                                <option>LEASE_RECEIPT - Recibo de Arrendamiento</option>
                                                <option>HONORARY RECEIPT - Recibo de Honorarios</option>
                                                <option>PARTIAL_INVOICE - Factura Parcial</option>
                                                <option>TRANSPORT_DOCUMENT - Documento Transportista</option>
                                                <option>AUTO_INVOICE - Auto Factura</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                    <label>Estado </label>
                                        <div className="input-group mb-3">
                                            <select
                                                className="custom-select"
                                                id="inputGroupSelect01"
                                            >
                                                <option selected>ORIGINAL - Original</option>
                                                <option>COPY - Copia</option>
                                                <option>REEMPLAZA - remplaza</option>
                                                <option>DELETE - Eliminar</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                    <div className="col-md-6">
                        <div className="card">
                            <h5 className="card-header card-titulo">Números de Ordenes de Compra o Pedidos (Requerida)</h5>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6 col-sm-6">
                                        <label>Fecha</label>
                                            <div className="input-group">
                                                <input
                                                type="text"
                                                className="form-control"
                                                id="datepicker"
                                                placeholder="Seleccione fecha"
                                                ></input>
                                                <div className="input-group-append" data-toggle="tooltip" data-placement="top" title="Seleccione fecha">
                                                <button
                                                    className="input-group-text"
                                                    id="datepicker"
                                                >
                                                    <i
                                                    className="fas fa-calendar-alt"
                                                    data-toggle="datepicker"
                                                    ></i>
                                                </button>
                                                </div>
                                            </div>
                                        </div>
                                    <div className="col-md-6 col-sm-6">
                                        <label>
                                            Número de Orden
                                        </label>
                                        <div className="form-group">
                                            <input className="form-control" />
                                        </div>
                                    </div>
                                <div className="col-md-12">
                                    <div className="float-right">
                                        <button type="button" className="btn btn-primary"><i className="fas fa-plus"></i>Agregar</button>
                                    </div>
                                </div>
                                <div className="col-md-12 mt-2">
                                    <div className="table-responsive">
                                        <table className="table table-bordered">
                                            <thead className="thead-dark">
                                                <tr>
                                                <th>Orden de la Compra</th>
                                                <th></th>
                                                <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                            <td>1</td>
                                                <td>
                                                    <button type="button" className="btn btn-primary"><i className="fas fa-edit"></i>Editar</button>
                                                </td>
                                                <td>
                                                    <button type="button" className="btn btn-primary"><i className="fas fa-trash"></i>Eliminar</button>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>                     
                            </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-md-6">
                        <div className="card">
                            <h5 className="card-header card-titulo">Información Adicional (Requerida)</h5>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6 col-sm-6">
                                        <div className="form-group">
                                        <label>Tipo: </label>
                                        <select className="form-control">
                                            <option>AAE - Cuentra Predial</option>
                                            <option>CK - Número de cheque</option>
                                            <option>ACE - Número de documento (Reemisión)</option>
                                            <option>ATZ - Número de Aprobación</option>
                                            <option>DQ - Folio de recibo de mercancias</option>
                                            <option>IV - Número de Factura</option>
                                            <option>ON - Número de pedido(Comprador)</option>
                                            <option>AWR - Número de documento que se reemplaza</option>

                                        </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6">
                                        <label>
                                            Descripción
                                        </label>
                                        <div className="form-group">
                                            <input className="form-control" />
                                        </div>
                                    </div>
                                <div className="col-md-12">
                                <div className="float-right">
                                    <button type="button" className="btn btn-primary"><i className="fas fa-plus"></i>Agregar</button>
                                </div>
                                </div>
                                <div className="col-md-12 mt-2">
                                    <div className="table-responsive">
                                        <table className="table table-bordered">
                                            <thead className="thead-dark">
                                                <tr>
                                                <th>Tipo</th>
                                                <th></th>
                                                <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                            <td>1</td>
                                                <td>
                                                    <button type="button" className="btn btn-primary"><i className="fas fa-edit"></i>Editar</button>
                                                </td>
                                                <td>
                                                    <button type="button" className="btn btn-primary"><i className="fas fa-trash"></i>Eliminar</button>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>                     
                            </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="card">
                            <h5 className="card-header card-titulo">Información del Comprador (Requerida)</h5>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <label>Número Global de Localización (GLN)*</label>
                                            <div className="form-group">
                                                <input className="form-control"></input>                 
                                            </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                        <label>Tipo: </label>
                                        <select className="form-control">
                                            <option>SELLER_ASSIGNED_IDENTIFIER_FOR_A_PARTY - Número interno del proveedor</option>
                                            <option>IEPS_REFERENCE - Referencia Asignada IEPS</option>
                                        </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label>Descripción</label>
                                            <div className="form-group">
                                                <input className="form-control"></input>                 
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="card">
                            <h5 className="card-header card-titulo">Recepción de Mercancias(Opcional)</h5>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6 col-sm-6">
                                        <label>Fecha</label>
                                            <div className="input-group">
                                                <input
                                                type="text"
                                                className="form-control"
                                                id="datepicker"
                                                placeholder="Seleccione fecha"
                                                ></input>
                                                <div className="input-group-append" data-toggle="tooltip" data-placement="top" title="Seleccione fecha">
                                                <button
                                                    className="input-group-text"
                                                    id="datepicker"
                                                >
                                                    <i
                                                    className="fas fa-calendar-alt"
                                                    data-toggle="datepicker"
                                                    ></i>
                                                </button>
                                                </div>
                                            </div>
                                        </div>
                                    <div className="col-md-6 col-sm-6">
                                        <label>
                                            Número de Folio
                                        </label>
                                        <div className="form-group">
                                            <input className="form-control" />
                                        </div>
                                    </div>
                                <div className="col-md-12">
                                <div className="float-right">
                                    <button type="button" className="btn btn-primary"><i className="fas fa-plus"></i>Agregar</button>
                                </div>
                                </div>
                                <div className="col-md-12 mt-2">
                                    <div className="table-responsive">
                                        <table className="table table-bordered">
                                            <thead className="thead-dark">
                                                <tr>
                                                <th>Número de ContraRecibo</th>
                                                <th></th>
                                                <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                            <td>1</td>
                                                <td>
                                                    <button type="button" className="btn btn-primary"><i className="fas fa-edit"></i>Editar</button>
                                                </td>
                                                <td>
                                                    <button type="button" className="btn btn-primary"><i className="fas fa-trash"></i>Eliminar</button>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>                     
                            </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <h5 className="card-header card-titulo">Instrucciones especiales (Opcional)</h5>
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                        <label>Tipo: </label>
                                        <select className="form-control">
                                            <option>AAB - Condiciones de pago</option>
                                            <option>DUT - Información de impuestos (Pedimientos)</option>
                                            <option>PUR - Información de compras</option>
                                            <option>ZZZ - Importe con letra</option>
                                        </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label>Descripción</label>
                                            <div className="form-group">
                                                <input className="form-control"></input>                 
                                            </div>
                                    </div>
                                <div className="col-md-12">
                                <div className="float-right">
                                    <button type="button" className="btn btn-primary"><i className="fas fa-plus"></i>Agregar</button>
                                </div>
                                </div>
                                <div className="col-md-12 mt-2">
                                    <div className="table-responsive">
                                        <table className="table table-bordered">
                                            <thead className="thead-dark">
                                                <tr>
                                                <th>Tipo</th>
                                                <th></th>
                                                <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                            <td>1</td>
                                                <td>
                                                    <button type="button" className="btn btn-primary"><i className="fas fa-edit"></i>Editar</button>
                                                </td>
                                                <td>
                                                    <button type="button" className="btn btn-primary"><i className="fas fa-trash"></i>Eliminar</button>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div> 
                            </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="card">
                            <h5 className="card-header card-titulo">Enviar A (Opcional)</h5>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <label>Número Global de Localización (GLN)</label>
                                        <div className="form-group">
                                            <input className="form-control"></input>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label>Destinatario</label>
                                        <div className="form-group">
                                            <input className="form-control"></input>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label>Calle</label>
                                        <div className="form-group">
                                            <input className="form-control"></input>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label>Ciudad</label>
                                        <div className="form-group">
                                            <input className="form-control"></input>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label>Código Postal</label>
                                        <div className="form-group">
                                            <input className="form-control"></input>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="card">
                            <h5 className="card-header card-titulo">Datos del Emisor de la Factura (Opcional)</h5>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <label>Número Global de Localización (GLN)</label>
                                        <div className="form-group">
                                            <input className="form-control"></input>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label>Tipo: </label>
                                        <div className="form-group">
                                            <select className="form-control">
                                                <option>Seleccione</option>
                                                <option>VA - Identificación tributaria</option>
                                                <option>IA - Número interno del proveedor</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label>Destinatario</label>
                                        <div className="form-group">
                                            <input className="form-control"></input>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label>Descripción</label>
                                        <div className="form-group">
                                            <input className="form-control"></input>
                                        </div>
                                    </div>
                                   <div className="col-md-6">
                                        <label>Emisor</label>
                                        <div className="form-group">
                                            <input className="form-control"></input>
                                        </div>
                                   </div>
                                   <div className="col-md-6">
                                        <label>Calle</label>
                                        <div className="form-group">
                                            <input className="form-control"></input>
                                        </div>
                                   </div>
                                   <div className="col-md-6">
                                        <label>Ciudad</label>
                                        <div className="form-group">
                                            <input className="form-control"></input>
                                        </div>
                                   </div>
                                   <div className="col-md-6">
                                        <label>Código Postal</label>
                                        <div className="form-group">
                                            <input className="form-control"></input>
                                        </div>
                                   </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <h5 className="card-header card-titulo">Divisas y Tipo de Cambio (Opcional)</h5>
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                        <label>Moneda: </label>
                                        <select className="form-control">
                                            <option>MXN - Pesos Mexicanos</option>
                                            <option>XEU - Euro</option>
                                            <option>USD - Dólar</option>
                                        </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                        <label>Tipo: </label>
                                        <select className="form-control">
                                            <option>BILLING_CURRENCY - Divisa Facturación</option>
                                            <option>PRICE_CURRENCY - Divisa de Precio</option>
                                            <option>PAYMENT_CURRENCY - Divisa de Pago</option>
                                        </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label>Tipo de cambio</label>
                                            <div className="form-group">
                                                <input className="form-control"></input>                 
                                            </div>
                                    </div>
                                <div className="col-md-12">
                                <div className="float-right">
                                    <button type="button" className="btn btn-primary"><i className="fas fa-plus"></i>Agregar</button>
                                </div>
                                </div>
                                <div className="col-md-12 mt-2">
                                    <div className="table-responsive">
                                        <table className="table table-bordered">
                                            <thead className="thead-dark">
                                                <tr>
                                                <th>Moneda</th>
                                                <th>Tipo</th>
                                                <th>Tasa de Cambio</th>
                                                <th></th>
                                                <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                            <td>1</td>
                                            <td>1</td>
                                            <td>1</td>
                                                <td>
                                                    <button type="button" className="btn btn-primary"><i className="fas fa-edit"></i>Editar</button>
                                                </td>
                                                <td>
                                                    <button type="button" className="btn btn-primary"><i className="fas fa-trash"></i>Eliminar</button>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div> 
                            </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="card">
                            <h5 className="card-header card-titulo">Ubicación de la Aduana(Opcional)</h5>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <label>Número Global de Localización(GLN)</label>
                                            <div className="form-group">
                                                <input className="form-control"></input>                 
                                            </div>
                                    </div>
                                    <div className="col-md-12">
                                    <div className="float-right">
                                        <button type="button" className="btn btn-primary"><i className="fas fa-plus"></i>Agregar</button>
                                    </div>
                                    </div>

                                    <div className="col-md-12 mt-2">
                                        <div className="table-responsive">
                                            <table className="table table-bordered">
                                                <thead className="thead-dark">
                                                    <tr>
                                                    <th>Número Global de Localización(GLN)</th>
                                                    <th></th>
                                                    <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                <td>1</td>
                                                    <td>
                                                        <button type="button" className="btn btn-primary"><i className="fas fa-edit"></i>Editar</button>
                                                    </td>
                                                    <td>
                                                        <button type="button" className="btn btn-primary"><i className="fas fa-trash"></i>Eliminar</button>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="card">
                            <h5 className="card-header card-titulo">Información de Cargos o Descuentos(Opcional)</h5>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <label>Cargo o descuento</label>
                                        <div className="form-group">
                                        <select className="form-control">
                                                <option>ALLOWANCE_GLOBAL - Descuento Global</option>
                                                <option>CHARGE_GLOBAL - Cargo Global</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label>Imputación del descuento o cargo</label>
                                        <div className="form-group">
                                        <select className="form-control">
                                                <option>ALLOWANCE_GLOBAL - Descuento Global</option>
                                                <option>CHARGE_GLOBAL - Cargo Global</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label>Secuencia de cálculo</label>
                                        <div className="form-group">
                                            <input className="form-control"></input>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label>Tipo de descuento o cargo</label>
                                        <div className="form-group">
                                        <select className="form-control">
                                                <option>AA - Abono por Publicidad</option>
                                                <option>ABZ - Rebaja o descuento misceláneo</option>
                                                <option>ADS - Pedido de un palet completo</option>
                                                <option>ADT - Recolección</option>
                                                <option>ADO - Descuento Lógistico</option>
                                                <option>AJ - Ajustes</option>
                                                <option>CAC - Descuento/efectivo</option>
                                                <option>COD - Pago contra entrega</option>
                                                <option>DA - Descuento defectuoso</option>
                                                <option>DI - Descuento</option>
                                                <option>EAA - Descuento por pronta compra de los clientes</option>
                                                <option>FA - Descuento por flete</option>
                                                <option>FC - Cargo por flete</option>
                                                <option>FG - Descuento por mercancias gratuitas</option>
                                                <option>FI - Cargo financiero</option>
                                                <option>HD - Cargo por manejo de mercancia</option>
                                                <option>QP - Cantidad de descuento</option>
                                                <option>PAD - Descuento promocional</option>
                                                <option>PI - Descuento por recolección</option>
                                                <option>QD - Cantidad de descuento</option>
                                                <option>RAA - Rebaja</option>
                                                <option>SAB - Descuentos especiales</option>
                                                <option>TAE - Descuento por Camioneta</option>
                                                <option>TD - Descuento comercial</option>
                                                <option>TS - Impuesto estatal</option>
                                                <option>TX - Impuestos</option>
                                                <option>TZ - Descuento temporal</option>
                                                <option>UM - Descuento de mercancía invendible</option>
                                                <option>VAB - Descuento por volumen</option>
                                                <option>ZZZ - Mutuamente</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label>Porcentaje</label>
                                        <div className="form-group">
                                            <input className="form-control"></input>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="float-right">
                                            <button type="button" className="btn btn-primary"><i className="fas fa-plus"></i>Agregar</button>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-2">
                                        <div className="table-responsive">
                                            <table className="table table-bordered">
                                                <thead className="thead-dark">
                                                    <tr>
                                                    <th>Cargo o descuento</th>
                                                    <th>Imputación</th>
                                                    <th>Secuencia de cálculo</th>
                                                    <th>Tipo</th>
                                                    <th>Porcentaje</th>
                                                    <th></th>
                                                    <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                <td>1</td>
                                                <td>1</td>
                                                <td>1</td>
                                                <td>1</td>
                                                <td>1</td>
                                                    <td>
                                                        <button type="button" className="btn btn-primary"><i className="fas fa-edit"></i>Editar</button>
                                                    </td>
                                                    <td>
                                                        <button type="button" className="btn btn-primary"><i className="fas fa-trash"></i>Eliminar</button>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="card">
                            <h5 className="card-header card-titulo">Linea de detalle (Opcional)</h5>
                            <div className="card-body">
                                <div className="col-md-12">
                                    <div className="card">
                                        <h5 className="card-header card-titulo">Producto</h5>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <label>Producto</label>
                                                    <div className="form-group">
                                                        <select className="form-control">
                                                            <option>Seleccione</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <label>Unidad*</label>
                                                    <div className="form-group">
                                                    <input className="form-control"></input>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <label>Cantidad Facturada*</label>
                                                    <div className="form-group">
                                                        <input className="form-control"></input>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <label>Precio Bruto*</label>
                                                    <div className="form-group">
                                                        <input className="form-control"></input>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <label>Precio Neto*</label>
                                                    <div className="form-group">
                                                        <input className="form-control"></input>
                                                    </div>
                                                </div>
                                            <div className="col-md-4">
                                                <label>Tipo de Referencia</label>
                                                    <div className="form-group">
                                                        <input className="form-control"></input>
                                                    </div>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="card">
                                        <h5 className="card-header card-titulo">Detalle</h5>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <label>Tipo de línea de detalle</label>
                                                    <div className="form-group">
                                                        <input className="form-control"></input>
                                                    </div>
                                                </div>
                                               <div className="col-md-4">
                                                <label>Número de secuencia</label>
                                                    <div className="form-group">
                                                        <input className="form-control"></input>
                                                    </div>
                                               </div>
                                                <div className="col-md-4">
                                                <label>Código EAN*</label>
                                                <div className="form-group">
                                                    <input className="form-control"></input>
                                                </div>
                                                </div>
                                                <div className="col-md-4">
                                                <label>Código del Comprador</label>
                                                <div className="form-group">
                                                    <input className="form-control"></input>
                                                </div>
                                                </div>
                                                <div className="col-md-4">
                                                <label>Código del Vendedor</label>
                                                <div className="form-group">
                                                    <input className="form-control"></input>
                                                </div>
                                                </div>
                                                <div className="col-md-4">
                                                <label>Número de Serie</label>
                                                <div className="form-group">
                                                    <input className="form-control"></input>
                                                </div>
                                                </div>
                                                <div className="col-md-4">
                                                <label>Lenguaje</label>
                                                <div className="form-group">
                                                    <select className="form-control">
                                                        <option>ES - Español</option>
                                                        <option>EN - Inglés</option>
                                                    </select>
                                                </div>
                                                </div>
                                                <div className="col-md-4">
                                                <label>Cantidad Unidades Gratis</label>
                                                <div className="form-group">
                                                    <input className="form-control"></input>
                                                </div>
                                                </div>
                                                <div className="col-md-4">
                                                <label>Unidades de Consumo Adicionales</label>
                                                <div className="form-group">
                                                    <input className="form-control"></input>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="card">
                                        <h5 className="card-header card-titulo">Identificación Logistica</h5>
                                        <div className="card-body">
                                            <div className="form-group">
                                                <select className="form-control">
                                                    <option>BJ - Código Seriado de Unidad de Envío</option>
                                                    <option>SRV - Número global de unidades de comercialización (GTIN - Código EAN/UPC)</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="card">
                                        <h5 className="card-header card-titulo">Empaquetado</h5>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label>Número de Paquetes*</label>
                                                    <div className="form-group">
                                                        <input className="form-control"></input>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <label>Tipo de Empaquetado*</label>
                                                    <div className="form-group">
                                                        <select className="form-control">
                                                            <option>EXCHANGE_PALLETS - Palet sin Retorno</option>
                                                            <option>RETURN_PALLETS - Palet Retornable</option>
                                                            <option>PALLET_80X100 - Palet 80x100</option>
                                                            <option>CASE - Cajon</option>
                                                            <option>BOX - Caja</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <label>Descripción*</label>
                                                    <div className="form-group">
                                                        <input className="form-control"></input>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <label>Método de Pago</label>
                                                    <div className="form-group">
                                                        <select className="form-control">
                                                            <option>PREPAID_BY_SELLER - Prepagado por el vendedor</option>
                                                            <option>PAID_BY_BUYER - Pagado por el comprador</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <label>Lote</label>
                                                    <div className="form-group">
                                                        <input className="form-control"></input>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <label>Fecha de producción</label>
                                                        <div className="input-group">
                                                            <input
                                                            type="text"
                                                            className="form-control"
                                                            id="datepicker"
                                                            placeholder="Seleccione fecha"
                                                            ></input>
                                                            <div className="input-group-append" data-toggle="tooltip" data-placement="top" title="Seleccione fecha">
                                                            <button
                                                                className="input-group-text"
                                                                id="datepicker"
                                                            >
                                                                <i
                                                                className="fas fa-calendar-alt"
                                                                data-toggle="datepicker"
                                                                ></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="card">
                                        <h5 className="card-header card-titulo">Importe por línea</h5>
                                        <div className="card-body">
                                           <div className="row">
                                                    <div className="col-md-6">
                                                        <label>Importe Bruto*</label>
                                                        <div className="form-group">
                                                            <input className="form-control"></input>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label>Importe Neto*</label>
                                                        <div className="form-group">
                                                            <input className="form-control"></input>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="float-right">
                                                            <button type="button" className="btn btn-primary"><i className="fas fa-plus"></i>Agregar</button>
                                                        </div>
                                                    </div>
                                        <div className="col-md-12 mt-2">
                                            <div className="table-responsive">
                                                <table className="table table-bordered">
                                                    <thead className="thead-dark">
                                                        <tr>
                                                        <th>Cargo o descuento</th>
                                                        <th>Imputación</th>
                                                        <th>Secuencia de cálculo</th>
                                                        <th>Tipo</th>
                                                        <th>Porcentaje</th>
                                                        <th></th>
                                                        <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                    <td>1</td>
                                                    <td>1</td>
                                                    <td>1</td>
                                                    <td>1</td>
                                                    <td>1</td>
                                                        <td>
                                                            <button type="button" className="btn btn-primary"><i className="fas fa-edit"></i>Editar</button>
                                                        </td>
                                                        <td>
                                                            <button type="button" className="btn btn-primary"><i className="fas fa-trash"></i>Eliminar</button>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div> 
                                           </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    </div>

                </div>
            </div>            
        </div>
    );
}

export default ComplementoDetallista;