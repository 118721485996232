import React from 'react';

function Certificado() {
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <h3>Certificados de Sello Digital</h3>
                            <div className="float-right">
                                <button type="button" data-target=".subircertificado"  data-toggle="modal" className="btn btn-primary"><i className="fas fa-plus"></i>Agregar</button>
                            </div>
                        </div>
                        <div className="modal fade subircertificado" tabIndex={-1} role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-lg" role="document">
                                <div className="modal-content">
                                 <div className="modal-header">
                                    <h4>Nuevo Certificado de Sello Digital</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                 </div>
                                 <div className="modal-body">
                                    <div className="card">
                                        <div className="card-titulo card-body">
                                        <span><strong>Atención</strong> Si usted ha generado el CSD hace menos de 72 horas es probable que el SAT aún no agregue su RFC a la lista de régimen de contribuyentes (LOC) y por lo tanto tenga que esperar dicho periodo para poder facturar.</span>
                                        </div>
                                    </div>
                                   <form>
                                    <div className="form-group">
                                        <label htmlFor="exampleFormControlFile1">Llave Pública (.cer)*</label>
                                        <input type="file" className="form-control-file" id="exampleFormControlFile1" />
                                    </div>
                                    </form>
                                   <form>
                                    <div className="form-group">
                                        <label htmlFor="exampleFormControlFile1">Llave Privada (.key)*</label>
                                        <input type="file" className="form-control-file" id="exampleFormControlFile1" />
                                    </div>
                                    </form>
                                   <div className="form-group">
                                        <label>Contraseña</label>
                                        <div className="col-sm-10">
                                            <input type="password" className="form-control" id="inputPassword" />
                                        </div>
                                        </div>

                                 </div>
                                 <div className="modal-footer">
                                     <button type="button" className="btn btn-danger"><i className="fas fa-times"></i>Cancelar</button>
                                     <button type="button" className="btn btn-primary"><i className="fas fa-save"></i>Subir</button>
                                 </div>
                                </div>
                            </div>
                            </div>

                        <div className="card-body">
                            <div className="table-responsive">
                                <table id="example1" className="table table-bordered table-striped">
                                            <thead>
                                                <tr>
                                                <th>No. Certificado</th>
                                                <th>Fecha Inicial</th>
                                                <th>Fecha Final</th>
                                                <th>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                <td>30001000000300023709</td>
                                                <td>1 enero 2019</td>
                                                <td>1 enero 2023</td>
                                                <td>Activo</td>
                                                </tr>
                                            </tbody>
                                        </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Certificado;