import React, { useState, useEffect } from "react";
import Autosuggest from "react-autosuggest";
import clienteAxios from '../../config/axios';
import { Formik, Form, FieldArray, Field } from "formik";
import * as Yup from 'yup';
import Cargador from "../generales/Cargador";

import { useRouteMatch, useParams, useHistory } from "react-router-dom";


// import toastr from "//cdnjs.cloudflare.com/ajax/libs/toastr.js/latest/js/toastr.min.js";

// import "//cdnjs.cloudflare.com/ajax/libs/toastr.js/latest/css/toastr.min.css";

//cdnjs.cloudflare.com/ajax/libs/toastr.js/latest/js/toastr.min.js
//cdnjs.cloudflare.com/ajax/libs/toastr.js/latest/css/toastr.min.css

const EditarProducto = () => {
    const [claveProductoSAT, setClaveProductoSAT] = useState("");
    const [claveUnidadSAT, setClaveUnidadSAT] = useState("");
    
    const [impIvaTrasladado, setImpIvaTrasladado] = useState([]);
    const [impIepsTrasladado, setImpIepsTrasladado] = useState([]);
    const [impIvaRetenido, setImpIvaRetenido] = useState([]);
    const [impIsrRetenido, setImpIsrRetenido] = useState([]);
    const [impLocalTraslado, setImpLocalTraslado] = useState([]);
    const [impLocalRetenido, setImpLocalRetenido] = useState([]);

    let { idProducto } = useParams();

    let history = useHistory();
  


    const [suggestions, setSuggestions] = useState([]);
/*
    const [producto, setProducto] = useState ({
        codigo_barras : '',
        descripcion : '',
        clave_producto_sat : '',
        clave_unidad_sat : '',
        unidad_medida : '',
        precio : '',
        cuenta_predial: '',
        impuesto_federal_trasladado: '',
        ieps: '',
        iva_retenido: '',
        isr_retenido: '',
        impuestos_locales_trasladados: [],
        impuestos_locales_retenidos: []
      });
      */
      const [producto, setProducto] = useState (null);
      const predialReg = /^[0-9]{1,150}$/;
      // const predialReg = /^[0-9]{5}$/
      const profileShema = Yup.object().shape({
        codigo_barras: Yup.string()
          .required('Debe escribir un codigo de barras'),
        clave_producto_sat: Yup.string()
          .required('Debe seleccionar una clave de producto SAT'),
        descripcion: Yup.string()
          .min(1, 'Minimo 1 caracter')
          .max(1000, 'Máximo 1000 caracteres')
          .required('Debe escribir una descripción'),
        clave_unidad_sat: Yup.string()
          .required('Debe seleccionar una clave unidad SAT'),
        unidad_medida: Yup.string()
          .required('Debe escribir una unidad de medida'),
        precio: Yup.string()
          .required('Debe teclear un precio'),
        cuenta_predial: Yup.string()
          .matches( predialReg, 'Solo deben ser numeros y maximo 150 caracteres')
    
      })

      const selecProducto = (event,suggestion1) => {
        event.preventDefault();
        // console.log('LA RAZON SOCIAL ES');
        // console.log(suggestion1);
        setProducto ({
          ...producto,
          clave_producto_sat : suggestion1.suggestion.id,
        })
        // console.log('EL ID DEL CLIENTE ES');
        // console.log(suggestion1.suggestion.id)
      }

      const selecClaveUnidad = (event,suggestion1) => {
        event.preventDefault();
        // console.log('LA RAZON SOCIAL ES');
        // console.log(suggestion1);
        setProducto ({
          ...producto,
          clave_unidad_sat : suggestion1.suggestion.id,
        })
        // console.log('EL ID DEL CLIENTE ES');
        // console.log(suggestion1.suggestion.id)
      }

      useEffect( () => {
        // const impuestos1 = obtenerImpuestos();
        
        // console.log('LOS IMPUESTOS SON'+ impuestos);

        // const producto1 = await obtenerProducto(idProducto);
        async function obtenerImpuestos(){
            try {
                const resultado = await clienteAxios.get(`/v3/impuestos?no_paginacion=1`);
                
                console.log(resultado.data.data); 
                const impuestos = resultado.data.data;
                // return resultado.data.data;
                const impIvaTrasladado1 = impuestos.filter(impuesto => (impuesto.impuesto_entidad == 'Federal' && impuesto.impuesto_tipo == 'T' && impuesto.impuesto_nombre == '002' ) );
                const impIepsTrasladado1 = impuestos.filter(impuesto => (impuesto.impuesto_entidad == 'Federal' && impuesto.impuesto_tipo == 'T' && impuesto.impuesto_nombre == '003' ) );
                const impIvaRetenido1 = impuestos.filter(impuesto => (impuesto.impuesto_entidad == 'Federal' && impuesto.impuesto_tipo == 'R' && impuesto.impuesto_nombre == '002' ) );
                const impIsrRetenido1 = impuestos.filter(impuesto => (impuesto.impuesto_entidad == 'Federal' && impuesto.impuesto_tipo == 'R' && impuesto.impuesto_nombre == '001' ) );
    
                const impLocalTraslado1 = impuestos.filter(impuesto => (impuesto.impuesto_entidad == 'Local' && impuesto.impuesto_tipo == 'T' ) );
                const impLocalRetenido1 = impuestos.filter(impuesto => (impuesto.impuesto_entidad == 'Local' && impuesto.impuesto_tipo == 'R' ) );
                
                setImpIvaTrasladado(
                  impIvaTrasladado1
                );
                setImpIepsTrasladado(
                  impIepsTrasladado1
                );
                setImpIvaRetenido(
                  impIvaRetenido1
                );
                setImpIsrRetenido(
                  impIsrRetenido1
                );
                setImpLocalTraslado(
                  impLocalTraslado1
                );
                setImpLocalRetenido(
                  impLocalRetenido1
                );
                // console.log( impIvaTrasladado1)
                // console.log( impIepsTrasladado1)
                // console.log( impIvaRetenido1 )
                // console.log( impIsrRetenido1 )
                // console.log( impLocalTraslado1)
                // console.log(impLocalRetenido1)
                // setImpuestosFederalesSucursal ({
                //   resultado.data.data
                    
                // })
                
                
            } catch (error) {
                const alerta = {
                    msg: 'Hubo un error',
                    categoria: 'alerta-error'
                }
                console.log(alerta);
                console.error('outer', error.message);
                
                // dispatch({
                //     type: FACTURA_ERROR,
                //     payload: alerta
                // })
            } 
    
        }
         obtenerImpuestos();
        async function obtenerProducto(idProducto){
            try {
                const produ = await clienteAxios.get(`/v3/productos/${idProducto}`);
                console.log('RESULTADO PRODUCTO.....');
                console.log(produ.data.data); 
                const producto1 = produ.data.data;
                

              const impIvaTrasladadoSelected = producto1?.producto_impuestos.filter(impuesto => (impuesto.producto_impuesto_entidad == 'Federal' && impuesto.producto_impuesto_impuesto_tipo == 'T' && impuesto.producto_impuesto_nombre == '002' ) )[0];
              const impIepsTrasladadoSelected = producto1?.producto_impuestos.filter(impuesto => (impuesto.producto_impuesto_entidad == 'Federal' && impuesto.producto_impuesto_impuesto_tipo == 'T' && impuesto.producto_impuesto_nombre == '003' ) )[0];
              const impIvaRetenidoSelected = producto1?.producto_impuestos.filter(impuesto => (impuesto.producto_impuesto_entidad == 'Federal' && impuesto.producto_impuesto_impuesto_tipo == 'R' && impuesto.producto_impuesto_nombre == '002' ) )[0];
              const impIsrRetenidoSelected = producto1?.producto_impuestos.filter(impuesto => (impuesto.producto_impuesto_entidad == 'Federal' && impuesto.producto_impuesto_impuesto_tipo == 'R' && impuesto.producto_impuesto_nombre == '001' ) )[0];

              const impLocalTrasladoSelected = producto1?.producto_impuestos.filter(impuesto => (impuesto.producto_impuesto_entidad == 'Local' && impuesto.producto_impuesto_impuesto_tipo == 'T') );
              const impLocalRetenidoSelected = producto1?.producto_impuestos.filter(impuesto => (impuesto.producto_impuesto_entidad == 'Local' && impuesto.producto_impuesto_impuesto_tipo == 'R') );
              
              const arrayImpLocalTraslado = impLocalTrasladoSelected.map((imp)=>{
                return imp.producto_impuesto_identificador_comparacion;
              });

              const arrayImpLocalRetenido = impLocalRetenidoSelected.map((imp)=>{
                return imp.producto_impuesto_identificador_comparacion;
              });
              
              console.log('LOCAL RETENIDO');
              console.log(arrayImpLocalRetenido);
              console.log('LOCAL TRASLADO');
              console.log(arrayImpLocalTraslado);
              
              
              // console.log('IVA TRASLADADOR');
              // console.log(impIvaTrasladadoSelected?.producto_impuesto_identificador_comparacion)
              // console.log('ISR retenido');
              // console.log(impIsrRetenidoSelected?.producto_impuesto_identificador_comparacion)

              setProducto ({
                ...producto,  
                codigo_barras : producto1.producto_clave,
                descripcion : producto1.producto_descripcion,
                clave_producto_sat : producto1.producto_clave_producto_servicio,
                clave_unidad_sat : producto1.producto_clave_unidad_sat,
                unidad_medida : producto1.producto_unidad,
                precio : producto1.producto_precio_venta,
                cuenta_predial: producto1.producto_cuenta_predial,
                // impuesto_federal_trasladado:'',
                // ieps: '',
                // iva_retenido: '',
                // isr_retenido: '',
                // impuestos_locales_trasladados: [],
                // impuestos_locales_retenidos: []
                impuesto_federal_trasladado: impIvaTrasladadoSelected?.producto_impuesto_identificador_comparacion,
                ieps: impIepsTrasladadoSelected?.producto_impuesto_identificador_comparacion,
                iva_retenido: impIvaRetenidoSelected?.producto_impuesto_identificador_comparacion,
                isr_retenido: impIsrRetenidoSelected?.producto_impuesto_identificador_comparacion,
                impuestos_locales_trasladados: (arrayImpLocalTraslado) ? arrayImpLocalTraslado : [],
                impuestos_locales_retenidos: (arrayImpLocalRetenido) ? arrayImpLocalRetenido : []
            })
                // return productos2;
    
                
            } catch (error) {
                const alerta = {
                    msg: 'Hubo un error al consultar producto',
                    categoria: 'alerta-error'
                }
                console.log(alerta);
                
            } 
    
        }
        obtenerProducto(idProducto);
        //console.log('PRODUCTO' + producto1);
        //console.log( producto1);
        
        
        // const result = impuestos.filter(impuesto => impuesto.impuesto_entidad = 'Federal');
        // console.log(result);
        
        // setImpuestosFederalesSucursal ({
        //   ...impuestosFederalesSucursal,
        //   impuestos,
          
        // })
      }, [idProducto]);


      
    
   

    const ivaTrasladadoSelect = (
      <>
      {
        impIvaTrasladado.map( imp =>{
          return(
            <option key={imp.impuesto_identificador_comparacion} value={imp.impuesto_identificador_comparacion}>{`${imp.impuesto_descripcion} ${imp.impuesto_tipo_factor} ${imp.impuesto_valor}  `}</option>
          )
        } )
      }
      </>
    )

    const iepsTrasladadoSelect = (
      <>
      {
        impIepsTrasladado.map( imp =>{
          return(
            <option key={imp.impuesto_identificador_comparacion} value={imp.impuesto_identificador_comparacion}>{`${imp.impuesto_descripcion} ${imp.impuesto_tipo_factor} ${imp.impuesto_valor}  `}</option>
          )
        } )
      }
      </>
    )
    
    // const ivaRetenidoSelect = (
    //   <select 
    //     name="ivaRetenido" 
    //     id="ivaRetenido" 
    //     className="form-control"
    //     onChange={handleChange}
    //     onBlur={handleBlur}
    //     value={values.ivaRetenido}
    //     >
    //     <option value="">No Aplica</option>
    //   {
    //     impIvaRetenido.map( imp =>{
    //       return(
    //         <option key={imp.impuesto_identificador_comparacion} value={imp.impuesto_identificador_comparacion}>{`${imp.impuesto_descripcion} ${imp.impuesto_tipo_factor} ${imp.impuesto_valor}  `}</option>
    //       )
    //     } )
    //   }
    //   </select>
    // )
    const ivaRetenidoSelect = (
      <>
      {
        impIvaRetenido.map( imp =>{
          return(
            <option key={imp.impuesto_identificador_comparacion} value={imp.impuesto_identificador_comparacion}>{`${imp.impuesto_descripcion} ${imp.impuesto_tipo_factor} ${imp.impuesto_valor}  `}</option>
          )
        } )
      }
      </>
    )
    

    const isrRetenidoSelect = (
      <>
      {
        impIsrRetenido.map( imp =>{
          return(
            <option key={imp.impuesto_identificador_comparacion} value={imp.impuesto_identificador_comparacion}>{`${imp.impuesto_descripcion} ${imp.impuesto_tipo_factor} ${imp.impuesto_valor}  `}</option>
          )
        } )
      }
      </>
    )

    console.log('PRODUCTO2' );
    console.log(producto);

    // const impIvaTrasladadoSelected = producto?.producto_impuestos.filter(impuesto => (impuesto.producto_impuesto_entidad == 'Federal' && impuesto.producto_impuesto_impuesto_tipo == 'T' && impuesto.producto_impuesto_nombre == '002' ) );

    return (
      <div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
            { (producto == null) ? (
              <Cargador/>
            ) :(
            <Formik
                  enableReinitialize
                  initialValues={{ 
                    codigo_barras: producto.codigo_barras, 
                    descripcion: producto.descripcion,
                    clave_producto_sat: producto.clave_producto_sat,
                    clave_unidad_sat: producto.clave_unidad_sat,
                    unidad_medida: producto.unidad_medida,
                    precio: producto.precio,
                    cuenta_predial: producto.cuenta_predial,
                    ivaTrasladado: producto.impuesto_federal_trasladado,
                    iepsTrasladado: producto.ieps,
                    ivaRetenido: producto.iva_retenido,
                    isrRetenido: producto.isr_retenido,
                    impuestos_locales_trasladados: producto.impuestos_locales_trasladados,
                    impuestos_locales_retenidos: producto.impuestos_locales_retenidos
                   
                  }}
                  validationSchema={profileShema}
                  onSubmit={ async (values, { setSubmitting })=>{
                    
                      // alert(JSON.stringify(values, null, 2));
                      // formData.append("informacion_json", JSON.stringify(values, null, 2));
                      let formJson = {
                        'producto_descripcion': values.descripcion,
                        // 'producto_modelo':values.,
                        'producto_clave':values.codigo_barras,
                        // 'producto_impuesto':values.,
                        // 'producto_costo':values.,
                        // 'producto_existencia':values.,
                        'producto_unidad':values.unidad_medida,
                        // 'producto_aplica_imp':values.,
                        // 'producto_aplica_iva':values.,
                        // 'producto_aplica_isr':values.,
                        // 'producto_imp_local':values.,
                        // 'producto_aplica_ieps':values.,
                        'producto_precio_venta':values.precio,
                        // 'producto_ventas_inex':values.,
                        // 'producto_descuento':values.,
                        'producto_cuenta_predial':values.cuenta_predial,
                        'producto_clave_producto_servicio':values.clave_producto_sat,
                        'producto_clave_unidad_sat':values.clave_unidad_sat,
                        // 'producto_estado':values.,
                        // 'producto_cancelacion':values.,
                        // 'producto_marca':values.,
                        // 'producto_submodelo':values.,
                        "producto_impuesto_iva_trasladado": values.ivaTrasladado,
                        // "producto_impuesto_iva_trasladado_cuota": values.,
                        "producto_impuesto_ieps_trasladado": values.iepsTrasladado,
                        "producto_impuesto_iva_retenido": values.ivaRetenido,
                        "producto_impuesto_isr_retenido": values.isrRetenido,
                        "producto_impuestos_locales_trasladados": values.impuestos_locales_trasladados,
                        "producto_impuestos_locales_retenidos": values.impuestos_locales_retenidos
          
                        
                      }

                      try {
                          const resultado = await clienteAxios({
                              method: 'put',                        
                              url: `https://app.facturandoenlinea.mx/v3/productos/${idProducto}`,
                              data: formJson,
                              // headers: {'Content-Type': 'multipart/form-data' }
                          })
                          console.log(resultado.data.data);
                          console.log(resultado.status);
                          if(resultado.status===200){
                            alert("PRODUCTO ACTUALIZADO CON EXITO");
                            // toastr.success("PRODUCTO ACTUALIZADO CON EXITO")
                            // history.push('/productos');
                            history.goBack();
                            setSubmitting(false);
                          }else{
                            alert("NO SE ACTUALIZO"+resultado.data.data);
                          }
                      } catch (error) { 
                          console.log('Hubo un error al enviar api '+error);
                          alert(error.response.data.error);
                          console.log('code', error.response.data.code);
                          console.log('error', error.response.data.error);
                          // console.log('errorType', typeof error);
                          // console.log('error', Object.assign({}, error));
                          // console.log('getOwnPropertyNames', Object.getOwnPropertyNames(error));
                          // console.log('stackProperty', Object.getOwnPropertyDescriptor(error, 'stack'));
                          // console.log('messageProperty', Object.getOwnPropertyDescriptor(error, 'message'));
                          // console.log('stackEnumerable', error.propertyIsEnumerable('stack'));
                          // console.log('messageEnumerable', error.propertyIsEnumerable('message'));
                      }
                      setSubmitting(false);
                      
                   
                  }}

                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleSubmit,
                    handleBlur,
                    isSubmitting,
                    setFieldValue
                  })=> (
                <form role="form" onSubmit={handleSubmit} autoComplete="off">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="card-title">
                    Editar producto o servicio
                  </h3>
                  {/* <button type="submit" className="btn btn-primary">
                    <i className="fas fa-save"></i>Guardar
                  </button> */}
                  <button type="submit" className="btn btn-primary" disabled={isSubmitting} >
                    <i className="fas fa-save"></i>
                    {isSubmitting ? 'Actualizando' : 'Actualizar'}
                  </button>
                  <button type="submit" className="btn btn-secondary" onClick={
                    (e) => {
                      e.preventDefault();
                      history.goBack();
                    }
                  }>
                    <i className="fas fa-arrow-left"></i>Atrás
                  </button>
                </div>
                {/* aqui estafa form */}
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="clave">
                            Clave / Código de Barras:
                          </label>
                          <input
                            type="text"
                            className={
                              errors.codigo_barras && touched.codigo_barras
                              ? "form-control error"
                              : "form-control"
                            }
                            id="codigo_barras"
                            name="codigo_barras"
                            placeholder="001-000-00"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.codigo_barras}

                          />
                          {errors.codigo_barras && touched.codigo_barras && (
                                <div className="input-feedback">{errors.codigo_barras}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="descripcion">Descripción*: </label>
                          <input
                            type="text"
                            className={
                              errors.descripcion && touched.descripcion
                              ? "form-control error"
                              : "form-control"
                            }
                            id="descripcion"
                            name="descripcion"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.descripcion}
                          />
                           {errors.descripcion && touched.descripcion && (
                                <div className="input-feedback">{errors.descripcion}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="descripcion">
                            Clave Producto SAT*:{" "}
                          </label>
                          <input
                            type="hidden"
                            className="form-control"
                            name="clave_producto_sat"
                            id="clave_producto_sat"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.clave_producto_sat}
                            
                          />
                          <Autosuggest 
                            inputProps={{
                              className: errors.clave_producto_sat && touched.clave_producto_sat
                              ? "form-control error"
                              : "form-control",
                              placeholder: "Clave producto SAT",
                              autoComplete: "abcd",
                              name: "c_producto_sat",
                              id: "c_producto_sat",
                              // value: claveProductoSAT,
                              value: values.clave_producto_sat,
                              onChange: (_event, { newValue}) => {
                                // setClaveProductoSAT(newValue);
                                setProducto ({
                                  ...producto,
                                  clave_producto_sat : newValue,
                                
                                })
                                if(!newValue){
                                  setProducto ({
                                    ...producto,
                                    clave_producto_sat : '',
                                  
                                  })

                                  setFieldValue('clave_producto_sat', '');
                                  
                                  
                                }
                                
                              }
                            }}
                            suggestions={suggestions}
                            onSuggestionsFetchRequested = {async ({ value }) => {
                              if (!value) {
                                setSuggestions([]);
                                return;
                              }
                              try {
                                const resultado = await clienteAxios.get(`/v3/claves-producto-sat?busqueda=${value}`);
                                
                                // console.log(resultado.data.data);
                                console.log('CONSULTA')
                                resultado.data.data.map(row =>{ console.log(row.clave_producto_sat)})
                                setSuggestions(
                                  resultado.data.data.map(row => (
                                    {
                                      id: row.clave_producto_sat_codigo,
                                      descripcion: row.clave_producto_sat_descripcion,
                                      concat: row.clave_producto_sat_concat,
                                    }
                                  ))
                                )
                                
                        
                              } catch (error) { 
                                  setSuggestions([]);
                                  console.log('Hubo un error '+error);
                              }
                            }}
                            onSuggestionsClearRequested={() =>{
                              setSuggestions([]);
                              // actualizarFactura ({
                              //   ...factura,
                              //   factura_cliente_identificador : '',
                              // })
                            }}
                            onSuggestionSelected={( event, suggestion )=> {
                              selecProducto(event, suggestion);
                              setFieldValue('clave_producto_sat', suggestion.suggestion.id);
                              // setFieldValue('descripcion', suggestion.suggestion.descripcion);
                              // setFieldValue('factura_cliente_rfc', suggestion.suggestion.rfc);
                              // setFieldValue('factura_uso_cfdi', suggestion.suggestion.uso_cfdi);
                            }}
                            
                            getSuggestionValue={suggestion => suggestion.id}
                            renderSuggestion={suggestion => <div>{suggestion.id+' '+suggestion.descripcion}</div>}

                          />

                          {errors.clave_producto_sat && touched.clave_producto_sat && (
                                <div className="input-feedback">{errors.clave_producto_sat}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="unidad">Clave Unidad SAT*: </label>
                          <input
                            type="hidden"
                            className="form-control"
                            id="clave_unidad_sat"
                            type="hidden"
                            name="clave_unidad_sat"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.clave_unidad_sat}
                          />
                          <Autosuggest 
                            inputProps={{
                              className: errors.clave_unidad_sat && touched.clave_unidad_sat
                              ? "form-control error"
                              : "form-control",
                              placeholder: "Clave Unidad SAT",
                              autoComplete: "abcd",
                              name: "c_unidad_sat",
                              id: "c_unidad_sat",
                              // value: claveUnidadSAT,
                              value: values.clave_unidad_sat,
                              onChange: (_event, { newValue}) => {
                                // setClaveUnidadSAT(newValue);
                                setProducto ({
                                  ...producto,
                                  clave_unidad_sat : newValue,
                                
                                })
                                if(!newValue){
                                  setProducto ({
                                    ...producto,
                                    clave_unidad_sat : '',
                                  
                                  })

                                  setFieldValue('clave_unidad_sat', '');
                                  
                                  
                                }
                                
                              }
                            }}
                            suggestions={suggestions}
                            onSuggestionsFetchRequested = {async ({ value }) => {
                              if (!value) {
                                setSuggestions([]);
                                return;
                              }
                              try {
                                const resultado = await clienteAxios.get(`/v3/claves-unidad-sat?busqueda=${value}`);
                                
                                // console.log(resultado.data.data);
                                console.log('CONSULTA')
                                resultado.data.data.map(row =>{ console.log(row.clave_unidad_sat)})
                                setSuggestions(
                                  resultado.data.data.map(row => (
                                    {
                                      id: row.clave_unidad_sat,
                                      descripcion: row.clave_unidad_sat_descripcion,
                                      concat: row.clave_unidad_sat_concat,
                                    }
                                  ))
                                )
                                
                        
                              } catch (error) { 
                                  setSuggestions([]);
                                  console.log('Hubo un error '+error);
                              }
                            }}
                            onSuggestionsClearRequested={() =>{
                              setSuggestions([]);
                              
                            }}
                            onSuggestionSelected={( event, suggestion )=> {
                              selecClaveUnidad(event, suggestion);
                              setFieldValue('clave_unidad_sat', suggestion.suggestion.id);
                              setFieldValue('unidad_medida', suggestion.suggestion.descripcion);
                              
                            }}
                            
                            getSuggestionValue={suggestion => suggestion.id}
                            renderSuggestion={suggestion => <div>{suggestion.id+' '+suggestion.descripcion}</div>}

                          />

                          {errors.clave_unidad_sat && touched.clave_unidad_sat && (
                                <div className="input-feedback">{errors.clave_unidad_sat}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="unidad">Unidad Medida: </label>
                          <input
                            type="text"
                            className={
                              errors.unidad_medida && touched.unidad_medida
                              ? "form-control error"
                              : "form-control"
                            }
                            id="unidad_medida"
                            name="unidad_medida"
                            placeholder="Unidad Medida"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.unidad_medida}
                          />
                          {errors.unidad_medida && touched.unidad_medida && (
                                <div className="input-feedback">{errors.unidad_medida}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="unidad">Precio sin/iva*: </label>
                          <input
                            type="text"
                            className={
                              errors.precio && touched.precio
                              ? "form-control error"
                              : "form-control"
                            }
                            id="precio"
                            name="precio"
                            placeholder="0.00"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.precio}
                          />
                          {errors.precio && touched.precio && (
                                <div className="input-feedback">{errors.precio}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label htmlFor="unidad">Cuenta Predial: </label>
                          <input
                            type="text"
                            className={
                              errors.cuenta_predial && touched.cuenta_predial
                              ? "form-control error"
                              : "form-control"
                            }
                            name="cuenta_predial"
                            id="cuenta_predial"
                            placeholder=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.cuenta_predial}
                          />
                          {errors.cuenta_predial && touched.cuenta_predial && (
                                <div className="input-feedback">{errors.cuenta_predial}</div>
                          )}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="card ">
                          <h4 className="card-header card-subtitle">
                            Impuestos Federales Trasladados
                          </h4>

                          <div className="card-body">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>IVA Trasladado: </label>
                                  {/* <select className="form-control">
                                    <option>No Aplica</option>
                                    <option>IVA Tasa 0.0160000</option>
                                    <option>IVA Tasa 0.0000000</option>
                                    <option>IVA Exento</option>
                                    <option>IVA Cuota</option>
                                    <option>IVA Tasa 0.080000</option>
                                  </select> */}
                                  {/* <select 
                                    name="ivaTrasladado" 
                                    id="ivaTrasladado" 
                                    className="form-control"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    defaultValue={values.ivaTrasladado}
                                    >
                                    <option value="">No Aplica</option>
                                    {ivaTrasladadoSelect}
                                  </select> */}
                                  <Field as="select" name="ivaTrasladado" className="form-control">
                                    <option value="">No Aplica</option>
                                    {ivaTrasladadoSelect}
                                  </Field>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>IEPS Trasladado: </label>
                                  {/* <select className="form-control">
                                    <option>No Aplica</option>
                                    <option>IEPS Tasa 0.080000</option>
                                    <option>IEPS Tasa 0.060000</option>
                                    <option>IEPS Tasa 0.070000</option>
                                    <option>IEPS Tasa 0.265000</option>
                                    <option>IEPS Tasa 0.300000</option>
                                    <option>IEPS Tasa 0.530000</option>
                                  </select> */}
                                  <select 
                                    name="iepsTrasladado" 
                                    id="iepsTrasladado" 
                                    className="form-control"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.iepsTrasladado}
                                    >
                                    <option value="">No Aplica</option>
                                    {iepsTrasladadoSelect}
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="card ">
                          <h4 className="card-header card-subtitle">
                            Impuestos Federales Retenidos
                          </h4>

                          <div className="card-body">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>IVA Retenido: </label>
                                  {/* <select className="form-control">
                                    <option>No Aplica</option>
                                    <option>IVA Tasa 0.106667</option>
                                    <option>IVA Tasa 0.040000</option>
                                    <option>IVA Tasa 0.160000</option>
                                    <option>IVA Tasa 0.106666</option>
                                    <option>IVA Tasa 0.106700</option>
                                    <option>IVA Tasa 0.053300</option>
                                    <option>IVA Tasa 0.053335</option>
                                    <option>IVA Tasa 0.053333</option>
                                    <option>IVA Tasa 0.053350</option>
                                  </select> */}
                                  <select 
                                    name="ivaRetenido" 
                                    id="ivaRetenido" 
                                    className="form-control"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.ivaRetenido}
                                  >
                                  <option value="">No Aplica</option>
                                  {ivaRetenidoSelect}
                                  </select>
                                  
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>ISR Retenido: </label>
                                  {/* <select className="form-control">
                                    <option>No Aplica</option>
                                    <option>ISR Tasa 0.0100000</option>
                                    <option>ISR Tasa 0.0200000</option>
                                  </select> */}
                                  <select 
                                    name="isrRetenido" 
                                    id="isrRetenido" 
                                    className="form-control"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.isrRetenido}
                                    >
                                  <option value="">No Aplica</option>
                                  {isrRetenidoSelect}
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div class="card">
                          <h4 class="card-header card-subtitle">
                            Impuestos Locales Trasladados
                          </h4>
                          <div class="card-body">
                            <div className="row">
                              
                                <FieldArray
                                  name="impuestos_locales_trasladados"
                                  render={arrayHelpers => (
                                    <>
                                      {impLocalTraslado.map(impuest => (
                                      
                                          <div key={impuest.impuesto_identificador_comparacion} className="col-md-6">
                                          <div className="form-check">
                                          <label>
                                            <input
                                              name="impuestos_locales_trasladados"
                                              type="checkbox"
                                              value={impuest.impuesto_identificador_comparacion}
                                              checked={values.impuestos_locales_trasladados.includes(impuest.impuesto_identificador_comparacion)}
                                              onChange={e => {
                                                if (e.target.checked) arrayHelpers.push(impuest.impuesto_identificador_comparacion);
                                                else {
                                                  const idx = values.impuestos_locales_trasladados.indexOf(impuest.impuesto_identificador_comparacion);
                                                  arrayHelpers.remove(idx);
                                                }
                                              }}
                                            />
                                            {` ${impuest.impuesto_descripcion} ${impuest.impuesto_tipo_factor} ${impuest.impuesto_valor}`}
                                          </label>
                                          </div>
                                          </div>
                                      ))}
                                    </>
                                  )}
                                />
                                  {/* <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="impuestos_locales_trasladados"
                                    name="impuestos_locales_trasladados"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.impuestos_locales_trasladados}
                                  /> */}
                                  {/* <label
                                    className="form-check-label"
                                    htmlFor="exampleCheck1"
                                  >
                                    ISH Tasa 0.030000
                                  </label> */}
                                
                              {/* <div className="col-md-6">
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="impuestos_locales_trasladados"
                                    name="impuestos_locales_trasladados"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.impuestos_locales_trasladados}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="exampleCheck1"
                                  >
                                    ISH Tasa 0.020000
                                  </label>
                                </div>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div class="card">
                          <h4 class="card-header card-subtitle">
                            Impuestos Locales Retenidos
                          </h4>
                          <div class="card-body">
                            <div className="row">
                            <FieldArray
                                  name="impuestos_locales_retenidos"
                                  render={arrayHelpers => (
                                    <>
                                      {impLocalRetenido.map(impuest => (
                                      
                                          <div key={impuest.impuesto_identificador_comparacion} className="col-md-6">
                                          <div className="form-check">
                                            <label>
                                            <input
                                              name="impuestos_locales_retenidos"
                                              type="checkbox"
                                              value={impuest.impuesto_identificador_comparacion}
                                              checked={values.impuestos_locales_retenidos.includes(impuest.impuesto_identificador_comparacion)}
                                              onChange={e => {
                                                if (e.target.checked) arrayHelpers.push(impuest.impuesto_identificador_comparacion);
                                                else {
                                                  const idx = values.impuestos_locales_retenidos.indexOf(impuest.impuesto_identificador_comparacion);
                                                  arrayHelpers.remove(idx);
                                                }
                                              }}
                                            />
                                            
                                            {` ${impuest.impuesto_descripcion} ${impuest.impuesto_tipo_factor} ${impuest.impuesto_valor}`}
                                          </label>
                                          </div>
                                          </div>
                                      ))}
                                    </>
                                  )}
                                />
                              {/* <div className="col-md-6">
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="impLocalesRetenidos"
                                    name="impLocalesRetenidos"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="exampleCheck1"
                                  >
                                    ISH Tasa 0.030000
                                  </label>
                                </div>
                              </div> */}
                              {/* <div className="col-md-6">
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="impLocalesRetenidos"
                                    name="impLocalesRetenidos"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="exampleCheck1"
                                  >
                                    ISH Tasa 0.020000
                                  </label>
                                </div>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*  fin row */}
                  </div>
                
                
               
                <div className="card-footer">
                    <div className="float-right">
                      
                    {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}

                    <button type="submit" className="btn btn-primary" disabled={isSubmitting} >
                              <i className="fas fa-save"></i>
                              {isSubmitting ? 'Actualizando' : 'Actualizar'}
                    </button>
                    </div>
                  </div>
                 {/* fin formik */}
            </div>
            </form>
            )}
            </Formik>
            )}
            </div>
          </div>
        </div>
        
      </div>
    );

}

export default EditarProducto;
