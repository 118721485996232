import React from 'react';
import { Link } from 'react-router-dom';

function ComprarFolios () {
    return (
        <div className="container-fluid">
            <div className="folios">
                <div className="row">
                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                        <div className="card">
                            <div className="card-body">
                                <div className="text-center">
                                    <i class="fas fa-box-open"></i>
                                    <h5>Paquete 25 Folios</h5>
                                    <span>$145</span>
                                    <p>MXN</p>
                                </div>
                                <Link to="/datos-compra" className="btn btn-primary btn-lg"><i className="fas fa-check"></i>Comprar</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                        <div className="card">
                            <div className="card-body">
                                <div className="text-center">
                                    <i class="fas fa-box-open"></i>
                                    <h5>Paquete 50 Folios</h5>
                                    <span>$274.92</span>
                                    <p>MXN</p>
                                </div>
                                <Link to="/datos-compra" className="btn btn-primary btn-lg"><i className="fas fa-check"></i>Comprar</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                        <div className="card">
                            <div className="card-body">
                                <div className="text-center">
                                    <i class="fas fa-box-open"></i>
                                    <h5>Paquete 100 Folios</h5>
                                    <span>$462.84</span>
                                    <p>MXN</p>
                                </div>
                                <Link to="/datos-compra" className="btn btn-primary btn-lg"><i className="fas fa-check"></i>Comprar</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                        <div className="card">
                            <div className="card-body">
                                <div className="text-center">
                                    <i class="fas fa-box-open"></i>
                                    <h5>Paquete 200 Folios</h5>
                                    <span>$832.88</span>
                                    <p>MXN</p>
                                </div>
                                <Link to="/datos-compra" className="btn btn-primary btn-lg"><i className="fas fa-check"></i>Comprar</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                        <div className="card">
                            <div className="card-body">
                                <div className="text-center">
                                    <i class="fas fa-box-open"></i>
                                    <h5>Paquete 300 Folios</h5>
                                    <span>$1110.12</span>
                                    <p>MXN</p>
                                </div>
                                <Link to="/datos-compra" className="btn btn-primary btn-lg"><i className="fas fa-check"></i>Comprar</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                        <div className="card">
                            <div className="card-body">
                                <div className="text-center">
                                    <i class="fas fa-box-open"></i>
                                    <h5>Paquete 500 Folios</h5>
                                    <span>$1386.20</span>
                                    <p>MXN</p>
                                </div>
                                <Link to="/datos-compra" className="btn btn-primary btn-lg"><i className="fas fa-check"></i>Comprar</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                        <div className="card">
                            <div className="card-body">
                                <div className="text-center">
                                    <i class="fas fa-box-open"></i>
                                    <h5>Paquete 1000 Folios</h5>
                                    <span>$2436</span>
                                    <p>MXN</p>
                                </div>
                                <Link to="/datos-compra" className="btn btn-primary btn-lg"><i className="fas fa-check"></i>Comprar</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                        <div className="card">
                            <div className="card-body">
                                <div className="text-center">
                                    <i class="fas fa-box-open"></i>
                                    <h5>Paquete 3000 Folios</h5>
                                    <span>$5220</span>
                                    <p>MXN</p>
                                </div>
                                <Link to="/datos-compra" className="btn btn-primary btn-lg"><i className="fas fa-check"></i>Comprar</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                        <div className="card">
                            <div className="card-body">
                                <div className="text-center">
                                    <i class="fas fa-box-open"></i>
                                    <h5>Paquete 5000 Folios</h5>
                                    <span>$8100</span>
                                    <p>MXN</p>
                                </div>
                                <Link to="/datos-compra" className="btn btn-primary btn-lg"><i className="fas fa-check"></i>Comprar</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                        <div className="card">
                            <div className="card-body">
                                <div className="text-center">
                                    <i class="fas fa-box-open"></i>
                                    <h5>Paquete 8000 Folios</h5>
                                    <span>$11120</span>
                                    <p>MXN</p>
                                </div>
                                <Link to="/datos-compra" className="btn btn-primary btn-lg"><i className="fas fa-check"></i>Comprar</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                        <div className="card">
                            <div className="card-body">
                                <div className="text-center">
                                    <i class="fas fa-boxes"></i>
                                    <h5>Contamos con más</h5>
                                    <span>paquetes</span>
                                    <p>folios sin vigencia</p>
                                </div>
                                <a href="#" className="btn btn-primary btn-lg"><i className="fas fa-check"></i>Contáctanos</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ComprarFolios;