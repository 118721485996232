import React from 'react';
import { Link } from 'react-router-dom';

function TicketSoporte() {
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">Ticket de Soporte</h3>
                        <button type="button" className="btn btn-primary"><i className="fas fa-envelope"></i>Consultar Reportes</button>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Tipo de reporte:*</label>
                                                <input className="form-control"></input>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Razón Social:*</label>
                                                <input className="form-control"></input>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>RFC:*</label>
                                                <input className="form-control"></input>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Email:*</label>
                                                <input className="form-control"></input>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Teléfono:*</label>
                                                <input className="form-control"></input>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Comentarios:*</label>
                                                <textarea className="form-control"/>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <form>
                                                <div className="form-group">
                                                    <label htmlFor="exampleFormControlFile1">Subir archivo anexo</label>
                                                    <input type="file" className="form-control-file" id="exampleFormControlFile1" />
                                                    <button className="btn btn-primary" type="button">Agregar</button>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="card card-titulo">
                                                <p className="card-text">
                                                    <ul>
                                                        <li>Puedes adjuntar archivos con extensión: jpg, jpeg, png, pdf, doc, docx, txt, xlsx, xlsm, xls, csb, xml, xlt, zip, rar.</li> 
                                                        <li>Máximo 5 archivos por ticket.</li>
                                                        <li>De un tamaño máximo de 10 MB por archivo.</li>
                                                    </ul>
                                            </p>
                                            </div>
                                        </div>
                        </div>
                    </div>
                    <div className="card-footer">
                        <div className="float-right">
                        <button type="button" className="btn btn-primary"><i className="M"></i>Consultar Reportes</button>
                            <button type="button" className="btn btn-primary"><i className="fas fa-envelope"></i>Enviar</button>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    );
}

export default TicketSoporte;