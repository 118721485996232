import React, { useState, useEffect, Fragment } from 'react';
import { useRouteMatch, useParams } from "react-router-dom";
//import clienteAxios from '../config/axios';
import axios from 'axios';

const DescargarXml = () => {

    //let match1 = useRouteMatch("/descagar-xml/:idFactura");
    console.log('IMPRIMIMOS MATCH ');
    // console.log(match);

    const [cfdiBase64, setCfdiBase64] = useState();

    //let idFactura = match1.params.idFactura;
    let { idFactura } = useParams();


    useEffect(() => {
        console.log('USEEFFECT DE DESCARGA XML ');
        obtenerXml(idFactura);
     }, [idFactura]);
 
     async function obtenerXml(idFactura){
         console.log('CARGO USE EFFECT id fact');
         console.log(idFactura);
        const token ="eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyIiwianRpIjoiZTBmNDBlZTFiYWEzNThkMzYwMDU2MjJiYjU3MTVjNDliNGQ2ODk4YWE1ZmJmZWMyY2EzMmEyNzIyNWJmNDBlZDBlZWQ0ZDIwYzcxOGE1MDkiLCJpYXQiOjE1ODU0NjExNTgsIm5iZiI6MTU4NTQ2MTE1OCwiZXhwIjoxNTg2NzUzNTU4LCJzdWIiOiI2Iiwic2NvcGVzIjpbXX0.q8mYHZrV7rwzQkbqIMQv7pyrXUynnyuZ8s0vjkboXwyJKHOd50qX_Mw7ZMxcHkMCzaxTOCLHp-2ibyHbmMFYoO8EZE-S8gWkHvK7Doyw3oUeROAdgmyKYsuYx1WbWucDhNzIuImrtCbo3v_OEVJWxkXvs-UN3OBTkTpo_qpisFPQjw9SzYKEyjEpkpEcy7hFznjlWJ2y9OOIiAux2QeazkdcxPenmLBuMdYK1oPP2eIVU-6-ACVU2Ni5jTg6hxFhGaHjvp3n2hb5eJBhzJ0DDtgn_pH15SB44r2Fxrnv1WmYExiWpEFiNZRcI7A76gnwtOxzWBw9l_ny-2IMyM5qbNOP9j_AaClgil7xTr4woGxwl3DHT1JkVUp6P6tR6-av9r_1vqJggg6-U3AcenIVBh-LJiKBe4Fu4naCr1yiYOuFk5maN7l4UrrdAHyPRP-oeIGxH7JnJYQtvAKnYkt8tZUavUB1-u9_p3Jq5kZIFXde8Fq56YOZrI5ge_Nzz-1zwqy71sORklu2gKQ2ynBYGTfQvP39r6npd47uT4sKxXxWxBo9v_HSV2Y1vA25a6iS1KPBj7o9Bdh4zmpyVAZMKLkRTjimMAJIFdIMeRWOeqV0iUdydaejAWHXdG4Jw3BoTW_Wkr2bUUYz20VMCPV5WPiCemjgmwGTvToHJnwf470";
        await axios({
            //"https://app.facturandoenlinea.mx/v3/facturas-xml/eyJpdiI6IjZiQWc3WVBLREsrdFNhRzBCM3p0UGc9PSIsInZhbHVlIjoiRW1Fd3BncEpObVM5UlI0aDZUQnFLdz09IiwibWFjIjoiMzNjMjk2OTk3ZWExNTA5ZGE3NTY5NGI5NzE1MWE4YTg4ZTA3NDQ1ZDc4NGE1NWJiMjMyMGE1ZWRmNjhlNTUyMCJ9"
            url: `https://app.facturandoenlinea.mx/v3/facturas-xml/${idFactura}`,
            headers: { Authorization: `Bearer ${token}` },
            method: 'GET',
            responseType: 'blob', // important
          }).then((response) => {
            if (!window.navigator.msSaveOrOpenBlob){
              // BLOB NAVIGATOR
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', 'download.xml');
              document.body.appendChild(link);
              link.click();
            }else{
              // BLOB FOR EXPLORER 11
              const url = window.navigator.msSaveOrOpenBlob(new Blob([response.data]),"download.xml");
            }
          });


         /*
         return axios({
            method: 'GET',
            headers: { Authorization: `Bearer ${jwt}` },
            url: `https://example.com/api/${url}`,
            responseType: 'blob'
          })
          .then(response => {
            if (response) {
              const file = new Blob([response.data], {type:'image/png'})
              return file
            }
            return Promise.reject('An unknown error occurred');
          });


         try {
             const resultado = await clienteAxios.get(`/v3/link-xml/${idFactura}`);
             console.log('PDF BASE64');
             console.log(resultado.data.data);
            const url = window.URL.createObjectURL(new Blob([resultado.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'file.pdf'); //or any other extension
            document.body.appendChild(link);
            link.click();
             
         } catch (error) {
             const alerta = {
                 msg: 'Hubo un error',
                 categoria: 'alerta-error'
             }
             console.log(alerta);
             
             // dispatch({
             //     type: FACTURA_ERROR,
             //     payload: alerta
             // })
         } 
         */
 
    }


    return (  
        <div>
            descargar xml
        </div>
    );
}
 
export default DescargarXml;