import React from 'react';
import EditarCtaBancaria from './EditarCtaBancaria';
import NuevaCtaBancaria from './NuevaCtaBancaria';

function MisBancos() {
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12">
                <div className="card">
                    <h3 className="card-header">Bancos
                    <div className="float-right">
                        <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#newbanco"><i class="fas fa-plus"></i>Agregar</button>
                    </div>
                    </h3>
                <div className="card-body">
                <table id="example1" className="table table-bordered table-striped">
                    <thead>
                    <tr>
                    <th>Banco</th>
                    <th>RFC</th>
                    <th>Num. Cuenta</th>
                    <th>Status</th>
                    <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                    <td>Banamex</td>
                    <td>
                        BNM840515VB1
                    </td>
                    <td>79461332655498</td>
                    <td>Activo</td>
                    <td><button type="button" className="btn btn-primary" data-toggle="modal" data-target="#editbanco"><i className="fas fa-edit"></i>Editar</button></td>
                    </tr>
                    <tr>
                    <td>Banamex</td>
                    <td>
                        BNM840515VB1
                    </td>
                    <td>794561423321654</td>
                    <td>Activo</td>
                    <td><button type="button" className="btn btn-primary" data-toggle="modal" data-target="#editbanco"><i className="fas fa-edit"></i>Editar</button></td>
                    </tr>
                    <tr>
                    <td>Banamex</td>
                    <td>
                        BNM840515VB1
                    </td>
                    <td>794561423321654</td>
                    <td>Activo</td>
                    <td><button type="button" className="btn btn-primary" data-toggle="modal" data-target="#editbanco"><i className="fas fa-edit"></i>Editar</button></td>
                    </tr>
                    <tr>
                    <td>Banamex</td>
                    <td>
                        BNM840515VB1
                    </td>
                    <td>7889456123321</td>
                    <td>Activo</td>
                    <td><button type="button" className="btn btn-primary" data-toggle="modal" data-target="#editbanco"><i className="fas fa-edit"></i>Editar</button></td>
                    </tr>
                    </tbody>
                </table>
                </div>
            </div>
                </div>
            </div>
        <div class="modal fade bd-example-modal-lg" id="editbanco" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Nueva Cuenta Bancaria</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <EditarCtaBancaria />
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-danger" data-dismiss="modal"><i className="fas fa-times"></i>Cerrar</button>
              <button type="button" class="btn btn-primary"><i className="fas fa-save"></i>Guardar</button>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade bd-example-modal-lg" id="newbanco" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Nueva Cuenta Bancaria</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <NuevaCtaBancaria />
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-danger" data-dismiss="modal"><i className="fas fa-times"></i>Cerrar</button>
              <button type="button" class="btn btn-primary"><i className="fas fa-save"></i>Guardar</button>
            </div>
          </div>
        </div>
      </div>
        </div>

    );
}

export default MisBancos;