import React, { Component, Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { loadTree } from "../js/MenuTreeHelper";

class Sidebar extends Component {
  componentDidMount() { loadTree(); }

 
  // useEffect( () => {
    //   loadTree();
    // })
    render() {
        return (
            <Fragment>
              <aside className="main-sidebar sidebar-dark-primary elevation-4">
                <Link to="/dashboard" className="brand-link">
                  <img src="/dist/img/logo.png" alt="Red2000 Logo" className="brand-image elevation-3" style={{opacity: '.8'}} />
                  <span className="brand-text font-weight-light">Facturando en línea</span>
                </Link>
                <div className="sidebar">
                  <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                    <div className="image">
                      <i className="far fa-user" style={{color: 'white', marginLeft:'0.5rem'}}></i>
                    </div>
                    <div className="info">
                      <a href="#" className="d-block">Alexander Pierce</a>
                    </div>
                  </div>
                  <nav className="mt-2">
                    <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                      <li className="nav-item">
                        <Link to="/dashboard" className="nav-link">
                          <i className="nav-icon fas fa-home" />
                          <p>
                            Inicio
                          </p>
                        </Link>
                      </li>
                      <li className="nav-item has-treeview">
                        <a href="#" className="nav-link">
                          <i className="nav-icon fas fa-copy" />
                          <p>
                            Facturación
                            <i className="fas fa-angle-left right" />
                          </p>
                        </a>
                        <ul className="nav nav-treeview">
                          <li className="nav-item">
                            <Link to="/carta-porte" className="nav-link">
                              <p>CFDI Traslado</p>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link to="/cfdi-traslado" className="nav-link">
                              <p>Consultar Traslados</p>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link to="/nuevo-cfdi-pago2" className="nav-link">
                              <p>CFDI de Pago 2</p>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link to="/nuevo-cfdi-pago" className="nav-link">
                              <p>CFDI de Pago</p>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link to="/cfdi-pago" className="nav-link">
                              <p>Consultar Pagos</p>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link to="/nuevo-nota-credito3_3" className="nav-link">
                              <p>Nota de Crédito</p>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link to="/cfdi-nota-credito3_3" className="nav-link">
                             
                              <p>Consultar Nota de Crédito</p>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link to="/nuevo-cfdi" className="nav-link">
                              <p>Facturar</p>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link to="/cfdi" className="nav-link">
                              <p>Consultar Facturas 3.3</p>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link to="/precfdi" className="nav-link">
                              <p>Consultar Pre-Facturas</p>
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="nav-item has-treeview">
                        <a href="#" className="nav-link">
                          <i className="nav-icon fas fa-users" />
                          <p>
                            Clientes
                            <i className="right fas fa-angle-left" />
                          </p>
                        </a>
                        <ul className="nav nav-treeview">
                          <li className="nav-item">
                            <Link to="/nuevo-cliente" className="nav-link">
                              <p>Registrar 3.3</p>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link to="/cliente" className="nav-link">
                              <p>Buscar 3.3</p>
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="nav-item has-treeview">
                        <a href="#" className="nav-link">
                          <i className="nav-icon fas fa-user-graduate" />
                          <p>
                            Alumno
                            <i className="right fas fa-angle-left" />
                          </p>
                        </a>
                        <ul className="nav nav-treeview">
                          <li className="nav-item">
                            <Link to="/nuevo-alumno" className="nav-link">
                              <p>Registrar 3.3</p>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link to="/alumno" className="nav-link">
                              <p>Buscar 3.3</p>
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="nav-item has-treeview">
                        <Link to="/nuevo-producto" className="nav-link">
                          <i className="nav-icon fas fa-tags" />
                          <p>
                            Productos o Servicios
                            <i className="fas fa-angle-left right" />
                          </p>
                        </Link>
                        <ul className="nav nav-treeview">
                          <li className="nav-item">
                            <Link to="/nuevo-producto" className="nav-link">
                              <p>Registrar 3.3</p>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link to="/productos/1" className="nav-link">
                              <p>Buscar 3.3</p>
                            </Link>
                          </li>
                        </ul>
                      </li>

                      <li className="nav-item has-treeview">
                        <Link to="/nuevo-producto" className="nav-link">
                          <i className="nav-icon fas fa-clipboard" />
                          <p>
                            Reportes
                            <i className="fas fa-angle-left right" />
                          </p>
                        </Link>
                        <ul className="nav nav-treeview">
                          <li className="nav-item">
                            <Link to="/reporte-ventas" className="nav-link">
                              <p>Reporte de Ventas</p>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link to="/reporte-consumo" className="nav-link">
                              <p>Reporte de Consumo</p>
                            </Link>
                          </li>
                        </ul>
                      </li>

                      <li className="nav-item has-treeview">
                        <a href="#" className="nav-link">
                          <i className="nav-icon fas fa-cogs" />
                          <p>
                            Configuración
                            <i className="fas fa-angle-left right" />
                          </p>
                        </a>
                        <ul className="nav nav-treeview">
                          <li className="nav-item">
                            <Link to="/datos-empresa" className="nav-link">
                              <p>Datos Empresa</p>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link to="/mis-bancos" className="nav-link">
                              <p>Bancos</p>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link to="/series" className="nav-link">
                              <p>Series</p>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link to="/certificados" className="nav-link">
                              <p>Certificados</p>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link to="/usuarios" className="nav-link">
                              <p>Usuarios</p>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link to="/cambiar-contraseña" className="nav-link">
                              <p>Cambiar Contraseña</p>
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </nav>
                </div>
              </aside>
            </Fragment>

        );
    }
}

export default Sidebar;