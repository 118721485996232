import React, { useContext, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import Header from './Header';
import Sidebar from './Sidebar';
// import Footer from './Footer';
// import Productos from './productos/Productos';
import Productos from './productos/Productos2';
import EditarProducto from './productos/EditarProducto';
import Dashboard from './Dashboard';
import NuevoProducto from './productos/NuevoProducto';
import CfdiTraslado from './CfdiTraslado';
import NuevoCfdiTraslado from './NuevoCfdiTraslado';
import NuevoCfdiDePago2 from './NuevoCfdiDePago2';
import NuevoCfdiDePago from './NuevoCfdiDePago';
import CfdiDePago from './CfdiDePago';
import NuevoCfdiNotaDeCredito from './NuevoCfdiNotaDeCredito';
import CfdiNotaDeCredito3 from './CfdiNotaDeCredito3';
import PreCfdi from './PreCfdi';
import Cfdi from './Cfdi';
import ReporteVentas from './ReporteVentas';
import ReporteConsumo from './ReporteConsumo';
import ReporteConsumoFolios from './ReporteConsumoFolios';
import NuevoCfdi from './NuevoCfdi';

import NuevoCliente from './clientes/NuevoCliente';
import EditarCliente from './clientes/EditarCliente';
import Clientes from './clientes/Clientes';
import DatosEmpresa from './DatosEmpresa';
import EditarDatosEmpresa from './EditarDatosEmpresa';
import SubirCertificado from './SubirCertificado';
import EditarFactura from './EditarFactura';
import PaquetesAdquiridos from './PaquetesAdquiridos';
import Alumno from './Alumno';
import NuevoAlumno from './NuevoAlumno';
import EditarAlumno from './EditarAlumno';
import CambiarContrasena from './CambiarContrasena';
import ComplementoDetallista from './ComplementoDetallista';
// import ComplementoDetallista2 from './ComplementoDetallista2';
import ComplementoNotarioPublico from './ComplementoNotarioPublico';
import ComplementoIne from './ComplementoIne';
import ComplementoDonatarias from './ComplementoDonatarias';
import ComplementoAerolineas from './ComplementoAerolineas';
import ComplementoLeyendas from './ComplementoLeyendas';
import ComplementoDivisa from './ComplementoDivisa';
import ComplementoComercio from './ComplementoComercio';
import ComplementoIedu from './ComplementoIedu';
import ComplementoVehiculoUsado from './ComplementoVehiculoUsado';
import ComplementoServiciosConstruccion from './ComplementoServiciosConstruccion';
import ComplementoConceptoTerceros from './ComplementoConceptoTerceros';
import CartaPorte from './CartaPorte';
import ReporteVentasFolios from './ReporteVentasFolios';
import ComplementoPago from './ComplementoPago';
import ComprarFolios from './ComprarFolios';
import DatosCompra from './DatosCompra';
import MisBancos from './MisBancos';
import RecuperarContrasena from './RecuperarContrasena';
import Series from './Series';
import Soporte from './Soporte';
import Certificado from './Certificado';
import TicketSoporte from './TicketSoporte';
import DisenoFactura from './DisenoFactura';
import HistorialReportes from './HistorialReportes';
import Usuarios from './usuarios';
import PreguntasFrecuentes from './PreguntasFrecuentes';
import AgregarCorreos from './AgregarCorreos';
import ImportarClientes from './ImportarClientes';
import ImportarProductos from './ImportarProductos';
import ConsultarTickets from './ConsultarTickets';
import InicioSesion from './InicioSesion';
import FacturaVerPdf from './facturas/FacturaVerPdf';
import FacturaVerAcusePdf from './facturas/FacturaVerAcusePdf';
import DescargarXml from './DescargarXml';
import RutaPrivada from './rutas/RutaPrivada';
import SorianaRemision from './SorianaRemision';
import AuthContext from '../context/autenticacion/authContext';
import RutaPublica from './rutas/RutaPublica';
// import { useContext } from 'react';





const Content = ( ) => {

    const authContext = useContext(AuthContext);
    const { autenticado, autenticando, setAutenticando, cargando, usuarioAutenticado } = authContext;

    useEffect(() => {
        usuarioAutenticado();
        // eslint-disable-next-line
    }, [autenticado]);

    // useEffect(()=>{

    // },[setAutenticando])   
    if ( cargando ) {
        return (
            <h1>Espere...</h1>
        )
    }
    return (
            <Switch>
                <RutaPublica exact path="/recuperar-contrasena" component={RecuperarContrasena} autenticado={autenticado} cargando={cargando}/>
                <RutaPublica exact path="/" component={InicioSesion} autenticado={autenticado} cargando={cargando}/>
                <RutaPublica exact path="/login" component={InicioSesion}  autenticado={autenticado} cargando={cargando}/>
                <div className="wrapper">
                <Header/>
                <Sidebar/>
                <div className="content-wrapper">
                <RutaPrivada exact path="/cfdi-traslado" component={CfdiTraslado} autenticado={autenticado} cargando={cargando}/>
                <RutaPrivada exact path="/nuevo-cfdi-traslado" component={NuevoCfdiTraslado} autenticado={autenticado} cargando={cargando}/>
                <RutaPrivada exact path="/nuevo-cfdi-pago2" component={NuevoCfdiDePago2} autenticado={autenticado} cargando={cargando}/>
                <RutaPrivada exact path="/nuevo-cfdi-pago" component={NuevoCfdiDePago} autenticado={autenticado} cargando={cargando}/>
                <RutaPrivada exact path="/cfdi-pago" component={CfdiDePago} autenticado={autenticado} cargando={cargando}/>
                <RutaPrivada exact path="/productos/:paginaNumero" component={Productos} autenticado={autenticado} cargando={cargando}/>
                <RutaPrivada exact path="/nuevo-producto" component={NuevoProducto} autenticado={autenticado} cargando={cargando}/>
                <RutaPrivada exact path="/editar-producto/:idProducto" component={EditarProducto} autenticado={autenticado} cargando={cargando}/>
                <RutaPrivada exact path="/cfdi-nota-credito3_3" component={CfdiNotaDeCredito3} autenticado={autenticado} cargando={cargando}/>
                <RutaPrivada exact path="/nuevo-nota-credito3_3" component={NuevoCfdiNotaDeCredito} autenticado={autenticado} cargando={cargando}/>
                <RutaPrivada exact path="/nuevo-cfdi" component={NuevoCfdi} autenticado={autenticado} cargando={cargando}/>
                <RutaPrivada exact path="/cfdi" component={Cfdi} autenticado={autenticado} cargando={cargando}/>
                <RutaPrivada exact path="/precfdi" component={PreCfdi} autenticado={autenticado} cargando={cargando}/>
                <RutaPrivada exact path="/reporte-ventas" component={ReporteVentas} autenticado={autenticado} cargando={cargando}/>
                <RutaPrivada exact path="/reporte-consumo" component={ReporteConsumo} autenticado={autenticado} cargando={cargando}/>
                <RutaPrivada exact path="/nuevo-cliente" component={NuevoCliente} autenticado={autenticado} cargando={cargando}/>
                <RutaPrivada exact path="/cliente" component={Clientes} autenticado={autenticado} cargando={cargando}/>
                <RutaPrivada exact path="/editar-cliente/:idCliente" component={EditarCliente} autenticado={autenticado} cargando={cargando}/>
                <RutaPrivada exact path="/datos-empresa" component={DatosEmpresa} autenticado={autenticado} cargando={cargando}/>
                <RutaPrivada exact path="/editar-datos-empresa/:idSucursal" component={EditarDatosEmpresa} autenticado={autenticado} cargando={cargando}/>
                <RutaPrivada exact path="/subir-certificado" component={SubirCertificado} autenticado={autenticado} cargando={cargando}/>
                <RutaPrivada exact path="/editar-factura/:idFactura" component={EditarFactura} autenticado={autenticado} cargando={cargando}/>
                <RutaPrivada exact path="/alumno" component={Alumno} autenticado={autenticado} cargando={cargando}/>
                <RutaPrivada exact path="/nuevo-alumno" component={NuevoAlumno} autenticado={autenticado} cargando={cargando}/>
                <RutaPrivada exact path="/editar-alumno/:idAlumno" component={EditarAlumno} autenticado={autenticado} cargando={cargando}/>
                <RutaPrivada exact path="/consumo-folios" component={ReporteConsumoFolios} autenticado={autenticado} cargando={cargando}/>
                <RutaPrivada exact path="/paquetes-adquiridos" component={PaquetesAdquiridos} autenticado={autenticado} cargando={cargando}/>
                <RutaPrivada exact path="/cambiar-contrasena" component={CambiarContrasena} autenticado={autenticado} cargando={cargando}/>
                <RutaPrivada exact path="/complemento-detallista" component={ComplementoDetallista} autenticado={autenticado} cargando={cargando}/>
                <RutaPrivada exact path="/complemento-notario" component={ComplementoNotarioPublico} autenticado={autenticado} cargando={cargando}/>
                <RutaPrivada exact path="/complemento-ine" component={ComplementoIne} autenticado={autenticado} cargando={cargando}/>
                <RutaPrivada exact path="/complemento-donatarias" component={ComplementoDonatarias} autenticado={autenticado} cargando={cargando}/>
                <RutaPrivada exact path="/complemento-aerolineas" component={ComplementoAerolineas} autenticado={autenticado} cargando={cargando}/>
                <RutaPrivada exact path="/complemento-leyendas" component={ComplementoLeyendas} autenticado={autenticado} cargando={cargando}/>
                <RutaPrivada exact path="/complemento-divisa" component={ComplementoDivisa} autenticado={autenticado} cargando={cargando}/>
                <RutaPrivada exact path="/complemento-comercio" component={ComplementoComercio} autenticado={autenticado} cargando={cargando}/>
                <RutaPrivada exact path="/complemento-iedu" component={ComplementoIedu} autenticado={autenticado} cargando={cargando}/>
                <RutaPrivada exact path="/complemento-vehiculo-usado" component={ComplementoVehiculoUsado} autenticado={autenticado} cargando={cargando}/>
                <RutaPrivada exact path="/complemento-servicios-construccion" component={ComplementoServiciosConstruccion} autenticado={autenticado} cargando={cargando}/>
                <RutaPrivada exact path="/complemento-concepto-terceros" component={ComplementoConceptoTerceros} autenticado={autenticado} cargando={cargando}/>
                <RutaPrivada exact path="/carta-porte" component={CartaPorte} autenticado={autenticado} cargando={cargando}/>
                <RutaPrivada exact path="/reporte-ventas-folios" component={ReporteVentasFolios} autenticado={autenticado} cargando={cargando}/>
                <RutaPrivada exact path="/complemento-pago" component={ComplementoPago} autenticado={autenticado} cargando={cargando}/>
                <RutaPrivada exact path="/comprar-folios" component={ComprarFolios} autenticado={autenticado} cargando={cargando}/>
                <RutaPrivada exact path="/datos-compra" component={DatosCompra} autenticado={autenticado} cargando={cargando}/>
                <RutaPrivada exact path="/mis-bancos" component={MisBancos} autenticado={autenticado} cargando={cargando}/>
                <RutaPrivada exact path="/series" component={Series} autenticado={autenticado} cargando={cargando}/>
                <RutaPrivada exact path="/soporte" component={Soporte} autenticado={autenticado} cargando={cargando}/>
                <RutaPrivada exact path="/certificados" component={Certificado} autenticado={autenticado} cargando={cargando}/>
                <RutaPrivada exact path="/ticket-soporte" component={TicketSoporte} autenticado={autenticado} cargando={cargando}/>
                <RutaPrivada exact path="/diseno-factura" component={DisenoFactura} autenticado={autenticado} cargando={cargando}/>
                <RutaPrivada exact path="/historial-reportes" component={HistorialReportes} autenticado={autenticado} cargando={cargando}/>
                <RutaPrivada exact path="/usuarios" component={Usuarios} autenticado={autenticado} cargando={cargando}/>
                <RutaPrivada exact path="/preguntas-frecuentes" component={PreguntasFrecuentes} autenticado={autenticado} cargando={cargando}/>
                <RutaPrivada exact path="/agregar-correos" component={AgregarCorreos} autenticado={autenticado} cargando={cargando}/>
                <RutaPrivada exact path="/importar-clientes" component={ImportarClientes} autenticado={autenticado} cargando={cargando}/>
                <RutaPrivada exact path="/consultar-tickets" component={ConsultarTickets} autenticado={autenticado} cargando={cargando}/>
                <RutaPrivada exact path="/importar-productos" component={ImportarProductos} autenticado={autenticado} cargando={cargando}/>
                <RutaPrivada exact path="/factura-ver-pdf/:idFactura/:uuid/:emails" component={FacturaVerPdf} autenticado={autenticado} cargando={cargando}/>
                <RutaPrivada exact path="/factura-ver-acuse-pdf/:idFactura/:uuid/:emails" component={FacturaVerAcusePdf} autenticado={autenticado} cargando={cargando}/>
                <RutaPrivada exact path="/descargar-xml/:idFactura/:uuid" component={DescargarXml} autenticado={autenticado} cargando={cargando}/>
                <RutaPrivada exact path="/soriana-remision" component={SorianaRemision} autenticado={autenticado} cargando={cargando}/>
                <RutaPrivada exact path="/dashboard" component={Dashboard} autenticado={autenticado} cargando={cargando}/>
                </div>
                </div>
            </Switch>
    );
}




// class Content extends Component {
//     render() {
//         return (
//             <div className="content-wrapper">



//             </div>
//         );
//     }
// }

export default Content;