import React from 'react';

function EnvasesUniversales() {
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-4">
                   <label>Número de pedido:</label>
                   <div className="form-group">
                       <input className="form-control" /> 
                   </div>
                </div>
                <div className="col-md-4">
                   <label>Entrada Almacen 1:</label>
                   <div className="form-group">
                       <input className="form-control" /> 
                   </div>
                </div>
                <div className="col-md-4">
                   <label>Entrada Almacen 2:</label>
                   <div className="form-group">
                       <input className="form-control" /> 
                   </div>
                </div>
                <div className="col-md-4">
                   <label>Entrada Almacen 3:</label>
                   <div className="form-group">
                       <input className="form-control" /> 
                   </div>
                </div>
                <div className="col-md-4">
                   <label>Entrada Almacen 4:</label>
                   <div className="form-group">
                       <input className="form-control" /> 
                   </div>
                </div>
                <div className="col-md-4">
                   <label>Entrada Almacen 5:</label>
                   <div className="form-group">
                       <input className="form-control" /> 
                   </div>
                </div>
                <div className="col-md-4">
                   <label>Entrada Almacen 6:</label>
                   <div className="form-group">
                       <input className="form-control" /> 
                   </div>
                </div>
                <div className="col-md-4">
                   <label>Entrada Almacen 7:</label>
                   <div className="form-group">
                       <input className="form-control" /> 
                   </div>
                </div>
                <div className="col-md-4">
                   <label>Entrada Almacen 8:</label>
                   <div className="form-group">
                       <input className="form-control" /> 
                   </div>
                </div>
                <div className="col-md-4">
                   <label>Entrada Almacen 9:</label>
                   <div className="form-group">
                       <input className="form-control" /> 
                   </div>
                </div>
                <div className="col-md-4">
                   <label>Entrada Almacen 10:</label>
                   <div className="form-group">
                       <input className="form-control" /> 
                   </div>
                </div>
                <div className="col-md-6">
                    <button className="btn btn-primary"><i className="fas fa-plus"></i>Agregar</button>
                </div>
                <div className="col-md-6">
                    <button className="btn btn-primary"><i className="fas fa-times"></i>Eliminar</button>
                </div>
                <div className="col-md-12">
                    <div className="table-responsive mt-2">
                        <table className="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th colSpan="11">Ordenes y entradas al almacen</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>Pedido</th>
                                    <th>Entrada Almacen 1</th>
                                    <th>Entrada Almacen 2</th>
                                    <th>Entrada Almacen 3</th>
                                    <th>Entrada Almacen 4</th>
                                    <th>Entrada Almacen 5</th>
                                    <th>Entrada Almacen 6</th>
                                    <th>Entrada Almacen 7</th>
                                    <th>Entrada Almacen 8</th>
                                    <th>Entrada Almacen 9</th>
                                    <th>Entrada Almacen 10</th>
                                </tr>
                                <tr>
                                    <td>123</td>
                                    <td>123</td>
                                    <td>123</td>
                                    <td>123</td>
                                    <td>123</td>
                                    <td>123</td>
                                    <td>123</td>
                                    <td>123</td>
                                    <td>123</td>
                                    <td>123</td>
                                    <td>123</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="col-md-4">
                    <label>Transacción:</label>
                    <div className="form-group">
                        <input className="form-control"/>
                    </div>
                </div>
                <div className="col-md-4">
                    <label>Moneda:</label>
                    <div className="form-group">
                        <select className="form-control">
                            <option selected>MXN - Peso Mexicano</option>
                            <option>USD - Dolar Americano</option>
                            <option>EUR - Euro</option>
                        </select>
                    </div>
                </div>
                <div className="col-md-4">
                    <label>Tipo de cambio:</label>
                    <div className="form-group">
                        <input className="form-control"/>
                    </div>
                </div>
                <div className="col-md-4">
                    <label>SubTotal:</label>
                    <div className="form-group">
                        <input className="form-control"/>
                    </div>
                </div>
                <div className="col-md-4">
                    <label>Imp. Trasladado:</label>
                    <div className="form-group">
                        <input className="form-control"/>
                    </div>
                </div>
                <div className="col-md-4">
                    <label>Imp. Retenido:</label>
                    <div className="form-group">
                        <input className="form-control"/>
                    </div>
                </div>
                <div className="col-md-4">
                    <label>Total:</label>
                    <div className="form-group">
                        <input className="form-control"/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EnvasesUniversales;