import React, { Component } from 'react';

class EditarDatosEmpresa extends Component {
    render() {
        return (
                 <div className="container-fluid">
                    <div ClassName="row">
                        <div className="col-md-10">
                        <div className="card card-primary">
                        <div className="card-header">
                            <h3 className="card-title">Configuración Datos de Empresa</h3>
                            <button type="submit" className="btn btn-primary float"><i class="fas fa-save"></i>Actualizar Datos</button>
                            <button type="submit" className="btn btn-secondary"><i class="fas fa-arrow-left"></i>Atrás</button>
                        </div>
                            <div className="card-body">
                                <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="razon_social">Nombre / Razón Social: </label>
                                        <input type="text" className="form-control" name="razon_social" id="razon_social" placeholder="Buscar Nombre Empresa o Razón Social" />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="rfc">R.F.C.: </label>
                                        <input type="text" className="form-control" name="rfc" required id="rfc" placeholder="" />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="rfc">Nombre Comercial: </label>
                                        <input type="text" className="form-control" name="nombre_comercial" required id="rfc" placeholder="" />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="calle">Calle: </label>
                                        <input type="text" className="form-control" name="calle" placeholder="" />
                                    </div>
                                </div>
                            <div className="col-md-2">
                                <div className="form-group">
                                    <label htmlFor="num_ext">Número Exterior: </label>
                                    <input type="text" className="form-control" name="num_ext" placeholder="" />
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="form-group">
                                    <label htmlFor="num_int">Número Interior: </label>
                                    <input type="text" className="form-control" name="num_int" placeholder="" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="cp">Código Postal: </label>
                                    <input type="text" className="form-control" name="cp" placeholder="" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="colonia">Colonia: </label>
                                    <input type="text" className="form-control" name="colonia" placeholder="" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="localidad">Localidad: </label>
                                    <input type="text" className="form-control" name="localidad" placeholder="" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="referencia">Referencia: </label>
                                    <input type="text" className="form-control" name="referencia" placeholder="" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="delegacion">Delegación/Municipio: </label>
                                    <input type="text" className="form-control" name="delegacion" placeholder="" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="estado">Estado </label>
                                    <input type="text" className="form-control" name="estado" placeholder="" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="pais">País: </label>
                                    <input type="text" className="form-control" name="pais" placeholder="" />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <div className="form-check">
                                    <label htmlFor="precio">Activar Sucursal: </label>
                                        <input type="checkbox" name="act_sucursal" />
                                     </div>
                            
                                </div>
                            </div>
                            <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="calle">Sucursal Calle: </label>
                                        <input type="text" className="form-control" name="calle" placeholder="" />
                                    </div>
                                </div>
                            <div className="col-md-2">
                                <div className="form-group">
                                    <label htmlFor="num_ext">Sucursal Número Exterior: </label>
                                    <input type="text" className="form-control" name="num_ext" placeholder="" />
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="form-group">
                                    <label htmlFor="num_int">Sucursal Número Interior: </label>
                                    <input type="text" className="form-control" name="num_int" placeholder="" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="cp">Sucursal Código Postal: </label>
                                    <input type="text" className="form-control" name="cp" placeholder="" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="colonia">Sucursal Colonia: </label>
                                    <input type="text" className="form-control" name="colonia" placeholder="" />
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="form-group">
                                    <label htmlFor="localidad">Sucursal Localidad: </label>
                                    <input type="text" className="form-control" name="localidad" placeholder="" />
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="form-group">
                                    <label htmlFor="delegacion">Sucursal Delegación/Municipio: </label>
                                    <input type="text" className="form-control" name="delegacion" placeholder="" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="estado">Sucursal Estado: </label>
                                    <select class="form-control" name="residencia_fiscal">
                                        <option>Aguascalientes</option>
                                        <option>Baja California</option>
                                        <option>Baja California Sur</option>
                                        <option>Campeche</option>
                                        <option>Coahuila</option>
                                        <option>Colima</option>
                                        <option>Chiapas</option>
                                        <option>Chihuahua</option>
                                        <option>Ciudad de México</option>
                                        <option>Durango</option>
                                        <option>Guanajuato</option>
                                        <option>Guerrero</option>
                                        <option>Hidalgo</option>
                                        <option>Jalisco</option>
                                        <option>Michoacán</option>
                                        <option>Morelos</option>
                                        <option>Nayarit</option>
                                        <option>Nuevo Leon</option>
                                        <option>Oaxaca</option>
                                        <option>Puebla</option>
                                        <option>Queretaro</option>
                                        <option>Quintana Roo</option>
                                        <option>San Luis Potosi</option>
                                        <option>Sinaloa</option>
                                        <option>Sonora</option>
                                        <option>Tabasco</option>
                                        <option>Tamaulipas</option>
                                        <option>Tlaxcala</option>
                                        <option>Veracruz</option>
                                        <option>Yucatan</option>
                                        <option>Zacatecas</option>
                                        </select>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="email">E-mail: </label>
                                    <input type="email" className="form-control" name="email" placeholder="tucorreo@dominio.com" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="telefonos">Teléfonos: </label>
                                    <input type="text" className="form-control" name="telefonos" placeholder="" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="residencia_fiscal">Regimen Fiscal: </label>
                                        <select class="form-control" name="residencia_fiscal">
                                        <option>REGIMEN SIMPLIFICADO PERSONA MORAL</option>
                                        <option>REGIMEN ACTIVIDAD EMPRESARIAL Y PROFESIONAL PERSONA FISICA</option>
                                        <option>REGIMEN CON FINES NO LUCRATIVAS PERSONA MORAL</option>
                                        <option>REGIMEN INTERMEDIO ACTIVIDAD EMPRESARIAL PERSONA FISICA 4,000,000</option>
                                        <option>REGIMEN DE INCORPORACIÓN FISCAL PERSONA FISICA</option>
                                        <option>REGIMEN GENERAL PERSONA</option>
                                        </select>
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="form-group">
                                    <label htmlFor="residencia_fiscal">Impuesto Comercial: </label>
                                        <select class="form-control" name="residencia_fiscal">
                                        <option>IVA 16%</option>
                                        <option>IVA 0%</option>
                                        <option>EXCENTO IVA</option>
                                        </select>
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="form-group">
                                    <label htmlFor="residencia_fiscal">Documento Emitir: </label>
                                        <select class="form-control" name="residencia_fiscal">
                                        <option>FACTURA</option>
                                        <option>RECIBO DE HONORARIOS</option>
                                        <option>RECIBO DE ARRENDAMIENTO</option>
                                        <option>RECIBO DE DONATIVOS</option>
                                        <option>NOTA DE CARGO</option>
                                        <option>COMPROBANTE DE PAGO A PLAZOS</option>
                                        <option>CARTA DE PORTE</option>
                                        <option>BOLETA DE EMPEÑO</option>
                                        </select>
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="form-group">
                                    <label htmlFor="residencia_fiscal">Retención IVA: </label>
                                        <select class="form-control" name="residencia_fiscal">
                                        <option>4% RETENCION IVA</option>
                                        <option>10% RETENCION IVA</option>
                                        <option>NO APLICA</option>
                                        <option>66.6667% RETENCION IVA</option>
                                        </select>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <div className="form-check">
                                    <label htmlFor="aplica_sobre_iva">Aplica sobre IVA trasladado: </label>
                                        <input type="checkbox" name="aplica_sobre_iva" value="1"/>
                                     </div>
                            
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="ret_isr">Retención ISR: </label>
                                        <select class="form-control" name="ret_isr">
                                        <option>10% RETENCION ISR</option>
                                        <option>NO APLICA</option>
                                        </select>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="imp_local">Impuesto Local: </label>
                                        <select class="form-control" name="imp_local">
                                        <option>NO APLICAR</option>
                                        <option>3% ISH</option>
                                        </select>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="imp_local">5 AL MILLAR: </label>
                                        <select class="form-control" name="implocal_5millar">
                                        <option></option>
                                        </select>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="imp_local">Ret x atraso programa obra: </label>
                                        <select class="form-control" name="ret_gob_por" id="ret_gob_por">
                                        <option>NO APLICAR</option>
                                        <option>5% Retencion</option>
                                        </select>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="imp_local">Devolucion: </label>
                                        <select class="form-control" name="devolucion" id="devolucion">
                                        <option>ACTIVADO</option>
                                        <option>DESACTIVADO</option>
                                        </select>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="regimen">Regimen Fiscal 3.3: </label>
                                        <select class="form-control" name="regimen_clave" required>
                                        <option>601 General de Ley Personas Morales</option>
                                        <option>603 Personas Morales con Fines no Lucrativos</option>
                                        <option>605 Sueldos y Salarios e Ingresos Asimilados a Salarios</option>
                                        <option>606 Arrendamiento</option>
                                        <option>608 Demás Ingresos</option>
                                        <option>609 Consolidación</option>
                                        <option>610 Residentes en el Extranjero sin Establecimiento Permanente en México</option>
                                        <option>611 Ingresos por Dividendos (socios y accionistas)</option>
                                        <option>612 Personas Fisicas con Actividades Empresariales y Profesionales</option>
                                        <option>614 Ingresos por intereses</option>
                                        <option>616 Sin obligaciones fiscales</option>
                                        <option>620 Sociedades Cooperativas de Producción que optan por diferir sus ingresos</option>
                                        <option>621 Incorporación Fiscal</option>
                                        <option>622 Actividades Agricolas, Ganaderas, Silvicolas y Pesqueras</option>
                                        <option>623 Opcional para Grupos de Sociedades</option>
                                        <option>624 Coordinados</option>
                                        <option>628 Hidrocarburos</option>
                                        <option>607 Regimen de Enajenación o Adquisición de Bienes</option>
                                        <option>629 De los Regimenes Fiscales Preferentes y de las Empresas Multinacionales</option>
                                        <option>630 Enajenación de acciones en bolsa de valores</option>
                                        <option>615 Regimen de los ingresos por obtención de premios</option>
                                        </select>
                                </div>
                            </div>
                           
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="banco_nombre">Banco: </label>
                                    <input type="text" className="form-control" id="banco_nombre" name="banco_nombre" placeholder="Busque el banco" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="banco_rfc">RFC: </label>
                                    <input type="text" className="form-control" id="banco_rfc" name="banco_rfc" placeholder="" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="numero_cuenta_ben">Numero Cuenta: </label>
                                    <input type="text" className="form-control" id="numero_cuenta_ben" name="numero_cuenta_ben" placeholder="Teclee el número de cuenta de su cliente" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <div className="form-check">
                                    <label htmlFor="desglose_imp_pdf">Desglose Impuestos PDF: </label>
                                        <input type="checkbox" name="desglose_imp_pdf" value="1" />
                                     </div>
                            
                                </div>
                            </div>
                
                           
                            
                            </div>
                           
                            <div className="card-footer">
                                <div className="float-right">
                                <button type="submit" className="btn btn-primary float"><i class="fas fa-save"></i>Actualizar Datos</button>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
        );
    }
}

export default EditarDatosEmpresa;