export const MOSTRAR_ALERTA = 'MOSTRAR_ALERTA';
export const OCULTAR_ALERTA = 'OCULTAR_ALERTA';

export const REGISTRO_EXITOSO = 'REGISTRO_EXITOSO';
export const REGISTRO_ERROR = 'REGISTRO_ERROR';
export const OBTENER_USUARIO = 'OBTENER_USUARIO';
export const LOGIN_EXITOSO = 'LOGIN_EXITOSO';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const CERRAR_SESION = 'CERRAR_SESION';

export const FORMULARIO_FACTURA = 'FORMULARIO_FACTURA';
export const OBTENER_FACTURAS = 'OBTENER_FACTURAS';
export const AGREGAR_FACTURA = 'AGREGAR_FACTURA';
export const FACTURA_ERROR = 'FACTURA_ERROR';
export const VALIDAR_FORMULARIO_FACTURA = 'VALIDAR_FORMULARIO_FACTURA';
export const FACTURA_ACTUAL = 'FACTURA_ACTUAL';
export const CANCELAR_FACTURA = 'CANCELAR_FACTURA';
export const ACTUALIZAR_ENVIADA = 'ACTUALIZAR_ENVIADA';

export const FORMULARIO_PRODUCTO = 'FORMULARIO_PRODUCTO';  
export const OBTENER_PRODUCTOS = 'OBTENER_PRODUCTOS'; 
export const AGREGAR_PRODUCTO = 'AGREGAR_PRODUCTO'; 
export const PRODUCTO_ERROR = 'PRODUCTO_ERROR'; 
export const VALIDAR_FORMULARIO_PRODUCTO = 'VALIDAR_FORMULARIO_PRODUCTO'; 
export const PRODUCTO_ACTUAL = 'PRODUCTO_ACTUAL'; 
export const ELIMINAR_PRODUCTO = 'ELIMINAR_PRODUCTO'; 

export const FORMULARIO_CLIENTE = 'FORMULARIO_CLIENTE'; 
export const OBTENER_CLIENTES = 'OBTENER_CLIENTES';
export const AGREGAR_CLIENTE = 'AGREGAR_CLIENTE';
export const CLIENTE_ERROR = 'CLIENTE_ERROR';
export const VALIDAR_FORMULARIO_CLIENTE = 'VALIDAR_FORMULARIO_CLIENTE';
export const CLIENTE_ACTUAL = 'CLIENTE_ACTUAL';
export const ELIMINAR_CLIENTE = 'ELIMINAR_CLIENTE';





    