import React, { useReducer, useState, useContext } from 'react';

import productoContext from './productoContext';
import productoReducer from './productoReducer';

import AuthContext from '../autenticacion/authContext';


import { 
    FORMULARIO_PRODUCTO, 
    OBTENER_PRODUCTOS,
    AGREGAR_PRODUCTO,
    PRODUCTO_ERROR,
    VALIDAR_FORMULARIO_PRODUCTO,
    PRODUCTO_ACTUAL,
    ELIMINAR_PRODUCTO,
    ACTUALIZAR_ENVIADA
} from '../../types';

import clienteAxios from '../../config/axios';


const ProductoState = props => {

    const authContext = useContext(AuthContext);
    const { cerrarSesion } = authContext;
    
    // const [pagina, setPagina] = useState(1);
    const [buscar, setBuscar] = useState("");

    const initialState = {
        productos : [],
        paginacion : [],
        formulario : false,
        errorformulario: false,
        proyecto: null, 
        mensaje: null,
        cargando_productos: true,
    }

    // Dispatch para ejecutar las acciones
    const [state, dispatch] = useReducer(productoReducer, initialState)

    // Serie de funciones para el CRUD
    const mostrarFormulario = () => {
        dispatch({
            type: FORMULARIO_PRODUCTO
        })
    }

    // Obtener los proyectos
    //const obtenerProductos = async (pagina, desde, hasta, serie, folio, estado, rfc, razon_social) => {
    //const obtenerProductos = async (pagina, serie, folio) => {
    // const actualizarEnviada = (factu) => {
    //     dispatch({
    //         type: ACTUALIZAR_ENVIADA,
    //         payload: factu
    //     })
    // } 
    async function obtenerProductos (pagina, buscar) {
        
        //console.log('ESTOS SON LOS PARAMETROS '+folio);
        try {
            //const resultado = await clienteAxios.get(`/v3/facturas?page=${pagina}`,{params: {parametros}});
            const resultado = await clienteAxios.get('/v3/productos',{params: {
                page: pagina,
                busqueda: buscar,
               
            }});
            dispatch({
                type: OBTENER_PRODUCTOS,
                payload: resultado.data
            })
        } catch (error) {
            const alerta = {
                msg: 'Hubo un error',
                categoria: 'alerta-error'
            }
            console.log('CODIGO ERROR');
            
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            if(error.response.status===401){
                console.log('CODIGO 401');
                // localStorage.removeItem('token');
                cerrarSesion();
            }
            dispatch({
                type: PRODUCTO_ERROR,
                payload: alerta
            })
        }
    }

    // Agregar nuevo producto
    const agregarProducto = async producto => {

        try {
            const resultado = await clienteAxios.post('/v3/productos', producto);
            console.log(resultado);
            // Insertar el producto en el state
            dispatch({
                type: AGREGAR_PRODUCTO,
                payload: resultado.data
            })
        } catch (error) {
            if(error.response.status===401){
                cerrarSesion();
            }
            const alerta = {
                msg: 'Hubo un error',
                categoria: 'alerta-error'
            }
            
            dispatch({
                type: PRODUCTO_ERROR,
                payload: alerta
            })
        }
    }

    // Valida el formulario por errores
    const mostrarError = () => {
        dispatch({
            type: VALIDAR_FORMULARIO_PRODUCTO
        })
    } 

    // Selecciona el producto que el usuario dio click
    const productoActual = productoId => {
        dispatch({
            type: PRODUCTO_ACTUAL,
            payload: productoId
        })
    }

    // Elimina un proyecto
    const eliminarProducto = async productoId => {
        try {
            await clienteAxios.delete(`/v3/productos/${productoId}`);
            dispatch({
                type: ELIMINAR_PRODUCTO,
                payload: productoId
            })
        } catch (error) {
            if(error.response.status===401){
                cerrarSesion();
            }
            const alerta = {
                msg: 'Hubo un error',
                categoria: 'alerta-error'
            }
            
            dispatch({
                type: PRODUCTO_ERROR,
                payload: alerta
            })
        }
    }


    return (
        <productoContext.Provider
            value={{
                productos: state.productos,
                paginacion: state.paginacion,
                formulario: state.formulario,
                errorformulario: state.errorformulario,
                producto: state.producto,
                mensaje: state.mensaje,
                cargando_productos: state.cargando_productos,
                mostrarFormulario,
                obtenerProductos,
                agregarProducto,
                mostrarError,
                productoActual,
                eliminarProducto,
                buscar,
                setBuscar
                // actualizarEnviada
                // pagina, 
                // setPagina
                
            }}
        >
            {props.children}
        </productoContext.Provider>
        
    )
}

export default ProductoState;