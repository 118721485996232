import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import clienteAxios from "../../config/axios";
import Cliente from "./Cliente";
import Paginacion from "./Paginacion"

// import productoContext from '../context/productos/productoContext';
// import productoContext from '../../context/productos/productoContext';
import clienteContext from '../../context/clientes/clienteContext';


const Clientes = () => {
  const [buscar, setBuscar] = useState("");
  const [pagina, setPagina] = useState(1);
  const [buscando, setBuscando] = useState(0);
  // const [productos, setProductos] = useState([]);

  const clientesContext = useContext(clienteContext);
  const { cargando_clientes, mensaje, clientes, paginacion, obtenerClientes, actualizarEnviada } = clientesContext;


  const handleChange = (e) => {
    // setBuscar([e.target.name] : e.target.value)
    setBuscar(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      console.log('Oprimio tecla enter para buscar');
      handleSubmit(e);
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setBuscando(1);
    setPagina(1);
    obtenerClientes(pagina, buscar);
    setBuscando(0);
  };

  useEffect(() => {
    setBuscando(0);
    obtenerClientes(pagina, buscar);
  }, []);
/*
  const obtenerClientes = async (pagina, buscar) => {
    try {
      const resultado = await clienteAxios.get("/v3/productos", {
        params: {
          page: pagina,
          producto_descripcion: buscar,
        },
      });
      const tproductos = resultado.data.data;
      setBuscando(0);
      setProductos(tproductos);
      // console.log(resultado.data.data);
    //   console.log(tproductos);
    } catch (error) {
      const alerta = {
        msg: "Hubo un error",
        categoria: "alerta-error",
      };
      setBuscando(0);
    //   console.log(alerta);

      // dispatch({
      //     type: FACTURA_ERROR,
      //     payload: alerta
      // })
    }
  };
  */

  // console.log('LOS PRODUCTOS');
  // console.log(productos);
  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">Clientes</h3>
                <div className="float-left">
                  <Link
                    to="nuevo-producto"
                    type="button"
                    className="btn btn-primary ml-1"
                  >
                    <i className="fas fa-plus"></i>Agregar
                  </Link>
                </div>
                <div className="float-right">
                  <Link
                    to="importar-productos"
                    type="button"
                    className="btn btn-primary ml-1"
                  >
                    <i className="fas fa-file-import"></i>Importar
                  </Link>
                  <Link to="" type="button" className="btn btn-primary ml-1">
                    <i className="fas fa-file-upload"></i>Exportar
                  </Link>
                </div>
              </div>
              {/* <div className="col-md-12">
                                <div id="example1_filter" className="dataTables_filter">
                                    <label>
                                        <input type="search" className="form-control form-control-sm" placeholder="Buscar" aria-controls="example1" />
                                    </label>
                                </div>
                            </div> */}
              <div className="card-body">
                <div className="row">
                  <div className="col-md-3 col-sm-3">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Buscar"
                        name="buscar"
                        onKeyDown={handleKeyDown}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-3">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={handleSubmit}
                      disabled={buscando}
                    >
                      <i className="fa fa-search"></i>
                      {buscando ? "Buscando" : "Buscar"}
                    </button>
                  </div>
                </div>
                <div className="table-responsive">
                  <table
                    id="example1"
                    className="table table-bordered table-striped"
                  >
                    <thead>
                      <tr>
                              {/* <td></td> */}
                              <th>Razón Social</th>
                              <th>RFC</th>
                              {/* <th>Telefonos</th> */}
                              {/* <th>Email</th> */}
                              {/* <th>Estado</th> */}
                              <th>Mun/Alc</th>
                              <th></th>
                              <th></th>
                              {/* <th></th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {!clientes?.length ? (
                        <label>NO SE ENCONTRARON RESULTADOS......</label>
                      ) : (
                        clientes.map((cliente) => (
                          <Cliente
                            key={cliente.identificador}
                            cliente={cliente}
                          />
                        ))
                      )}
                      
                    </tbody>
                  </table>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="float-right">
        <div
          className="dataTables_paginate paging_simple_numbers"
          id="example1_paginate"
        >
           <Paginacion    
                                            parametros={buscar}
                                            // setParametros={setBuscar}
                                            pagina={pagina}
                                            key={`paginacion-${paginacion.current_page}`}
                                            
                                        />
        </div>
      </div>
    </div>
  );
};

export default Clientes;
