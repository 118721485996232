import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import clienteAxios from '../../config/axios';

const Factura = ({ factura, correos, setCorreos, setIdFactura, email_asunto, email_mensaje, setParamFormulario, setEnviado }) => {

    const abrirModal = ( e, correo, fact, email_asunto, email_mensaje ) => {
        e.preventDefault();
        setEnviado(1);
        setCorreos(correo);
        setIdFactura(fact.factura_identificador);
        setParamFormulario({
            emailAsunto: email_asunto,
            emailMensaje1: email_mensaje,
        });
        console.log('Correos en variable correo '+ correo);
        console.log('Correos en state '+ correos);
        console.log('Identificador factura en state '+ correos);
        // $(ReactDOM.findDOMNode(this)).modal('show');
        // $(this.getDOMNode()).on('hidden.bs.modal', this.props.handleHideModal);
    }

    const descargarXml = async (idFact, uuid) => {
        console.log('Descargar xml '+idFact);
        const token ="eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyIiwianRpIjoiZTBmNDBlZTFiYWEzNThkMzYwMDU2MjJiYjU3MTVjNDliNGQ2ODk4YWE1ZmJmZWMyY2EzMmEyNzIyNWJmNDBlZDBlZWQ0ZDIwYzcxOGE1MDkiLCJpYXQiOjE1ODU0NjExNTgsIm5iZiI6MTU4NTQ2MTE1OCwiZXhwIjoxNTg2NzUzNTU4LCJzdWIiOiI2Iiwic2NvcGVzIjpbXX0.q8mYHZrV7rwzQkbqIMQv7pyrXUynnyuZ8s0vjkboXwyJKHOd50qX_Mw7ZMxcHkMCzaxTOCLHp-2ibyHbmMFYoO8EZE-S8gWkHvK7Doyw3oUeROAdgmyKYsuYx1WbWucDhNzIuImrtCbo3v_OEVJWxkXvs-UN3OBTkTpo_qpisFPQjw9SzYKEyjEpkpEcy7hFznjlWJ2y9OOIiAux2QeazkdcxPenmLBuMdYK1oPP2eIVU-6-ACVU2Ni5jTg6hxFhGaHjvp3n2hb5eJBhzJ0DDtgn_pH15SB44r2Fxrnv1WmYExiWpEFiNZRcI7A76gnwtOxzWBw9l_ny-2IMyM5qbNOP9j_AaClgil7xTr4woGxwl3DHT1JkVUp6P6tR6-av9r_1vqJggg6-U3AcenIVBh-LJiKBe4Fu4naCr1yiYOuFk5maN7l4UrrdAHyPRP-oeIGxH7JnJYQtvAKnYkt8tZUavUB1-u9_p3Jq5kZIFXde8Fq56YOZrI5ge_Nzz-1zwqy71sORklu2gKQ2ynBYGTfQvP39r6npd47uT4sKxXxWxBo9v_HSV2Y1vA25a6iS1KPBj7o9Bdh4zmpyVAZMKLkRTjimMAJIFdIMeRWOeqV0iUdydaejAWHXdG4Jw3BoTW_Wkr2bUUYz20VMCPV5WPiCemjgmwGTvToHJnwf470";
        // await axios({
        await clienteAxios({
            //"https://app.facturandoenlinea.mx/v3/facturas-xml/eyJpdiI6IjZiQWc3WVBLREsrdFNhRzBCM3p0UGc9PSIsInZhbHVlIjoiRW1Fd3BncEpObVM5UlI0aDZUQnFLdz09IiwibWFjIjoiMzNjMjk2OTk3ZWExNTA5ZGE3NTY5NGI5NzE1MWE4YTg4ZTA3NDQ1ZDc4NGE1NWJiMjMyMGE1ZWRmNjhlNTUyMCJ9"
            // url: `https://app.facturandoenlinea.mx/v3/facturas-xml/${idFact}?forzar_descarga=1`,
            url: `/v3/facturas-xml/${idFact}?forzar_descarga=1`,
            // headers: { Authorization: `Bearer ${token}` },
            method: 'GET',
            responseType: 'blob', // important
          }).then((response) => {
            if (!window.navigator.msSaveOrOpenBlob){
              // BLOB NAVIGATOR
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', `${uuid}.xml`);
              document.body.appendChild(link);
              link.click();
            }else{
              // BLOB FOR EXPLORER 11
              const url = window.navigator.msSaveOrOpenBlob(new Blob([response.data]),`${uuid}.xml`);
            }
          });
    }
   
    // let inicio = 49;
    // let fin    = factura["link-pdf"].length;
    // let subCadena = factura["link-pdf"].substring(inicio, fin);
    // console.log('SUBCADENA');
    // console.log(subCadena);

    return (  
        <tr>
            <td>{(factura.factura_anulada==1) ? 'Cancelada' : ''}</td>
            <td className={(factura.factura_metodo_pago=='PUE' && factura.factura_timbrada==1) ? 'pagado' : null} >{(factura.factura_serie!=null) ? `${factura.factura_serie}-` : ''} {`${factura.factura_folio}`}</td>
            <td>{factura.factura_fecha_sistema.substr(0,10)}
            </td>
            <td>{factura.factura_cliente}</td>
            <td>{factura.factura_costo_bruto}</td>
            <td>{factura.factura_impc1}</td>
            <td>{factura.factura_descuento}</td>
            <td>{factura.factura_total}</td>
            <td>{factura.factura_pagado}</td>
            <td>0</td>
            <td>{factura.factura_comentario_interno}</td>
            {
            (factura.factura_timbrada==1) ?
                (   <Fragment>
                    <td><button className="btn btn-primary"><i className="fas fa-copy"></i></button></td>
                    <td>
                        <img src="/dist/img/cfdi.gif" width="20" onClick={()=>descargarXml(factura.factura_identificador, factura.factura_uuid)} /> 
                        {/* <Link to={`descargar-xml/${factura.factura_identificador}`} target="_blank" title="Descargar XML"><img src="dist/img/cfdi.gif" width="20" /></Link>*/}
                    </td>
                    <td>
                        
                        <Link to={`/factura-ver-pdf/${factura.factura_identificador}/${factura.factura_uuid}/${factura.factura_cliente_emails}`} title="Descargar PDF"><i className="fas fa-file-pdf"></i></Link>
                        {/* <Link to="ver-pdf" title="Descargar PDF"><i className="fas fa-file-pdf"></i></Link> */}
                        {/* <Link 
                            to={{
                                pathname: `ver-pdf/${factura.factura_identificador}`,
                                state: {
                                    idFactura
                                }
                            }} 
                            title="Descargar PDF">
                            <i className="fas fa-file-pdf"></i>
                        </Link> */}

                    </td>
                    
                    {(factura.factura_anulada==1) ? 
                        (
                            <Fragment>
                                <td></td>
                                <td>
                                    <Link to={`/factura-ver-acuse-pdf/${factura.factura_identificador}/${factura.factura_uuid}/${factura.factura_cliente_emails}`} title="Descargar Acuse de Cancelación"><i className="fas fa-file-pdf"></i></Link>
                                </td>
                            </Fragment>
                        )
                    :
                        (
                            <Fragment>
                                <td><a title="Enviar por Correo" onClick={(e) => abrirModal(e, factura.factura_cliente_emails, factura, email_asunto, email_mensaje) } data-toggle="modal" data-target=".addcorreos" className="enviar-factura" > <i className={(factura.factura_enviada>0) ? 'fas fa-envelope email-enviado' :'fas fa-envelope email-sin-enviar'} ></i> </a></td>
                                <td>
                                    {
                                    (factura.factura_metodo_pago=='PPD' && factura.factura_pagado>0) ? 
                                    null 
                                    : <a href="#" title="Cancelar"><i className="fas fa-trash-alt"></i></a>
                                    }
                                    
                                </td>
                            </Fragment>
                        ) 
                    }
                    {/* <td><a title="Enviar por Correo" onClick={(e) => abrirModal(e, factura.factura_cliente_emails, factura, email_asunto, email_mensaje) } data-toggle="modal" data-target=".addcorreos" className="enviar-factura" > <i className={(factura.factura_enviada>0) ? 'fas fa-envelope email-enviado' :'fas fa-envelope email-sin-enviar'} ></i> </a></td>
                    <td>
                        <a href="#" title="Cancelar"><i className="fas fa-trash-alt"></i></a>
                    </td> */}
                    <td>
                        {(factura.factura_metodo_pago=='PPD' && factura.factura_timbrada==1) ? 
                            <div className="dropdown">
                                <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Opciones
                                </button>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <Link className="dropdown-item" to="/nuevo-cfdi-pago">Ver Pagos</Link>
                                    {(factura.factura_anulada == 1) ?
                                        ''
                                    : 
                                        <Link className="dropdown-item" to="/nuevo-cfdi-pago">Agregar Pago</Link>
                                    }
                                    
                                </div>
                            </div>
                        : null
                        }
                        
                    </td>
                    </Fragment>
                )
            :
                <td colSpan='6' className="retimbrar"><a href="#" title="Retimbrar"><i className="fas fa-sync"></i></a></td>
            }
           
            {/* <td><button className="btn btn-primary"><i className="fas fa-copy"></i></button></td>
            <td><img src="dist/img/cfdi.gif" width="20"/></td>
            <td><img src="dist/img/pdf.gif" width="20"/></td> */}
            {/* <td><a data-toggle="modal" data-target=".addcorreos"><img src="dist/img/email.png" width="20"/></a></td> */}
            {/* <td><a onClick={(e) => abrirModal(e, factura.factura_cliente_emails, factura, email_asunto, email_mensaje) } data-toggle="modal" data-target=".addcorreos" ><img src="dist/img/email.png" width="20"/></a></td> */}
            {/* <td><a onClick={(e) => abrirModal(e, factura.factura_cliente_emails, factura, email_asunto, email_mensaje) } data-toggle="modal" data-target=".addcorreos" > <i className={(factura.factura_enviada>0) ? 'fas fa-envelope email-enviado' :'fas fa-envelope email-sin-enviar'} ></i> </a></td>
            <td><img src="dist/img/anular.gif" width="20"/></td>
            <td>
            <select className="form-control">
                <option
                    selected
                    value="0"
                >Opciones</option>
                <option value="1">Ver Pagos</option>
                <option value="2">Agregar Pago</option>
            </select>
            </td> */}


        </tr>
    );
}
 
export default Factura;