import { 
    FORMULARIO_FACTURA, 
    OBTENER_FACTURAS,
    AGREGAR_FACTURA,
    FACTURA_ERROR,
    VALIDAR_FORMULARIO_FACTURA,
    FACTURA_ACTUAL,
    CANCELAR_FACTURA,
    ACTUALIZAR_ENVIADA
} from '../../types';


export default (state, action) => {
    switch(action.type) {
        case FORMULARIO_FACTURA:
            return {
                ...state,
                formulario: true
            }
        case OBTENER_FACTURAS:
            return {
                ...state,
                facturas: action.payload.data,
                paginacion: (action.payload?.meta?.pagination === undefined) ? '' : action.payload.meta.pagination,
                cargando_facturas: false
            }
        case AGREGAR_FACTURA:
            return {
                ...state,
                proyectos: [...state.proyectos, action.payload],
                formulario: false,
                errorformulario: false
            }
        case VALIDAR_FORMULARIO_FACTURA:
            return {
                ...state, 
                errorformulario: true
            }
        case FACTURA_ACTUAL:
            return {
                ...state,
                proyecto: state.proyectos.filter(proyecto => proyecto._id === action.payload )
            }
        case CANCELAR_FACTURA:
            return {
                ...state,
                proyectos: state.proyectos.filter(proyecto => proyecto._id !== action.payload ),
                proyecto: null
            }
        case FACTURA_ERROR:
            return {
                ...state,
                mensaje: action.payload
            }
        case ACTUALIZAR_ENVIADA:
            // console.log("IMPRIMIMOS PAYLOAD")
            // console.log(action.payload);
            // console.log("FIN IMPRIMIMOS PAYLOAD")
            return {
                ...state,
                // facturas: action.payload
            }
        default:
            return state;
    }
}