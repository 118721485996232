import React, { Component } from 'react';

class ComplementoDivisa extends Component {
    render() {
        return (
            <div>
                <div className="container-fluid">
                    <div className="col-md-12">
                              <h4>
                                Complemento Divisa
                              </h4>
                              <hr></hr>
                                <div className="row">
                                  <div className="col-md-12 col-sm-12">
                                    <label>Tipo de Operación:</label>
                                    <div className="input-group mb-3">
                                      <select
                                        className="custom-select"
                                        id="inputGroupSelect01"
                                      >
                                        <option selected>
                                          Seleccione Tipo de Operación
                                        </option>
                                        <option>Venta</option>
                                        <option>Compra</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                      </div>
                </div>
            </div>
        );
    }
}

export default ComplementoDivisa;