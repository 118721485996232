import React, { Component } from 'react';

class ReporteConsumoFolios extends Component {
    render() {
        return (
            <div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                            <div className="card-header">
                                <h3>Reporte de Consumo</h3>
                            </div>
                            <div className="card-body">
                                <table id="example1" className="table table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <th colSpan="3"><h3 className="card-title">Reporte de Consumo de Folios <br></br> Fecha y Hora de Consulta: </h3></th>
                                    </tr>
                                    <tr>
                                    <td></td>
                                    <th>Numero de Folios</th>
                                    <th>Fecha de Facturación</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                    <td></td>
                                    <td>1</td>
                                    <td>2020-01-03</td>
                                    </tr>
                                    <tr>
                                    <td></td>
                                    <td>1</td>
                                    <td>2020-01-03</td>
                                        </tr>
                                </tbody>
                                </table>
                            </div>
                            </div>
                                </div>
                            </div>
                        </div>
                    </div>
        );
    }
}

export default ReporteConsumoFolios;