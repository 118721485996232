import React from "react";

const CartaPorte = () => {
  return (
    <div className="container-fluid">
      <div ClassName="row">
        <div className="col-md-12">
          <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">Registro de Carta Porte</h3>
              <div className="float-right">
                <button type="submit" className="btn btn-primary">
                <i className="fas fa-save"></i>Timbrar
                </button>
                <button type="submit" className="btn btn-secondary">
                  <i className="fas fa-arrow-left"></i>Atrás
                </button>
              </div>
            </div>
            <form role="form">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-8">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label htmlFor="clave">
                            Cliente / Razón Social / RFC*:
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="clave"
                            placeholder="Buscar cliente por Razón Social O RFC"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4 col-sm-4">
                        <div className="form-group">
                          <label htmlFor="clave">Email: </label>
                          <input
                            type="text"
                            className="form-control"
                            id="email"
                            placeholder="Email"
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-3">
                        <div className="form-group">
                          <label htmlFor="clave">RFC: </label>
                          <input
                            type="text"
                            className="form-control"
                            id="rfc"
                            placeholder="RFC"
                          />
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-5">
                        <div className="form-group">
                          <label htmlFor="unidad">
                            Tipo de relación con CFDI's:
                          </label>
                          <select className="form-control">
                            <option>SELECCIONE USO DE CFDI</option>
                            <option>G01 Adquisición de mercancias</option>
                            <option>
                              G02 Devoluciones, descuentos o bonificaciones
                            </option>
                            <option>G03 Gastos en general</option>
                            <option>I01 Construcciones</option>
                            <option>
                              I02 Mobiliario y equipo de oficina por inversiones
                            </option>
                            <option>I03 Equipo de transporte</option>
                            <option>I04 Equipo de computo y accesorios</option>
                            <option>
                              I05 Dados, troqueles, moldes, matrices y
                              herramental
                            </option>
                            <option>I06 Comunicaciones telefónicas</option>
                            <option>I07 Comunicaciones satelitales</option>
                            <option>I08 Otra maquinaria y equipo</option>
                            <option>P01 Por definir</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-1"></div>
                  <div className="col-md-3">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label htmlFor="unidad">Seleccione serie: </label>
                          <select className="form-control">
                            <option>SELECCIONE SERIE</option>
                            <option>Factura A559</option>
                            <option>Factura TCC22</option>
                            <option>Factura E188</option>
                            <option>Factura 2222loloo3</option>
                            <option>
                              I02 Mobiliario y equipo de oficina por inversiones
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label htmlFor="unidad">Seleccione fecha: </label>
                          <div className="input-group">
                            <input
                              type="text"
                              className="form-control"
                              id="datepicker"
                              placeholder="Seleccione fecha"
                            ></input>
                            <div className="input-group-append" data-toggle="tooltip" data-placement="top" title="Seleccione fecha">
                              <button
                                className="input-group-text"
                                id="datepicker"
                              >
                                <i
                                  className="fas fa-calendar-alt"
                                  data-toggle="datepicker"
                                ></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <label htmlFor="">Descripción: </label>
                    <div className="input-group">
                      <input type="text" className="form-control" />
                      <div className="input-group-append" data-toggle="tooltip" data-placement="top" title="Buscar Producto o Servicio">
                        <span
                          className="input-group-text"
                          data-toggle="modal"
                          data-target="#descripcion"
                        >
                          <i className="fas fa-pencil-alt"></i>
                        </span>
                      </div>
                    </div>
                    <div
                      className="modal fade"
                      id="descripcion"
                      tabIndex={-1}
                      role="dialog"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog" role="document">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                              Descripción
                            </h5>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">×</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="form-group">
                              <textarea
                                className="form-control"
                                id="exampleFormControlTextarea1"
                                rows={3}
                                defaultValue={""}
                              />
                            </div>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-danger"
                              data-dismiss="modal"
                            >
                              <i className="fas fa-times"></i>Eliminar
                            </button>
                            <button type="button" className="btn btn-primary">
                              <i className="fas fa-save"></i>Guardar
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2 col-sm-6">
                    <label htmlFor="">Clave SAT: </label>
                    <div className="input-group">
                      <input type="text" className="form-control" />
                      <div className="input-group-append">
                        <span
                          className="input-group-text"
                          data-toggle="modal"
                          data-target="#clavesat"
                        >
                          <i className="fas fa-search"></i>
                        </span>
                      </div>
                    </div>
                    <div
                      className="modal fade bd-example-modal-lg"
                      id="clavesat"
                      tabindex="-1"
                      role="dialog"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                              Catálogo SAT de Productos o Servicios
                            </h5>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="form-group">
                              <label>División </label>
                              <select className="form-control">
                                <option>Todas</option>
                                <option>IVA Tasa 0.0160000</option>
                                <option>IVA Tasa 0.0000000</option>
                                <option>IVA Exento</option>
                                <option>IVA Cuota</option>
                                <option>IVA Tasa 0.080000</option>
                              </select>
                            </div>
                            <div className="form-group">
                              <label>Grupo </label>
                              <select className="form-control" disabled>
                                <option></option>
                                <option>IVA Tasa 0.0160000</option>
                                <option>IVA Tasa 0.0000000</option>
                                <option>IVA Exento</option>
                                <option>IVA Cuota</option>
                                <option>IVA Tasa 0.080000</option>
                              </select>
                            </div>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                aria-label="Amount"
                                placeholder="Buscar por palabra el catálogo"
                              />
                              <div className="input-group-append">
                                <button className="input-group-text">
                                  <i className="fas fa-times"></i>
                                </button>
                              </div>
                            </div>
                            <br></br>
                            <div className="table-responsive">
                              <table className="table table-bordered">
                                <thead>
                                  <tr>
                                    <th scope="col">Seleccionar</th>
                                    <th scope="col">Clave SAT</th>
                                    <th scope="col">Descripción</th>
                                    <th scope="col">Estimulo Frontera</th>
                                    <th scope="col">Similar</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <th scope="row">
                                      <div className="form-check form-check-inline">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          id="inlineCheckbox1"
                                          defaultValue="option1"
                                        />
                                      </div>
                                    </th>
                                    <td>
                                      <p>01010101</p>
                                    </td>
                                    <td>
                                      <p>No existe en el catálogo</p>
                                    </td>
                                    <td>
                                      <span className="label label-default">
                                        No
                                      </span>
                                    </td>
                                    <td>Público en general</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">
                                      <div className="form-check form-check-inline">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          id="inlineCheckbox1"
                                          defaultValue="option1"
                                        />
                                      </div>
                                    </th>
                                    <td>
                                      <p>01010101</p>
                                    </td>
                                    <td>
                                      <p>No existe en el catálogo</p>
                                    </td>
                                    <td>
                                      <span className="label label-default">
                                        No
                                      </span>
                                    </td>
                                    <td>Público en general</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">
                                      <div className="form-check form-check-inline">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          id="inlineCheckbox1"
                                          defaultValue="option1"
                                        />
                                      </div>
                                    </th>
                                    <td>
                                      <p>01010101</p>
                                    </td>
                                    <td>
                                      <p>No existe en el catálogo</p>
                                    </td>
                                    <td>
                                      <span className="label label-default">
                                        No
                                      </span>
                                    </td>
                                    <td>Público en general</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-danger"
                              data-dismiss="modal"
                            >
                              <i className="fas fa-times"></i>
                              Cerrar
                            </button>
                            <button type="button" className="btn btn-primary">
                              <i className="fas fa-save"></i>Guardar
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2 col-sm-6">
                    <div className="form-group">
                      <label htmlFor="descripcion">Cantidad: </label>
                      <input
                        type="text"
                        className="form-control"
                        id="descripcion"
                        placeholder="Cantidad"
                      />
                    </div>
                  </div>
                  <div className="col-md-2 col-sm-6">
                    <label htmlFor="">Unidad: </label>
                    <div className="input-group">
                      <input type="text" className="form-control" />
                      <div className="input-group-append" data-toggle="tooltip" data-placement="top" title="Buscar Unidad">
                        <span
                          className="input-group-text"
                          data-toggle="modal"
                          data-target="#unidad"
                        >
                          <i className="fas fa-search"></i>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div
                    className="modal fade bd-example-modal-lg"
                    id="unidad"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="myLargeModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-lg">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="exampleModalLabel">
                            Catálogo SAT de Unidades de Medida
                          </h5>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <div className="col-md-12">
                            <div className="input-group mb-3">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Recipient's username"
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2"
                              />
                              <div className="input-group-append">
                                <button
                                  className="btn btn-danger"
                                  type="button"
                                >
                                  <i className="fas fa-times"></i>
                                </button>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="input-group">
                                <button
                                  className="btn btn-primary"
                                  type="button"
                                >
                                  <i className="fas fa-search"></i>Buscar
                                </button>
                              </div>
                            </div>
                            <br></br>
                            <div className="table-responsive">
                              <table className="table table-bordered">
                                <thead>
                                  <tr>
                                    <th colspan="3">
                                      Catálogo Oficial del SAT
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <th scope="col">Seleccionar</th>
                                    <th scope="col">Clave SAT</th>
                                    <th scope="col">Descripción</th>
                                  </tr>
                                  <tr>
                                    <th>
                                      <div className="form-check form-check-inline">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          id="inlineCheckbox1"
                                          defaultValue="option1"
                                        />
                                      </div>
                                    </th>
                                    <td>21</td>
                                    <td>Contenedor de cuarenta pies</td>
                                  </tr>
                                  <tr>
                                    <th>
                                      <div className="form-check form-check-inline">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          id="inlineCheckbox1"
                                          defaultValue="option1"
                                        />
                                      </div>
                                    </th>
                                    <td>21</td>
                                    <td>Contenedor de cuarenta pies</td>
                                  </tr>
                                  <tr>
                                    <th>
                                      <div className="form-check form-check-inline">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          id="inlineCheckbox1"
                                          defaultValue="option1"
                                        />
                                      </div>
                                    </th>
                                    <td>21</td>
                                    <td>Contenedor de cuarenta pies</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-sm-12 col-sm-6">
                              <div
                                className="dataTables_length"
                                id="example1_length"
                              >
                                <label>Show entries</label>
                                <select
                                  name="example1_length"
                                  aria-controls="example1"
                                  className="custom-select custom-select-sm form-control form-control-sm"
                                >
                                  <option value="10">10</option>
                                  <option value="25">25</option>
                                  <option value="50">50</option>
                                  <option value="100">100</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-sm-12 col-sm-6">
                              <nav aria-label="Page navigation example">
                                <ul className="pagination">
                                  <li className="page-item">
                                    <a className="page-link" href="#">
                                      Previous
                                    </a>
                                  </li>
                                  <li className="page-item">
                                    <a className="page-link" href="#">
                                      1
                                    </a>
                                  </li>
                                  <li className="page-item">
                                    <a className="page-link" href="#">
                                      2
                                    </a>
                                  </li>
                                  <li className="page-item">
                                    <a className="page-link" href="#">
                                      3
                                    </a>
                                  </li>
                                  <li className="page-item">
                                    <a className="page-link" href="#">
                                      Next
                                    </a>
                                  </li>
                                </ul>
                              </nav>
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-danger"
                            data-dismiss="modal"
                          >
                            <i className="fas fa-times"></i>
                            Cancelar
                          </button>
                          <button type="button" className="btn btn-primary">
                            <i className="fas fa-save"></i>
                            Aceptar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-2 col-sm-6">
                    <label htmlFor="">Precio: </label>
                    <div className="input-group mb-2">
                      <div className="input-group-prepend">
                        <div className="input-group-text">$</div>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        id="inlineFormInputGroup"
                      />
                    </div>
                  </div>

                  <div className="container-fluid">
                    <div className="card my-4">
                        <h4 className="card-header card-titulo">Datos del Remitente</h4>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-6 col-sm-6">
                            <label>Nombre Remitente</label>
                            <div className="form-group">
                              <input className="form-control"></input>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-6">
                            <label> Origen</label>
                            <div className="form-group">
                              <input className="form-control"></input>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-6">
                            <label>Calle</label>
                            <div className="form-group">
                              <input className="form-control"></input>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-6">
                            <label>Colonia</label>
                            <div className="form-group">
                              <input className="form-control"></input>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-6">
                            <label>Municipio</label>
                            <div className="form-group">
                              <input className="form-control"></input>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-6">
                            <label>Estado</label>
                            <div className="form-group">
                              <input className="form-control"></input>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-6">
                            <label>Recolección</label>
                            <div className="form-group">
                              <input className="form-control"></input>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-6">
                            <label>Referencia</label>
                            <div className="form-group">
                              <input className="form-control"></input>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-6">
                            <label>Clave</label>
                            <div className="form-group">
                              <input className="form-control"></input>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="card">
                                  <h4 className="card-header card-titulo">Reembarco</h4>
                              <div className="card-body">
                                <div className="row">
                                  <div className="col-md-6 col-sm-6">
                                    <label>Condujo de</label>
                                    <div className="form-group">
                                      <input className="form-control"></input>
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-sm-6">
                                    <label>Reembarcarse con</label>
                                    <div className="form-group">
                                      <input className="form-control"></input>
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-sm-6">
                                    <label>Conducirá Origen</label>
                                    <div className="form-group">
                                      <input className="form-control"></input>
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-sm-6">
                                    <label>Conducirá Destino</label>
                                    <div className="form-group">
                                      <input className="form-control"></input>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="container-fluid">
                    <div className="card my-4">
                        <h4 className="card-header card-titulo">Datos del Destinatario</h4>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-6 col-sm-6">
                            <label>Nombre Destinatario</label>
                            <div className="form-group">
                              <input className="form-control"></input>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-6">
                            <label>Destino</label>
                            <div className="form-group">
                              <input className="form-control"></input>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-6">
                            <label>Calle</label>
                            <div className="form-group">
                              <input className="form-control"></input>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-6">
                            <label>Colonia</label>
                            <div className="form-group">
                              <input className="form-control"></input>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-6">
                            <label>Municipio</label>
                            <div className="form-group">
                              <input className="form-control"></input>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-6">
                            <label>Estado</label>
                            <div className="form-group">
                              <input className="form-control"></input>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-6">
                            <label>Entrega</label>
                            <div className="form-group">
                              <input className="form-control"></input>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-6">
                            <label>Referencia</label>
                            <div className="form-group">
                              <input className="form-control"></input>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-6">
                            <label>Clave</label>
                            <div className="form-group">
                              <input className="form-control"></input>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="card">
                                  <h4 className="card-header card-titulo">
                                    Datos Carta Porte
                                  </h4>
                              <div className="card-body">
                                <div className="row">
                                  <div className="col-md-6 col-sm-6">
                                    <label>Fracción</label>
                                    <div className="form-group">
                                      <input className="form-control"></input>
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-sm-6">
                                    <label>Clase</label>
                                    <div className="form-group">
                                      <input className="form-control"></input>
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-sm-6">
                                    <label>Cuota Toneladas</label>
                                    <div className="form-group">
                                      <input className="form-control"></input>
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-sm-6">
                                    <label>Valor Declarado</label>
                                    <div className="form-group">
                                      <input className="form-control"></input>
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-sm-6">
                                    <label>Nombre Chofer</label>
                                    <div className="form-group">
                                      <input className="form-control"></input>
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-sm-6">
                                    <label>Observaciones</label>
                                    <div className="form-group">
                                      <textarea className="form-control"></textarea>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="table-responsive my-5">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th scope="col">Clave</th>
                            <th scope="col">Descripción</th>
                            <th scope="col">Cantidad</th>
                            <th scope="col">Precio</th>
                            <th scope="col">Importe</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td colspan="5"></td>
                          </tr>
                          <tr>
                            <td colspan="5"></td>
                          </tr>
                          <tr>
                            <td>
                              <div className="observaciones">
                                <a
                                  data-toggle="modal"
                                  data-target="#observaciones"
                                >
                                  Observaciones
                                </a>
                                <div
                                  className="modal fade"
                                  id="observaciones"
                                  tabIndex={-1}
                                  role="dialog"
                                  aria-labelledby="exampleModalLabel"
                                  aria-hidden="true"
                                >
                                  <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                      <div className="modal-header">
                                        <h5
                                          className="modal-title"
                                          id="exampleModalLabel"
                                        >
                                          Observaciones
                                        </h5>
                                        <button
                                          type="button"
                                          className="close"
                                          data-dismiss="modal"
                                          aria-label="Close"
                                        >
                                          <span aria-hidden="true">×</span>
                                        </button>
                                      </div>
                                      <div className="modal-body">
                                        <textarea
                                          className="form-control"
                                          placeholder="Observaciones"
                                        ></textarea>
                                      </div>
                                      <div className="modal-footer">
                                        <button
                                          type="button"
                                          className="btn btn-danger"
                                          data-dismiss="modal"
                                        >
                                          <i className="fas fa-times"></i>
                                          Eliminar
                                        </button>
                                        <button
                                          type="button"
                                          className="btn btn-primary"
                                        >
                                          <i className="fas fa-save"></i>
                                          Guardar
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="descglobal">
                                <a
                                  data-toggle="modal"
                                  data-target="#descuentoglobal"
                                >
                                  Descuento Global
                                </a>
                                <div
                                  className="modal fade"
                                  id="descuentoglobal"
                                  tabIndex={-1}
                                  role="dialog"
                                  aria-labelledby="exampleModalLabel"
                                  aria-hidden="true"
                                >
                                  <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                      <div className="modal-header">
                                        <h5
                                          className="modal-title"
                                          id="exampleModalLabel"
                                        >
                                          Introduzca Tasa %
                                        </h5>
                                        <button
                                          type="button"
                                          className="close"
                                          data-dismiss="modal"
                                          aria-label="Close"
                                        >
                                          <span aria-hidden="true">×</span>
                                        </button>
                                      </div>
                                      <div className="modal-body">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="0"
                                        ></input>
                                      </div>
                                      <div className="modal-footer">
                                        <button
                                          type="button"
                                          className="btn btn-danger"
                                          data-dismiss="modal"
                                        >
                                          <i className="fas fa-times"></i>
                                          Eliminar
                                        </button>
                                        <button
                                          type="button"
                                          className="btn btn-primary"
                                        >
                                          <i className="fas fa-save"></i>
                                          Guardar
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td colspan="3">
                              <div>
                                <div className="row">
                                  <div className="col-md-3">
                                    <a
                                      data-toggle="modal"
                                      data-target="#facturaglobal"
                                    >
                                      Factura Global
                                    </a>
                                    <div
                                      className="modal fade"
                                      id="facturaglobal"
                                      tabIndex={-1}
                                      role="dialog"
                                      aria-labelledby="exampleModalLabel"
                                      aria-hidden="true"
                                    >
                                      <div
                                        className="modal-dialog"
                                        role="document"
                                      >
                                        <div className="modal-content">
                                          <div className="modal-header">
                                            <h5
                                              className="modal-title"
                                              id="exampleModalLabel"
                                            >
                                              Asistente para elaborar factura
                                              global
                                            </h5>
                                            <button
                                              type="button"
                                              className="close"
                                              data-dismiss="modal"
                                              aria-label="Close"
                                            >
                                              <span aria-hidden="true">×</span>
                                            </button>
                                          </div>
                                          <div className="modal-body">
                                            <label>No. Folio Operación:</label>
                                            <div className="input-group mb-3">
                                              <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                  #
                                                </span>
                                              </div>
                                              <input
                                                type="text"
                                                className="form-control"
                                                aria-label="Amount"
                                                placeholder="No. Nota, Ticket, Recibo"
                                              />
                                            </div>

                                            <label>Total de la Venta:</label>
                                            <div className="input-group mb-3">
                                              <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                  $
                                                </span>
                                              </div>
                                              <input
                                                type="text"
                                                className="form-control"
                                                aria-label="Amount"
                                              />
                                            </div>
                                          </div>
                                          <div className="modal-footer">
                                            <button
                                              type="button"
                                              className="btn btn-danger"
                                              data-dismiss="modal"
                                            >
                                              <i className="fas fa-times"></i>
                                              Eliminar
                                            </button>
                                            <button
                                              type="button"
                                              className="btn btn-primary"
                                            >
                                              <i className="fas fa-save"></i>
                                              Guardar
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="col-sm-6 col-sm-6">
                    <div className="row">
                      <div className="col-md-4 col-sm-6 float-md-left">
                        <div className="form-group">
                          <label>Moneda*: </label>
                          <select className="form-control">
                            <option>MXN</option>
                            <option>IVA Tasa 0.0160000</option>
                            <option>IVA Tasa 0.0000000</option>
                            <option>IVA Exento</option>
                            <option>IVA Cuota</option>
                            <option>IVA Tasa 0.080000</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-6 col-sm-6 float-md-left">
                        <div className="form-group">
                          <label htmlFor="descripcion">Tipo de Cambio*:</label>
                          <input
                            type="text"
                            className="form-control"
                            id="producto_sat"
                            placeholder="1"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-10 float-md-left">
                        <div className="form-group">
                          <label>Condiciones de Pago: </label>
                          <select className="form-control">
                            <option>CONTADO</option>
                            <option>CREDITO</option>
                            <option>CREDITO 3 DIAS</option>
                            <option>CREDITO 7 DIAS</option>
                            <option>CREDITO 15 DIAS</option>
                            <option>CREDITO 30 DIAS</option>
                            <option>CREDITO 45 DIAS</option>
                            <option>CREDITO 60 DIAS</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-10 float-md-left">
                        <div className="form-group">
                          <label>Método de Pago: </label>
                          <select className="form-control">
                            <option>PUE Pago en una sola exhibición</option>
                            <option>
                              PPD Pago en parcialidades o diferido
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-10 float-md-left">
                        <label>CFDI's Relacionados: </label>
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Buscar folio"
                          />
                          <div className="input-group-append">
                            <span className="input-group-text">
                              <i
                                className="fas fa-search"
                                data-toggle="modal"
                                data-target="#cfdirelacionados"
                              ></i>
                            </span>
                          </div>
                        </div>

                        <div
                          className="modal fade"
                          id="cfdirelacionados"
                          tabIndex={-1}
                          role="dialog"
                          aria-labelledby="exampleModalLabel"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog" role="document">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5
                                  className="modal-title"
                                  id="exampleModalLabel"
                                >
                                  CFDI's Relacionados
                                </h5>
                                <button
                                  type="button"
                                  className="close"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                >
                                  <span aria-hidden="true">×</span>
                                </button>
                              </div>
                              <div className="modal-body">
                                <div className="form-group">
                                  <label htmlFor="unidad">
                                    Tipo de relación con CFDI's:
                                  </label>
                                  <select className="form-control">
                                    <option>NO APLICA</option>
                                    <option>
                                      01 Nota de crédito de los documentos
                                      relacionados
                                    </option>
                                    <option>
                                      02 Nota de débito de los documentos
                                      relacionados
                                    </option>
                                    <option>
                                      03 Devolución de mercancía sobre facturas
                                      o traslados previos
                                    </option>
                                    <option>
                                      04 Sustitución de los CFDI previos
                                    </option>
                                    <option>
                                      05 Traslados de mercancias facturados
                                      previamente
                                    </option>
                                    <option>
                                      06 Factura generada por los traslados
                                      previos
                                    </option>
                                    <option>
                                      07 CFDI por aplicación de anticipo
                                    </option>
                                  </select>
                                </div>
                                <div className="form-group">
                                  <label>Buscar CFDI's: </label>
                                  <div className="form-check">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      id="exampleCheck1"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="exampleCheck1"
                                    >
                                      Marcar si el CFDI a relacionar no se hizo
                                      en el sistema
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="uuid"
                                      placeholder="Buscar UUID's por folio, fecha, monto"
                                      disabled
                                    />
                                  </div>
                                </div>
                                <div className="modal-footer">
                                  <button
                                    type="button"
                                    className="btn btn-danger"
                                    data-dismiss="modal"
                                  >
                                    <i className="fas fa-times"></i>Eliminar
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                  >
                                    <i className="fas fa-save"></i>Guardar
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-6 col-sm-6">
                    <div className="col-md-10 float-md-right">
                      <div className="row">
                        <label>Subtotal: </label>
                        <div className="input-group mb-2">
                          <div className="input-group-prepend">
                            <div className="input-group-text">$</div>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            id="inlineFormInputGroup"
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-10 float-md-right">
                      <div className="row">
                        <label>IVA: </label>
                        <div className="input-group mb-2">
                          <div className="input-group-prepend">
                            <div className="input-group-text">$</div>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            id="inlineFormInputGroup"
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-10 float-md-right">
                      <div className="row">
                        <label>Total: </label>
                        <div className="input-group mb-2">
                          <div className="input-group-prepend">
                            <div className="input-group-text">$</div>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            id="inlineFormInputGroup"
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


              </div>
            </form>
            <div className="card-footer">
                  <div className="float-right">
                  <button type="submit" className="btn btn-success mr-1">
                  <i className="fas fa-save"></i>Guardar sin Timbrar
                  </button>
                  <button type="submit" className="btn btn-primary">
                  <i className="fas fa-save"></i>Timbrar
                  </button>
                  </div>
                </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartaPorte;
