import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import clienteAxios from '../config/axios';
import ComplementoLeyendas from "./ComplementoLeyendas";
import axios from 'axios';

var CancelToken = axios.CancelToken;
var cancel;

class Autocomplete extends Component {

  static propTypes = {
    suggestions: PropTypes.instanceOf(Array)
  };

  static defaultProps = {
    suggestions: []
  };

  constructor(props) {
    super(props);

    this.state = {
      // The active selection's index
      activeSuggestion: 0,
      // The suggestions that match the user's input
      filteredSuggestions: [],
      // Whether or not the suggestion list is shown
      showSuggestions: false,
      // What the user has entered
      userInput: ""
    };
  }

  // Event fired when the input value is changed
  onChange = async (e) => {
  // async onChange(e) {
    // const { suggestions } = this.props;
    
    const userInput = e.currentTarget.value;
    let filteredSuggestions = [];
    // if(userInput.length>3){

      try {
        const resultado = await clienteAxios.get(`/v3/clientes-busqueda?busqueda=${userInput}`);
        // console.log('RESULTADO DE CONSULTA A API');
        // console.log(resultado.data.data);
        // filteredSuggestions = JSON.parse(resultado.data.data);
        filteredSuggestions = resultado.data.data;
        // return resultado.data.data;
        // setEnviado(3);
        // cambiarEnviada(idFactura);

      } catch (error) { 
          // setEnviado(4);
          console.log('Hubo un error '+error);
      }



      // Filter our suggestions that don't contain the user's input
      // filteredSuggestions = suggestions.filter(
      //   suggestion =>
      //     suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
      // );
      
      // filteredSuggestions = this.buscarCliente(userInput);
      // console.log("RESULTADO DE BUSQUEDA111");
      // console.log(filteredSuggestions);
      // Update the user input and filtered suggestions, reset the active
      // suggestion and make sure the suggestions are shown
      
    // }
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions,
      showSuggestions: true,
      // userInput: userInput
      userInput: e.currentTarget.value
    });

  };

  async buscarCliente(userInput) {
    // setEnviado(2);
    try {
        const resultado = await clienteAxios.get(`/v3/clientes-busqueda?busqueda=${userInput}`);
        // console.log('RESULTADO DE CONSULTA A API');
        // console.log(resultado.data.data);
        return resultado.data.data;
        // setEnviado(3);
        // cambiarEnviada(idFactura);

    } catch (error) { 
        // setEnviado(4);
        console.log('Hubo un error '+error);
    }
  }

  // Event fired when the user clicks on a suggestion
  onClick = e => {
    // Update the user input and reset the rest of the state
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: e.currentTarget.innerText
    });
  };

  // Event fired when the user presses a key down
  onKeyDown = e => {
    const { activeSuggestion, filteredSuggestions } = this.state;
    
    // User pressed the enter key, update the input and close the
    // suggestions
    if (e.keyCode === 13) {
      this.setState({
        activeSuggestion: 0,
        showSuggestions: false,
        userInput: filteredSuggestions[activeSuggestion]?.cliente_nombre
      });
    }
    // User pressed the up arrow, decrement the index
    else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion - 1 });
    }
    // User pressed the down arrow, increment the index
    else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion + 1 });
    }
    
    // console.log("CLIC ABAJO");
    // console.log(filteredSuggestions[activeSuggestion]?.cliente_nombre);
    // console.log("FIN CLIC ABAJO");
    
  };

  render() {
    const {
      onChange,
      onClick,
      onKeyDown,
      state: {
        activeSuggestion,
        filteredSuggestions,
        showSuggestions,
        userInput
      }
    } = this;

    let suggestionsListComponent;
    // console.log('Se muestran sugerencias');
    // console.log(showSuggestions);
    console.log('USER INPUT');
    console.log(userInput);
    
    if (showSuggestions && userInput) {
    // if (showSuggestions) {
      // console.log('PASO SUGESTION AND USERINPUT');
      // console.log('CONTENIDO DE filteredSuggestions');
      // console.log(filteredSuggestions);
      if (filteredSuggestions.length) {
        // console.log('TOTAL DE FILTRADOS');
        // console.log(filteredSuggestions.length);
        suggestionsListComponent = (
          <ul className="suggestions">
            {filteredSuggestions.map((suggestion, index) => {
              // console.log('SUGGESTION ');
              // console.log(suggestion);
              let className;

              // Flag the active suggestion with a class
              if (index === activeSuggestion) {
                className = "suggestion-active";
              }

              return (
                <li
                  className={className}
                  key={suggestion.identificador}
                  onClick={onClick}
                >
                  {suggestion.cliente_nombre +' '+ suggestion.cliente_rfc }
                </li>
              );
            })}
          </ul>
        );
      } else {
        suggestionsListComponent = (
          <div className="no-suggestions">
            <em>¡No se encontraron resultados!</em>
          </div>
        );
      }
    }

    return (
      <Fragment>
        <input
          type="text"
          onChange={onChange}
          onKeyDown={onKeyDown}
          value={userInput}
          name={this.props.name}
          className="form-control"
        />
        {suggestionsListComponent}
      </Fragment>
    );
  }
}

export default Autocomplete;