import React, { Component } from 'react';

class PaquetesAdquiridos extends Component {
    render() {
        return (
            <div>
                <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">Consulta de Datos Empresa</h3>
                      </div>
                      <form className="form-inline ml-3">
                        <div className="input-group input-group-sm">
                            <input className="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search" />
                            <div className="input-group-append">
                            <button className="btn btn-navbar" type="submit">
                                <i className="fas fa-search" />
                            </button>
                            </div>
                        </div>
                        </form>
                      <div className="card-body">
                        <p className="text-center">Resumen por Empresa</p>
                        <table id="example1" className="table table-bordered table-striped">
                          <tbody>
                            <tr>
                              <td>7001 PAQUETE : FECHA : 2013-12-19 12:04:05</td>
                            </tr>
                            <tr>
                              <td>7001 PAQUETE : FECHA : 2013-12-19 12:04:05</td>
                            </tr>
                            <tr>
                              <td>7001 PAQUETE : FECHA : 2013-12-19 12:04:05</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

        );
    }
}

export default PaquetesAdquiridos;