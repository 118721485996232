import React, { useState,Component, Fragment } from "react";
import ComplementoIedu from "./ComplementoIedu";
import ComplementoVehiculoUsado from "./ComplementoVehiculoUsado";
import ComplementoConceptoTerceros from "./ComplementoConceptoTerceros";
import ComplementoDetallista2 from "./ComplementoDetallista2";
import ComplementoServiciosConstruccion from "./ComplementoServiciosConstruccion";
import ComplementoDonatarias from "./ComplementoDonatarias";
import ComplementoAerolineas from "./ComplementoAerolineas";
import ComplementoIne from "./ComplementoIne";
import ComplementoDivisa from "./ComplementoDivisa";
import ComplementoLeyendas from "./ComplementoLeyendas";
import ComplementoComercio from "./ComplementoComercio";
import ComplementoNotarioPublico from "./ComplementoNotarioPublico";
import NuevoCliente from "./NuevoCliente";
import Clientes from "./clientes/Clientes";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import es from 'date-fns/locale/es';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import clienteAxios from '../config/axios';
import Autocomplete from "./Autocomplete";
import { Formik, Form } from "formik";
import * as Yup from 'yup';

/*
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';


import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
*/

import subHours from "date-fns/subHours";
import EnvasesUniversales from "./EnvasesUniversales";
import SorianaRemision from './SorianaRemision'
import Autosuggest from "react-autosuggest";




// import CKEditor from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';



// function modal() {
//   const [editConcepto, setLgShow] = useState(false);
// }

// function NuevoCfdi() {
//   const [startDate, endDate] = useState(false);
// }


const NuevoCfdi = () => {
  const [razonSocial, setRazonSocial] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  
  const [factura, actualizarFactura] = useState ({
    factura_email_asunto : '',
    factura_email_cuerpo : '',
    factura_emails : '',
    factura_serie_identificador : '',
    factura_cliente_identificador : '',
    factura_uso_cfdi : '',
    factura_costo : '',
    factura_total : '',
    factura_costo_bruto : '',
    factura_conceptos : '',
    factura_moneda : '',
    factura_tipo_cambio : '',
    factura_metodo_pago : '',
    factura_forma_pago : '',
    factura_tipo_documento : '',
    factura_tipo_operacion : '',
    factura_comentario : '',
    factura_comentario_interno : '',
    factura_descuento : '',
    factura_descuento_motivo : '',
    factura_tipo_relacion : '',
    factura_cfdi_relacionados: '', 
    cfdi_identificador : '',
    cfdi_uuid : '',
    factura_detalle_precio_venta : '',
    factura_detalle_cantidad : '',
    factura_detalle_bruto : '',
    factura_detalle_total : '',
    factura_detalle_operacion_tipo : '',
    factura_detalle_clave_producto_servicio : '',
    factura_detalle_clave_unidad_sat : '',
    factura_detalle_identificador_producto : '',
    factura_detalle_descripcion : '',
    factura_detalle_descuento : ''
  });

  const actualizarState = e => {
    actualizarFactura ({
      ...factura,
      [e.target.name]: e.target.value
    })
  }

  const [startDate, setStartDate] = useState(new Date());
  registerLocale('es', es);


  const [error, actualizarError] = useState(false);


  const {factura_email_asunto, factura_email_cuerpo, factura_emails, factura_serie_identificador,
  factura_cliente_identificador, factura_uso_cfdi, factura_costo, factura_total,
  factura_costo_bruto, factura_conceptos, factura_moneda, factura_tipo_cambio, factura_metodo_pago,
  factura_forma_pago, factura_tipo_documento, factura_tipo_operacion, factura_comentario, factura_comentario_interno,
  factura_descuento, factura_descuento_motivo, factura_tipo_relacion, factura_cfdi_relacionados, cfdi_identificador, cfdi_uuid} = factura;

    const submitFactura = e => {
      e.preventDefault();
      console.log('se envió formulario')

    if (factura_email_asunto.trim() === ''|| factura_email_cuerpo.trim() === ''|| factura_emails.trim() === ''|| factura_serie_identificador.trim() === ''|| 
    factura_cliente_identificador.trim() === ''|| factura_uso_cfdi.trim() === ''|| factura_costo.trim() === ''|| factura_total.trim() === ''|| 
    factura_costo_bruto.trim() === ''|| factura_conceptos.trim() === ''|| factura_moneda.trim() === ''|| factura_tipo_cambio.trim() === ''||
    factura_metodo_pago.trim() === ''|| factura_forma_pago.trim() === ''|| factura_tipo_documento.trim() === ''|| factura_tipo_operacion.trim() === ''||
    factura_comentario.trim() === ''|| factura_comentario_interno.trim() === ''|| factura_descuento.trim() === ''|| factura_descuento_motivo.trim() === '' ||
    factura_tipo_relacion.trim() === ''|| factura_cfdi_relacionados.trim() === ''|| cfdi_identificador.trim() === ''|| cfdi_uuid.trim() === ''){
    actualizarError(true);
    return;
    }


    actualizarError(false);
  }
  
  const editorConfiguration = {
    //plugins: [ Essentials, Bold, Italic, Paragraph ],
    //toolbar:  [ 'bold', 'italic', 'bulletedList', 'numberedList', 'blockQuote' ]
    //plugins: [Essentials, Heading, Paragraph, Bold, Italic, BlockQuote, Alignment, List, Link],
    //toolbar: ['Heading', '|', 'Bold', 'Italic', 'Alignment', 'Link', 'List', 'ListUI', 'BlockQuote', 'Undo', 'Redo'],
    toolbar: ['Heading', '|', 'Bold', 'Italic', 'Alignment', 'List', 'ListUI', 'bulletedList', 'numberedList', 'insertTable', 'Undo', 'Redo'],
  };

  const selecCliente = (event,suggestion1) => {
    event.preventDefault();
    // console.log('LA RAZON SOCIAL ES');
    // console.log(suggestion1);
    actualizarFactura ({
      ...factura,
      factura_cliente_identificador : suggestion1.suggestion.id,
    })
    // console.log('EL ID DEL CLIENTE ES');
    // console.log(suggestion1.suggestion.id)
  }

  const timbrarFactura = (event) => {
    event.preventDefault();
    console.log('EL IDENTIFICADOR DEL CLIENTE SELECCIONADO ES: ');
    console.log(factura.factura_cliente_identificador);
  }
 
  const profileShema = Yup.object().shape({
    factura_emails: Yup.string()
      .email('Email invalido'),
      // .required('Email Requerido'),
    factura_serie_identificador: Yup.string()
      .required('Debe seleccionar un uso del CFDI'),
    factura_uso_cfdi: Yup.string()
      .required('Debe seleccionar un uso del CFDI'),
    factura_metodo_pago: Yup.string()
      .required('Debe seleccionar un metodo de pago'),
    factura_forma_pago: Yup.string()
      .required('Debe seleccionar una forma de pago'),
    factura_moneda: Yup.string()
      .required('Debe seleccionar una moneda'),
    factura_tipo_cambio: Yup.string()
      .required('Debe seleccionar un tipo cambio'),
    id_cliente: Yup.string()
      .required('Debe seleccionar un cliente'),
    // factura_cliente_identificador: Yup.string()
    //   .required('Debe seleccionar un cliente'),
    factura_cliente_rfc: Yup.string()
    .required('El cliente debe tener un RFC'),
    
      //fecha_expedicion: Yup.date()
    // .isValid('Invalida')
    // .format(['DD/MM/YY - HH:mm'],true)
      //.required('Fecha Requerida'),

  })
  return (
    <Fragment>
      <div className="container-fluid">
        <Formik
          initialValues={{ 
            factura_emails: '', 
            //fecha_expedicion: '', 
            factura_cliente_identificador: '',
            factura_uso_cfdi: '',
            factura_serie_identificador: '' ,
            factura_metodo_pago: '',
            factura_forma_pago: '',
            factura_moneda: 'MXN',
            factura_tipo_cambio: '1',
            id_cliente: '',
            factura_cliente_rfc: ''
          }}
          validationSchema={profileShema}
          onSubmit={(values, { setSubmitting })=>{
            setTimeout(()=>{
              alert(JSON.stringify(values, null, 2));
              setSubmitting(false);
            },5000);
          }}

        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            handleBlur,
            isSubmitting,
            setFieldValue
          })=> (

          <Form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-12">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="card-title">Registro de Factura</h3>
                  <div className="float-right">
                    <button type="submit" className="btn btn-primary" disabled={isSubmitting} >
                              <i className="fas fa-save"></i>
                              {isSubmitting ? 'Timbrando' : 'Timbrar'}
                    </button>
                    <button type="submit" className="btn btn-secondary">
                      <i className="fas fa-arrow-left"></i>Atrás
                    </button>
                  </div>
                </div>
                  <div className="card-body">
                    <div className="row">
                    {error ? <p className="alert alert-danger" role="alert">Todos los campos son obligatorios.</p> : null}
                      <div className="col-md-8">
                        <div className="row">
                          <div className="col-md-12">
                          <label htmlFor="">Cliente / Razón Social / RFC: <i className="fa fa-question-circle" aria-hidden="true" data-toggle="popover" title="Ayuda" data-content="Debe teclear las primeras tres letras del nombre de su cliente para que se muestren las sugerencias"></i></label>
                          <div className="input-group">
                          {/* <input type="text" 
                          className="form-control" 
                          name="factura_cliente_identificador" 
                          onChange={actualizarState}
                          /> */}
                          {/* <Autocomplete
                            suggestions={[
                              "Alligator",
                              "Bask",
                              "Crocodilian",
                              "Death Roll",
                              "Eggs",
                              "Jaws",
                              "Reptile",
                              "Solitary",
                              "Tail",
                              "Wetlands"
                            ]}
                            name="factura_cliente_identificador"
                          /> */}
                          <input
                            type="hidden"
                            name="id_cliente"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.id_cliente}
                            //value={factura.factura_cliente_identificador}
                          />
                          <Autosuggest 
                            inputProps={{
                              className: "form-control",
                              placeholder: "Nombre o Razon Social",
                              autoComplete: "abcd",
                              name: "factura_cliente_identificador",
                              id: "factura_cliente_identificador",
                              value: razonSocial,
                              onChange: (_event, { newValue}) => {
                                setRazonSocial(newValue);
                                if(!newValue){
                                  actualizarFactura ({
                                    ...factura,
                                    factura_cliente_identificador : '',
                                  
                                  })

                                  setFieldValue('id_cliente', '');
                                  setFieldValue('factura_emails', '');
                                  setFieldValue('factura_cliente_rfc', '');
                                  
                                }
                                
                              }
                            }}
                            suggestions={suggestions}
                            onSuggestionsFetchRequested = {async ({ value }) => {
                              if (!value) {
                                setSuggestions([]);
                                return;
                              }
                              try {
                                const resultado = await clienteAxios.get(`/v3/clientes?busqueda=${value}`);
                                
                                // console.log(resultado.data.data);
                                console.log('CONSULTA')
                                resultado.data.data.map(row =>{ console.log(row.cliente_email)})
                                setSuggestions(
                                  resultado.data.data.map(row => (
                                    {
                                      id: row.identificador,
                                      nombre: row.cliente_nombre,
                                      rfc: row.cliente_rfc,
                                      emails: row.cliente_email,
                                      uso_cfdi: 'G03',
                                    }
                                  ))
                                )
                                
                        
                              } catch (error) { 
                                  setSuggestions([]);
                                  console.log('Hubo un error '+error);
                              }
                            }}
                            onSuggestionsClearRequested={() =>{
                              setSuggestions([]);
                              // actualizarFactura ({
                              //   ...factura,
                              //   factura_cliente_identificador : '',
                              // })
                            }}
                            onSuggestionSelected={( event, suggestion )=> {
                              selecCliente(event, suggestion);
                              setFieldValue('id_cliente', suggestion.suggestion.id);
                              setFieldValue('factura_emails', suggestion.suggestion.emails);
                              setFieldValue('factura_cliente_rfc', suggestion.suggestion.rfc);
                              setFieldValue('factura_uso_cfdi', suggestion.suggestion.uso_cfdi);
                            }}
                            /*
                            onSuggestionSelected={( event, { suggestion, method } )=> {
                              event.preventDefault();
                              if (method === "enter"){
                                
                              }
                              setRazonSocial(suggestions.nombre);
                            }}
                            */
                            getSuggestionValue={suggestion => suggestion.nombre}
                            renderSuggestion={suggestion => <div>{suggestion.nombre+' '+suggestion.rfc}</div>}
                            shouldRenderSuggestions={(value, reason)=>{
                              //minimo de caracteres para buscar
                              return value.trim().length > 3;
                            }}
                          />
                          

                          <div className="input-group-append" data-toggle="tooltip"  >
                            <span
                              className="input-group-text"
                              data-toggle="modal"
                              data-target="#nuevocliente"
                              data-placement="right"
                              title="Registro nuevo cliente"
                            >
                              <i className="fas fa-plus"></i>
                            </span>
                            <span 
                              className="input-group-text"
                              data-toggle="modal"
                              data-target="#buscarcliente"
                              data-placement="top"
                              title="Buscar cliente"
                              >
                              <i className="fas fa-search"></i>
                            </span>
                          </div>
                          
                        </div>
                        {errors.id_cliente && touched.id_cliente && (
                          <div className="input-feedback">{errors.id_cliente}</div>
                        )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4 col-sm-4">
                            <div className="form-group">
                              <label htmlFor="clave">Email: </label>
                              <input
                                type="email"
                                className={
                                  errors.factura_emails && touched.factura_emails
                                  ? "form-control error"
                                  : "form-control"
                                }
                                id="email"
                                placeholder="Email"
                                name="factura_emails"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.factura_emails}
                                //onChange={actualizarState}
                              />
                              { //errors.factura_emails && touched.factura_emails && errors.factura_emails
                              }

                              {errors.factura_emails && touched.factura_emails && (
                                <div className="input-feedback">{errors.factura_emails}</div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-3">
                            <div className="form-group">
                              <label htmlFor="clave">RFC: </label>
                              <input
                                type="text"
                                name="factura_cliente_rfc"
                                className={
                                  errors.factura_cliente_rfc && touched.factura_cliente_rfc
                                  ? "form-control error"
                                  : "form-control"
                                }
                                placeholder="RFC"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.factura_cliente_rfc}
                                readOnly
                              />
                               {errors.factura_cliente_rfc && touched.factura_cliente_rfc && (
                                <div className="input-feedback">{errors.factura_cliente_rfc}</div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-5">
                            <div className="form-group">
                              <label htmlFor="unidad">
                                Uso del CFDI:
                              </label>
                              <select 
                                name="factura_uso_cfdi" 
                                className={
                                  errors.factura_uso_cfdi && touched.factura_uso_cfdi
                                  ? "form-control error"
                                  : "form-control"
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.factura_uso_cfdi}
                              >
                                <option value="">SELECCIONE USO DE CFDI</option>
                                <option value="G01">G01 Adquisición de mercancias</option>
                                <option value="G02">
                                  G02 Devoluciones, descuentos o bonificaciones
                                </option>
                                <option value="G03">G03 Gastos en general</option>
                                <option value="I01">I01 Construcciones</option>
                                <option value="I02">
                                  I02 Mobiliario y equipo de oficina por
                                  inversiones
                                </option>
                                <option value="I03">I03 Equipo de transporte</option>
                                <option value="I04">
                                  I04 Equipo de computo y accesorios
                                </option>
                                <option value="I05">
                                  I05 Dados, troqueles, moldes, matrices y
                                  herramental
                                </option>
                                <option value="I06">I06 Comunicaciones telefónicas</option>
                                <option value="I07">I07 Comunicaciones satelitales</option>
                                <option value="I08">I08 Otra maquinaria y equipo</option>
                                <option value="P01">P01 Por definir</option>
                              </select>
                              {errors.factura_uso_cfdi && touched.factura_uso_cfdi && (
                                <div className="input-feedback">{errors.factura_uso_cfdi}</div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-1"></div>
                      <div className="col-md-3">
                        <div className="row">
                          <div className="col-md-12">
                          <label htmlFor="unidad">Seleccione serie: </label>
                            <div className="input-group">
                              <select 
                                name="factura_serie_identificador" 
                                className={
                                  errors.factura_serie_identificador && touched.factura_serie_identificador
                                  ? "form-control error"
                                  : "form-control"
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.factura_serie_identificador}
                              >
                                <option value="">SELECCIONE SERIE</option>
                                <option value="A559">Factura A559</option>
                                <option value="TCC22">Factura TCC22</option>
                                <option value="E188">Factura E188</option>
                                <option value="2222">Factura 2222</option>
                              </select>
                              
                              <div className="input-group-append" data-toggle="tooltip" data-placement="top" title="Seleccione serie">
                                  <span
                                    className="input-group-text"
                                    data-toggle="modal"
                                    data-target="#serie"
                                  >
                                    <i className="fas fa-search"></i>
                                  </span>
                                </div>
                            </div>
                            {errors.factura_serie_identificador && touched.factura_serie_identificador && (
                                <div className="input-feedback">{errors.factura_serie_identificador}</div>
                            )}
                          </div>
                          <div className="col-md-12">
                            
                          <label htmlFor="unidad">Seleccione fecha: </label>
                            <div className="form-group">

                            {/* <DatePicker
                              selected={startDate}
                              onChange={date => setStartDate(date)}
                              minDate={subDays(new Date(), 5)}
                              placeholderText="Select a date after 5 days ago"
                            /> */}
                              {/* validar fecha
                              alert(moment("23/04/2020 - 17:58", 'DD/MM/YYYY - HH:mm',true).isValid()); */}
                              <DatePicker
                                name="fecha_expedicion"
                                selected={startDate}
                                onChange={date => setStartDate(date)}
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={1}
                                timeCaption="time"
                                dateFormat="d/MM/yyyy - HH:mm"
                                className={
                                  errors.fecha_expedicion && touched.fecha_expedicion
                                  ? "form-control error"
                                  : "form-control"
                                }
                                locale="es"
                                minDate={subHours(new Date(), 72)}
                                maxDate={new Date()}
                              
                              />
                              {errors.fecha_expedicion && touched.fecha_expedicion && (
                                <div className="input-feedback">{errors.fecha_expedicion}</div>
                              )}
                              
                              
                              {/* <div className="input-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="datepicker"
                                  placeholder="Seleccione fecha"
                                ></input>
                                <div className="input-group-append" data-toggle="tooltip" data-placement="top" title="Seleccione fecha">
                                  <button
                                    className="input-group-text"
                                    id="datepicker"
                                  >
                                    <i
                                      className="fas fa-calendar-alt"
                                      data-toggle="datepicker"
                                    ></i>
                                  </button>
                                </div>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <label htmlFor="">Descripción: </label>
                        <div className="input-group">
                          <textarea type="text" className="form-control" name="factura_conceptos" onChange={actualizarState} />
                          <div className="input-group-append" data-toggle="tooltip" data-placement="top" title="Agregar Producto o Servicio">
                            <span
                              className="input-group-text"
                              data-toggle="modal"
                              data-target="#agregardescripcion"
                            >
                              <i className="fas fa-plus"></i>
                            </span>
                          </div>
                          <div className="input-group-append" data-toggle="tooltip" data-placement="top" title="Buscar Producto o Servicio">
                            <span
                              className="input-group-text"
                              data-toggle="modal"
                              data-target="#buscardescripcion"
                            >
                              <i className="fas fa-search"></i>
                            </span>
                          </div>
                          <div className="input-group-append" data-toggle="tooltip" data-placement="top" title="Editar Producto o Servicio">
                            <span
                              className="input-group-text"
                              data-toggle="modal"
                              data-target="#descripcion"
                            >
                              <i className="fas fa-pencil-alt"></i>
                            </span>
                          </div>
                        </div>
                        <div
                          className="modal fade"
                          id="descripcion"
                          tabIndex={-1}
                          role="dialog"
                          aria-labelledby="exampleModalLabel"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog" role="document">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5
                                  className="modal-title"
                                  id="exampleModalLabel"
                                >
                                  Descripción
                                </h5>
                                <button
                                  type="button"
                                  className="close"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                >
                                  <span aria-hidden="true">×</span>
                                </button>
                              </div>
                              <div className="modal-body">
                                <div className="form-group">
                                  <textarea
                                    className="form-control"
                                    id="exampleFormControlTextarea1"
                                    rows={3}
                                    defaultValue={""}
                                  />
                                </div>
                              </div>
                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn btn-danger"
                                  data-dismiss="modal"
                                >
                                  <i className="fas fa-times"></i>Eliminar
                                </button>
                                <button type="button" className="btn btn-primary">
                                  <i className="fas fa-save"></i>Guardar
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 col-sm-6">
                        <label htmlFor="">Clave SAT: </label>
                        <div className="input-group">
                          <input 
                          type="text" 
                          className="form-control" 
                          name="factura_detalle_clave_unidad_sat"
                          />
                          <div className="input-group-append" data-toggle="tooltip" data-placement="top" title="Busca la Clave del SAT">
                            <span
                              className="input-group-text"
                              data-toggle="modal"
                              data-target="#clavesat"
                            >
                              <i className="fas fa-search"></i>
                            </span>
                          </div>
                        </div>
                        <div
                          className="modal fade bd-example-modal-lg"
                          id="clavesat"
                          tabIndex="-1"
                          role="dialog"
                          aria-labelledby="exampleModalLabel"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5
                                  className="modal-title"
                                  id="exampleModalLabel"
                                >
                                  Catálogo SAT de Productos o Servicios
                                </h5>
                                <button
                                  type="button"
                                  className="close"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                >
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              <div className="modal-body">
                                <div className="form-group">
                                  <label>División </label>
                                  <select className="form-control">
                                    <option>Todas</option>
                                    <option>IVA Tasa 0.0160000</option>
                                    <option>IVA Tasa 0.0000000</option>
                                    <option>IVA Exento</option>
                                    <option>IVA Cuota</option>
                                    <option>IVA Tasa 0.080000</option>
                                  </select>
                                </div>
                                <div className="form-group">
                                  <label>Grupo </label>
                                  <select className="form-control" disabled>
                                    <option></option>
                                    <option>IVA Tasa 0.0160000</option>
                                    <option>IVA Tasa 0.0000000</option>
                                    <option>IVA Exento</option>
                                    <option>IVA Cuota</option>
                                    <option>IVA Tasa 0.080000</option>
                                  </select>
                                </div>
                                <div className="input-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    aria-label="Amount"
                                    placeholder="Buscar por palabra el catálogo"
                                  />
                                  <div className="input-group-append">
                                    <button className="input-group-text">
                                      <i className="fas fa-times"></i>
                                    </button>
                                  </div>
                                </div>
                                <br></br>
                                <div className="table-responsive">
                                  <table className="table table-bordered">
                                    <thead>
                                      <tr>
                                        <th scope="col">Seleccionar</th>
                                        <th scope="col">Clave SAT</th>
                                        <th scope="col">Descripción</th>
                                        <th scope="col">Estimulo Frontera</th>
                                        <th scope="col">Similar</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <th scope="row">
                                          <div className="form-check form-check-inline">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="inlineCheckbox1"
                                              defaultValue="option1"
                                            />
                                          </div>
                                        </th>
                                        <td>
                                          <p>01010101</p>
                                        </td>
                                        <td>
                                          <p>No existe en el catálogo</p>
                                        </td>
                                        <td>
                                          <span className="label label-default">
                                            No
                                          </span>
                                        </td>
                                        <td>Público en general</td>
                                      </tr>
                                      <tr>
                                        <th scope="row">
                                          <div className="form-check form-check-inline">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="inlineCheckbox1"
                                              defaultValue="option1"
                                            />
                                          </div>
                                        </th>
                                        <td>
                                          <p>01010101</p>
                                        </td>
                                        <td>
                                          <p>No existe en el catálogo</p>
                                        </td>
                                        <td>
                                          <span className="label label-default">
                                            No
                                          </span>
                                        </td>
                                        <td>Público en general</td>
                                      </tr>
                                      <tr>
                                        <th scope="row">
                                          <div className="form-check form-check-inline">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="inlineCheckbox1"
                                              defaultValue="option1"
                                            />
                                          </div>
                                        </th>
                                        <td>
                                          <p>01010101</p>
                                        </td>
                                        <td>
                                          <p>No existe en el catálogo</p>
                                        </td>
                                        <td>
                                          <span className="label label-default">
                                            No
                                          </span>
                                        </td>
                                        <td>Público en general</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn btn-danger"
                                  data-dismiss="modal"
                                >
                                  <i className="fas fa-times"></i>
                                  Cerrar
                                </button>
                                <button type="button" className="btn btn-primary">
                                  <i className="fas fa-save"></i>Guardar
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 col-sm-6">
                        <div className="form-group">
                          <label htmlFor="descripcion">Cantidad: </label>
                          <input
                            type="text"
                            className="form-control"
                            id="descripcion"
                            placeholder="Cantidad"
                            name="factura_detalle_cantidad"
                          />
                        </div>
                      </div>
                      <div className="col-md-2 col-sm-6">
                        <label htmlFor="">Unidad: </label>
                        <div className="input-group">
                          <input 
                          type="text" 
                          className="form-control" 
                          name="factura_detalle_clave_unidad_sat"
                          />
                          <div className="input-group-append" data-toggle="tooltip" data-placement="top" title="Buscar Unidad">
                            <span
                              className="input-group-text"
                              data-toggle="modal"
                              data-target="#unidad"
                            >
                              <i className="fas fa-search"></i>
                            </span>
                          </div>
                        </div>
                      </div>

                      <div
                        className="modal fade bd-example-modal-lg"
                        id="unidad"
                        tabIndex="-1"
                        role="dialog"
                        aria-labelledby="myLargeModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog modal-lg">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="exampleModalLabel">
                                Catálogo SAT de Unidades de Medida
                              </h5>
                              <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div className="modal-body">
                              <div className="col-md-12">
                                <div className="input-group mb-3">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Recipient's username"
                                    aria-label="Recipient's username"
                                    aria-describedby="basic-addon2"
                                  />
                                  <div className="input-group-append">
                                    <button
                                      className="btn btn-danger"
                                      type="button"
                                    >
                                      <i className="fas fa-times"></i>
                                    </button>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="input-group">
                                    <button
                                      className="btn btn-primary"
                                      type="button"
                                    >
                                      <i className="fas fa-search"></i>Buscar
                                    </button>
                                  </div>
                                </div>
                                <br></br>
                                <div className="table-responsive">
                                  <table className="table table-bordered">
                                    <thead>
                                      <tr>
                                        <th colSpan="3">
                                          Catálogo Oficial del SAT
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <th scope="col">Seleccionar</th>
                                        <th scope="col">Clave SAT</th>
                                        <th scope="col">Descripción</th>
                                      </tr>
                                      <tr>
                                        <th>
                                          <div className="form-check form-check-inline">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="inlineCheckbox1"
                                              defaultValue="option1"
                                            />
                                          </div>
                                        </th>
                                        <td>21</td>
                                        <td>Contenedor de cuarenta pies</td>
                                      </tr>
                                      <tr>
                                        <th>
                                          <div className="form-check form-check-inline">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="inlineCheckbox1"
                                              defaultValue="option1"
                                            />
                                          </div>
                                        </th>
                                        <td>21</td>
                                        <td>Contenedor de cuarenta pies</td>
                                      </tr>
                                      <tr>
                                        <th>
                                          <div className="form-check form-check-inline">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              id="inlineCheckbox1"
                                              defaultValue="option1"
                                            />
                                          </div>
                                        </th>
                                        <td>21</td>
                                        <td>Contenedor de cuarenta pies</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-sm-12 col-md-6">
                                  <div
                                    className="dataTables_length"
                                    id="example1_length"
                                  >
                                    <label>Show entries</label>
                                    <select
                                      name="example1_length"
                                      aria-controls="example1"
                                      className="custom-select custom-select-sm form-control form-control-sm"
                                    >
                                      <option value="10">10</option>
                                      <option value="25">25</option>
                                      <option value="50">50</option>
                                      <option value="100">100</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="col-sm-12 col-md-6">
                                  <nav aria-label="Page navigation example">
                                    <ul className="pagination">
                                      <li className="page-item">
                                        <a className="page-link" href="#">
                                          Previous
                                        </a>
                                      </li>
                                      <li className="page-item">
                                        <a className="page-link" href="#">
                                          1
                                        </a>
                                      </li>
                                      <li className="page-item">
                                        <a className="page-link" href="#">
                                          2
                                        </a>
                                      </li>
                                      <li className="page-item">
                                        <a className="page-link" href="#">
                                          3
                                        </a>
                                      </li>
                                      <li className="page-item">
                                        <a className="page-link" href="#">
                                          Next
                                        </a>
                                      </li>
                                    </ul>
                                  </nav>
                                </div>
                              </div>
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-danger"
                                data-dismiss="modal"
                              >
                                <i className="fas fa-times"></i>
                                Cancelar
                              </button>
                              <button type="button" className="btn btn-primary">
                                <i className="fas fa-save"></i>
                                Aceptar
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-2 col-sm-6">
                        <label htmlFor="">Precio: </label>
                        <div className="input-group mb-2">
                          <div className="input-group-prepend">
                            <div className="input-group-text">$</div>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            
                            name="factura_detalle_precio_venta"
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <a
                          data-toggle="collapse"
                          href="#collapse"
                          role="button"
                          aria-expanded="false"
                          aria-controls="collapseExample"
                        >
                          <i className="fa fa-plus" aria-hidden="true"></i>
                          Complementos
                        </a>
                      </div>

                      <div className="collapse col-md-12" id="collapse">
                        <div className="row">
                          <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="complemento-iedu"
                                id="complemento-iedu"
                                defaultValue="option1"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineCheckbox1"
                              >
                                Complemento IEDU
                              </label>
                            </div>
                          </div>
                        
                          <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="inlineCheckbox1"
                                defaultValue="option1"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineCheckbox1"
                              >
                                Complemento Venta de Vehículo
                              </label>
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="inlineCheckbox1"
                                defaultValue="option1"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineCheckbox1"
                              >
                                Complemento Terceros
                              </label>
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="inlineCheckbox1"
                                defaultValue="option1"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineCheckbox1"
                              >
                                Complemento Acreditamiento del IEPS
                              </label>
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="inlineCheckbox1"
                                defaultValue="option1"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineCheckbox1"
                              >
                                Complemento Aerolineas
                              </label>
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="inlineCheckbox1"
                                defaultValue="option1"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineCheckbox1"
                              >
                                Complemento Comercio Exterior
                              </label>
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="inlineCheckbox1"
                                defaultValue="option1"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineCheckbox1"
                              >
                                Complemento Detallista
                              </label>
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="inlineCheckbox1"
                                defaultValue="option1"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineCheckbox1"
                              >
                                Complemento Divisa
                              </label>
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="inlineCheckbox1"
                                defaultValue="option1"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineCheckbox1"
                              >
                                Complemento Donatarias
                              </label>
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="inlineCheckbox1"
                                defaultValue="option1"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineCheckbox1"
                              >
                                Complemento INE
                              </label>
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="inlineCheckbox1"
                                defaultValue="option1"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineCheckbox1"
                              >
                                Complemento Leyendas
                              </label>
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="inlineCheckbox1"
                                defaultValue="option1"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineCheckbox1"
                              >
                                Complemento Notarios Publicos
                              </label>
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="inlineCheckbox1"
                                defaultValue="option1"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineCheckbox1"
                              >
                                Complemento Servicios Parciales Construcción
                              </label>
                            </div>
                          </div>
                          
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="complementos">
                          {/* <ComplementoIedu /> */}
                          {/* <ComplementoVehiculoUsado /> */}
                          {/* <ComplementoConceptoTerceros /> */}
                          {/* <ComplementoDetallista2 /> */}
                          {/* <ComplementoServiciosConstruccion /> */}
                          {/* <ComplementoDonatarias /> */}
                          {/* <ComplementoAerolineas /> */}
                          {/* <ComplementoIne /> */}
                          {/* <ComplementoDivisa /> */}
                          {/* <ComplementoLeyendas /> */}
                          {/* <ComplementoComercio /> */}
                          {/* <ComplementoNotarioPublico /> */}
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="table-responsive my-5">
                          <table className="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col">Clave</th>
                                <th scope="col">Descripción</th>
                                <th scope="col">Cantidad</th>
                                <th scope="col">Precio</th>
                                <th scope="col">Importe</th>
                                <th></th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>001</td>
                                <td>Maletin</td>
                                <td>1</td>
                                <td>$1500.00</td>
                                <td>$1500.00</td>
                                <td><button type="button" data-toggle="modal" data-target=".editar-concepto" className="btn btn-primary"><i className="fas fa-edit"></i></button></td>
                                <td><button type="button" data-toggle="modal" data-target=".editar-concepto" className="btn btn-danger"><i className="fas fa-trash"></i></button></td>
                              </tr>
                              <tr>
                                <td colSpan="7"></td>
                              </tr>
                            </tbody>
                            <tfoot>
                            <tr>
                                <td>
                                  <div className="observaciones">
                                    <a
                                      data-toggle="modal"
                                      data-target="#observaciones"
                                    >
                                      Observaciones
                                    </a>
                                    <div
                                      className="modal fade"
                                      id="observaciones"
                                      tabIndex={-1}
                                      role="dialog"
                                      aria-labelledby="exampleModalLabel"
                                      aria-hidden="true"
                                    >
                                      <div
                                        className="modal-dialog"
                                        role="document"
                                      >
                                        <div className="modal-content">
                                          <div className="modal-header">
                                            <h5
                                              className="modal-title"
                                              id="exampleModalLabel"
                                            >
                                              Observaciones
                                            </h5>
                                            <button
                                              type="button"
                                              className="close"
                                              data-dismiss="modal"
                                              aria-label="Close"
                                            >
                                              <span aria-hidden="true">×</span>
                                            </button>
                                          </div>
                                          <div className="modal-body">
                                            <textarea
                                              className="form-control"
                                              placeholder="Observaciones"
                                            ></textarea>
                                          </div>
                                          <div className="modal-footer">
                                            <button
                                              type="button"
                                              className="btn btn-danger"
                                              data-dismiss="modal"
                                            >
                                              <i className="fas fa-times"></i>
                                              Eliminar
                                            </button>
                                            <button
                                              type="button"
                                              className="btn btn-primary"
                                            >
                                              <i className="fas fa-save"></i>
                                              Guardar
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="descglobal">
                                    <a
                                      data-toggle="modal"
                                      data-target="#descuentoglobal"
                                    >
                                      Descuento Global
                                    </a>
                                    <div
                                      className="modal fade"
                                      id="descuentoglobal"
                                      tabIndex={-1}
                                      role="dialog"
                                      aria-labelledby="exampleModalLabel"
                                      aria-hidden="true"
                                    >
                                      <div
                                        className="modal-dialog"
                                        role="document"
                                      >
                                        <div className="modal-content">
                                          <div className="modal-header">
                                            <h5
                                              className="modal-title"
                                              id="exampleModalLabel"
                                            >
                                              Introduzca Tasa %
                                            </h5>
                                            <button
                                              type="button"
                                              className="close"
                                              data-dismiss="modal"
                                              aria-label="Close"
                                            >
                                              <span aria-hidden="true">×</span>
                                            </button>
                                          </div>
                                          <div className="modal-body">
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder="0"
                                            ></input>
                                          </div>
                                          <div className="modal-footer">
                                            <button
                                              type="button"
                                              className="btn btn-danger"
                                              data-dismiss="modal"
                                            >
                                              <i className="fas fa-times"></i>
                                              Eliminar
                                            </button>
                                            <button
                                              type="button"
                                              className="btn btn-primary"
                                            >
                                              <i className="fas fa-save"></i>
                                              Guardar
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td colSpan="5">
                                  <div>
                                    <div className="row">
                                      <div className="col-md-3">
                                        <a
                                          data-toggle="modal"
                                          data-target="#facturaglobal"
                                        >
                                          Factura Global
                                        </a>
                                        <div
                                          className="modal fade"
                                          id="facturaglobal"
                                          tabIndex={-1}
                                          role="dialog"
                                          aria-labelledby="exampleModalLabel"
                                          aria-hidden="true"
                                        >
                                          <div
                                            className="modal-dialog"
                                            role="document"
                                          >
                                            <div className="modal-content">
                                              <div className="modal-header">
                                                <h5
                                                  className="modal-title"
                                                  id="exampleModalLabel"
                                                >
                                                  Asistente para elaborar factura
                                                  global
                                                </h5>
                                                <button
                                                  type="button"
                                                  className="close"
                                                  data-dismiss="modal"
                                                  aria-label="Close"
                                                >
                                                  <span aria-hidden="true">
                                                    ×
                                                  </span>
                                                </button>
                                              </div>
                                              <div className="modal-body">
                                                <label>
                                                  No. Folio Operación:
                                                </label>
                                                <div className="input-group mb-3">
                                                  <div className="input-group-prepend">
                                                    <span className="input-group-text">
                                                      #
                                                    </span>
                                                  </div>
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    aria-label="Amount"
                                                    placeholder="No. Nota, Ticket, Recibo"
                                                  />
                                                </div>

                                                <label>Total de la Venta:</label>
                                                <div className="input-group mb-3">
                                                  <div className="input-group-prepend">
                                                    <span className="input-group-text">
                                                      $
                                                    </span>
                                                  </div>
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    aria-label="Amount"
                                                  />
                                                </div>
                                              </div>
                                              <div className="modal-footer">
                                                <button
                                                  type="button"
                                                  className="btn btn-danger"
                                                  data-dismiss="modal"
                                                >
                                                  <i className="fas fa-times"></i>
                                                  Eliminar
                                                </button>
                                                <button
                                                  type="button"
                                                  className="btn btn-primary"
                                                >
                                                  <i className="fas fa-save"></i>
                                                  Guardar
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td></td>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                      </div>

                      <div className="col-md-6 col-sm-6">
                        <div className="row">
                          <div className="col-md-4 col-sm-6 float-md-left">
                            <div className="form-group">
                              <label>Moneda*: </label>
                              <select 
                                name="factura_moneda" 
                                className={
                                  errors.factura_moneda && touched.factura_moneda
                                  ? "form-control error"
                                  : "form-control"
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.factura_moneda}
                              >
                                <option value="MXN">MXN</option>
                                <option value="USD">USD</option>
                                <option value="EUR">EUR</option>
                                
                              </select>
                              {errors.factura_moneda && touched.factura_moneda && (
                                <div className="input-feedback">{errors.factura_moneda}</div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-6 float-md-left">
                            <div className="form-group">
                              <label htmlFor="descripcion">
                                Tipo de Cambio*:
                              </label>
                              <input
                                name="factura_tipo_cambio"
                                type="text"
                                className={
                                  errors.factura_tipo_cambio && touched.factura_tipo_cambio
                                  ? "form-control error"
                                  : "form-control"
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.factura_tipo_cambio}
                              />
                              {errors.factura_tipo_cambio && touched.factura_tipo_cambio && (
                                <div className="input-feedback">{errors.factura_tipo_cambio}</div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-10 float-md-left">
                            <div className="form-group">
                              <label>Condiciones de Pago: </label>
                                <div className="input-group">
                                <input
                                  type="text"
                                  className="form-control"
                                />
                                <div className="input-group-append" data-toggle="tooltip" data-placement="top" title="Agregar Condiciones de Pago">
                                  <span className="input-group-text">
                                    <i
                                      className="fas fa-plus"
                                      data-toggle="modal"
                                      data-target="#condiciones"
                                    ></i>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-10 float-md-left">
                            <div className="form-group">
                              <label>Método de Pago: </label>
                              <select 
                                name="factura_metodo_pago" 
                                className={
                                  errors.factura_metodo_pago && touched.factura_metodo_pago
                                  ? "form-control error"
                                  : "form-control"
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.factura_metodo_pago}
                              >
                                <option value="">SELECCIONE UN METODO DE PAGO</option>
                                <option value="PUE">PUE Pago en una sola exhibición</option>
                                <option value="PPD">
                                  PPD Pago en parcialidades o diferido
                                </option>
                              </select>
                              {errors.factura_metodo_pago && touched.factura_metodo_pago && (
                                <div className="input-feedback">{errors.factura_metodo_pago}</div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-10 float-md-left">
                            <div className="form-group">
                              <label>Forma de Pago: </label>
                              <select 
                                name="factura_forma_pago" 
                                className={
                                  errors.factura_forma_pago && touched.factura_forma_pago
                                  ? "form-control error"
                                  : "form-control"
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.factura_forma_pago}
                              >
                                <option value="">SELECCIONE FORMA DE PAGO</option>
                                <option value="01">01 - Efectivo</option>
                                <option value="02">02 - Cheque nominativo</option>
                                <option value="03">03 - Transferencia electrónica de fondos</option>
                                <option value="04">04 - Tarjeta de crédito</option>
                                <option value="05">05 - Monedero electrónico</option>
                                <option value="06">06 - Dinero electrónico</option>
                                <option value="07">08 - Vales de despensa</option>
                                <option value="12">12 - Dación en pago</option>
                                <option value="13">13 - Pago por subrogación</option>
                                <option value="14">14 - Pago por consignación</option>
                                <option value="15">15 - Condonación</option>
                                <option value="17">17 - Compensación</option>
                                <option value="23">23 - Novación</option>
                                <option value="24">24 - Confusión</option>
                                <option value="25">25 - Remisión de deuda</option>
                                <option value="26">26 - Prescripción o caducidad</option>
                                <option value="27">27 - A satisfacción del acreedor</option>
                                <option value="28">28 - Tarjeta de débito</option>
                                <option value="29">29 - Tarjeta de servicios</option>
                                <option value="30">30 - Aplicación de anticipos</option>
                                <option value="31">31 - Intermediarios</option>
                                <option value="99">99 - Por definir</option>
                              </select>
                              {errors.factura_forma_pago && touched.factura_forma_pago && (
                                <div className="input-feedback">{errors.factura_forma_pago}</div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-10 float-md-left">
                            <label>CFDI's Relacionados: </label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Buscar folio"
                              />
                              <div className="input-group-append" data-toggle="tooltip" data-placement="top" title="Buscar Folios">
                                <span className="input-group-text">
                                  <i
                                    className="fas fa-search"
                                    data-toggle="modal"
                                    data-target="#cfdirelacionados"
                                  ></i>
                                </span>
                              </div>
                            </div>

                            <div
                              className="modal fade"
                              id="cfdirelacionados"
                              tabIndex={-1}
                              role="dialog"
                              aria-labelledby="exampleModalLabel"
                              aria-hidden="true"
                            >
                              <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                  <div className="modal-header">
                                    <h5
                                      className="modal-title"
                                      id="exampleModalLabel"
                                    >
                                      CFDI's Relacionados
                                    </h5>
                                    <button
                                      type="button"
                                      className="close"
                                      data-dismiss="modal"
                                      aria-label="Close"
                                    >
                                      <span aria-hidden="true">×</span>
                                    </button>
                                  </div>
                                  <div className="modal-body">
                                    <div className="form-group">
                                      <label htmlFor="unidad">
                                        Tipo de relación con CFDI's:
                                      </label>
                                      <select name="factura_tipo_documento" className="form-control">
                                        <option>NO APLICA</option>
                                        <option>
                                          01 Nota de crédito de los documentos
                                          relacionados
                                        </option>
                                        <option>
                                          02 Nota de débito de los documentos
                                          relacionados
                                        </option>
                                        <option>
                                          03 Devolución de mercancía sobre
                                          facturas o traslados previos
                                        </option>
                                        <option>
                                          04 Sustitución de los CFDI previos
                                        </option>
                                        <option>
                                          05 Traslados de mercancias facturados
                                          previamente
                                        </option>
                                        <option>
                                          06 Factura generada por los traslados
                                          previos
                                        </option>
                                        <option>
                                          07 CFDI por aplicación de anticipo
                                        </option>
                                      </select>
                                    </div>
                                    <div className="form-group">
                                      <label>Buscar CFDI's: </label>
                                      <div className="form-check">
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          id="exampleCheck1"
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="exampleCheck1"
                                        >
                                          Marcar si el CFDI a relacionar no se
                                          hizo en el sistema
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="uuid"
                                          placeholder="Buscar UUID's por folio, fecha, monto"
                                          disabled
                                        />
                                      </div>
                                    </div>
                                    <div className="modal-footer">
                                      <button
                                        type="button"
                                        className="btn btn-danger"
                                        data-dismiss="modal"
                                      >
                                        <i className="fas fa-times"></i>Eliminar
                                      </button>
                                      <button
                                        type="button"
                                        className="btn btn-primary"
                                      >
                                        <i className="fas fa-save"></i>Guardar
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 col-sm-6">
                        <div className="col-md-10 float-md-right">
                          <div className="row">
                            <label>Subtotal: </label>
                            <div className="input-group mb-2">
                              <div className="input-group-prepend">
                                <div className="input-group-text">$</div>
                              </div>
                              <input
                                type="text"
                                className="form-control"
                                
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-10 float-md-right">
                          <div className="row">
                            <label>IVA: </label>
                            <div className="input-group mb-2">
                              <div className="input-group-prepend">
                                <div className="input-group-text">$</div>
                              </div>
                              <input
                                type="text"
                                className="form-control"
                                
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-10 float-md-right">
                          <div className="row">
                            <label>Total: </label>
                            <div className="input-group mb-2">
                              <div className="input-group-prepend">
                                <div className="input-group-text">$</div>
                              </div>
                              <input
                                type="text"
                                className="form-control"
                                
                                name="factura_total"
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mt-3">
                        <hr/>
                        <label>Observaciones</label>
                              <CKEditor
                            editor={ ClassicEditor }
                            name="factura_comentario"
                            data="<p>¡Escriba las observaciones aquí!</p>"
                            onInit={ editor => {
                                // You can store the "editor" and use when it is needed.
                                // console.log( 'Editor is ready to use!', editor );
                            } }
                            config={
                              editorConfiguration
                            }
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                console.log( { event, editor, data } );
                            } }
                            onBlur={ ( event, editor ) => {
                                console.log( 'Blur.', editor );
                            } }
                            onFocus={ ( event, editor ) => {
                                console.log( 'Focus.', editor );
                            } }
                        />
                        <span>Utiliza las observaciones para agregar información importante. Visibles en pdf de factura o prefactura.</span>
                      </div>
                    <div className="col-md-12 mt-3">
                      <hr/>
                      <label>Notas:</label>
                      <textarea className="form-control" name="factura_comentario_interno" placeholder="Escribe una nota" />
                      <span>Utiliza las notas para agregar información importante. No visibles en pdf de factura o prefactura solo será un campo visible en consulta de facturas o prefacturas.</span>
                    
                    </div>

                  </div>
                  </div>
                      <div className="card-footer">
                      <div className="float-right">
                          <button type="button" className="btn btn-outline-dark" data-toggle="modal" data-target=".addendas" ><i className="fas fa-file"></i>Addendas</button>
                              <button type="submit" className="btn btn-success mr-1">
                              <i className="fas fa-save"></i>Pre-Factura
                              </button>
                              <button type="submit" className="btn btn-primary" disabled={isSubmitting} >
                              <i className="fas fa-save"></i>
                              {isSubmitting ? 'Timbrando' : 'Timbrar'}
                              </button>
                          </div>
                        </div>
              </div>
            </div>
          </div>
          

          </Form>    

          )}
        </Formik>
      </div> {/* fin container-fuid */}
     

  {/* ----------------------
  MODAL Addendas 
  --------------------- */}
      <div className="modal fade addendas" tabIndex={-1} role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Agregar Addenda</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                  <select className="form-control">
                    <option>Seleccione</option>
                    <option >Envases Universales</option>
                    <option>Soriana</option>
                  </select>
                  </div>
                </div>
                {/* <EnvasesUniversales/> */}
                <SorianaRemision />
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-danger" data-dismiss="modal"><i className="fas fa-times"></i>Cancelar</button>
              <button type="button" className="btn btn-primary"><i className="fas fa-save"></i>Guardar</button>
            </div>
          </div>
        </div>
      </div>


  {/* -------------------------
    MODAL Editar Factura
    ------------------- */}
<div className="modal fade editar-concepto" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-lg" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Editar Concepto</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="row">
          <div className="col-md-8">
            <div className="form-group">
              <label>Descripción</label>
              <textarea className="form-control" />
            </div>
          </div>
        <div className="col-md-4">
            <div className="form-group">
              <label>Clave</label>
              <input className="form-control" />
            </div>
        </div>
        <div className="col-md-6">
        <div className="form-group">
            <label>Cantidad</label>
            <input className="form-control" />
          </div>
        </div>
        <div className="col-md-6">
        <div className="form-group">
            <label>Cantidad #</label>
            <input className="form-control" />
          </div>
        </div>
          <div className="col-md-4">
            <label>Precio</label>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">$</span>
                </div>
                <input type="text" className="form-control"/>
              </div>
          </div>
          <div className="col-md-4">
            <label>Sub-total</label>
              <div className="input-group mb-3">
                <div className="input-group-prepend" disabled>
                  <span className="input-group-text">$</span>
                </div>
                <input type="text" className="form-control"/>
              </div>
          </div>
        <div className="col-md-4">
            <label>Descuento</label>
              <div className="input-group mb-3">
                <input type="text" className="form-control"/>
                <div className="input-group-append">
                  <span className="input-group-text">%</span>
                </div>
              </div>
          </div>
          <div className="col-md-6">
            <div className="form-check form-check-inline">
              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" defaultValue="option1" />
              <label className="form-check-label" htmlFor="inlineCheckbox1">IVA</label>
            </div>
            <div className="form-check form-check-inline">
              <input className="form-check-input" type="checkbox" id="inlineCheckbox2" defaultValue="option2" />
              <label className="form-check-label" htmlFor="inlineCheckbox2">Excento</label>
            </div>

              <div className="input-group mb-3">
                <input type="text" className="form-control"/>
                <div className="input-group-append">
                  <span className="input-group-text">%</span>
                </div>
              </div>
          </div>
          <div className="col-md-6">
            <div className="form-check">
              <input className="form-check-input" type="checkbox" defaultValue id="defaultCheck1" />
              <label className="form-check-label" htmlFor="defaultCheck1">
                Ret. IVA
              </label>
            </div>
              <div className="input-group mb-3">
                <input type="text" className="form-control"/>
                <div className="input-group-append">
                  <span className="input-group-text">%</span>
                </div>

              </div>
          </div>
          <div className="col-md-6">
          <label>Total</label>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">$</span>
                </div>
                <input type="text" className="form-control"/>
              </div>
          </div>
        </div>

      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-danger" data-dismiss="modal"><i className="fas fa-times"></i>Cancelar</button>
        <button type="button" className="btn btn-primary"><i className="fas fa-save"></i>Guardar</button>
      </div>
    </div>
  </div>
</div>

{/* //MODALS CLIENTE */}
<div className="modal fade bd-example-modal-lg" id="nuevocliente" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                      <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                        <div className="modal-header">
                          {/* <h5 className="modal-title">Nuevo Cliente / Razón Social / RFC</h5> */}
                          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <NuevoCliente />
                      </div>
                        <div className="modal-footer">
                          <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                        </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal fade bd-example-modal-lg" id="buscarcliente" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                      <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                        <div className="modal-header">
                          {/* <h5 className="modal-title">Nuevo Cliente / Razón Social / RFC</h5> */}
                          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <Clientes />
                      </div>
                        <div className="modal-footer">
                          <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                        </div>
                        </div>
                      </div>
                    </div>



      {/* <Modal
        size="lg"
        show={editConcepto}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Editar Concepto
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>...</Modal.Body>
      </Modal> */}

    </Fragment>
  );
}

export default NuevoCfdi;
