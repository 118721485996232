import React, { useState, useEffect, Fragment } from 'react';
// import { Document } from 'react-pdf'
import { useRouteMatch, useParams } from "react-router-dom";

import clienteAxios from '../../config/axios';


const FacturaVerAcusePdf = () => {
// function FacturaVerAcusePdf({idFactura}) {

    const [cfdiBase64, setCfdiBase64] = useState();
    // const [cargandoPdf, setCargandoPdf] = useState(0);


    // let match = useRouteMatch("/ver-pdf/:idFactura");
    // console.log('IMPRIMIMOS MATCH ');
    // console.log(match);


    let { idFactura, uuid, emails } = useParams();

    // let idFactura1 = match.params.idFactura;

    let idFactura1 = idFactura;

    console.log('ID FACTURA');
        console.log(idFactura1);
    
    useEffect(() => {
        
       obtenerPdf(idFactura1);
        
        
       
    }, [idFactura1]);

    async function obtenerPdf(idFactura1){
        console.log('CARGO USE EFFECT id fact');
        console.log(idFactura1);

        try {
            const resultado = await clienteAxios.get(`/v3/facturas-acuse/${idFactura1}`);
            console.log('PDF BASE64');
            console.log(resultado.data.data);
            console.log(resultado.data.code);

            const cfdiBase64_2 = resultado.data.data;
            setCfdiBase64(cfdiBase64_2);
            // return cfdiBase64_2;
            
        } catch (error) {
            const alerta = {
                msg: 'Hubo un error',
                categoria: 'alerta-error'
            }
            console.log(alerta);
            
            // dispatch({
            //     type: FACTURA_ERROR,
            //     payload: alerta
            // })
        } 

    }
    console.log('CFDI EN BASE 64');
    console.log(cfdiBase64);
    return (
        <div className="container-fluid">
           <div className="row">
               <div className="col-md-12">
                   <div className="card">
                       <div className="card-header">
                           <h3 className="card-title">ACUSE DE CANCELACION CFDI</h3>
                           <div className="btn-group float-right">
                            <button className="btn btn-primary"><i className="fas fa-file-download"></i>Descargar</button>
                            {/* <button className="btn btn-primary"><i className="fas fa-file"></i>XML</button> */}
                            <button className="btn btn-primary"><i className="fas fa-envelope"></i>Enviar por correo</button>
                            {/* <button className="btn btn-danger"><i className="fas fa-times"></i>Cancelar</button> */}
                           </div>
                       </div>
                        <div className="card-body cardpdf">
                            {/* <iframe src="3AD3FDDA-174C-4BBB-B324-2CDF90602F09.pdf"></iframe> */}
                            {
                                (cfdiBase64?.length>0) ? 
                                    (<iframe src={`data:application/pdf;base64,${cfdiBase64}`}></iframe>)
                                :
                                'Cargando PDF por.....'

                            }
                            
                            
                            {/* <Document file="3AD3FDDA-174C-4BBB-B324-2CDF90602F09.pdf" /> */}
                        </div>
                   </div>
               </div>
           </div>
        </div>
    );
}

export default FacturaVerAcusePdf;