import React, {useState} from 'react';

function AgregarCorreos({correos, asunto, emailMensaje}) {
    console.log('clic modal '+ correos);
    const [paramFormulario, setParamFormulario] = useState({
        emails: correos,
        emailAsunto: asunto,
        emailMensaje1: emailMensaje,
    });

    const handleChange = e => {
        // actualizar el state
        setParamFormulario({
            ...paramFormulario,
            [e.target.name] : e.target.value
        });
    }

    const {emails, emailAsunto, emailMensaje1} = paramFormulario;
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12">
                    <div className="form-group">
                        <label>Correo</label>
                        <input 
                            type="email" 
                            className="form-control"
                            name="emails"
                            value={paramFormulario.emails}
                            onChange={handleChange}
                        ></input>
                    </div>
                    <div className="form-group">
                        <label>Asunto</label>
                        <input 
                            type="text" 
                            className="form-control" 
                            name="emailAsunto"
                            value={emailAsunto}
                            onChange={handleChange}
                        ></input>
                    </div>
                    <div className="form-group">
                        <label>Mensaje</label>
                        <textarea 
                            className="form-control"
                            name="emailMensaje"
                            value={emailMensaje1}
                            onChange={handleChange}
                        >
                        </textarea>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AgregarCorreos;