import React from 'react';

function HistorialReportes() {
    return (
        <div className="container-fluid">
            <div className="card">
                <h3 className="card-header">Historial de Reportes</h3>
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="form-group d-inline">
                                <input className="form-control"/>
                                <button type="button" className="btn btn-primary">Búscar</button>
                            </div>
                        </div>
                        <div className="col-md-8">
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label>Estatus</label>
                                <select className="form-control">
                                    <option>Sin elección</option>
                                    <option>Pendiente</option>
                                    <option>En proceso</option>
                                    <option>Procesado</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label>Desde</label>
                                <div className="input-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="desde-reporte"
                                    ></input>
                                    <div className="input-group-append">
                                        <button
                                        className="input-group-text"
                                        id="desde-reporte"
                                        >
                                        <i
                                            className="fas fa-calendar-alt"
                                        ></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label>Hasta</label>
                                <div className="input-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="hasta-reporte"
                                    ></input>
                                    <div className="input-group-append">
                                        <button
                                        className="input-group-text"
                                        id="hasta-reporte"
                                        >
                                        <i
                                            className="fas fa-calendar-alt"
                                        ></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead className="thead-dark">
                                        <tr>
                                            <th>No. Reporte</th>
                                            <th>Estatus</th>
                                            <th>Desde</th>
                                            <th>Hasta</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>En Proceso</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>1</td>
                                            <td>En Proceso</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HistorialReportes;