import React from 'react';

import { Route, Redirect } from 'react-router-dom';
// import AuthContext from '../../context/autenticacion/authContext';

const RutaPublica = ({ autenticado, cargando, component: Component, ...props  }) => {

    // const authContext = useContext(AuthContext);
    // const { autenticado, cargando, usuarioAutenticado } = authContext;
    console.log('AUTENTICADO');
    console.log(autenticado);

    return ( 
        // <Route { ...props } render={ props => !autenticado && !cargando ?  (
        <Route { ...props } render={ props => autenticado ?  (
            // <Route { ...props } render={ props => !autenticado ?  (   
            <Redirect to="/dashboard" />
        )  : (
            <Component {...props} />
        ) } />

     );
}
 
export default RutaPublica;

