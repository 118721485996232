import React, { Component } from "react";

class ComplementoDetallista extends Component {
  render() {
    return (
        <div className="container-fluid">
          <div className="col-md-12">
            <h4>Complemento Detallista</h4>
            <hr></hr>
            <div className="row">
                <h5>Comprobante</h5>
                <div className="card-body">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-6 col-sm-12">
                        <label>DocumentStatus </label>
                        <div className="input-group mb-3">
                          <select
                            className="custom-select"
                            id="inputGroupSelect01"
                          >
                            <option selected></option>
                            <option>Original</option>
                            <option>Copia</option>
                            <option>Remplaza</option>
                            <option>Elimina</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card-titulo my-5">
                        <div className="card">
                          <div className="card-header">
                            <h5 className="text-center"></h5>
                          </div>
                          <div className="card-body">
                            <div className="row">
                              <div className="col-md-6">
                                  <div className="card my-5">
                                      <h4 className="card-header card-titulo">
                                        Request For Payment Identification
                                      </h4>
                                    <div className="card-body">
                                      <div className="row">
                                        <div className="col-md-6 col-sm-6">
                                          <label>EntityType*</label>
                                          <div className="input-group mb-3">
                                            <select
                                              className="custom-select"
                                              id="inputGroupSelect01"
                                            >
                                              <option selected></option>
                                              <option>FACTURA - INVOICE</option>
                                              <option>
                                                NOTA DE DEBITO - DEBIT_NOTE
                                              </option>
                                              <option>
                                                NOTA DE CRÉDITO - CREDIT_NOTE
                                              </option>
                                            </select>
                                          </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6">
                                          <label>
                                            Special Instruction Code*
                                          </label>
                                          <div className="input-group mb-3">
                                            <select
                                              className="custom-select"
                                              id="inputGroupSelect01"
                                            >
                                              <option selected></option>
                                              <option>AAB</option>
                                              <option>DUT</option>
                                              <option>PUR</option>
                                            </select>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                              </div>
                              <div className="col-md-6">
                                  <div className="card my-5">
                                      <h4 className="card-header card-titulo">
                                        Aditional Information
                                      </h4>
                                    <div className="card-body">
                                      <div className="row">
                                        <div className="col-md-6 col-sm-6">
                                          <label>
                                            Reference Identification*
                                          </label>
                                          <div className="from-group">
                                            <input className="form-control" />
                                          </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6">
                                          <label>Type*</label>
                                          <div className="input-group mb-3">
                                            <select
                                              className="custom-select"
                                              id="inputGroupSelect01"
                                            >
                                              <option selected></option>
                                              <option>AAE</option>
                                              <option>CK</option>
                                              <option>ACE</option>
                                            </select>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                              </div>
                              <div className="col-md-6">
                                  <div className="card my-5">
                                      <h4 className="card-header card-titulo">
                                        Order Identification
                                      </h4>
                                    <div className="card-body">
                                      <div className="row">
                                        <div className="col-md-6 col-sm-6">
                                          <label>
                                            Reference Identification*
                                          </label>
                                          <div className="from-group">
                                            <input className="form-control" />
                                          </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6">
                                          <label>Reference Date</label>
                                          <div className="input-group">
                                            <input
                                              type="text"
                                              className="form-control"
                                              id="datepicker"
                                              placeholder="Seleccione fecha"
                                            ></input>
                                            <div className="input-group-append" data-toggle="tooltip" data-placement="top" title="Seleccione fecha">
                                              <button
                                                className="input-group-text"
                                                id="datepicker"
                                              >
                                                <i
                                                  className="fas fa-calendar-alt"
                                                  data-toggle="datepicker"
                                                ></i>
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                              </div>
                              <div className="col-md-6">
                                  <div className="card my-5">
                                      <h4 className="card-header card-titulo">
                                        Delivery Note
                                      </h4>
                                    <div className="card-body">
                                      <div className="row">
                                        <div className="col-md-6 col-sm-6">
                                          <label>
                                            Reference Identification*
                                          </label>
                                          <div className="from-group">
                                            <input className="form-control" />
                                          </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6">
                                          <label>Fecha</label>
                                          <div className="input-group">
                                            <input
                                              type="text"
                                              className="form-control"
                                              id="datepicker"
                                              placeholder="Seleccione fecha"
                                            ></input>
                                            <div className="input-group-append" data-toggle="tooltip" data-placement="top" title="Seleccione fecha">
                                              <button
                                                className="input-group-text"
                                                id="datepicker"
                                              >
                                                <i
                                                  className="fas fa-calendar-alt"
                                                  data-toggle="datepicker"
                                                ></i>
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                        <div className="card my-5">
                            <h4 className="card-titulo card-header">Buyer</h4>
                          <div className="card-body">
                            <div className="row">
                              <div className="col-md-6 col-sm-6">
                                <label>GLN* </label>
                                <div className="from-group">
                                  <input className="form-control" />
                                </div>
                              </div>

                              <div className="col-md-6 col-sm-6">
                                <label>Buyer Person Or Department Name*</label>
                                <div className="from-group">
                                  <input className="form-control" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card my-5">
                            <h4 className="card-header card-titulo">Seller</h4>
                          <div className="card-body">
                            <div className="row">
                              <div className="col-md-4 col-sm-6">
                                <label>GLN* </label>
                                <div className="from-group">
                                  <input className="form-control" />
                                </div>
                              </div>
                              <div className="col-md-4 col-sm-6">
                                <label>Type* </label>
                                <div className="from-group">
                                  <input className="form-control" />
                                </div>
                              </div>
                              <div className="col-md-4 col-sm-6">
                                <label>Alternate* </label>
                                <div className="from-group">
                                  <input className="form-control" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                        <div className="card my-5">
                            <h4 className="card-header card-titulo">Invoice Creator:</h4>
                          <div className="card-body">
                            <div className="row">
                              <div className="col-md-6 col-sm-6">
                                <label>GLN </label>
                                <div className="from-group">
                                  <input className="form-control" />
                                </div>
                              </div>
                              <div className="col-md-6 col-sm-6">
                                <label>Alternate Party Identification</label>
                                <div className="from-group">
                                  <input className="form-control" />
                                </div>
                              </div>
                              <div className="col-md-6 col-sm-6">
                                <div className="form-group">
                                  <label>Type: </label>
                                  <select className="form-control">
                                    <option>VA</option>
                                    <option>IA</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-6 col-sm-6">
                                <label>Name </label>
                                <div className="from-group">
                                  <input className="form-control" />
                                </div>
                              </div>
                              <div className="col-md-6 col-sm-6">
                                <label>Street Address One</label>
                                <div className="from-group">
                                  <input className="form-control" />
                                </div>
                              </div>
                              <div className="col-md-6 col-sm-6">
                                <label>City </label>
                                <div className="from-group">
                                  <input className="form-control" />
                                </div>
                              </div>
                              <div className="col-md-6 col-sm-6">
                                <label>Postal Code </label>
                                <div className="from-group">
                                  <input className="form-control" />
                                </div>
                              </div>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                  <div className="card my-5">
                      <h4 className="card-header card-titulo">Ship To:</h4>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-6 col-sm-6">
                          <label>GLN </label>
                          <div className="from-group">
                            <input className="form-control" />
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                          <label>Name </label>
                          <div className="from-group">
                            <input className="form-control" />
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                          <label>Street Address One</label>
                          <div className="from-group">
                            <input className="form-control" />
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                          <label>City </label>
                          <div className="from-group">
                            <input className="form-control" />
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                          <label>Postal Code </label>
                          <div className="from-group">
                            <input className="form-control" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                  <div className="card my-5">
                      <h4 className="card-header card-titulo">Customs:</h4>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-12 col-sm-12">
                          <label>GLN </label>
                          <div className="from-group">
                            <input className="form-control" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
              <div className="col-md-6">
                  <div className="card my-5">
                      <h4 className="card-header card-titulo">Currency:</h4>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-4 col-sm-6">
                              <label>Currency ISO </label>
                              <div className="from-group">
                                <input className="form-control" />
                              </div>
                            </div>
                            <div className="col-md-4 col-sm-6">
                              <label>Currency Function</label>
                              <div className="from-group">
                                <input className="form-control" />
                              </div>
                            </div>
                            <div className="col-md-4 col-sm-6">
                              <label>Rate of Change </label>
                              <div className="from-group">
                                <input className="form-control" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>

              <div className="col-md-12">
                  <div className="card my-5">
                      <h4 className="card-header card-titulo">Payment Terms:</h4>
                    <div className="card-body">
                      <div className="col-md-12">
                          <div className="card my-5">
                              <h5 className="card-header card-titulo">Net Payment:</h5>
                            <div className="card-body">
                              <div className="col-md-12">
                                <div className="row">
                                  <div className="col-md-4 col-sm-6">
                                    <label>Payment Terms Event</label>
                                    <div className="input-group mb-3">
                                      <select
                                        className="custom-select"
                                        id="inputGroupSelect01"
                                      >
                                        <option selected></option>
                                        <option>DATE_OF_INVOICE</option>
                                        <option>EFFECTIVE_DATE</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-md-4 col-sm-6">
                                    <label>Payment TermsRelation Time</label>
                                    <div className="input-group mb-3">
                                      <select
                                        className="custom-select"
                                        id="inputGroupSelect01"
                                      >
                                        <option selected></option>
                                        <option>REFERENCE_AFTER</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-md-4 col-sm-6">
                                    <label>Net Payment TermsType</label>
                                    <div className="input-group mb-3">
                                      <select
                                        className="custom-select"
                                        id="inputGroupSelect01"
                                      >
                                        <option selected></option>
                                        <option>BASIC_NET</option>
                                        <option>END_OF_MONTH</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-md-4 col-sm-6">
                                    <label>Payment Time Period</label>
                                    <div className="from-group">
                                      <input
                                        type="number"
                                        className="form-control"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-4 col-sm-6">
                                    <label>Time Period </label>
                                    <div className="input-group mb-3">
                                      <select
                                        className="custom-select"
                                        id="inputGroupSelect01"
                                      >
                                        <option selected>Seleccione</option>
                                        <option>DAYS</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>

                      <div className="col-md-12">
                          <div className="card my-5">
                              <h4 className="card-header card-titulo">Discount Payment:</h4>
                            <div className="card-body">
                              <div className="col-md-12">
                                <div className="row">
                                  <div className="col-md-6 col-sm-6">
                                    <label>Discount Payment Type</label>
                                    <div className="input-group mb-3">
                                      <select
                                        className="custom-select"
                                        id="inputGroupSelect01"
                                      >
                                        <option selected></option>
                                        <option>
                                          ALLOWANCE_BY_PAYMENT_ON_TIME
                                        </option>
                                        <option>SANCTION</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-sm-6">
                                    <label>Porcentaje</label>
                                    <div className="from-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                    </div>
                </div>
              </div>

              <div className="col-md-12">
                  <div className="card my-5">
                      <h4 className="card-header card-titulo">Line Item:</h4>
                    <div className="card-body">
                      <div className="col-md-4 col-sm-6">
                        <label>Idioma del Producto </label>
                        <div className="input-group mb-3">
                          <select
                            className="custom-select"
                            id="inputGroupSelect01"
                          >
                            <option selected>ES</option>
                            <option>EN</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-12">
                          <div className="card my-5">
                              <h4 className="card-header card-titulo">Customs:</h4>
                            <div className="card-body">
                              <div className="col-md-12">
                                <div className="row">
                                  <div className="col-md-4 col-sm-6">
                                    <label>GLN </label>
                                    <div className="from-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-4 col-sm-6">
                                    <label>
                                      Alternate Party Identification
                                    </label>
                                    <div className="from-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-4 col-sm-6">
                                    <label>Reference Date</label>
                                    <div className="input-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="datepicker"
                                        placeholder="Seleccione fecha"
                                      ></input>
                                      <div className="input-group-append" data-toggle="tooltip" data-placement="top" title="Seleccione fecha">
                                        <button
                                          className="input-group-text"
                                          id="datepicker"
                                        >
                                          <i
                                            className="fas fa-calendar-alt"
                                            data-toggle="datepicker"
                                          ></i>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-4 col-sm-6">
                                    <label>Name</label>
                                    <div className="from-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>

                      <div className="col-md-12">
                          <div className="card my-5">
                              <h4 className="card-header card-titulo">Logistic Units:</h4>
                            <div className="card-body">
                              <div className="col-md-12">
                                <div className="row">
                                  <div className="col-md-4 col-sm-6">
                                    <label>
                                      Serial Shipping Container Code
                                    </label>
                                    <div className="from-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-4 col-sm-6">
                                    <label>Type</label>
                                    <div className="input-group mb-3">
                                      <select
                                        className="custom-select"
                                        id="inputGroupSelect01"
                                      >
                                        <option selected>SRV</option>
                                        <option>BJ</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-md-4 col-sm-6">
                                    <label>Pallet Quantity</label>
                                    <div className="from-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-4 col-sm-6">
                                    <label>Description Type</label>
                                    <div className="input-group mb-3">
                                      <select
                                        className="custom-select"
                                        id="inputGroupSelect01"
                                      >
                                        <option selected>BOX</option>
                                        <option>EXCHANGE_PALLETS</option>
                                        <option>RETURN_PALLETS</option>
                                        <option>PALLET_80x100</option>
                                        <option>CASE</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-md-4 col-sm-6">
                                    <label>Transport</label>
                                    <div className="input-group mb-3">
                                      <select
                                        className="custom-select"
                                        id="inputGroupSelect01"
                                      >
                                        <option selected>PAID_BY_BUYER</option>
                                        <option>PREPAID_BY_SELLER</option>
                                        <option>RETURN_PALLETS</option>
                                        <option>PALLET_80x100</option>
                                        <option>CASE</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-md-4 col-sm-6">
                                    <label>Lot Number</label>
                                    <div className="from-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-4 col-sm-6">
                                    <label>Production Date</label>
                                    <div className="input-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="datepicker"
                                        placeholder="Seleccione fecha"
                                      ></input>
                                      <div className="input-group-append" data-toggle="tooltip" data-placement="top" title="Seleccione fecha">
                                        <button
                                          className="input-group-text"
                                          id="datepicker"
                                        >
                                          <i
                                            className="fas fa-calendar-alt"
                                            data-toggle="datepicker"
                                          ></i>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>
              </div>

              <div className="col-md-12">
                  <div className="card my-5">
                      <h5 className="card-header card-titulo">Allowance Charge:</h5>
                    <div className="card-body">
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-4 col-sm-6">
                            <label>Allowance Charge Type</label>
                            <div className="input-group mb-3">
                              <select
                                className="custom-select"
                                id="inputGroupSelect01"
                              >
                                <option selected>ALLOWANCE_GLOBAL</option>
                                <option>CHARGE_GLOBAL</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-6">
                            <label>Settlement Type</label>
                            <div className="input-group mb-3">
                              <select
                                className="custom-select"
                                id="inputGroupSelect01"
                              >
                                <option selected>OFF_INVOICE</option>
                                <option>CHARGE_TO_BE_PAID_BY_VENDOR</option>
                                <option>CHARGE_TO_BE_PAID_BY_CUSTOMER</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-6">
                            <label>Sequence Number</label>
                            <div className="from-group">
                              <input type="text" className="form-control" />
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-6">
                            <label>Special Services Type</label>
                            <div className="input-group mb-3">
                              <select
                                className="custom-select"
                                id="inputGroupSelect01"
                              >
                                <option selected>AA</option>
                                <option>AJ</option>
                                <option>ADO</option>
                                <option>ADT</option>
                                <option>ADS</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-6">
                            <label>Percentaje</label>
                            <div className="from-group">
                              <input type="text" className="form-control" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

export default ComplementoDetallista;
