import React, { Component } from 'react';

class ComplementoDonatarias extends Component {
    render() {
        return (
            <div>
                <div className="container-fluid">
                <div className="col-md-12">
                              <h4>
                                Complemento Donatarias
                              </h4>
                              <hr></hr>
                                <div className="row">
                                  <div className="col-md-4 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                      <label>No. de Autorización*:</label>
                                      <input
                                        type="email"
                                        className="form-control"
                                        id="inputEmail4"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-4 col-sm-6">
                                    <div className="form-group">
                                      <label htmlFor="unidad">
                                        Fecha de Autorización*:
                                      </label>
                                      <div className="input-group">
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="datepicker"
                                          placeholder="Seleccione fecha"
                                        ></input>
                                        <div className="input-group-append" data-toggle="tooltip" data-placement="top" title="Seleccione fecha">
                                          <button
                                            className="input-group-text"
                                            id="datepicker"
                                          >
                                            <i
                                              className="fas fa-calendar-alt"
                                              data-toggle="datepicker"
                                            ></i>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-4 col-sm-12">
                                    <div className="form-group">
                                      <label>Leyenda*:</label>
                                      <textarea className="form-control"></textarea>
                                    </div>
                                  </div>
                                </div>
                      </div>
                </div>
            </div>
        );
    }
}

export default ComplementoDonatarias;