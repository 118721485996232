import React, { useState, useEffect, useContext } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import clienteAxios from "../../config/axios";
import Producto from "./Producto";
import Paginacion from "./Paginacion2"
import Cargador from "../generales/Cargador";
// import productoContext from '../context/productos/productoContext';
// import productoContext from '../../context/productos/productoContext';
import productoContext from '../../context/productos/productoContext';


const Productos = () => {
  
  const { paginaNumero } = useParams();
  let history = useHistory();

  // const [buscar, setBuscar] = useState("");
  const [pagina, setPagina] = useState(1);
  
  const [buscando, setBuscando] = useState(0);
  // const [productos, setProductos] = useState([]);

  const productosContext = useContext(productoContext);
  const { cargando_productos, mensaje, productos, paginacion, obtenerProductos, actualizarEnviada, buscar, setBuscar } = productosContext;

  console.log('paginaNumero' + paginaNumero)
  console.log('pagina' + pagina)

  const handleChange = (e) => {
    // setBuscar([e.target.name] : e.target.value)
    setBuscar(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      console.log('Oprimio tecla enter para buscar');
      handleSubmit(e);
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setBuscando(1);
    setPagina(1);
    console.log('PAGINA ACTUAL CONFIGURADA ' + pagina);
    history.push('/productos/1');
    obtenerProductos(1, buscar);
    setBuscando(0);
  };

  useEffect(() => {
    setBuscando(0);
    setPagina(paginaNumero);
    obtenerProductos(paginaNumero, buscar);
  }, [paginaNumero]);
/*
  const obtenerProductos = async (pagina, buscar) => {
    try {
      const resultado = await clienteAxios.get("/v3/productos", {
        params: {
          page: pagina,
          producto_descripcion: buscar,
        },
      });
      const tproductos = resultado.data.data;
      setBuscando(0);
      setProductos(tproductos);
      // console.log(resultado.data.data);
    //   console.log(tproductos);
    } catch (error) {
      const alerta = {
        msg: "Hubo un error",
        categoria: "alerta-error",
      };
      setBuscando(0);
    //   console.log(alerta);

      // dispatch({
      //     type: FACTURA_ERROR,
      //     payload: alerta
      // })
    }
  };
  */

  // console.log('LOS PRODUCTOS');
  // console.log(productos);
  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">Productos o Servicios</h3>
                <div className="float-left">
                  <Link
                    to="/nuevo-producto"
                    type="button"
                    className="btn btn-primary ml-1"
                  >
                    <i className="fas fa-plus"></i>Agregar
                  </Link>
                </div>
                <div className="float-right">
                  <Link
                    to="/importar-productos"
                    type="button"
                    className="btn btn-primary ml-1"
                  >
                    <i className="fas fa-file-import"></i>Importar
                  </Link>
                  <Link to="/" type="button" className="btn btn-primary ml-1">
                    <i className="fas fa-file-upload"></i>Exportar
                  </Link>
                </div>
              </div>
              {/* <div className="col-md-12">
                                <div id="example1_filter" className="dataTables_filter">
                                    <label>
                                        <input type="search" className="form-control form-control-sm" placeholder="Buscar" aria-controls="example1" />
                                    </label>
                                </div>
                            </div> */}
              <div className="card-body">
                <div className="row">
                  <div className="col-md-3 col-sm-3">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Buscar"
                        name="buscar"
                        onKeyDown={handleKeyDown}
                        onChange={handleChange}
                        value={buscar}
                      />
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-3">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={handleSubmit}
                      disabled={buscando}
                    >
                      <i className="fa fa-search"></i>
                      {buscando ? "Buscando" : "Buscar"}
                    </button>
                  </div>
                </div>
                <div className="table-responsive">
                  <table
                    id="example1"
                    className="table table-bordered table-striped"
                  >
                    <thead>
                      <tr>
                        <th></th>
                        <th>Clave</th>
                        <th>Descripción</th>
                        <th>Unidad</th>
                        <th>Precio</th>
                        <th>Editar</th>
                        <th>Eliminar</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                      cargando_productos ? 
                      (<Cargador/>) :
                      
                      /*!productos?.length ? 
                      (
                        <label>NO SE ENCONTRARON RESULTADOS......</label>
                      ) :*/ 
                      (
                        productos.map((producto) => (
                          <Producto
                            key={producto.producto_identificador}
                            producto={producto}
                          />
                        ))
                      )}
                      {/* <tr>
                                      <td></td>
                                      <td>S0001-0114</td>
                                      <td>ANALISIS CLINICOS
                                      </td>
                                      <td>Unidad de servicio</td>
                                      <td>1</td>
                                          <td><Link to="/editar-producto"><img src="dist/img/consultar.gif" width="20"/></Link></td>
                                          <td><img src="dist/img/anular.gif" width="20"/></td>
                                          
                                      </tr>
                                      */}
                    </tbody>
                  </table>
                </div>
                <div className="float-right mt-3">
                    <div
                    className="dataTables_paginate paging_simple_numbers"
                    id="example1_paginate"
                    >
                    <Paginacion    
                            parametros={buscar}
                            pagina={pagina}
                            key={`paginacion-${paginacion.current_page}`}                                
                        />
                    </div>
                </div>
               </div> {/*fin card body */}
               {/* <div className="card-footer">
                <Paginacion    
                    parametros={buscar}
                    pagina={pagina}
                    key={`paginacion-${paginacion.current_page}`}      
                />
               </div> */}
               {/*fin card footer */}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="float-right">
        <div
          className="dataTables_paginate paging_simple_numbers"
          id="example1_paginate"
        >
           <Paginacion    
                parametros={buscar}
                pagina={pagina}
                key={`paginacion-${paginacion.current_page}`}                                
            />
        </div>
      </div> */}
    </div>
  );
};

export default Productos;
