import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class ReporteConsumo extends Component {
    render() {
        return (
            <div>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12">
                  <div className="card card-primary">
                    <div className="card-header">
                      <h3 className="card-title">Reporte de Consumo</h3>
                    </div>
                      <div className="card-body">
                          <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                            <label htmlFor="exampleInputPassword1">Razón Social</label>
                            <input type="text" className="form-control" id="exampleInputPassword1" placeholder="Razón Social" />
                            </div>
                        </div>
                      <div className="col-md-6">
                      <div className="form-group">
                          <label htmlFor="exampleInputPassword1">Fecha Inicial</label>
                          <input type="date" className="form-control" id="exampleInputPassword1" placeholder="Razón Social" />
                        </div>
                      </div>
                      <div className="col-md-6">
                      <div className="form-group">
                          <label htmlFor="exampleInputPassword1">Fecha Final</label>
                          <input type="date" className="form-control" id="exampleInputPassword1" placeholder="Razón Social" />
                        </div>
                      </div>
                      </div>
                      </div>
                      <div className="card-footer">
                        <Link type="button" to="consumo-folios" className="btn btn-primary">Consultar</Link>
                      </div>
                  </div>
                </div>
                  </div>
              </div>
            </div>

        );
    }
}

export default ReporteConsumo;