import React, { useState, useEffect, useRef, useContext } from "react";
import clienteAxios from '../../config/axios';
import { Formik, Form, Field, FieldArray } from "formik";
import * as Yup from 'yup';
import { useHistory } from "react-router-dom";

import Autosuggest from "react-autosuggest";

// import AuthContext from '../../context/autenticacion/authContext';







const NuevoCliente = () => {

  // const authContext = useContext(AuthContext);
  // const { usuario, cargando } = authContext;
  

  const [usoCfdi, setUsoCfdi] = useState([]);
  const [formaPago, setFormaPago] = useState([]);
  const [paises, setPaises] = useState([]);
  const [bancoCuentas, setBancoCuentas] = useState([]);
  

  const [contadorCuentas, setContadorCuentas] = useState(0);

  const [bancoNombre, setBancoNombre] = useState("");
  const [bancoCuenta, setBancoCuenta] = useState("");
  const [bancoRfc, setBancoRfc] = useState("");
  
  const [suggestions, setSuggestions] = useState([]);

  const cuenta_banco_nombre_ref = useRef(null);
  const cuenta_banco_rfc_ref = useRef(null);
  const cuenta_banco_numero_ref = useRef(null);

  const [complementos, setComplementos] = useState([
    // {
    //   'complemento_clave': 'pago',
    //   'complemento_descripcion': 'Complemento de pago',
    // },
    // {
    //   'complemento_clave': 'ine',
    //   'complemento_descripcion': 'Complemento INE',
    // },
    // {
    //   'complemento_clave': 'cvu',
    //   'complemento_descripcion': 'Complemento Compra Vehiculos Usados',
    // },
    // {
    //   'complemento_clave': 'donat',
    //   'complemento_descripcion': 'Complemento DONAT',
    // },
    // {
    //   'complemento_clave': 'cce',
    //   'complemento_descripcion': 'Complemento Comercio Exterior',
    // },
    // {
    //   'complemento_clave': 'iedu',
    //   'complemento_descripcion': 'Complemento IEDU',
    // },
    // {
    //   'complemento_clave': 'spconstruccion',
    //   'complemento_descripcion': 'Complemento Servicios Parciales de Construcción',
    // },
    // {
    //   'complemento_clave': 'aerolineas',
    //   'complemento_descripcion': 'Complemento Aerolineas',
    // },
    // {
    //   'complemento_clave': 'leyenda',
    //   'complemento_descripcion': 'Complemento Leyendas Fiscales',
    // },
    // {
    //   'complemento_clave': 'notario',
    //   'complemento_descripcion': 'Complemento Notarios Publicos',
    // },
    // {
    //   'complemento_clave': 'vv',
    //   'complemento_descripcion': 'Complemento ',
    // },
    // {
    //   'complemento_clave': 'divisa',
    //   'complemento_descripcion': 'Complemento Divisa',
    // },
    // {
    //   'complemento_clave': 'tercero',
    //   'complemento_descripcion': 'Complemento Tercero',
    // },
    // {
    //   'complemento_clave': 'detallista',
    //   'complemento_descripcion': 'Complemento Detallista',
    // }
    
]);

  let history = useHistory();
  const barrasReg = /^[a-zA-Z0-9]*$/;
  const predialReg = /^[0-9]{1,150}$/;
  const rfcReg = /^([A-ZÑ]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[A-Z|\d]{3})$/;
  const cuentaBancariaReg = /^([0-9]{10,11}|[0-9]{15,16}|[0-9]{18}|[A-Z0-9_]{10,50})$/;

  const profileShema = Yup.object().shape({
    cliente_nombre: Yup.string()
      .min(1, 'Minimo 1 caracter')
      .max(1000, 'Máximo 1000 caracteres')
      .required('Debe escribir un Nombre o Razon Social'),
    cliente_rfc: Yup.string()
      .matches( rfcReg, 'El rfc no es valido')
      .required('Debe escribir un rfc'),
    cliente_uso_cfdi: Yup.string()
      .required('Debe seleccionar el Uso de CFDI por defecto'),
    cliente_forma_pago: Yup.string()
      .required('Debe seleccionar la Forma de Pago por defecto')
   
    // unidad_medida: Yup.string()
    //   .required('Debe escribir una unidad de medida'),
    // precio: Yup.string()
    //   .required('Debe teclear un precio'),
    // cuenta_predial: Yup.string()
    //   .matches( predialReg, 'Solo deben ser numeros y maximo 150 caracteres')
  })

  const cuentaBancariaSchema = Yup.object().shape({
    banco_id: Yup.number().typeError('id debe ser numerico').required('id es requerido'),
    cuenta_banco: Yup.string().required('Nombre banco es requerido'),
    cuenta_banco_rfc: Yup.string().matches( rfcReg, 'El rfc no es valido').required('Debe escribir un rfc'),
    cuenta_numero: Yup.string().matches( cuentaBancariaReg, 'La cuenta bancaria no es valida').required('Debe escribir una cuenta bancaria')
    // cuenta_numero: Yup.number().typeError('Numero de cuenta debe ser numerico').required('Numero de cuenta es requerido')
  });

  useEffect(() => {
    obtenerUsoCfdi();
    obtenerFormaPago();
    obtenerPaises();
    obtenerComplementos()
    
    
  }, []);

  //SE COMENTO DEBIDO A QUE NO SE USA EL CONTEXTO PARA OBTENER EL ID DE SUCURSAL SI FUNCIONO BIEN PARA ESPERAR EL VALOR
  // useEffect(() => {
  //   if(!cargando){
  //     obtenerComplementos()
  //   }
  // }, [cargando, usuario]);

  async function obtenerUsoCfdi(){
    try {
        const resultado = await clienteAxios.get(`/v3/usos-cfdi?no_paginacion=1`);
        // console.log(resultado.data.data); 
        const usoCfdi1 = resultado.data.data;
       
        setUsoCfdi(
          usoCfdi1
        );
        // console.log( usoCfdi)
    } catch (error) {
        const alerta = {
            msg: 'Hubo un error',
            categoria: 'alerta-error'
        }
        console.log(alerta); 
    } 
  }

  async function obtenerFormaPago(){
    try {
        const resultado = await clienteAxios.get(`/v3/formas-pago?no_paginacion=1`);
        // console.log(resultado.data.data); 
        const formaPago1 = resultado.data.data;
       
        setFormaPago(
          formaPago1
        );
        // console.log( formaPago)
    } catch (error) {
        const alerta = {
            msg: 'Hubo un error',
            categoria: 'alerta-error'
        }
        console.log(alerta); 
    } 
  }

  async function obtenerPaises(){
    try {
        const resultado = await clienteAxios.get(`/v3/paises?no_paginacion=1`);
        // console.log(resultado.data.data); 
        const paises1 = resultado.data.data;
       
        setPaises(
          paises1
        );
        // console.log( paises)
    } catch (error) {
        const alerta = {
            msg: 'Hubo un error',
            categoria: 'alerta-error'
        }
        console.log(alerta); 
    } 
  }

  async function obtenerComplementos(){
    try {
        
      const resultado = await clienteAxios.get(`/v3/listado-complementos-factura`);
        // const resultado = await clienteAxios.get(`/v3/listado-complementos-factura?sucursal_identificador=${sucursal_id}`);
        const complementos1 = resultado.data.data;
      
        

        // const resultado = await clienteAxios.get(`/v3/listado-complementos-factura`);
        // console.log(resultado.data.data); 
        
       
        setComplementos(
          complementos1
        );
        // console.log( usoCfdi)
    } catch (error) {
        const alerta = {
            msg: 'Hubo un error',
            categoria: 'alerta-error'
        }
        console.log(alerta); 
    } 
  }

  const usoCfdiSelect = (
    <>
    {
      usoCfdi.map( imp =>{
        return(
          <option key={imp.catalogo_usocfdi_clave} value={imp.catalogo_usocfdi_clave}>{`${imp.catalogo_usocfdi_clave} ${imp.catalogo_usocfdi_descripcion}`}</option>
        )
      } )
    }
    </>
  )

  const formaPagoSelect = (
    <>
    {
      formaPago.map( imp =>{
        return(
          <option key={imp.catalogo_forma_pago_clave} value={imp.catalogo_forma_pago_clave}>{`${imp.catalogo_forma_pago_clave} ${imp.catalogo_forma_pago_descripcion}`}</option>
        )
      } )
    }
    </>
  )

  const paisesSelect = (
    <>
    {
      paises.map( imp =>{
        return(
          <option key={imp.catalogo_pais_pais} value={imp.catalogo_pais_pais}>{`${imp.catalogo_pais_descripcion}`}</option>
        )
      } )
    }
    </>
  )
  
  const agregarCuenta = () =>{
    // alert('Clic a bonton agregar');
    // let contadorC = contadorCuentas+1;
    setContadorCuentas(contadorCuentas+1);
    // console.log('IMPRIME REFERENCIA');
    // console.log(cuenta_banco_rfc_ref);
    // console.log(cuenta_banco_nombre_ref?.current?.defaultValue);
    // console.log(cuenta_banco_rfc_ref?.current?.defaultValue);
    // console.log(cuenta_banco_numero_ref?.current?.defaultValue);
    let nuevoBanco = 
      {
        banco_id : contadorCuentas,
        // cuenta_banco : cuenta_banco_nombre_ref?.current?.defaultValue,
        // cuenta_banco_rfc : cuenta_banco_rfc_ref?.current?.defaultValue,
        // cuenta_numero: cuenta_banco_numero_ref?.current?.defaultValue
        cuenta_banco : bancoNombre,
        cuenta_banco_rfc : bancoRfc,
        cuenta_numero: bancoCuenta
      };
      cuentaBancariaSchema
      .validate(nuevoBanco)
      .then(function(value) {
        console.log(value); // returns car object
        setBancoCuentas([
          ...bancoCuentas,
          // cuenta_banco : e.target.value,
          nuevoBanco
        ]);
       setBancoNombre('');
       setBancoRfc('');
       setBancoCuenta('');
        console.log(bancoCuentas);
      })
      .catch(function(err) {
        console.log(err);
        // err.errors.map(e)=>{ 
        // }
        alert(err.errors?.toString());
      });
    
    
  }

  const eliminarBanco = (id) => {
    const bancoCuentas1 = bancoCuentas.filter(banco => (banco.banco_id !== id ) );
    setBancoCuentas(bancoCuentas1);
  }

  const tablaCuentasBancarias = (
    <>
    {
      bancoCuentas.map( banco =>{
        return(
          <tr key={banco.banco_id} >
            <td>{banco.cuenta_banco}</td>
            <td>{banco.cuenta_banco_rfc}</td>
            <td>{banco.cuenta_numero}</td>
            <td><button type="button" className="btn btn-danger" onClick={()=>{eliminarBanco(banco.banco_id)}}>Quitar</button></td>
          </tr>  
        )
      } )
    }
    </>
  )

  const cuentasBancariasInputs = (
    <>
    {
      bancoCuentas.map( (banco, index) =>{
        return(
          <div key={index}>
            <input type="text" name={`cliente_cuentas_bancarias[${index}].cuenta_banco_rfc`}  value={banco.cuenta_banco_rfc} />
            <input type="text" name={`cliente_cuentas_bancarias[${index}].cuenta_numero`}  value={banco.cuenta_numero} />
            <input type="text" name={`cliente_cuentas_bancarias[${index}].cuenta_banco`} value={banco.cuenta_banco} />
          </div>
        )
      } )
    }
    </>
  )




  // console.log(tablaCuentasBancarias);
    return (
      <div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
            <Formik
                  initialValues={{ 
                    cliente_nombre: '', 
                    cliente_rfc: '',
                    cliente_uso_cfdi: '',
                    cliente_forma_pago: '',
                    cliente_calle: '' ,
                    cliente_numero: '',
                    cliente_num_interior: '',
                    cliente_colonia: '',
                    cliente_localidad: '',
                    cliente_delegacion: '',
                    cliente_pais: '',
                    cliente_telefonos: '',
                    cliente_email: '',
                    cliente_codigo_postal: '',
                    cliente_estado: '',
                    cliente_residencia: '',
                    cliente_referencia: '',
                    cliente_num_tributario: '',
                    cuenta_banco_nombre:'',
                    cuenta_banco_nombre2:'',
                    cuenta_banco_rfc:'',
                    cuenta_numero:'',
                    cliente_complementos: [],
                    cliente_cuentas_bancarias: bancoCuentas
                  }}
                  validationSchema={profileShema}
                  onSubmit={ async (values, { setSubmitting })=>{
                    // setTimeout(()=>{
                    //   alert(JSON.stringify(values, null, 2));
                    //   setSubmitting(false);
                    // },2000);
                    let formJson = {
                      'cliente_nombre': values.cliente_nombre,
                      'cliente_rfc': values.cliente_rfc,
                      'cliente_uso_cfdi': values.cliente_uso_cfdi,
                      'cliente_calle': values.cliente_calle,
                      'cliente_numero': values.cliente_numero,
                      'cliente_num_interior': values.cliente_num_interior,
                      'cliente_tipo_pago': values.cliente_forma_pago,
                      'cliente_colonia': values.cliente_colonia,
                      'cliente_localidad': values.cliente_localidad,
                      'cliente_delegacion': values.cliente_delegacion,
                      'cliente_telefonos': values.cliente_telefonos,
                      'cliente_email': values.cliente_email,
                      'cliente_codigo_postal': values.cliente_codigo_postal,
                      'cliente_estado': values.cliente_estado,
                      'cliente_pais': values.cliente_pais,
                      'cliente_residencia': values.cliente_residencia,
                      'cliente_referencia': values.cliente_referencia,
                      'cliente_num_tributario': values.cliente_num_tributario,
                      'cliente_complementos': values.cliente_complementos,
                      // 'cliente_cuentas_bancarias': values.cliente_cuentas_bancarias
                      'cliente_cuentas_bancarias': bancoCuentas

                    }
                    console.log(formJson);
                    try {
                        const resultado = await clienteAxios({
                            method: 'post',                        
                            url: `/v3/clientes`,
                            data: formJson,
                        })
                        console.log(resultado.data.data);
                        console.log(resultado.status);
                        if((resultado.status===200) || (resultado.status===201)){
                          alert("CLIENTE REGISTRADO CON EXITO");
                          // history.goBack();
                          history.push('/cliente');
                          setSubmitting(false);
                        }else{
                          alert("No se guardo el cliente, hubo un error "+JSON.stringify(resultado.data.data, null, 2));
                        }
                    } catch (error) { 
                        console.log('Hubo un error al enviar api '+error);
                        alert(JSON.stringify(error.response.data.error));
                        console.log('code', error.response.data.code);
                        console.log('error', JSON.stringify(error.response.data.error, null, 2));
                       
                    }
                    setSubmitting(false);
                    
                  }}

                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleSubmit,
                    handleBlur,
                    isSubmitting,
                    setFieldValue
                  })=> (
                <form role="form" onSubmit={handleSubmit} autoComplete="off">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="card-title">Registro de nuevo Cliente</h3>
                  {/* <button type="submit" className="btn btn-primary float">
                    <i className="fas fa-save"></i>Guardar
                  </button>
                  <button type="submit" className="btn btn-secondary">
                    <i className="fas fa-arrow-left"></i>Atrás
                  </button> */}
                  <button type="submit" className="btn btn-primary" disabled={isSubmitting} >
                              <i className="fas fa-save"></i>
                              {isSubmitting ? 'Guardando' : 'Guardar'}
                  </button>
                  <button type="submit" className="btn btn-secondary" onClick={
                    (e) => {
                      e.preventDefault();
                      history.goBack();
                    }  
                  }>
                    <i className="fas fa-arrow-left"></i>Atrás
                  </button>
                </div>
                  <div className="card-body">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                      <li className="nav-item">
                        <a className="nav-link active" id="new-cliente" data-toggle="tab" href="#nuevo-cliente" role="tab" aria-controls="nuevo-cliente" aria-selected="true">Nuevo Cliente</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" id="accound-bank" data-toggle="tab" href="#cuentas-bancarias" role="tab" aria-controls="cuentas-bancarias" aria-selected="false">Cuentas Bancarias</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" id="complements" data-toggle="tab" href="#complementos" role="tab" aria-controls="complementos" aria-selected="false">Complementos</a>
                      </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                      <div className="tab-pane fade show active" id="nuevo-cliente" role="tabpanel" aria-labelledby="new-cliente">
                        <div className="row mt-2">
                        <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="razon_social">
                            Cliente / Nombre / Razón Social*:
                          </label>
                          <input
                            type="text"
                            className={
                              errors.cliente_nombre && touched.cliente_nombre
                              ? "form-control error"
                              : "form-control"
                            }
                            id="cliente_nombre"
                            name="cliente_nombre"
                            placeholder="Nombre o Razón Social"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.cliente_nombre}

                          />
                          {errors.cliente_nombre && touched.cliente_nombre && (
                                <div className="input-feedback">{errors.cliente_nombre}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="rfc">R.F.C.*: </label>
                          <input
                            type="text"
                            className={
                              errors.cliente_rfc && touched.cliente_rfc
                              ? "form-control error"
                              : "form-control"
                            }
                            id="cliente_rfc"
                            name="cliente_rfc"
                            placeholder="Nombre o Razón Social"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.cliente_rfc}

                          />
                          {errors.cliente_rfc && touched.cliente_rfc && (
                                <div className="input-feedback">{errors.cliente_rfc}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="residencia_fiscal">
                            Residencia Fiscal(Extranjero):
                          </label>
                          <select 
                                    name="cliente_residencia" 
                                    id="cliente_residencia" 
                                    className="form-control"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.cliente_residencia}
                                    >
                                    <option value="">No Aplica</option>
                                    {paisesSelect}
                                  </select>
                                  {errors.cliente_residencia && touched.cliente_residencia && (
                                <div className="input-feedback">{errors.cliente_residencia}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="cliente_num_tributario">
                            Num Reg Id Trib(Extranjero):
                          </label>
                          <input
                            type="text"
                            className={
                              errors.cliente_num_tributario && touched.cliente_num_tributario
                              ? "form-control error"
                              : "form-control"
                            }
                            id="cliente_num_tributario"
                            name="cliente_num_tributario"
                            placeholder=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.cliente_num_tributario}

                          />
                          {errors.cliente_num_tributario && touched.cliente_num_tributario && (
                                <div className="input-feedback">{errors.cliente_num_tributario}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="uso-cfdi">Uso CFDI*: </label>
                          
                          <select 
                                    name="cliente_uso_cfdi" 
                                    id="cliente_uso_cfdi" 
                                    className="form-control"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.cliente_uso_cfdi}
                                    >
                                    <option value="">No Aplica</option>
                                    {usoCfdiSelect}
                                  </select>
                                  {errors.cliente_uso_cfdi && touched.cliente_uso_cfdi && (
                                <div className="input-feedback">{errors.cliente_uso_cfdi}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="forma_pago">Forma de Pago*: </label>
                          <select 
                                    name="cliente_forma_pago" 
                                    id="cliente_forma_pago" 
                                    className="form-control"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.cliente_forma_pago}
                                    >
                                    <option value="">No Aplica</option>
                                    {formaPagoSelect}
                                  </select>
                                  {errors.cliente_forma_pago && touched.cliente_forma_pago && (
                                <div className="input-feedback">{errors.cliente_forma_pago}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="calle">Calle: </label>
                          <input
                            type="text"
                            className={
                              errors.cliente_calle && touched.cliente_calle
                              ? "form-control error"
                              : "form-control"
                            }
                            id="cliente_calle"
                            name="cliente_calle"
                            placeholder=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.cliente_calle}

                          />
                          {errors.cliente_calle && touched.cliente_calle && (
                                <div className="input-feedback">{errors.cliente_calle}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group">
                          <label htmlFor="num_ext">Número Exterior: </label>
                          <input
                            type="text"
                            className={
                              errors.cliente_numero && touched.cliente_numero
                              ? "form-control error"
                              : "form-control"
                            }
                            id="cliente_numero"
                            name="cliente_numero"
                            placeholder=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.cliente_numero}

                          />
                          {errors.cliente_numero && touched.cliente_numero && (
                                <div className="input-feedback">{errors.cliente_numero}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group">
                          <label htmlFor="num_int">Número Interior: </label>
                          <input
                            type="text"
                            className={
                              errors.cliente_num_interior && touched.cliente_num_interior
                              ? "form-control error"
                              : "form-control"
                            }
                            id="cliente_num_interior"
                            name="cliente_num_interior"
                            placeholder=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.cliente_num_interior}

                          />
                          {errors.cliente_num_interior && touched.cliente_num_interior && (
                                <div className="input-feedback">{errors.cliente_num_interior}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="cp">Código Postal: </label>
                          <input
                            type="text"
                            className={
                              errors.cliente_codigo_postal && touched.cliente_codigo_postal
                              ? "form-control error"
                              : "form-control"
                            }
                            id="cliente_codigo_postal"
                            name="cliente_codigo_postal"
                            placeholder=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.cliente_codigo_postal}

                          />
                          {errors.cliente_codigo_postal && touched.cliente_codigo_postal && (
                                <div className="input-feedback">{errors.cliente_codigo_postal}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="colonia">Colonia: </label>
                          <input
                            type="text"
                            className={
                              errors.cliente_colonia && touched.cliente_colonia
                              ? "form-control error"
                              : "form-control"
                            }
                            id="cliente_colonia"
                            name="cliente_colonia"
                            placeholder=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.cliente_colonia}

                          />
                          {errors.cliente_colonia && touched.cliente_colonia && (
                                <div className="input-feedback">{errors.cliente_colonia}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="localidad">Localidad: </label>
                          <input
                            type="text"
                            className={
                              errors.cliente_localidad && touched.cliente_localidad
                              ? "form-control error"
                              : "form-control"
                            }
                            id="cliente_localidad"
                            name="cliente_localidad"
                            placeholder=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.cliente_localidad}

                          />
                          {errors.cliente_localidad && touched.cliente_localidad && (
                                <div className="input-feedback">{errors.cliente_localidad}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="referencia">Referencia: </label>
                          <input
                            type="text"
                            className={
                              errors.cliente_referencia && touched.cliente_referencia
                              ? "form-control error"
                              : "form-control"
                            }
                            id="cliente_referencia"
                            name="cliente_referencia"
                            placeholder=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.cliente_referencia}

                          />
                          {errors.cliente_referencia && touched.cliente_referencia && (
                                <div className="input-feedback">{errors.cliente_referencia}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="delegacion">
                            Alcaldia/Municipio:
                          </label>
                          <input
                            type="text"
                            className={
                              errors.cliente_delegacion && touched.cliente_delegacion
                              ? "form-control error"
                              : "form-control"
                            }
                            id="cliente_delegacion"
                            name="cliente_delegacion"
                            placeholder=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.cliente_delegacion}

                          />
                          {errors.cliente_delegacion && touched.cliente_delegacion && (
                                <div className="input-feedback">{errors.cliente_delegacion}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="estado">Estado </label>
                          <input
                            type="text"
                            className={
                              errors.cliente_estado && touched.cliente_estado
                              ? "form-control error"
                              : "form-control"
                            }
                            id="cliente_estado"
                            name="cliente_estado"
                            placeholder=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.cliente_estado}

                          />
                          {errors.cliente_estado && touched.cliente_estado && (
                                <div className="input-feedback">{errors.cliente_estado}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="pais">País: </label>
                          <input
                            type="text"
                            className={
                              errors.cliente_pais && touched.cliente_pais
                              ? "form-control error"
                              : "form-control"
                            }
                            id="cliente_pais"
                            name="cliente_pais"
                            placeholder=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.cliente_pais}

                          />
                          {errors.cliente_pais && touched.cliente_pais && (
                                <div className="input-feedback">{errors.cliente_pais}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="email">
                            E-mail(separar con comas varios):
                          </label>
                          <input
                            type="text"
                            className={
                              errors.cliente_email && touched.cliente_email
                              ? "form-control error"
                              : "form-control"
                            }
                            id="cliente_email"
                            name="cliente_email"
                            placeholder=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.cliente_email}

                          />
                          {errors.cliente_email && touched.cliente_email && (
                                <div className="input-feedback">{errors.cliente_email}</div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="telefonos">Teléfonos: </label>
                          <input
                            type="text"
                            className={
                              errors.cliente_telefonos && touched.cliente_telefonos
                              ? "form-control error"
                              : "form-control"
                            }
                            id="cliente_telefonos"
                            name="cliente_telefonos"
                            placeholder=""
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.cliente_telefonos}

                          />
                          {errors.cliente_telefonos && touched.cliente_telefonos && (
                                <div className="input-feedback">{errors.cliente_telefonos}</div>
                          )}
                        </div>
                      </div>
                      
                        </div>

                      </div>

                      <div className="tab-pane fade" id="cuentas-bancarias" role="tabpanel" aria-labelledby="accound-bank">
                          <div className="col-md-12 mt-2">
                          {/* <FieldArray
                            name="cliente_cuentas_bancarias"
                            render={arrayHelpers => (
                              <div>
                                {values.cliente_cuentas_bancarias.map((friend, index) => (
                                  <div key={index}>
                                    
                                    <Field name={`cliente_cuentas_bancarias.[${index}].cuenta_banco`} placeholder="Banco" />
                                    <Field name={`cliente_cuentas_bancarias.[${index}].cuenta_cuenta_banco_rfc`} placeholder="RFC" />
                                    <Field name={`cliente_cuentas_bancarias.[${index}].cuenta_numero`} placeholder="Numero Cuenta" />
                        
                                    <button type="button" onClick={() => arrayHelpers.remove(index)}>
                                      -
                                    </button>
                                  </div>
                                ))}
                                <button
                                  type="button"
                                  onClick={() => arrayHelpers.push()}
                                >
                                  +
                                </button>
                              </div>
                            )}
                          /> */}
                          {/* <FieldArray
                            name="cliente_cuentas_bancarias"
                            render={arrayHelpers => (
                              <div>
                                {bancoCuentas.map((banc, index) => (
                                  <div key={index}>
                                    <Field name={`cliente_cuentas_bancarias[${index}].cuenta_banco`} value={banc[0].cuenta_banco} />
                                    <Field name={`cliente_cuentas_bancarias[${index}].cuenta_banco_rfc`}  value={banc[0].cuenta_banco_rfc} />
                                    <Field name={`cliente_cuentas_bancarias[${index}].cuenta_numero`}  value={banc[0].cuenta_numero} />
                                  </div>
                                ))}
                               
                              </div>
                            )}
                          /> */}
                          {/* <FieldArray
                            name="cliente_cuentas_bancarias"
                            component={cuentasBancariasInputs}
                          /> */}

                          <div className="card">
                            <h2 className="card-header card-titulo">Cuentas Bancarias</h2>
                          
                          <div className="card-body">
                            <div className="row">
                            <div className="col-md-3">
                            <div className="form-group">
                              <label htmlFor="descripcion">Banco: </label>
                              <Autosuggest 
                                inputProps={{
                                  className: "form-control",
                                  placeholder: "buscar banco",
                                  autoComplete: "abcd",
                                  name: "cuenta_banco_nombre",
                                  id: "cuenta_banco_nombre",
                                  value: bancoNombre,
                                  onChange: (_event, { newValue}) => {
                                    setBancoNombre(newValue);
                                    if(!newValue){
                                      // actualizarFactura ({
                                      //   ...factura,
                                      //   cuenta_banco_nombre : '',
                                      
                                      // })

                                      // setFieldValue('cuenta_banco_rfc', '');
                                      // setFieldValue('cuenta_banco_nombre2', '');
                                    
                                      setBancoNombre('');
                                    setBancoRfc('');
                                      
                                      
                                    }
                                    
                                  }
                                }}
                                suggestions={suggestions}
                                onSuggestionsFetchRequested = {async ({ value }) => {
                                  if (!value) {
                                    setSuggestions([]);
                                    return;
                                  }
                                  try {
                                    const resultado = await clienteAxios.get(`/v3/bancos?busqueda=${value}`);
                                    
                                    // console.log(resultado.data.data);
                                    console.log('CONSULTA')
                                    resultado.data.data.map(row =>{ console.log(row.catalogo_banco_rfc)})
                                    setSuggestions(
                                      resultado.data.data.map(row => (
                                        {
                                          id: row.catalogo_banco_clave,
                                          nombre: row.catalogo_banco_descripcion,
                                          rfc: row.catalogo_banco_rfc,
                                          // emails: row.cliente_email,
                                          // uso_cfdi: 'G03',
                                        }
                                      ))
                                    )
                                    
                            
                                  } catch (error) { 
                                      setSuggestions([]);
                                      console.log('Hubo un error '+error);
                                  }
                                }}
                                onSuggestionsClearRequested={() =>{
                                  setSuggestions([]);
                                  // actualizarFactura ({
                                  //   ...factura,
                                  //   cuenta_banco_nombre : '',
                                  // })
                                }}
                                onSuggestionSelected={( event, suggestion )=> {
                                  // selecCliente(event, suggestion);
                                  
                                  // setFieldValue('cuenta_banco_rfc', suggestion.suggestion.rfc);
                                  // setFieldValue('cuenta_banco_nombre2', suggestion.suggestion.nombre);

                                  setBancoNombre(suggestion.suggestion.nombre);
                                  setBancoRfc(suggestion.suggestion.rfc);
                                }}
                                /*
                                onSuggestionSelected={( event, { suggestion, method } )=> {
                                  event.preventDefault();
                                  if (method === "enter"){
                                    
                                  }
                                  setRazonSocial(suggestions.nombre);
                                }}
                                */
                                getSuggestionValue={suggestion => suggestion.nombre}
                                renderSuggestion={suggestion => <div>{suggestion.nombre+' '+suggestion.rfc}</div>}
                                shouldRenderSuggestions={(value, reason)=>{
                                  //minimo de caracteres para buscar
                                  return value.trim().length > 3;
                                }}
                                

                              />
                              
                              <input
                                type="hidden"
                                id="cuenta_banco_nombre2"
                                name="cuenta_banco_nombre2"
                                ref= {cuenta_banco_nombre_ref}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                // value={values.cuenta_banco_nombre2}
                                value={bancoNombre}
                              
                              />
                              {errors.cuenta_banco_nombre2 && touched.cuenta_banco_nombre2 && (
                                <div className="input-feedback">{errors.cuenta_banco_nombre2}</div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label htmlFor="descripcion">RFC: </label>
                              {/* <input
                                type="text"
                                className="form-control"
                                id="cuenta_banco_rfc"
                                name="cuenta_banco_rfc"
                                placeholder=""
                                disabled="true"
                              /> */}
                              <input
                                type="text"
                                className={
                                  errors.cuenta_banco_rfc && touched.cuenta_banco_rfc
                                  ? "form-control error"
                                  : "form-control"
                                }
                                id="cuenta_banco_rfc"
                                placeholder="RFC"
                                name="cuenta_banco_rfc"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                // value={values.cuenta_banco_rfc}
                                value={bancoRfc}
                                ref={cuenta_banco_rfc_ref}
                                disabled="true"
                                //onChange={actualizarState}
                              />
                              
                              {errors.cuenta_banco_rfc && touched.cuenta_banco_rfc && (
                                <div className="input-feedback">{errors.cuenta_banco_rfc}</div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label htmlFor="descripcion">Numero Cuenta: </label>
                              <input
                                type="text"
                                className="form-control"
                                id="cuenta_numero"
                                name="cuenta_numero"
                                placeholder="Teclee el número de cuenta de su cliente"
                                // onChange={handleChange}
                                onChange={(e) => setBancoCuenta(e.target.value)}
                                onBlur={handleBlur}
                                // value={values.cuenta_numero}
                                value={bancoCuenta}
                                ref={cuenta_banco_numero_ref}
                              />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              {/* <label htmlFor="btnAgregar">Agregar: </label> */}
                              <button type="button" className="btn btn-success" onClick={()=>{agregarCuenta()}}>Agregar</button>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="table-responsive">
                              <table className="table">
                                <thead>
                                  {/* <tr> */}
                                    {/* <td colSpan="4">CUENTAS BANCARIAS</td> */}
                                    <tr>
                                      <td><strong>Banco</strong></td>
                                      <td><strong>RFC</strong></td>
                                      <td><strong>Numero Cuenta</strong></td>
                                      <td><strong>Eliminar</strong></td>
                                    </tr>
                                  {/* </tr> */}
                                </thead>
                                <tbody>
                                  {
                                  // (Object.keys(bancoCuentas).length === 0) ? 'No hay cuentas bancarias' : tablaCuentasBancarias 
                                  (bancoCuentas.length === 0) ? 'No hay cuentas bancarias' : tablaCuentasBancarias 
                                  }
                                </tbody>
                              </table>
                            </div>
                            {/* <div className="form-group">
                              <span>
                                *Los campos marcados con asterisco son obligatorios
                              </span>
                            </div> */}
                          </div>
                        </div>
                        </div>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="complementos" role="tabpanel" aria-labelledby="complements">
                        <div className="row mt-2">
                          
                        <FieldArray
                                  name="cliente_complementos"
                                  render={arrayHelpers => (
                                    <>
                                      {complementos.map(complemento => (
                                      
                                          <div key={complemento.complemento_clave} className="col-md-4">
                                          <div className="form-check">
                                            <label>
                                            <input
                                              name="cliente_complementos"
                                              type="checkbox"
                                              value={complemento.complemento_clave}
                                              checked={values.cliente_complementos.includes(complemento.complemento_clave)}
                                              onChange={e => {
                                                if (e.target.checked) arrayHelpers.push(complemento.complemento_clave);
                                                else {
                                                  const idx = values.cliente_complementos.indexOf(complemento.complemento_clave);
                                                  arrayHelpers.remove(idx);
                                                }
                                              }}
                                            />
                                            
                                            {` ${complemento.complemento_descripcion}`}
                                          </label>
                                          </div>
                                          </div>
                                      ))}
                                    </>
                                  )}
                                />
                          
                          {/* <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="inlineCheckbox1"
                                defaultValue="option1"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineCheckbox1"
                              >
                                Complemento IEDU
                              </label>
                            </div>
                          </div>
                        
                          <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="inlineCheckbox1"
                                defaultValue="option1"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineCheckbox1"
                              >
                                Complemento Venta de Vehículo
                              </label>
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="inlineCheckbox1"
                                defaultValue="option1"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineCheckbox1"
                              >
                                Complemento Terceros
                              </label>
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="inlineCheckbox1"
                                defaultValue="option1"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineCheckbox1"
                              >
                                Complemento Acreditamiento del IEPS
                              </label>
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="inlineCheckbox1"
                                defaultValue="option1"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineCheckbox1"
                              >
                                Complemento Aerolineas
                              </label>
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="inlineCheckbox1"
                                defaultValue="option1"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineCheckbox1"
                              >
                                Complemento Comercio Exterior
                              </label>
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="inlineCheckbox1"
                                defaultValue="option1"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineCheckbox1"
                              >
                                Complemento Detallista
                              </label>
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="inlineCheckbox1"
                                defaultValue="option1"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineCheckbox1"
                              >
                                Complemento Divisa
                              </label>
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="inlineCheckbox1"
                                defaultValue="option1"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineCheckbox1"
                              >
                                Complemento Donatarias
                              </label>
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="inlineCheckbox1"
                                defaultValue="option1"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineCheckbox1"
                              >
                                Complemento INE
                              </label>
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="inlineCheckbox1"
                                defaultValue="option1"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineCheckbox1"
                              >
                                Complemento Leyendas
                              </label>
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="inlineCheckbox1"
                                defaultValue="option1"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineCheckbox1"
                              >
                                Complemento Notarios Publicos
                              </label>
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="inlineCheckbox1"
                                defaultValue="option1"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineCheckbox1"
                              >
                                Complemento Servicios Parciales Construcción
                              </label>
                            </div>
                          </div> */}


                        </div>
                      </div>
                    </div>
                     
                      
                      
                  </div>
                <div className="card-footer">
                      <div className="float-right">
                      <button type="submit" className="btn btn-primary" disabled={isSubmitting} >
                              <i className="fas fa-save"></i>
                              {isSubmitting ? 'Guardando' : 'Guardar'}
                      </button>
                      </div>
                  </div>
                  
              </div>
              </form>
              )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    );
}

export default NuevoCliente;
