import React, { Component } from 'react';

class EditarAlumno extends Component {
    render() {
        return (
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                        <div className="card card-primary">
                        <div className="card-header">
                            <h3 className="card-title">Configuración Alumno</h3>
                            <button type="submit" className="btn btn-primary float"><i class="fas fa-save"></i>Guardar</button>
                            <button type="submit" className="btn btn-secondary"><i class="fas fa-arrow-left"></i>Atrás</button>
                        </div>
                            <div className="card-body">
                                <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="razon_social">Nombre Alumno: </label>
                                        <input type="text" className="form-control" name="nombre_alumno" id="nombre_alumno" placeholder="Nombre Alumno" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="rfc">CURP*: </label>
                                        <input type="text" className="form-control" name="curp" required id="curp" placeholder="" />
                                    </div>
                                </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="nivel_educativo">Nivel Educativo*: </label>
                                        <select class="form-control" name="nivel_educativo">
                                        <option>Seleccionar Nivel Educativo</option>
                                        <option>Preescolar</option>
                                        <option>Primaria</option>
                                        <option>Secundaria</option>
                                        <option>Profesional técnico</option>
                                        <option>Bachillerato o su equivalente</option>
                                        </select>
                                </div>
                            </div>
                            <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="rfc">Clave Centro Trabajo*: </label>
                                        <input type="text" className="form-control" name="clave_trabajo" required id="curp" placeholder="" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="rfc">R.F.C. Pago*: </label>
                                        <input type="text" className="form-control" name="rfc_pago" required id="curp" placeholder="" />
                                    </div>
                                </div>
                            
                           
                            <div className="col-md-12">
                            <div className="form-group">
                                <span>*Los campos marcados con asterisco son obligatorios </span>
                                
                            </div>
                            </div>
                
                           
                            
                            </div>
                           

                            </div>
                        <div className="card-footer">
                            <div className="float-right">
                            <button type="submit" className="btn btn-primary float"><i class="fas fa-save"></i>Guardar</button>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
        );
    }
}

export default EditarAlumno;