import React, { Component } from "react";

class EditarFactura extends Component {
  render() {
    return (
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="card-title">Configuración Datos de Empresa</h3>
                </div>
                  <div className="card-body">
  <ul className="nav nav-tabs" id="custom-content-below-tab" role="tablist">
    <li className="nav-item">
      <a className="nav-link active" id="custom-content-below-home-tab" data-toggle="pill" href="#custom-content-below-home" role="tab" aria-controls="custom-content-below-home" aria-selected="true">Configuración</a>
    </li>
    <li className="nav-item">
      <a className="nav-link" id="complemento-tab" data-toggle="pill" href="#complemento" role="tab" aria-controls="complemento" aria-selected="false">Complementos</a>
    </li>
  </ul>
  <div className="tab-content" id="custom-content-below-tabContent">
    <div className="tab-pane fade show active" id="custom-content-below-home" role="tabpanel" aria-labelledby="custom-content-below-home-tab">
    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">
                            Folio Inicial Factura
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleInputEmail1"
                            placeholder=""
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">
                            Leyenda 1 (Opcional)
                          </label>
                          <textarea
                            type="text"
                            className="form-control"
                            id="exampleInputEmail1"
                            placeholder=""
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">
                            Leyenda 2 (Opcional)
                          </label>
                          <textarea
                            type="text"
                            className="form-control"
                            id="exampleInputEmail1"
                            placeholder=""
                          />
                        </div>

                        <div className="form-group">
                          <strong>Color de fondo de su Factura</strong>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="radio1"
                            />
                            <label className="form-check-label">Estandar</label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="radio1"
                            />
                            <label className="form-check-label">Azul</label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="radio1"
                            />
                            <label className="form-check-label">Amarillo</label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="radio1"
                            />
                            <label className="form-check-label">Rojo</label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="radio1"
                            />
                            <label className="form-check-label">Verde</label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="radio1"
                            />
                            <label className="form-check-label">Gris</label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="radio1"
                            />
                            <label className="form-check-label">Naranja</label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="radio1"
                            />
                            <label className="form-check-label">Morado</label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="radio1"
                            />
                            <label className="form-check-label">Beige</label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="radio1"
                            />
                            <label className="form-check-label">Gris</label>
                          </div>
                        </div>
                        <div className="from-group">
                          <strong>Logo de su Factura</strong>
                        </div>
                        <div className="form-group">
                          <label htmlFor="exampleInputFile">
                            Seleccionar archivo
                          </label>
                          <div className="input-group">
                            <div className="custom-file">
                              <input
                                type="file"
                                className="custom-file-input"
                                id="exampleInputFile"
                              />
                              <label
                                className="custom-file-label"
                                htmlFor="exampleInputFile"
                              >
                                Choose file
                              </label>
                            </div>
                            <div className="input-group-append">
                              <span className="input-group-text" id>
                                Subir
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">
                            Número de Certificado
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleInputEmail1"
                            placeholder=""
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">
                            Serie de Facturación
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleInputEmail1"
                            placeholder=""
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">
                            Fecha de Vigencia Sello (Inicio)
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleInputEmail1"
                            placeholder=""
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">
                            Fecha de Vigencia Sello (Final)
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleInputEmail1"
                            placeholder=""
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1">
                            Número de Aprobación
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleInputEmail1"
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div className="card-footer">
                        <div className="float-right">
                          <button type="submit" className="btn btn-primary float">
                            <i class="fas fa-save"></i>Guardar
                          </button>
                        </div>
                      </div>
                    </div>
                    
    </div>
    <div className="tab-pane fade" id="complemento" role="tabpanel" aria-labelledby="complemento-tab">
                        <div className="row">
                              <h4>Complemento Donatarias</h4>
                              <hr></hr>
                                  <div className="col-md-4 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                      <label>No. de Autorización*:</label>
                                      <input
                                        type="email"
                                        className="form-control"
                                        id="inputEmail4"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-4 col-sm-6">
                                    <div className="form-group">
                                      <label htmlFor="unidad">
                                        Fecha de Autorización*:
                                      </label>
                                      <div className="input-group">
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="datepicker"
                                          placeholder="Seleccione fecha"
                                        ></input>
                                        <div className="input-group-append" data-toggle="tooltip" data-placement="top" title="Seleccione fecha">
                                          <button
                                            className="input-group-text"
                                            id="datepicker"
                                          >
                                            <i
                                              className="fas fa-calendar-alt"
                                              data-toggle="datepicker"
                                            ></i>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-4 col-sm-12">
                                    <div className="form-group">
                                      <label>Leyenda*:</label>
                                      <textarea className="form-control"></textarea>
                                    </div>
                                  </div>
                                  <div className="card-footer">
                                    <div className="float-right">
                                      <button type="submit" className="btn btn-primary float">
                                        <i class="fas fa-save"></i>Guardar
                                      </button>
                                    </div>
                                  </div>
                                </div> 
                              </div>
                            </div>

                    
                  </div>
                
              </div>
            </div>
          </div>
        </div>
    );
  }
}

export default EditarFactura;
