import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class DatosEmpresa extends Component {
    render() {
        return (
            <div>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">Consulta de Datos Empresa</h3>
                      </div>
                      <div className="col-md-12">
                                <div id="example1_filter" className="dataTables_filter">
                                    <label>
                                        <input type="search" className="form-control form-control-sm" placeholder="Buscar" aria-controls="example1" />
                                    </label>
                                </div>
                            </div>
                      <div className="card-body">
                        <p className="text-center">Resumen por Empresa</p>
                        <table id="example1" className="table table-bordered table-striped">
                          <thead>
                            <tr>
                              <th>Id</th>
                              <th>Clave</th>
                              <th>Razón Social</th>
                              <th>RFC</th>
                              <th>Regimen</th>
                              <th></th>
                              <th></th>
                              <th></th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>1</td>
                              <td>S0001</td>
                              <td>EMPRESA DE TRANSPORTES SA DE CV</td>
                              <td>EKU9003173C9</td>
                              <td>REGIMEN ACTIVIDAD EMPRESARIAL Y PROFESIONAL PERSONA FISICA</td>
                              <td><Link to="editar-datos-empresa"><img src="dist/img/consultar.gif" width="20"/></Link></td>
                                <td><Link to="subir-certificado"><img src="dist/img/cargar_ok.gif" width="20"/></Link></td>
                                <td><Link to="paquetes-adquiridos"><img src="dist/img/sellos.gif" width="20"/></Link></td>
                                <td><Link to="editar-factura"><img src="dist/img/configurar.gif" width="20"/></Link></td>
                            </tr>
                            
                          
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

        );
    }
}

export default DatosEmpresa;