import React, { Component } from 'react';

class ComplementoServiciosConstruccion extends Component {
    render() {
        return (
            <div>
                <div className="container-fluid">
                              <h4>
                                Complemento Servicios Parciales de Construcción
                              </h4>
                              <hr></hr>
                                <div className="row">
                                  <div className="col-md-3">
                                    <div className="form-group">
                                      <label>Número de Permiso*:</label>
                                      <input
                                        type="email"
                                        className="form-control"
                                        id="inputEmail4"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-4 col-sm-6">
                                    <div className="form-group">
                                      <label>Calle:</label>
                                      <input
                                        type="email"
                                        className="form-control"
                                        id="inputEmail4"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-1 col-sm-3 col-xs-6">
                                    <div className="form-group">
                                      <label>No. Ext:</label>
                                      <input
                                        type="email"
                                        className="form-control"
                                        id="inputEmail4"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-1 col-sm-3 col-xs-6">
                                    <div className="form-group">
                                      <label>No. Int:</label>
                                      <input
                                        type="email"
                                        className="form-control"
                                        id="inputEmail4"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-3 col-sm-6">
                                    <div className="form-group">
                                      <label>Colonia:</label>
                                      <input
                                        type="email"
                                        className="form-control"
                                        id="inputEmail4"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-3 col-sm-6">
                                    <div className="form-group">
                                      <label>Localidad:</label>
                                      <input
                                        type="email"
                                        className="form-control"
                                        id="inputEmail4"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-3 col-sm-6">
                                    <div className="form-group">
                                      <label>Referencia:</label>
                                      <input
                                        type="email"
                                        className="form-control"
                                        id="inputEmail4"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-3 col-sm-6">
                                    <div className="form-group">
                                      <label>Municipio:</label>
                                      <input
                                        type="email"
                                        className="form-control"
                                        id="inputEmail4"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-2 col-sm-6">
                                    <label>Estado:</label>
                                    <div className="input-group mb-3">
                                      <select
                                        className="custom-select"
                                        id="inputGroupSelect01"
                                      >
                                        <option selected>SELECCIONE</option>
                                        <option value="1">AGU</option>
                                        <option value="2">BCN</option>
                                        <option value="3">BCS</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-md-1 col-sm-6">
                                    <div className="form-group">
                                      <label>C.P. *</label>
                                      <input
                                        type="email"
                                        className="form-control"
                                        id="inputEmail4"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                          </div>
        );
    }
}

export default ComplementoServiciosConstruccion;