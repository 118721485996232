import React, { Component } from 'react';
import NuevoCliente from "./NuevoCliente";
import Clientes from "./clientes/Clientes";
import ComplementoPago from "./ComplementoPago";

class NuevoCfdiDePago extends Component {
    render() {
        return (
            <div className="container-fluid">
            <div ClassName="row">
              <div className="col-md-12">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">Registro de Cfdi de Pago</h3>
                    <div className="float-right">
                      <button type="submit" className="btn btn-primary">
                        <i class="fas fa-save"></i>Timbrar
                      </button>
                      <button type="submit" className="btn btn-secondary">
                        <i className="fas fa-arrow-left"></i>Atrás
                      </button>
                    </div>
                  </div>
                  <form role="form">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-8">
                          <div className="row">
                            <div className="col-md-12">
                            <label htmlFor="">Cliente / Razón Social / RFC: <i class="fa fa-question-circle" aria-hidden="true" data-toggle="popover" title="Popover title" data-content="And here's some amazing content. It's very engaging. Right?"></i></label>
                            <div className="input-group">
                            <input type="text" className="form-control" />
                            <div className="input-group-append" data-toggle="tooltip"  >
                              <span
                                className="input-group-text"
                                data-toggle="modal"
                                data-target="#nuevocliente"
                                data-placement="right"
                                title="Agregar nuevo cliente"
                              >
                                <i className="fas fa-plus"></i>
                              </span>
                              <span 
                                className="input-group-text"
                                data-toggle="modal"
                                data-target="#buscarcliente"
                                data-placement="top"
                                title="Agregar nuevo cliente"
                                >
                                <i className="fas fa-search"></i>
                              </span>
                            </div>
                          </div>
                          <div className="modal fade bd-example-modal-lg" id="nuevocliente" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                          <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                            <div className="modal-header">
                              {/* <h5 className="modal-title">Nuevo Cliente / Razón Social / RFC</h5> */}
                              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div className="modal-body">
                              <NuevoCliente />
                          </div>
                            <div className="modal-footer">
                              <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                            </div>
                            </div>
                          </div>
                        </div>
                        <div className="modal fade bd-example-modal-lg" id="buscarcliente" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                          <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                            <div className="modal-header">
                              {/* <h5 className="modal-title">Nuevo Cliente / Razón Social / RFC</h5> */}
                              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div className="modal-body">
                              <Clientes />
                          </div>
                            <div className="modal-footer">
                              <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                            </div>
                            </div>
                          </div>
                        </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-4 col-sm-4">
                              <div className="form-group">
                                <label htmlFor="clave">Email: </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="email"
                                  placeholder="Email"
                                />
                              </div>
                            </div>
                            <div className="col-md-4 col-sm-3">
                              <div className="form-group">
                                <label htmlFor="clave">RFC: </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="rfc"
                                  placeholder="RFC"
                                />
                              </div>
                            </div>
                            <div className="col-md-4 col-sm-5">
                              <div className="form-group">
                                <label htmlFor="unidad">
                                  Tipo de relación con CFDI's:
                                </label>
                                <select className="form-control">
                                  <option>SELECCIONE USO DE CFDI</option>
                                  <option>G01 Adquisición de mercancias</option>
                                  <option>
                                    G02 Devoluciones, descuentos o bonificaciones
                                  </option>
                                  <option>G03 Gastos en general</option>
                                  <option>I01 Construcciones</option>
                                  <option>
                                    I02 Mobiliario y equipo de oficina por
                                    inversiones
                                  </option>
                                  <option>I03 Equipo de transporte</option>
                                  <option>
                                    I04 Equipo de computo y accesorios
                                  </option>
                                  <option>
                                    I05 Dados, troqueles, moldes, matrices y
                                    herramental
                                  </option>
                                  <option>I06 Comunicaciones telefónicas</option>
                                  <option>I07 Comunicaciones satelitales</option>
                                  <option>I08 Otra maquinaria y equipo</option>
                                  <option>P01 Por definir</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-3">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group">
                                <label htmlFor="unidad">Seleccione serie: </label>
                                <select className="form-control">
                                  <option>SELECCIONE SERIE</option>
                                  <option>Factura A559</option>
                                  <option>Factura TCC22</option>
                                  <option>Factura E188</option>
                                  <option>Factura 2222loloo3</option>
                                  <option>
                                    I02 Mobiliario y equipo de oficina por
                                    inversiones
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <label htmlFor="unidad">Seleccione fecha: </label>
                                <div className="input-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="datepicker"
                                    placeholder="Seleccione fecha"
                                  ></input>
                                  <div className="input-group-append" data-toggle="tooltip" data-placement="top" title="Seleccione fecha">
                                    <button
                                      className="input-group-text"
                                      id="datepicker"
                                    >
                                      <i
                                        className="fas fa-calendar-alt"
                                        data-toggle="datepicker"
                                      ></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2 col-sm-6">
                          <label>Desde</label>
                            <div className="input-group mb-3">
                              <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon1">Desde</span>
                              </div>
                                <select id="inputState" className="form-control">
                                  <option selected>Presente Año</option>
                                  <option>8 Meses</option>
                                  <option>6 Meses</option>
                                  <option>3 Meses</option>
                                  <option>2 Meses</option>
                                  <option>30 Días</option>
                                </select>
                            </div>
                          </div>
                        <div className="col-md-6">
                          <label htmlFor="">CFDI: </label>
                          <div className="input-group">
                            <input type="text" className="form-control" placeholder="Buscar CFDI por Folio, UUID, Total" />
                          </div>
                          </div>
                          <ComplementoPago />
    
                        <div className="col-md-12">
                          <div className="table-responsive my-5">
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">Fecha Pago</th>
                                  <th scope="col">Forma Pago</th>
                                  <th scope="col">Moneda</th>
                                  <th scope="col">Tipo Cambio</th>
                                  <th scope="col">Importe</th>
                                  <th scope="col">Editar</th>
                                  <th scope="col">Eliminar</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td colspan="7"></td>
                                </tr>
                                <tr>
                                  <td colspan="7"></td>
                                </tr>
                                <tr>
                                  <td>
                                    <div className="observaciones">
                                      <a
                                        data-toggle="modal"
                                        data-target="#observaciones"
                                      >
                                        Observaciones
                                      </a>
                                      <div
                                        className="modal fade"
                                        id="observaciones"
                                        tabIndex={-1}
                                        role="dialog"
                                        aria-labelledby="exampleModalLabel"
                                        aria-hidden="true"
                                      >
                                        <div
                                          className="modal-dialog"
                                          role="document"
                                        >
                                          <div className="modal-content">
                                            <div className="modal-header">
                                              <h5
                                                className="modal-title"
                                                id="exampleModalLabel"
                                              >
                                                Observaciones
                                              </h5>
                                              <button
                                                type="button"
                                                className="close"
                                                data-dismiss="modal"
                                                aria-label="Close"
                                              >
                                                <span aria-hidden="true">×</span>
                                              </button>
                                            </div>
                                            <div className="modal-body">
                                              <textarea
                                                className="form-control"
                                                placeholder="Observaciones"
                                              ></textarea>
                                            </div>
                                            <div className="modal-footer">
                                              <button
                                                type="button"
                                                className="btn btn-danger"
                                                data-dismiss="modal"
                                              >
                                                <i className="fas fa-times"></i>
                                                Eliminar
                                              </button>
                                              <button
                                                type="button"
                                                className="btn btn-primary"
                                              >
                                                <i className="fas fa-save"></i>
                                                Guardar
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="descglobal">
                                      <a
                                        data-toggle="modal"
                                        data-target="#descuentoglobal"
                                      >
                                        Descuento Global
                                      </a>
                                      <div
                                        className="modal fade"
                                        id="descuentoglobal"
                                        tabIndex={-1}
                                        role="dialog"
                                        aria-labelledby="exampleModalLabel"
                                        aria-hidden="true"
                                      >
                                        <div
                                          className="modal-dialog"
                                          role="document"
                                        >
                                          <div className="modal-content">
                                            <div className="modal-header">
                                              <h5
                                                className="modal-title"
                                                id="exampleModalLabel"
                                              >
                                                Introduzca Tasa %
                                              </h5>
                                              <button
                                                type="button"
                                                className="close"
                                                data-dismiss="modal"
                                                aria-label="Close"
                                              >
                                                <span aria-hidden="true">×</span>
                                              </button>
                                            </div>
                                            <div className="modal-body">
                                              <input
                                                type="text"
                                                className="form-control"
                                                placeholder="0"
                                              ></input>
                                            </div>
                                            <div className="modal-footer">
                                              <button
                                                type="button"
                                                className="btn btn-danger"
                                                data-dismiss="modal"
                                              >
                                                <i className="fas fa-times"></i>
                                                Eliminar
                                              </button>
                                              <button
                                                type="button"
                                                className="btn btn-primary"
                                              >
                                                <i className="fas fa-save"></i>
                                                Guardar
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  <td colspan="5">
                                    <div>
                                      <div className="row">
                                        <div className="col-md-3">
                                          <a
                                            data-toggle="modal"
                                            data-target="#facturaglobal"
                                          >
                                            Factura Global
                                          </a>
                                          <div
                                            className="modal fade"
                                            id="facturaglobal"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-labelledby="exampleModalLabel"
                                            aria-hidden="true"
                                          >
                                            <div
                                              className="modal-dialog"
                                              role="document"
                                            >
                                              <div className="modal-content">
                                                <div className="modal-header">
                                                  <h5
                                                    className="modal-title"
                                                    id="exampleModalLabel"
                                                  >
                                                    Asistente para elaborar factura
                                                    global
                                                  </h5>
                                                  <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                  >
                                                    <span aria-hidden="true">
                                                      ×
                                                    </span>
                                                  </button>
                                                </div>
                                                <div className="modal-body">
                                                  <label>
                                                    No. Folio Operación:
                                                  </label>
                                                  <div className="input-group mb-3">
                                                    <div className="input-group-prepend">
                                                      <span className="input-group-text">
                                                        #
                                                      </span>
                                                    </div>
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      aria-label="Amount"
                                                      placeholder="No. Nota, Ticket, Recibo"
                                                    />
                                                  </div>
    
                                                  <label>Total de la Venta:</label>
                                                  <div className="input-group mb-3">
                                                    <div className="input-group-prepend">
                                                      <span className="input-group-text">
                                                        $
                                                      </span>
                                                    </div>
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      aria-label="Amount"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="modal-footer">
                                                  <button
                                                    type="button"
                                                    className="btn btn-danger"
                                                    data-dismiss="modal"
                                                  >
                                                    <i className="fas fa-times"></i>
                                                    Eliminar
                                                  </button>
                                                  <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                  >
                                                    <i className="fas fa-save"></i>
                                                    Guardar
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
    
                        <div className="col-md-6 col-sm-6">

                        </div>
    
                        <div className="col-md-6 col-sm-6">
                          <div className="col-md-10 float-md-right">
                            <div className="row">
                              <label>Subtotal: </label>
                              <div className="input-group mb-2">
                                <div className="input-group-prepend">
                                  <div className="input-group-text">$</div>
                                </div>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="inlineFormInputGroup"
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-10 float-md-right">
                            <div className="row">
                              <label>IVA: </label>
                              <div className="input-group mb-2">
                                <div className="input-group-prepend">
                                  <div className="input-group-text">$</div>
                                </div>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="inlineFormInputGroup"
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-10 float-md-right">
                            <div className="row">
                              <label>Total: </label>
                              <div className="input-group mb-2">
                                <div className="input-group-prepend">
                                  <div className="input-group-text">$</div>
                                </div>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="inlineFormInputGroup"
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      
    

                    </div>
                    </div>
                  </form>
                  <div className="card-footer">
                        <div className="float-right">
                        <button type="submit" className="btn btn-success mr-1">
                            <i class="fas fa-save"></i>Guardar sin Timbrar
                            </button>
                            <button type="submit" className="btn btn-primary">
                            <i class="fas fa-save"></i>Timbrar
                            </button>
                        </div>
                      </div>
                </div>
              </div>
            </div>
          </div>
        );
    }
}

export default NuevoCfdiDePago;