import React from 'react';
import ModalSerie from './ModalSerie'
import EditModalSerie from './EditModalSerie'

function Series() {
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                            <h3 className="card-header">Series de comprobantes
                            <div className="float-right">
                                <button type="button" className="btn btn-primary" data-toggle="modal" data-target=".addserie"><i className="fas fa-plus"></i>Agregar</button>
                            </div>
                            </h3>
                            <div className="modal fade bd-example-modal-lg addserie" tabIndex={-1} role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-lg" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h4>Nueva Serie de Factura</h4>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <ModalSerie />
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-primary"><i className="fas fa-save"></i>Guardar</button>
                                        <button type="button" className="btn btn-danger"><i className="fas fa-times"></i>Cancelar</button>
                                    </div>
                                </div>
                            </div>
                            </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="col-md-6">
                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" id="basic-addon1">CO</span>
                                            </div>
                                            <input type="number" className="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" />
                                        </div>
                                    </div>

                                    <div className="table-responsive">
                                        <table id="example1" className="table table-bordered table-striped">
                                        <thead>
                                            <tr>
                                            <th>Tipo</th>
                                            <th>Nombre</th>
                                            <th>Serie</th>
                                            <th>Folio Inicial</th>
                                            <th>Folio Final</th>
                                            <th>Predeteminado</th>
                                            <th>Status</th>
                                            <th></th>
                                            <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                            <td>Factura</td>
                                            <td>Sucursal</td>
                                            <td>SA</td>
                                            <td>1</td>
                                            <td>100</td>
                                            <td>Si</td>
                                            <td>Activo</td>
                                            <td><button type="button" className="btn btn-primary" data-toggle="modal" data-target=".editserie"><i className="fas fa-edit"></i>Editar</button></td>
                                            <div className="modal fade bd-example-modal-lg editserie" tabIndex={-1} role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                                                <div className="modal-dialog modal-lg" role="document">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h4>Nueva Serie de Factura</h4>
                                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                                <span aria-hidden="true">&times;</span>
                                                            </button>
                                                        </div>
                                                        <div className="modal-body">
                                                            <EditModalSerie />
                                                        </div>
                                                        <div className="modal-footer">
                                                            <button type="button" className="btn btn-primary"><i className="fas fa-save"></i>Guardar</button>
                                                            <button type="button" className="btn btn-danger"><i className="fas fa-times"></i>Cancelar</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>
                                            <td><button type="button" className="btn btn-danger"><i className="fas fa-trash"></i>Eliminar</button></td>
                                            </tr>
                                        </tbody>
                                        </table>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Series;