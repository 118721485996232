import React from 'react';

function ImportarProductos() {
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <h3 className="card-header">Importar Productos
                        <button type="button" className="btn btn-primary">Probar importación</button></h3>
                            <div className="card-body">
                            <div className="alert alert-danger" role="alert">
                            No se han encontrado registros coincidentes para nombre 'EJEMPLO' en el campo 'Compañia' en la fila 2
                            </div>
                            <div className="card card-titulo">
                                    <p><strong>Instrucciones:</strong><br></br>
                                        1. Seleccione y suba archivo Excel (*.xls,*.xlsx,*.csv)<br></br>
                                        2. Asocie la columna del catálogo de clientes con la columna del documento a importar<br></br>
                                        3. Hacer click en "Importar Excel"</p>
                                </div>
                                <div className="form-group">
                                <span className="badge badge-primary mr-1">1</span>
                                    <label>Seleccione Archivo a Importar:</label>
                                    <input type="file" className="form-control-file" id="exampleFormControlFile1" />
                                </div>
                                <span className="badge badge-primary mr-1">2</span>
                                <label>Asociar columnas:</label>
                                <div className="table-responsive">
                                    <table className="table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Clave / No. Identificación</th>
                                                <th>Clave ProdServ</th>
                                                <th>Descripción (Requerido)</th>
                                                <th>Precio $ (Requerido)</th>
                                                <th>Unidad</th>
                                                <th>Unidad Clave</th>
                                                <th>IVA</th>
                                                <th>IEPS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <select className="form-control select2" style={{width: '100%'}}>
                                                        <option selected="selected"></option>
                                                        <option>Clave / No. Identificación</option>
                                                        <option>Clave ProdServ</option>
                                                        <option>Descripción (Requerido)</option>
                                                        <option>Precio $ (Requerido)</option>
                                                        <option>Unidad</option>
                                                        <option>Unidad Clave</option>
                                                        <option>IVA</option>
                                                        <option>IEPS</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <select className="form-control select2" style={{width: '100%'}}>
                                                        <option selected="selected"></option>
                                                        <option>Clave / No. Identificación</option>
                                                        <option>Clave ProdServ</option>
                                                        <option>Descripción (Requerido)</option>
                                                        <option>Precio $ (Requerido)</option>
                                                        <option>Unidad</option>
                                                        <option>Unidad Clave</option>
                                                        <option>IVA</option>
                                                        <option>IEPS</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <select className="form-control select2" style={{width: '100%'}}>
                                                        <option selected="selected"></option>
                                                        <option>Clave / No. Identificación</option>
                                                        <option>Clave ProdServ</option>
                                                        <option>Descripción (Requerido)</option>
                                                        <option>Precio $ (Requerido)</option>
                                                        <option>Unidad</option>
                                                        <option>Unidad Clave</option>
                                                        <option>IVA</option>
                                                        <option>IEPS</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <select className="form-control select2" style={{width: '100%'}}>
                                                        <option selected="selected"></option>
                                                        <option>Clave / No. Identificación</option>
                                                        <option>Clave ProdServ</option>
                                                        <option>Descripción (Requerido)</option>
                                                        <option>Precio $ (Requerido)</option>
                                                        <option>Unidad</option>
                                                        <option>Unidad Clave</option>
                                                        <option>IVA</option>
                                                        <option>IEPS</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <select className="form-control select2" style={{width: '100%'}}>
                                                        <option selected="selected"></option>
                                                        <option>Clave / No. Identificación</option>
                                                        <option>Clave ProdServ</option>
                                                        <option>Descripción (Requerido)</option>
                                                        <option>Precio $ (Requerido)</option>
                                                        <option>Unidad</option>
                                                        <option>Unidad Clave</option>
                                                        <option>IVA</option>
                                                        <option>IEPS</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <select className="form-control select2" style={{width: '100%'}}>
                                                        <option selected="selected"></option>
                                                        <option>Clave / No. Identificación</option>
                                                        <option>Clave ProdServ</option>
                                                        <option>Descripción (Requerido)</option>
                                                        <option>Precio $ (Requerido)</option>
                                                        <option>Unidad</option>
                                                        <option>Unidad Clave</option>
                                                        <option>IVA</option>
                                                        <option>IEPS</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <select className="form-control select2" style={{width: '100%'}}>
                                                        <option selected="selected"></option>
                                                        <option>Clave / No. Identificación</option>
                                                        <option>Clave ProdServ</option>
                                                        <option>Descripción (Requerido)</option>
                                                        <option>Precio $ (Requerido)</option>
                                                        <option>Unidad</option>
                                                        <option>Unidad Clave</option>
                                                        <option>IVA</option>
                                                        <option>IEPS</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <select className="form-control select2" style={{width: '100%'}}>
                                                        <option selected="selected"></option>
                                                        <option>Clave / No. Identificación</option>
                                                        <option>Clave ProdServ</option>
                                                        <option>Descripción (Requerido)</option>
                                                        <option>Precio $ (Requerido)</option>
                                                        <option>Unidad</option>
                                                        <option>Unidad Clave</option>
                                                        <option>IVA</option>
                                                        <option>IEPS</option>
                                                    </select>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>XEXX010101000</td>
                                                <td>EMPRESA DE TRANSPORTES SA DE CV</td>
                                                <td>EMPRESA DE TRANSPORTES SA DE CV</td>
                                                <td>9865757</td>
                                                <td>EJEMPLO@EJEMPLO.COM</td>
                                                <td>AV. VENUSTIANO CARRANZA</td>
                                                <td>342</td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="card-footer">
                                <button type="button" className="btn btn-primary"><i className="fas fa-share"></i>Importar</button>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ImportarProductos;