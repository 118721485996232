import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import clienteAxios from "../../config/axios";


const Cliente = ({cliente}) => {
  console.log(cliente);
  return (
    <tr>
        <td>{cliente?.cliente_nombre}</td>
        <td>{cliente?.cliente_rfc}</td>
        <td>{cliente?.cliente_delegacion}</td>
        <td><Link to={`/editar-cliente/${cliente?.identificador}`}><img src="/dist/img/consultar.gif" width="20"/></Link></td>
        <td><img src="/dist/img/anular.gif" width="20"/></td>
    </tr>
  );
};

export default Cliente;
