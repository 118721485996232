import React, { Component } from "react";
import NuevoCliente from "./NuevoCliente";
import Clientes from "./clientes/Clientes";

class ComplementoPago extends Component {
  render() {
    return (
      <div className="container-fluid">
        <div className="col-md-12">
          <div class="card">
            <h4 className="card-header card-titulo">Complemento Pago</h4>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6 col-sm-6">
                  <div className="form-group date">
                    <label htmlFor="unidad">Fecha de pago: </label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        id="datepicker2"
                        placeholder="Seleccione fecha"
                      ></input>
                      <div
                        className="input-group-append datepicker"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Seleccione fecha"
                      >
                        <a
                          type="button"
                          className="btn input-group-text"
                          id="datepicker"
                        >
                          <i className="fas fa-calendar-alt"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6">
                  <div className="form-group">
                    <label htmlFor="unidad">Forma de Pago*:</label>
                    <select className="form-control">
                      <option></option>
                      <option>01 Efectivo</option>
                      <option>
                        02 Cheque nominativo 03 Transferencia electrónica de
                        fondos 04 Tarjeta de crédito 05 Monedero electrónico
                      </option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6">
                  <div className="form-group">
                    <label htmlFor="clave">Monto: </label>
                    <input type="text" className="form-control" id="email" />
                  </div>
                </div>
                <div className="col-md-6 col-sm-6">
                  <div className="form-group">
                    <label htmlFor="unidad">Moneda*:</label>
                    <select className="form-control">
                      <option></option>
                      <option>01 Efectivo</option>
                      <option>02 Cheque nominativo</option>
                      <option>03 Transferencia electrónica de fondos</option>
                      <option>04 Tarjeta de crédito</option>
                      <option>05 Monedero electrónico</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6">
                  <div className="form-group">
                    <label htmlFor="clave">Tipo de cambio: </label>
                    <input type="text" className="form-control" id="email" />
                  </div>
                </div>
                <div className="col-md-6 col-sm-6">
                  <div className="form-group">
                    <label htmlFor="clave">
                      
                      Numero de operación (opcional):
                    </label>
                    <input type="text" className="form-control" id="email" />
                  </div>
                </div>
                <div className="col-md-6 col-sm-6">
                  <div className="form-group">
                    <label htmlFor="clave">
                      RFC cuenta ordenante (opcional):
                    </label>
                    <input type="text" className="form-control" id="email" />
                  </div>
                </div>
                <div className="col-md-6 col-sm-6">
                  <div className="form-group">
                    <label htmlFor="clave">Cuenta ordenante (opcional): </label>
                    <input type="text" className="form-control" id="email" />
                  </div>
                </div>
                <div className="col-md-6 col-sm-6">
                  <div className="form-group">
                    <label htmlFor="clave">Nombre de banco (opcional): </label>
                    <input type="text" className="form-control" id="email" />
                  </div>
                </div>
                <div className="col-md-6 col-sm-6">
                  <div className="form-group">
                    <label htmlFor="clave">
                      RFC cuenta beneficiario (opcional):
                    </label>
                    <input type="text" className="form-control" id="email" />
                  </div>
                </div>
                <div className="col-md-6 col-sm-6">
                  <div className="form-group">
                    <label htmlFor="clave">
                      Cuenta beneficiario (opcional):
                    </label>
                    <input type="text" className="form-control" id="email" />
                  </div>
                </div>
              </div>
              <div className="card">
                <h4 className="card-header card-titulo">
                  Documentos relacionados con el pago
                  <div className="form-check form-check-inline ml-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="inlineCheckbox1"
                      defaultValue="option1"
                      data-toggle="collapse"
                      data-target="#relacionados"
                    />
                  </div>
                </h4>
                <div className="card-body" id="relacionados">
                  <div className="row">
                    <div className="col-md-6 col-sm-6">
                      <div className="form-group">
                        <label htmlFor="clave">Factura Original*: </label>
                        <input
                          type="text"
                          className="form-control"
                          id="email"
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6">
                      <div className="form-group">
                        <label htmlFor="clave">Monto Original: </label>
                        <input
                          type="text"
                          className="form-control"
                          id="email"
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6">
                      <div className="form-group">
                        <label htmlFor="clave">No. Parcialidad: </label>
                        <input
                          type="text"
                          className="form-control"
                          id="email"
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6">
                      <div className="form-group">
                        <label htmlFor="clave">Serie (Opcional): </label>
                        <input
                          type="text"
                          className="form-control"
                          id="email"
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6">
                      <div className="form-group">
                        <label htmlFor="clave">Folio (opcional): </label>
                        <input
                          type="text"
                          className="form-control"
                          id="email"
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6">
                      <div className="form-group">
                        <label htmlFor="unidad">Moneda*:</label>
                        <select className="form-control">
                          <option></option>
                          <option>01 Efectivo</option>
                          <option>02 Cheque nominativo</option>
                          <option>
                            03 Transferencia electrónica de fondos
                          </option>
                          <option>04 Tarjeta de crédito</option>
                          <option>05 Monedero electrónico</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6">
                      <div className="form-group">
                        <label htmlFor="clave">Tipo de cambio: </label>
                        <input
                          type="text"
                          className="form-control"
                          id="email"
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6">
                      <div className="form-group">
                        <label htmlFor="unidad">Metodo de pago*:</label>
                        <select className="form-control">
                          <option>PPD Pago en parcialidades o diferido</option>
                          <option>01 Efectivo</option>
                          <option>02 Cheque nominativo</option>
                          <option>
                            03 Transferencia electrónica de fondos
                          </option>
                          <option>04 Tarjeta de crédito</option>
                          <option>05 Monedero electrónico</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6">
                      <div className="form-group">
                        <label htmlFor="clave">
                          Importe de saldo anterior:
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="email"
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6">
                      <div className="form-group">
                        <label htmlFor="clave">Importe pagado: </label>
                        <input
                          type="text"
                          className="form-control"
                          id="email"
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6">
                      <div className="form-group">
                        <label htmlFor="clave">Saldo insoluto: </label>
                        <input
                          type="text"
                          className="form-control"
                          id="email"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="float-right">
                      <button type="submit" className="btn btn-primary">
                        <i class="fas fa-plus"></i>Agregar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer">
                <div className="float-right">
                  <button type="submit" className="btn btn-primary">
                    <i class="fas fa-plus"></i>Agregar
                  </button>
                </div>
              </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ComplementoPago;
