import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import es from 'date-fns/locale/es';
import subHours from "date-fns/subHours";
import { Formik, Form } from "formik";
import * as Yup from 'yup';
import clienteAxios from '../config/axios';
import moment from 'moment';
//ejemplo formulario con archivo
//https://hackernoon.com/formik-handling-files-and-recaptcha-209cbeae10bc

function SorianaRemision() {
    const [startDate, setStartDate] = useState(new Date());
    registerLocale('es', es)

    const profileShema = Yup.object().shape({
        proveedor: Yup.number()
          .typeError('Proveedor debe ser numerico')
          .required('Debe llenar el numero de proveedor'),
        tienda_entrega: Yup.number()
          .typeError('Tienda entrega debe ser numerico')
          .required('Tienda requerido'),
        tipo_bulto: Yup.number()
        .typeError('Tipo bulto debe ser numerico')
        .required('Debe seleccionar un tipo de empaque'),
        
        tienda: Yup.number()
        .typeError('tienda debe ser numerico')
          .required('Tienda requerido'),
        cantidad_bulto: Yup.number()
        .typeError('Cantidad bulto debe ser numerico')
          .required('Cantidad de bulto requerido'),
        codigo: Yup.number()
        .typeError('Codigo debe ser numerico'),
        folio_pedido: Yup.number()
        .typeError('Folio del pedido debe ser numerico'),
        fecha_entrega: Yup.string()
        .typeError('Debe seleccionar una fecha del calendario')
           .required('Fecha de entrega de mercancia es requerido'),
        
        
    })

    return (
        <div className="container-fluid">
            <Formik
            initialValues={{ 
                proveedor: '', 
                tienda_entrega: '',
                tipo_bulto: '',
                tienda: '',
                cantidad_bulto: '',
                fecha_remision: '',
                fecha_entrega: '',
                codigo: '',
                folio_pedido: '',
                cita: '',
                nota_entrada: '',
                pedido_proveedor: '',
                file: null,
                
            }}
            validationSchema={profileShema}
            onSubmit={ async (values, { setSubmitting })=>{
                // setTimeout(()=>{
                // alert(JSON.stringify(values, null, 2));
                // setSubmitting(false);
                // },1000);
                let formData = new FormData();
                // let fileData = new FileData();
                let fecha_ent = moment(values.fecha_entrega).format('DD/MM/YYYY');
                // let fecha_remi = moment(values.fecha_remision).format('DD/MM/YYYY');
                let fecha_remi = moment().format('DD/MM/YYYY');

                // console.log(fecha_ent);
                let infoJson = {
                    "addenda_tipo":"soriana_reverse",
                    "addenda_informacion":{
                       "tienda_entrega":values.tienda_entrega,
                       "proveedor":values.proveedor,
                       "codigo":values.codigo,
                       "tipo_bulto":values.tipo_bulto,
                       "tienda":values.tienda,
                       "consecutivo":"0",
                       "cantidad_bulto":values.cantidad_bulto,
                       "tipo_entrega":"normal",
                       "cantidad_pedidos":"1",
                    //    "fecha_remision":"07/05/2020",
                    //    "fecha_entrega":"09/05/2020",
                       "fecha_remision":fecha_remi,
                       "fecha_entrega":fecha_ent,
                       "folio_pedido":values.folio_pedido,
                       "entrega_mercancia":"1",
                       "cita": values.cita,
                       "nota_entrada": values.nota_entrada,
                       "pedido_emitido_proveedor": values.pedido_proveedor,
                       "remision":"",
                    }
                 }

                formData.append("xml", values.file);
                // formData.append("informacion_json", infoJson);

                formData.append("informacion_json", JSON.stringify(infoJson, null, 2));

                try {
                    const resultado = await clienteAxios({
                        method: 'post',                        
                        //regresa xml en base64 sin enviar
                        // url: "https://app.facturandoenlinea.mx/v3/addendas-xml-externo?antes_enviar=1",
                        url: "https://app.facturandoenlinea.mx/v3/addendas-xml-externo",
                        data: formData,
                        headers: {'Content-Type': 'multipart/form-data' }
                    })
                    console.log(resultado.data);
                    alert(resultado.data.data.mensaje + ' unique_creator_identification: ' +resultado.data.data.unique_creator_identification);
                   
                } catch (error) { 
                    console.log('Hubo un error '+error);
                    // alert('Hubo un error '+error);
                    alert(error.response.data.error);
                }

                // alert(JSON.stringify(values, null, 2));
                console.log(formData.get("xml"));
                console.log(formData.get("informacion_json"));
                setSubmitting(false);
            
            }}

            >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                handleBlur,
                isSubmitting,
                setFieldValue
            })=> (

            <Form onSubmit={handleSubmit}>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <h5 className="card-header card-titulo">Remisión</h5>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <label>Número de Proveedor</label>
                                    <div className="form-group">
                                    <input 
                                        type="text"
                                        className={
                                            errors.proveedor && touched.proveedor
                                            ? "form-control error"
                                            : "form-control"
                                        }
                                        name="proveedor" 
                                        id="email"
                                        placeholder="Numero de proveedor"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.proveedor}
                                    />
                                    {errors.proveedor && touched.proveedor && (
                                        <div className="input-feedback">{errors.proveedor}</div>
                                    )}
                                    
                                    
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label>Número de Tienda (Destino Final)</label>
                                    <div className="form-group">
                                        <input 
                                                type="text"
                                                className={
                                                    errors.tienda && touched.tienda
                                                    ? "form-control error"
                                                    : "form-control"
                                                }
                                                name="tienda" 
                                                id="tienda"
                                                placeholder="Numero de tienda"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.tienda}
                                        />
                                        {errors.tienda && touched.tienda && (
                                            <div className="input-feedback">{errors.tienda}</div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label>Tipo de Empaque</label>
                                    <div className="form-group">
                                        <select 
                                             name="tipo_bulto" 
                                             className={
                                               errors.tipo_bulto && touched.tipo_bulto
                                               ? "form-control error"
                                               : "form-control"
                                             }
                                             onChange={handleChange}
                                             onBlur={handleBlur}
                                             value={values.tipo_bulto}
                                        >
                                            <option value="">Seleccione</option>
                                            <option value="1">Caja</option>
                                            <option value="2">Bolsas</option>
                                        </select>
                                        {errors.tipo_bulto && touched.tipo_bulto && (
                                            <div className="input-feedback">{errors.tipo_bulto}</div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label>Número de Tienda de Entrega (Dejar vacio o 1)</label>
                                    <div className="form-group">
                                        <input 
                                            type="text"
                                            className={
                                                errors.tienda_entrega && touched.tienda_entrega
                                                ? "form-control error"
                                                : "form-control"
                                            }
                                            name="tienda_entrega" 
                                            id="tienda_entrega"
                                            placeholder="Numero de tienda"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.tienda_entrega}
                                        />
                                        {errors.tienda_entrega && touched.tienda_entrega && (
                                            <div className="input-feedback">{errors.tienda_entrega}</div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label>Cantidad de Bultos</label>
                                    <div className="form-group">
                                        {/* <input type="number" className="form-control" /> */}
                                        <input 
                                            type="text"
                                            className={
                                                errors.cantidad_bulto && touched.cantidad_bulto
                                                ? "form-control error"
                                                : "form-control"
                                            }
                                            name="cantidad_bulto" 
                                            id="cantidad_bulto"
                                            placeholder="cantidad de bulto"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.cantidad_bulto}
                                        />
                                        {errors.cantidad_bulto && touched.cantidad_bulto && (
                                            <div className="input-feedback">{errors.cantidad_bulto}</div>
                                        )}
                                    </div>
                                </div>
                                {/* <div className="col-md-6">
                                    <label>Fecha de Remisión</label>
                                    <div className="form-group">
                                    <DatePicker
                                        name="fecha_remision"
                                        selected={values.fecha_remision}
                                        dateFormat="dd/MM/yyyy"
                                        className={
                                            errors.fecha_remision && touched.fecha_remision
                                            ? "form-control error"
                                            : "form-control"
                                        }
                                        locale="es"
                                        onChange={(date, dateString) =>{
                                            setFieldValue("fecha_remision", date)
                                            }
                                          }
                                        value={values.fecha_remision !== "" ? values.fecha_remision : ""}
                                    />
                                    {errors.fecha_remision && touched.fecha_remision && (
                                        <div className="input-feedback">{errors.fecha_remision}</div>
                                    )}
                                    </div>
                                </div> */}
                                <div className="col-md-6">
                                    <label>Fecha de Entrega de Mercancia</label>
                                    <div className="form-group">
                                    <DatePicker
                                        name="fecha_entrega"
                                        selected={values.fecha_entrega}
                                        // selected={startDate}
                                        // onChange={date => setStartDate(date)}
                                        // showTimeSelect
                                        // timeFormat="HH:mm"
                                        // timeIntervals={1}
                                        // timeCaption="time"
                                        dateFormat="dd/MM/yyyy"
                                        className={
                                            errors.fecha_entrega && touched.fecha_entrega
                                            ? "form-control error"
                                            : "form-control"
                                        }
                                        locale="es"
                                        onChange={(date, dateString) =>{
                                            // setFieldValue("fecha_entrega", dateString)
                                            // console.log(dateString);
                                            // console.log(date);
                                            // let fecha_ent = moment(date).format('DD/MM/YYYY');
                                            //  console.log(fecha_ent);
                                            // setFieldValue("fecha_entrega", fecha_ent);

                                            setFieldValue("fecha_entrega", date)
                                            // console.log(values.fecha_entrega)
                                            }
                                          }
                                        value={values.fecha_entrega !== "" ? values.fecha_entrega : ""}
                                        
                                        //   value={values.fecha_entrega !== "" ? moment(values.fecha_entrega) : ""}
                                        // value={values.fecha_entrega !== "" ? moment(values.fecha_entrega) : ""}
                                        // minDate={subHours(new Date(), 72)}
                                        // maxDate={new Date()}
                                        
                                    />
                                    {errors.fecha_entrega && touched.fecha_entrega && (
                                        <div className="input-feedback">{errors.fecha_entrega}</div>
                                    )}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label>Folio de la Cita</label>
                                    <div className="form-group">
                                        {/* <input className="form-control" /> */}
                                        <input 
                                            type="text"
                                            className={
                                                errors.cita && touched.cita
                                                ? "form-control error"
                                                : "form-control"
                                            }
                                            name="cita" 
                                            id="cita"
                                            placeholder="Numero de cita"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.cita}
                                        />
                                        {errors.cita && touched.cita && (
                                            <div className="input-feedback">{errors.cita}</div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label>Folio Nota de Entrada</label>
                                    <div className="form-group">
                                        {/* <input className="form-control" /> */}
                                        <input 
                                            type="text"
                                            className={
                                                errors.nota_entrada && touched.nota_entrada
                                                ? "form-control error"
                                                : "form-control"
                                            }
                                            name="nota_entrada" 
                                            id="nota_entrada"
                                            placeholder="Numero de nota entrada"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.nota_entrada}
                                        />
                                        {errors.nota_entrada && touched.nota_entrada && (
                                            <div className="input-feedback">{errors.nota_entrada}</div>
                                        )}
                                    </div>
                                </div> 
                                <div className="col-md-6">
                                    <label>Codigo Producto(Provisional)</label>
                                    <div className="form-group">
                                        <input 
                                            type="text"
                                            className={
                                                errors.codigo && touched.codigo
                                                ? "form-control error"
                                                : "form-control"
                                            }
                                            name="codigo" 
                                            id="codigo"
                                            placeholder="codigo de producto"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.codigo}
                                        />
                                        {errors.codigo && touched.codigo && (
                                            <div className="input-feedback">{errors.codigo}</div>
                                        )}
                                    </div>
                                </div> 
                            </div>       
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="card">
                        <h5 className="card-header card-titulo">Pedido</h5>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <label>Folio del Pedido</label>
                                    <div className="form-group">
                                        {/* <input className="form-control" /> */}
                                        <input 
                                            type="text"
                                            className={
                                                errors.folio_pedido && touched.folio_pedido
                                                ? "form-control error"
                                                : "form-control"
                                            }
                                            name="folio_pedido" 
                                            id="folio_pedido"
                                            placeholder="folio pedido"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.folio_pedido}
                                        />
                                        {errors.folio_pedido && touched.folio_pedido && (
                                            <div className="input-feedback">{errors.folio_pedido}</div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label>Pedido Emitido por Proveedor</label>
                                    <div className="form-group">
                                        {/* <input className="form-control" /> */}
                                        <input 
                                            type="text"
                                            className={
                                                errors.pedido_proveedor && touched.pedido_proveedor
                                                ? "form-control error"
                                                : "form-control"
                                            }
                                            name="pedido_proveedor" 
                                            id="pedido_proveedor"
                                            placeholder="Numero de pedido proveedor"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.pedido_proveedor}
                                        />
                                        {errors.pedido_proveedor && touched.pedido_proveedor && (
                                            <div className="input-feedback">{errors.pedido_proveedor}</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="card">
                        <h5 className="card-header card-titulo">TIPS</h5>
                        <div className="card-body">
                            <div className="row">
                                <p>Para hacer una factura Extemporanea debe llenar: "Folio Nota de Entrada" y tambien "Pedido Emitido por Proveedor" - Este con la palabra "SI"</p>
                                <p>Tambien note que los datos de "Cita" y de "Nota de Entrada" son excluyentes, es decir, puede poner uno o el otro pero NO los dos al mismo tiempo.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="card">
                        <h5 className="card-header card-titulo">DATOS REQUERIDOS POR CADA CONCEPTO FACTURADO</h5>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <input 
                                        id="file" 
                                        name="file" 
                                        type="file" 
                                        onChange={(event) => {
                                            setFieldValue("file", event.currentTarget.files[0]);
                                        }} 
                                    />

                                </div>

                            </div>
                            <div className="row">
                                <div className="table-responsive">
                                    <table className="table table-bordered table-striped">
                                        <thead>
                                            <tr>
                                                <th>DESCRIPCIÓN</th>
                                                <th>CÓDIGO</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>PRUEBA 6%</td>
                                                <td>S0001-0136</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <button type="submit" className="btn btn-primary" disabled={isSubmitting} >
                              <i className="fas fa-save"></i>
                              {isSubmitting ? 'Enviando...' : 'Enviar'}
                    </button>
                </div>
            </div>     
            </Form>    

            )}
            </Formik>
        </div>
    );
}

export default SorianaRemision;