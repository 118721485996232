import React from 'react';
import NuevoUsuario from './NuevoUsuario';
import EditarUsuario from './EditarUsuario';

function Usuarios() {
    return (
        <div className="container-fluid">
            <div className="usuarios">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">Usuarios</h3>
                                <button type="button" className="btn btn-primary" data-toggle="modal" data-target=".agregar-usuario"><i className="fas fa-plus"></i>Agregar</button>
                            </div>
                            <div className="modal fade agregar-usuario" tabIndex={-1} role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                                <div className="modal-dialog modal-lg" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">Nuevo Usuario</h5>
                                           <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">×</span>
                                            </button>

                                        </div>
                                        <div className="modal-body">
                                            <NuevoUsuario />
                                        </div>
                                        <div className="modal-footer">
                                            <button className="btn btn-danger" data-dismiss="modal" arial-label="Close"><i className="fas fa-times"></i>Cancelar</button>
                                            <button className="btn btn-primary"><i className="fas fa-save"></i>Guardar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="card-body">
                               <div className="row">
                                <div className="col-lg-3 col-md-4 col-sm-4 col-xs-6">
                                        <div className="card" align="center">
                                        <img src="./dist/img/myuser.png" className="card-img-top" alt="..." />
                                            <div className="card-body">
                                                <h5 className="">Razón Social</h5>
                                                <p className="card-text">razonsocial@email.com</p>
                                                <p className="text-bold">Administrador</p>
                                                {/* <a href="#" className="btn btn-primary"><i className="fas fa-edit"></i>Editar</a> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-4 col-xs-6">
                                        <div className="card" align="center">
                                        <img src="./dist/img/myuser.png" className="card-img-top" alt="..." />
                                            <div className="card-body">
                                                <h5 className="">Razón Social 2</h5>
                                                <p className="card-text">razonsocial2@email.com</p>
                                                <a href="#" className="btn btn-danger mr-2"><i className="fas fa-trash"></i>Eliminar</a>
                                                <a href="#" className="btn btn-primary mr-2" data-toggle="modal" data-target=".editar-usuario"><i className="fas fa-edit"></i>Editar</a>
                                                <a href="#" className="btn btn-warning"><i className="fas fa-user-check"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-4 col-xs-6">
                                        <div className="card" align="center">
                                        <img src="./dist/img/myuser.png" className="card-img-top" alt="..." />
                                            <div className="card-body">
                                                <h5 className="">Razón Social 2</h5>
                                                <p className="card-text">razonsocial2@email.com</p>
                                                <a href="#" className="btn btn-danger mr-2"><i className="fas fa-trash"></i>Eliminar</a>
                                                <a href="#" className="btn btn-primary mr-2" data-toggle="modal" data-target=".editar-usuario"><i className="fas fa-edit"></i>Editar</a>
                                                <a href="#" className="btn btn-warning"><i className="fas fa-user-check"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    
                            <div className="modal fade editar-usuario" tabIndex={-1} role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                                <div className="modal-dialog modal-lg" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">Editar Usuario</h5>
                                            <div className="ml-3">
                                                <button type="button" className="btn btn-danger" data-dismiss="modal"><i className="fas fa-trash"></i>Eliminar</button>
                                            </div>
                                           <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">×</span>
                                            </button>

                                        </div>
                                        <div className="modal-body">
                                            <EditarUsuario />
                                        </div>
                                        <div className="modal-footer">
                                            <button className="btn btn-danger" data-dismiss="modal" arial-label="Close"><i className="fas fa-times"></i>Cancelar</button>
                                            <button className="btn btn-primary"><i className="fas fa-save"></i>Guardar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                    
                                    {/* <div className="col-md-6">
                                        <div className="card mb-3">
                                            <div className="row no-gutters">
                                                <div className="col-md-4">
                                                <img src="./dist/img/myuser.png" className="card-img" alt="..." />
                                                </div>
                                                <div className="col-md-8">
                                                    <div className="card-body">
                                                        <h5 className="card-title">Razón Social</h5>
                                                        <p className="card-text">razonsocial@ejemplo.com</p>
                                                        <p className="text-bold">Administrador</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                               </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Usuarios;