import React from "react";

function DatosCompra() {
  return (
    <div className="container-fluid">
      <div className="col-md-12">
        <div className="card">
          <h4 className="card-header card-titulo">Datos para tu factura</h4>
          <div className="card-body">
            <div className="row">
              <div className="form-group col-md-6">
                <label htmlFor="inputState">Tipo de Pago:</label>
                <select id="inputState" className="form-control">
                  <option>Tarjeta de Crédito/Débito</option>
                  <option>Paypal</option>
                  <option>Transferencia Bancaria</option>
                  <option>OXXO</option>
                </select>
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="inputState">Uso de CFDI:</label>
                <select id="inputState" className="form-control">
                  <option>G03 - Gastos en general</option>
                  <option>P01 - Por definir</option>
                </select>
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="inputState">Correo*:</label>
                <input className="form-control"></input>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-12">
        <div className="card">
          <h4 className="card-header card-titulo">Elige tu forma de pago</h4>
          <div className="card-body">
            <div className="row">
              <div className="form-group col-md-6">
                <label htmlFor="inputEmail4">Nombre titular tarjeta</label>
                <input type="email" className="form-control" id="inputEmail4" />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="inputAddress">No. Tarjeta</label>
                <input
                  type="text"
                  className="form-control"
                  id="inputAddress"
                  placeholder=""
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="inputState">Fecha de expiración</label>
                <div className="row">
                  <div className="col-md-6">
                    <select id="inputState" className="form-control">
                      <option selected>01</option>
                      <option>02</option>
                      <option>03</option>
                      <option>04</option>
                      <option>05</option>
                      <option>06</option>
                      <option>07</option>
                      <option>08</option>
                      <option>09</option>
                      <option>10</option>
                      <option>12</option>
                    </select>
                  </div>
                  <div className="col-md-6">
                    <select id="inputState" className="form-control">
                      <option selected>2020</option>
                      <option>2021</option>
                      <option>2022</option>
                      <option>2023</option>
                      <option>2024</option>
                      <option>2025</option>
                      <option>2026</option>
                      <option>2027</option>
                      <option>2028</option>
                      <option>2029</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group">
                  <label htmlFor="inputAddress2">CVC</label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputAddress2"
                  />
                </div>
              </div>
              <div className="form-group col-md-4">
                <label htmlFor="inputCity">No. Teléfono</label>
                <input type="text" className="form-control" id="inputCity" />
              </div>
            </div>
          </div>
          <div className="card-footer">
            <div className="float-right">
              <button type="submit" className="btn btn-primary">
                Pagar Ahora
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-12">
        <div className="card">
          <h4 className="card-header card-titulo">Elige tu forma de pago</h4>
          <div className="card-body">
            <div className="row">
              <div className="form-group col-md-6">
                <label htmlFor="inputAddress">Nombre Cuenta</label>
                <input
                  type="text"
                  className="form-control"
                  id="inputAddress"
                  value="RED2000 DISEÑO Y HOSPEDAJE WEB SA DE CV"
                  readOnly
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="inputEmail4">RFC</label>
                <input
                  type="email"
                  className="form-control"
                  id="inputEmail4"
                  value="RDH100125NF2"
                  readOnly
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="inputAddress">No. Cuenta</label>
                <input
                  type="text"
                  className="form-control"
                  id="inputAddress"
                  value="92-00150075-7"
                  readOnly
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="inputAddress">Clabe</label>
                <input
                  type="text"
                  className="form-control"
                  id="inputAddress"
                  value="014744920015007572"
                  readOnly
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="inputEmail4">Banco</label>
                <input
                  type="email"
                  className="form-control"
                  id="inputEmail4"
                  value="Santander"
                  readOnly
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="inputEmail4">Sucursal</label>
                <input
                  type="email"
                  className="form-control"
                  id="inputEmail4"
                  value="4569"
                  readOnly
                />
              </div>
            </div>
          </div>
          <div className="card-footer">
            <span>
              Envíe un correo con la imagen del pago realizado a
              ventas@red2000.mx indicando que está pagando, tambien incluya el
              nombre completo y usuario (Empieza con S) para que se le asignen
              los folios, apartir de 1 hora a 2 horas se le acreditarán los
              folios además de que se le enviará su factura por correo, en caso
              de requerir la factura a otro nombre u RFC agregue los datos
              fiscales completos en el correo.
            </span>
          </div>
        </div>
      </div>

      <div className="col-md-12">
        <div className="card">
          <h4 className="card-header card-titulo">Elige tu forma de pago</h4>
          <div className="card-body">
            <div className="row">
            <div className="oxxo
            " align="center">
                <img src="dist/img/oxxo.png"></img>
            </div>
              <span>
                Al presionar el botón de Pagar el sistema le generará su ficha
                de pago. Para comprobar su pago envíe la imagen del pago
                realizado a ventas@red2000.mx indicando que está
                pagando, además incluya el
                nombre completo y usuario (Empieza con S) para que se
                le asignen los folios, apartir de media hora o 1
                hora se le acreditarán los folios
                además de que se le enviará su factura por correo, en caso de
                requerir la factura a otro nombre u RFC agregue los datos
                fiscales completos en el correo.
              </span>
            </div>
          </div>
          <div className="card-footer">
            <div className="float-right">
            <button type="submit" className="btn btn-primary">
                Pagar Ahora
            </button>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-12">
        <div className="card">
          <h4 className="card-header card-titulo">Elige tu forma de pago</h4>
          <div className="card-body">
            <div className="row">
            <div className="paypal
            " align="center">
                <img src="dist/img/paypal.png"></img>
            </div>
              <span>
                Al presionar el botón de Pagar el sistema lo rediccionará automaticamente al sitio de pagos.
                Al realizar el pago se le acreditarán los folios a su cuenta, además que se le enviará su factura por correo.
              </span>
            </div>
          </div>
          <div className="card-footer">
            <div className="float-right">
            <button type="submit" className="btn btn-primary">
                Pagar Ahora
            </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DatosCompra;
