import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import AlertaContext from '../context/alertas/alertaContext';
import AuthContext from '../context/autenticacion/authContext';


const InicioSesion = (props) => {

    const alertaContext = useContext(AlertaContext);
    const { alerta, mostrarAlerta } = alertaContext;

    const authContext = useContext(AuthContext);
    const { mensaje, autenticado, iniciarSesion } = authContext;

      // En caso de que el api_password o usuario no exista
   /*
      useEffect(() => {
        if(autenticado) {
            props.history.push('/dashboard');
        }

        if(mensaje) {
            mostrarAlerta(mensaje.msg, mensaje.categoria);
        }
        // eslint-disable-next-line
    }, [mensaje, autenticado, props.history]);
*/
    // State para iniciar sesión
    const [usuario, guardarUsuario] = useState({
        api_usuario: '',
        api_password: ''
    });

    // extraer de usuario
    const { api_usuario, api_password } = usuario;

    const onChange = e => {
        guardarUsuario({
            ...usuario,
            [e.target.name] : e.target.value
        })
    }

    // Cuando el usuario quiere iniciar sesión
    const onSubmit = e => {
        e.preventDefault();

        // Validar que no haya campos vacios
        if(api_usuario.trim() === '' || api_password.trim() === '') {
            mostrarAlerta('Todos los campos son obligatorios', 'alerta-error');
        }

        // Pasarlo al action
        iniciarSesion({ api_usuario, api_password });
    }

    return (
        
           <div className="container">
                { alerta ? ( <div className={`alerta ${alerta.categoria}`}> {alerta.msg} </div> )  : null }
               <div className="row">
                    <div className="login-box">
                        <div className="login-logo">
                            <a href="#"><img src="dist/img/logo3.png"/></a>
                        </div>
                        <div className="card">
                            <form onSubmit={onSubmit}>
                                <div className="card-body login-card-body">
                                <p className="login-box-msg">Inicia Sesión</p>
                                <form action="../../index3.html" method="post">
                                    <div className="input-group mb-3">
                                    <input 
                                    type="text" 
                                    className="form-control" 
                                    id="api_usuario"
                                    name="api_usuario"
                                    placeholder="Usuario" 
                                    value={api_usuario}
                                    onChange={onChange}
                                    />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                        <span className="fas fa-envelope" />
                                        </div>
                                    </div>
                                    </div>
                                    <div className="input-group mb-3">
                                    <input 
                                    type="password" 
                                    className="form-control" 
                                    id="api_password"
                                    name="api_password"
                                    placeholder="Contraseña" 
                                    value={api_password}
                                    onChange={onChange}
                                    />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                        <span className="fas fa-lock" />
                                        </div>
                                    </div>
                                    </div>


                                    <div className="row">
                                    <div className="col-8">
                                        <div className="icheck-primary">
                                        <input type="checkbox" id="remember" />
                                        <label htmlFor="remember">
                                            Recordar Usuario
                                        </label>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <button type="submit" className="btn btn-primary btn-block">Entrar</button>
                                    </div>
                                    </div>
                                </form>
                                <p className="mb-1">
                                    <Link to="recuperar-contrasena">Recuperar mi contraseña</Link>
                                </p>
                                </div>
                            </form>
                        </div>
                    </div>
               </div>
           </div>
    );
}

export default InicioSesion;