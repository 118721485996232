import React from "react";

const Cargador = () => {
  return (
    <div className="container-loading mt-5">
        <img src="/dist/img/loading-bg-transparent.gif" alt="Cargando" className="loading" style={{ /*width: '85px'*/}} />
    </div>
  );
};

export default Cargador;