import React, { Component } from 'react';

class CfdiTraslado extends Component {
    render() {
        return (
            <div>
                 <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">Consulta de CFDI's de Traslado</h3>
                            </div>
                            <div className="col-md-12">
                                <div id="example1_filter" className="dataTables_filter">
                                    <label>
                                        <input type="search" className="form-control form-control-sm" placeholder="Buscar" aria-controls="example1" />
                                    </label>
                                </div>
                            </div>
                            <div className="card-body">
                                <table id="example1" className="table table-bordered table-striped">
                                <thead>
                                    <tr>
                                    <th>Folio</th>
                                    <th>Fecha</th>
                                    <th>Cliente</th>
                                    <th>Bruto</th>
                                    <th>I.V.A</th>
                                    <th>Desc.</th>
                                    <th>Total</th>
                                    <th>Pagado</th>
                                    <th>Saldo</th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                    <td>100</td>
                                    <td>2019-12-10
                                    </td>
                                    <td>EMPRESA DE TRANSPORTES SA DE CV</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>
                                        <td><img src="dist/img/cfdi.gif" width="20"/></td>
                                        <td><img src="dist/img/pdf.gif" width="20"/></td>
                                        <td><img src="dist/img/email.png" width="20"/></td>
                                        <td><img src="dist/img/anular.gif" width="20"/></td>
                                    </tr>
                                    <tr>
                                    <td>100</td>
                                    <td>2019-12-10</td>
                                    <td>EMPRESA DE TRANSPORTES SA DE CV</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>
                                        <td><img src="dist/img/cfdi.gif" width="20"/></td>
                                        <td><img src="dist/img/pdf.gif" width="20"/></td>
                                        <td><img src="dist/img/email.png" width="20"/></td>
                                        <td><img src="dist/img/anular.gif" width="20"/></td>
                                    </tr>
                                    <tr>
                                    <td>100</td>
                                    <td>2019-12-10</td>
                                    <td>EMPRESA DE TRANSPORTES SA DE CV</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>
                                        <td><img src="dist/img/cfdi.gif" width="20"/></td>
                                        <td><img src="dist/img/pdf.gif" width="20"/></td>
                                        <td><img src="dist/img/email.png" width="20"/></td>
                                        <td><img src="dist/img/anular.gif" width="20"/></td>
                                    </tr>
                                    <tr>
                                    <td>100</td>
                                    <td>2019-12-10</td>
                                    <td>EMPRESA DE TRANSPORTES SA DE CV</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>
                                        <td><img src="dist/img/cfdi.gif" width="20"/></td>
                                        <td><img src="dist/img/pdf.gif" width="20"/></td>
                                        <td><img src="dist/img/email.png" width="20"/></td>
                                        <td><img src="dist/img/anular.gif" width="20"/></td>
                                    </tr>
                                    <tr>
                                    <td>100</td>
                                    <td>2019-12-10</td>
                                    <td>EMPRESA DE TRANSPORTES SA DE CV</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>
                                        <td><img src="dist/img/cfdi.gif" width="20"/></td>
                                        <td><img src="dist/img/pdf.gif" width="20"/></td>
                                        <td><img src="dist/img/email.png" width="20"/></td>
                                        <td><img src="dist/img/anular.gif" width="20"/></td>
                                    </tr>
                                    <tr>
                                    <td>100</td>
                                    <td>2019-12-10</td>
                                    <td>EMPRESA DE TRANSPORTES SA DE CV</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>
                                        <td><img src="dist/img/cfdi.gif" width="20"/></td>
                                        <td><img src="dist/img/pdf.gif" width="20"/></td>
                                        <td><img src="dist/img/email.png" width="20"/></td>
                                        <td><img src="dist/img/anular.gif" width="20"/></td>
                                    </tr>
                                    <tr>
                                    <td>100</td>
                                    <td>2019-12-10</td>
                                    <td>EMPRESA DE TRANSPORTES SA DE CV</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>
                                        <td><img src="dist/img/cfdi.gif" width="20"/></td>
                                        <td><img src="dist/img/pdf.gif" width="20"/></td>
                                        <td><img src="dist/img/email.png" width="20"/></td>
                                        <td><img src="dist/img/anular.gif" width="20"/></td>
                                    </tr>
                                    <tr>
                                    <td>100</td>
                                    <td>2019-12-10</td>
                                    <td>EMPRESA DE TRANSPORTES SA DE CV</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>
                                        <td>0</td>
                                        <td>0</td>
                                        <td>0</td>
                                        <td><img src="dist/img/cfdi.gif" width="20"/></td>
                                        <td><img src="dist/img/pdf.gif" width="20"/></td>
                                        <td><img src="dist/img/email.png" width="20"/></td>
                                        <td><img src="dist/img/anular.gif" width="20"/></td>
                                    </tr>
                                    
                                    <tr>
                                    <td>100</td>
                                    <td>2019-12-10</td>
                                    <td>EMPRESA DE TRANSPORTES SA DE CV</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>
                                        <td><img src="dist/img/cfdi.gif" width="20"/></td>
                                        <td><img src="dist/img/pdf.gif" width="20"/></td>
                                        <td><img src="dist/img/email.png" width="20"/></td>
                                        <td><img src="dist/img/anular.gif" width="20"/></td>
                                    </tr>
                                    <tr>
                                    <td>100</td>
                                    <td>2019-12-10</td>
                                    <td>EMPRESA DE TRANSPORTES SA DE CV</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>
                                        <td><img src="dist/img/cfdi.gif" width="20"/></td>
                                        <td><img src="dist/img/pdf.gif" width="20"/></td>
                                        <td><img src="dist/img/email.png" width="20"/></td>
                                        <td><img src="dist/img/anular.gif" width="20"/></td>
                                    </tr>
                                    <tr>
                                    <td>100</td>
                                    <td>2019-12-10</td>
                                    <td>EMPRESA DE TRANSPORTES SA DE CV</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>
                                    <td>0</td>
                                        <td><img src="dist/img/cfdi.gif" width="20"/></td>
                                        <td><img src="dist/img/pdf.gif" width="20"/></td>
                                        <td><img src="dist/img/email.png" width="20"/></td>
                                        <td><img src="dist/img/anular.gif" width="20"/></td>
                                    </tr>
                                </tbody>
                                </table>
                            </div>
                            </div>
                        </div>
                    </div>
              </div>
             <div className="container-fluid">
                <div className="float-right">
                    <div className="dataTables_paginate paging_simple_numbers" id="example1_paginate">
                        <ul className="pagination">
                        <li className="paginate_button page-item previous disabled" id="example1_previous">
                            <a href="#" aria-controls="example1" data-dt-idx={0} tabIndex={0} className="page-link"> <i className="fas fa-chevron-left" /> </a></li><li className="paginate_button page-item active"><a href="#" aria-controls="example1" data-dt-idx={1} tabIndex={0} className="page-link">1</a>
                        </li>
                        <li className="paginate_button page-item ">
                            <a href="#" aria-controls="example1" data-dt-idx={2} tabIndex={0} className="page-link">2</a>
                        </li>
                        <li className="paginate_button page-item ">
                            <a href="#" aria-controls="example1" data-dt-idx={3} tabIndex={0} className="page-link">3</a>
                        </li>
                        <li className="paginate_button page-item ">
                            <a href="#" aria-controls="example1" data-dt-idx={4} tabIndex={0} className="page-link">4</a>
                        </li>
                        <li className="paginate_button page-item ">
                            <a href="#" aria-controls="example1" data-dt-idx={5} tabIndex={0} className="page-link">5</a>
                        </li>
                        <li className="paginate_button page-item ">
                            <a href="#" aria-controls="example1" data-dt-idx={6} tabIndex={0} className="page-link">6</a>
                        </li>
                        <li className="paginate_button page-item next" id="example1_next">
                            <a href="#" aria-controls="example1" data-dt-idx={7} tabIndex={0} className="page-link"> <i className="fas fa-chevron-right" /> </a>
                        </li>
                        </ul>
                    </div>
                    </div>

             </div>
            </div>
        );
    }
}

export default CfdiTraslado;