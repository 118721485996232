import React, { Component } from 'react';

class NuevoCfdiTraslado extends Component {
    render() {
        return (
            <div>
                <div className="container-fluid">
                    <div ClassName="row">
                        <div className="col-md-12">
                        <div className="card card-primary">
                        <div className="card-header">
                            <h3 className="card-title">Registro de CFDI Traslado</h3>
                           
                            <button type="submit" className="btn btn-primary">Siguiente<i class="fas fa-arrow-right"></i></button>
                            <button type="submit" className="btn btn-secondary" onClick="history.back()"><i class="fas fa-arrow-left"></i>Atrás</button>
                        </div>
                        <form role="form">
                            <div className="card-body">
                                <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="clave">Cliente / Razón Social / RFC*: </label>
                                        <input type="text" className="form-control" id="clave" placeholder="Buscar cliente por Razón Social O RFC" />
                                    </div>
                                </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Moneda*: </label>
                                        <select class="form-control">
                                        <option>MXN</option>
                                        <option>IVA Tasa 0.0160000</option>
                                        <option>IVA Tasa 0.0000000</option>
                                        <option>IVA Exento</option>
                                        <option>IVA Cuota</option>
                                        <option>IVA Tasa 0.080000</option>
                                        </select>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="descripcion">Tipo de Cambio*: </label>
                                    <input type="text" className="form-control" id="producto_sat" placeholder="1" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Condiciones de Pago: </label>
                                        <select class="form-control">
                                            <option>CONTADO</option>
                                            <option>CREDITO</option>
                                            <option>CREDITO 3 DIAS</option>
                                            <option>CREDITO 7 DIAS</option>
                                            <option>CREDITO 15 DIAS</option>
                                            <option>CREDITO 30 DIAS</option>
                                            <option>CREDITO 45 DIAS</option>
                                            <option>CREDITO 60 DIAS</option>
                                        </select>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="unidad">Tipo de relación con CFDI's: </label>
                                        <select class="form-control">
                                            <option>NO APLICA</option>
                                            <option>01 Nota de crédito de los documentos relacionados</option>
                                            <option>02 Nota de débito de los documentos relacionados</option>
                                            <option>03 Devolución de mercancía sobre facturas o traslados previos</option>
                                            <option>04 Sustitución de los CFDI previos</option>
                                            <option>05 Traslados de mercancias facturados previamente</option>
                                            <option>06 Factura generada por los traslados previos</option>
                                            <option>07 CFDI por aplicación de anticipo</option>
                                        </select>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Buscar CFDI's: </label>
                                    <div className="form-check">
                                        <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                                        <label className="form-check-label" htmlFor="exampleCheck1">Marcar si el CFDI a relacionar no se hizo en el sistema</label>
                                        <input type="text" className="form-control" id="uuid" placeholder="Buscar UUID's por folio, fecha, monto" disabled/>
                                     </div>
                            
                                </div>
                            </div>
                            <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="unidad">CFDI's relacionados: </label>
                                <textarea className="form-control" id="unidad" />
                            </div>
                            </div>
                
                           
                            
                            </div>
                           
                            <div className="card-footer">
                            <button type="submit" className="btn btn-primary">Siguiente<i class="fas fa-arrow-right"></i></button>
                            </div>
                            </div>
                        </form>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default NuevoCfdiTraslado;