import React, { Component } from 'react';
import  { Link } from 'react-router-dom';

class ReporteVentas extends Component {

    render() {
        return (
            <div>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12">
                  <div className="card card-primary">
                    <div className="card-header">
                      <h3 className="card-title">Reporte de Facturación</h3>
                    </div>
                      <div className="card-body">
                          <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Sucursal</label>
                                <input type="text" className="form-control" id="exampleInputEmail1" placeholder="S0001" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                            <label htmlFor="exampleInputPassword1">Razón Social</label>
                            <input type="text" className="form-control" id="exampleInputPassword1" placeholder="Razón Social" />
                            </div>
                        </div>
                      <div className="col-md-6">
                      <div className="form-group date">
                            <label htmlFor="unidad">Fecha Inicial: </label>
                            <div className="input-group">
                            <input
                                type="text"
                                className="form-control"
                                id="datepicker"                                
                                placeholder="Seleccione fecha"></input>
                              <div className="input-group-append datepicker" data-toggle="tooltip" data-placement="top" title="Seleccione fecha">
                                <a
                                type="button"
                                  className="btn input-group-text"
                                  id="datepicker"
                                >
                                  <i
                                    className="fas fa-calendar-alt"
                                  ></i>
                                </a>
                              </div>
                            </div>
                          </div>
                      </div>
                      <div className="col-md-6">
                      <div className="form-group">
                            <label htmlFor="unidad">Fecha Final: </label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                id="datepicker2"                                
                                placeholder="Seleccione fecha"></input>
                              <div className="input-group-append datepicker" data-toggle="tooltip" data-placement="top" title="Seleccione fecha">
                                <a
                                type="button"
                                  className="btn input-group-text"
                                  id="datepicker3"
                                >
                                  <i
                                    className="fas fa-calendar-alt"
                                  ></i>
                                </a>
                              </div>
                            </div>
                          </div>
                      </div>
                      </div>
                      </div>
                      <div className="card-footer">
                        <Link type="submit" to="reporte-ventas-folios" className="btn btn-primary">Consultar</Link>
                      </div>
                  </div>
                 </div>
                  </div>
              </div>
            </div>

        );
    }
}


export default ReporteVentas;