import React, { Component } from 'react';

class ReporteVentasFolios extends Component {
    render() {
        return (
            <div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                            <div className="card-header">
                                <h3>Reporte de Ventas</h3>
                            </div>
                            <div className="card-body">
                                <table id="example1" className="table table-bordered table-striped">
                                <thead>
                                    <tr>
                                    <td></td>
                                    <th>Folio Fact.</th>
                                    <th>Fecha Fact.</th>
                                    <th>Nombre Cliente</th>
                                    <th>RFC</th>
                                    <th>SubTotal</th>
                                    <th>Descuento</th>
                                    <th>IVA Tras.</th>
                                    <th>IEPS Tras.</th>
                                    <th>ISR Ret.</th>
                                    <th>IVA Ret.</th>
                                    <th>Total Fact.</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                    <td></td>
                                    <td>223</td>
                                    <td>2019-01-14 11:51:13</td>
                                    <td>COMISION FEDERAL DE ELECTRICIDAD</td>
                                    <td>RDH100125NF2</td>
                                    <td>$ 70,000.00</td>
                                    <td>$ 0.00</td>
                                    <td>$ 11,200.00</td>
                                    <td>$ 0.00</td>
                                    <td>$ 0.00</td>
                                    <td>$ 0.00</td>
                                    <td className="text-danger"><strong>$79,100</strong></td>
                                    </tr>
                                    <tr>
                                    <td></td>
                                    <td>223</td>
                                    <td>2019-01-14 11:51:13</td>
                                    <td>COMISION FEDERAL DE ELECTRICIDAD</td>
                                    <td>RDH100125NF2</td>
                                    <td>$ 70,000.00</td>
                                    <td>$ 0.00</td>
                                    <td>$ 11,200.00</td>
                                    <td>$ 0.00</td>
                                    <td>$ 0.00</td>
                                    <td>$ 0.00</td>
                                    <td className="text-danger"><strong>$79,100</strong></td>
                                    </tr>
                                    <tr>
                                    <td></td>
                                    <td>223</td>
                                    <td>2019-01-14 11:51:13</td>
                                    <td>COMISION FEDERAL DE ELECTRICIDAD</td>
                                    <td>RDH100125NF2</td>
                                    <td>$ 70,000.00</td>
                                    <td>$ 0.00</td>
                                    <td>$ 11,200.00</td>
                                    <td>$ 0.00</td>
                                    <td>$ 0.00</td>
                                    <td>$ 0.00</td>
                                    <td className="text-danger"><strong>$79,100</strong></td>
                                    </tr>
                                    <td colSpan="5">TOTAL</td>
                                    <th>$ 210,000.00</th>
                                    <th>$0.00</th>
                                    <th>$33,600</th>
                                    <th>$0.00</th>
                                    <th>$0.00</th>
                                    <th>$0.00</th>
                                    <th>$237,300.00</th>
                                </tbody>
                                </table>
                            </div>
                            </div>
                                </div>
                            </div>
                        </div>
                    </div>
        );
    }
}

export default ReporteVentasFolios;