import React, { Component } from 'react';

class ComplementoNotarioPublico extends Component {
    render() {
        return (
            <div>
                <div className="container-fluid">
                    <div className="col-md-12">
                              <h4>
                                Complemento de Notarios Públicos
                              </h4>
                              <hr></hr>
                            <div className="col-md-12">
                              <div className="card my-5">
                                <h4 className="card-header card-titulo">Inmuebles</h4>
                              <div className="card-body">
                                <div className="col-md-12">
                                  <div className="row">
                                    <div className="col-md-3 col-sm-6">
                                      <label>Tipo del Inmueble*</label>
                                      <div className="input-group mb-3">
                                        <select
                                          className="custom-select"
                                          id="inputGroupSelect01"
                                        >
                                          <option selected>Seleccione</option>
                                          <option>Terreno</option>
                                          <option>Terreno uso comercial</option>
                                          <option>
                                            Construcción habitacional
                                          </option>
                                          <option>
                                            Construcción uso comercial
                                          </option>
                                          <option>Uso mixto</option>
                                        </select>
                                      </div>
                                    </div>
                                    <div className="col-md-3 col-sm-6">
                                      <div className="form-group">
                                        <label>Calle del Inmueble*</label>
                                        <input
                                          type="email"
                                          className="form-control"
                                          id="inputEmail4"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-3 col-sm-6">
                                      <div className="form-group">
                                        <label>No. Exterior</label>
                                        <input
                                          type="email"
                                          className="form-control"
                                          id="inputEmail4"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-3 col-sm-6">
                                      <div className="form-group">
                                        <label>No Interior</label>
                                        <input
                                          type="email"
                                          className="form-control"
                                          id="inputEmail4"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-3 col-sm-6">
                                      <div className="form-group">
                                        <label>Colonia*</label>
                                        <input
                                          type="email"
                                          className="form-control"
                                          id="inputEmail4"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-3 col-sm-6">
                                      <div className="form-group">
                                        <label>Localidad</label>
                                        <input
                                          type="email"
                                          className="form-control"
                                          id="inputEmail4"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-3 col-sm-6">
                                      <div className="form-group">
                                        <label>Referencia</label>
                                        <input
                                          type="email"
                                          className="form-control"
                                          id="inputEmail4"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-3 col-sm-6">
                                      <div className="form-group">
                                        <label>Municipio*</label>
                                        <input
                                          type="email"
                                          className="form-control"
                                          id="inputEmail4"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-3 col-sm-6">
                                      <label>Estado*</label>
                                      <div className="input-group mb-3">
                                        <select
                                          className="custom-select"
                                          id="inputGroupSelect01"
                                        >
                                          <option selected>
                                            Clave Entidad
                                          </option>
                                          <option>Aguascalientes</option>
                                          <option>Baja California</option>
                                          <option>Baja California Sur</option>
                                          <option>Campeche</option>
                                          <option>Coahuila</option>
                                          <option>Chiapas</option>
                                          <option>Colima</option>
                                          <option>Ciudad de México</option>
                                          <option>Durango</option>
                                          <option>Guanajuato</option>
                                          <option>Guerrero</option>
                                          <option>Hidalgo</option>
                                          <option>Jalisco</option>
                                          <option>Michoacán</option>
                                        </select>
                                      </div>
                                    </div>
                                    <div className="col-md-3 col-sm-6">
                                      <label>País*</label>
                                      <div className="input-group mb-3">
                                        <select
                                          className="custom-select"
                                          id="inputGroupSelect01"
                                        >
                                          <option selected>
                                            MEXICO (ESTADOS UNIDOS)
                                          </option>
                                          <option>
                                            AFGANISTAN (EMRIATO ISLAMICO DE)
                                          </option>
                                          <option>
                                            ALBANIA (REPUBLICA DE)
                                          </option>
                                          <option>
                                            ALEMANIA (REPUBLICA FEDERAL DE)
                                          </option>
                                          <option>
                                            ANDORRA (PRINCIPADO DE)
                                          </option>
                                          <option>ANGOLA (REPUBLICA DE)</option>
                                          <option>ANGUILA</option>
                                          <option>ANTARTIDA</option>
                                          <option>
                                            ANTIGUA Y BARBUDA (COMUNIDAD
                                            BRITANICA DE NACIONES)
                                          </option>
                                          <option>
                                            ANTILLAS NEERLANDESAS (TERRITORIO
                                            HOLANDES DE ULTRAMAR)
                                          </option>
                                          <option>
                                            ARABIA SAUDITA (REINO DE)
                                          </option>
                                          <option>
                                            ARGELIA (REPUBLICA DEMOCRATICA Y
                                            POPULAR DE)
                                          </option>
                                          <option>ARGENTINA (REPUBLICA)</option>
                                          <option>
                                            ARMENIA (REPUBLICA DE)
                                          </option>
                                          <option>
                                            ARUBA (TERRITORIO HOLANDES DE
                                            ULTRAMAR)
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                    <div className="col-md-3 col-sm-6">
                                      <div className="form-group">
                                        <label>Código Postal*</label>
                                        <input
                                          type="email"
                                          className="form-control"
                                          id="inputEmail4"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-12 col-sm-12">
                                      <button className="btn btn-primary float-right">
                                        <i className="fas fa-plus"></i>Agregar
                                        Inmueble
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              </div>
                              <div className="table-responsive">
                                <table className="table">
                                  <thead className="thead-dark">
                                    <tr>
                                      <th scope="col">Tipo Inmueble</th>
                                      <th scope="col">Calle</th>
                                      <th scope="col">Municipio</th>
                                      <th scope="col">Estado</th>
                                      <th scope="col">País</th>
                                      <th scope="col">Código Postal</th>
                                      <th scope="col"></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>Uso mixto</td>
                                      <td>Av. Miguel Alemán</td>
                                      <td>Mazatlán</td>
                                      <td>Sinaloa</td>
                                      <td>México</td>
                                      <td>82000</td>
                                      <td>
                                        <button className="btn btn-danger" data-toggle="tooltip" data-placement="top" title="Eliminar">
                                          <i className="fas fa-trash-alt"></i>
                                        </button>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Uso mixto</td>
                                      <td>Av. Miguel Alemán</td>
                                      <td>Mazatlán</td>
                                      <td>Sinaloa</td>
                                      <td>México</td>
                                      <td>82000</td>
                                      <td>
                                        <button className="btn btn-danger" data-toggle="tooltip" data-placement="top" title="Eliminar">
                                          <i className="fas fa-trash-alt"></i>
                                        </button>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Uso mixto</td>
                                      <td>Av. Miguel Alemán</td>
                                      <td>Mazatlán</td>
                                      <td>Sinaloa</td>
                                      <td>México</td>
                                      <td>82000</td>
                                      <td>
                                        <button className="btn btn-danger" data-toggle="tooltip" data-placement="top" title="Eliminar">
                                          <i className="fas fa-trash-alt"></i>
                                        </button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="card my-5">
                                <h4 className="card-header card-titulo">
                                  Datos de operación
                                </h4>
                              <div className="card-body">
                                <div className="col-md-12">
                                  <div className="row">
                                    <div className="col-md-4 col-sm-6">
                                      <label>No. Instrumento Notarial*</label>
                                      <div className="input-group mb-3">
                                        <input
                                          type="number"
                                          className="form-control"
                                        ></input>
                                      </div>
                                    </div>
                                    <div className="col-md-4 col-sm-6">
                                      <div className="form-group">
                                        <label htmlFor="unidad">
                                          Fecha de Firma*:
                                        </label>
                                        <div className="input-group">
                                          <input
                                            type="text"
                                            className="form-control"
                                            id="datepicker"
                                            placeholder="Seleccione fecha"
                                          ></input>
                                          <div className="input-group-append" data-toggle="tooltip" data-placement="top" title="Seleccione fecha">
                                            <button
                                              className="input-group-text"
                                              id="datepicker"
                                            >
                                              <i
                                                className="fas fa-calendar-alt"
                                                data-toggle="datepicker"
                                              ></i>
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4 col-sm-6">
                                      <label>Monto de Operación*</label>
                                      <div className="input-group mb-3">
                                        <input
                                          type="number"
                                          className="form-control"
                                        ></input>
                                      </div>
                                    </div>
                                    <div className="col-md-4 col-sm-6">
                                      <label>Subtotal*</label>
                                      <div className="input-group mb-3">
                                        <input
                                          type="number"
                                          className="form-control"
                                        ></input>
                                      </div>
                                    </div>
                                    <div className="col-md-4 col-sm-6">
                                      <label>IVA*</label>
                                      <div className="input-group mb-3">
                                        <input
                                          type="number"
                                          className="form-control"
                                        ></input>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            </div>

                            <div className="col-md-12">
                              <div className="card my-5">
                                <h4 className="card-header card-titulo">
                                  Datos del Notario
                                </h4>
                              <div className="card-body">
                                <div className="col-md-12">
                                  <div className="row">
                                    <div className="col-md-4 col-sm-6">
                                      <label>CURP*</label>
                                      <div className="input-group mb-3">
                                        <input
                                          type="text"
                                          className="form-control"
                                        ></input>
                                      </div>
                                    </div>
                                    <div className="col-md-4 col-sm-6">
                                      <label>No. Notaria*</label>
                                      <div className="input-group mb-3">
                                        <input
                                          type="number"
                                          className="form-control"
                                        ></input>
                                      </div>
                                    </div>
                                    <div className="col-md-4 col-sm-6">
                                      <label>Entidad Federativa*</label>
                                      <div className="input-group mb-3">
                                        <select
                                          className="custom-select"
                                          id="inputGroupSelect01"
                                        >
                                          <option selected>Seleccione</option>
                                          <option>NW</option>
                                          <option>BA</option>
                                          <option>CO</option>
                                          <option>AC</option>
                                          <option>AY</option>
                                          <option>B1</option>
                                          <option>B2</option>
                                          <option>B3</option>
                                          <option>B4</option>
                                          <option>B5</option>
                                          <option>B6</option>
                                          <option>B7</option>
                                          <option>B8</option>
                                          <option>BAG</option>
                                        </select>
                                      </div>
                                    </div>
                                    <div className="col-md-4 col-sm-6">
                                      <label>Adscripción*</label>
                                      <div className="input-group mb-3">
                                        <textarea className="form-control"></textarea>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            </div>

                            <div className="col-md-12">
                              <div className="card my-5">
                                <h4 className="card-header card-titulo">Enajenantes</h4>
                              <div className="card-body">
                                <div className="col-md-12">
                                  <div className="row">
                                    <div className="col-md-4 col-sm-6">
                                      <label>Nombre*</label>
                                      <div className="input-group mb-3">
                                        <input
                                          type="text"
                                          className="form-control"
                                        ></input>
                                      </div>
                                    </div>
                                    <div className="col-md-4 col-sm-6">
                                      <label>Apellido Paterno*</label>
                                      <div className="input-group mb-3">
                                        <input
                                          type="text"
                                          className="form-control"
                                        ></input>
                                      </div>
                                    </div>
                                    <div className="col-md-4 col-sm-6">
                                      <label>Apellido Materno*</label>
                                      <div className="input-group mb-3">
                                        <input
                                          type="text"
                                          className="form-control"
                                        ></input>
                                      </div>
                                    </div>
                                    <div className="col-md-4 col-sm-6">
                                      <label>RFC*</label>
                                      <div className="input-group mb-3">
                                        <input
                                          type="text"
                                          className="form-control"
                                        ></input>
                                      </div>
                                    </div>
                                    <div className="col-md-4 col-sm-6">
                                      <label>CURP*</label>
                                      <div className="input-group mb-3">
                                        <input
                                          type="text"
                                          className="form-control"
                                        ></input>
                                      </div>
                                    </div>
                                    <div className="col-md-4 col-sm-6">
                                      <label>Porcentaje*</label>
                                      <div className="input-group mb-3">
                                        <input
                                          className="form-control"
                                          type="number"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-12 col-sm-12">
                                      <button className="btn btn-primary float-right">
                                        <i className="fas fa-plus"></i>Agregar
                                        enajenante
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            </div>
                            <div className="table-responsive">
                              <table className="table">
                                <thead className="thead-dark">
                                  <tr>
                                    <th scope="col">RFC</th>
                                    <th scope="col">Nombre (Completo)</th>
                                    <th scope="col">Porcentaje</th>
                                    <th scope="col">CURP</th>
                                    <th scope="col"></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>ROBC000414MZC</td>
                                    <td>Av. Miguel Alemán</td>
                                    <td>10%</td>
                                    <td>ROB000414MSL</td>
                                    <td>
                                      <button className="btn btn-danger" data-toggle="tooltip" data-placement="top" title="Eliminar">
                                        <i className="fas fa-trash-alt"></i>
                                      </button>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>ROBC000414MZC</td>
                                    <td>Av. Miguel Alemán</td>
                                    <td>10%</td>
                                    <td>ROB000414MSL</td>
                                    <td>
                                      <button className="btn btn-danger" data-toggle="tooltip" data-placement="top" title="Eliminar">
                                        <i className="fas fa-trash-alt"></i>
                                      </button>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>ROBC000414MZC</td>
                                    <td>Av. Miguel Alemán</td>
                                    <td>10%</td>
                                    <td>ROB000414MSL</td>
                                    <td>
                                      <button className="btn btn-danger" data-toggle="tooltip" data-placement="top" title="Eliminar">
                                        <i className="fas fa-trash-alt"></i>
                                      </button>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>

                            <div className="col-md-12">
                              <div className="card my-5">
                                <h4 className="card-header card-titulo">Adquiriente</h4>
                              <div className="card-body">
                                <div className="col-md-12">
                                  <div className="row">
                                    <div className="col-md-4 col-sm-6">
                                      <label>Nombre*</label>
                                      <div className="input-group mb-3">
                                        <input
                                          type="text"
                                          className="form-control"
                                        ></input>
                                      </div>
                                    </div>
                                    <div className="col-md-4 col-sm-6">
                                      <label>Apellido Paterno*</label>
                                      <div className="input-group mb-3">
                                        <input
                                          type="text"
                                          className="form-control"
                                        ></input>
                                      </div>
                                    </div>
                                    <div className="col-md-4 col-sm-6">
                                      <label>Apellido Materno*</label>
                                      <div className="input-group mb-3">
                                        <input
                                          type="text"
                                          className="form-control"
                                        ></input>
                                      </div>
                                    </div>
                                    <div className="col-md-4 col-sm-6">
                                      <label>RFC*</label>
                                      <div className="input-group mb-3">
                                        <input
                                          type="text"
                                          className="form-control"
                                        ></input>
                                      </div>
                                    </div>
                                    <div className="col-md-4 col-sm-6">
                                      <label>CURP*</label>
                                      <div className="input-group mb-3">
                                        <input
                                          type="text"
                                          className="form-control"
                                        ></input>
                                      </div>
                                    </div>
                                    <div className="col-md-4 col-sm-6">
                                      <label>Porcentaje*</label>
                                      <div className="input-group mb-3">
                                        <input
                                          className="form-control"
                                          type="number"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-12 col-sm-12">
                                      <button className="btn btn-primary float-right">
                                        <i className="fas fa-plus"></i>Agregar
                                        Adquiriente
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            </div>

                            <div className="table-responsive">
                              <table className="table">
                                <thead className="thead-dark">
                                  <tr>
                                    <th scope="col">RFC</th>
                                    <th scope="col">Nombre (Completo)</th>
                                    <th scope="col">Porcentaje</th>
                                    <th scope="col">CURP</th>
                                    <th scope="col"></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>ROBC000414MZC</td>
                                    <td>Av. Miguel Alemán</td>
                                    <td>10%</td>
                                    <td>ROB000414MSL</td>
                                    <td>
                                      <button className="btn btn-danger" data-toggle="tooltip" data-placement="top" title="Eliminar">
                                        <i className="fas fa-trash-alt"></i>
                                      </button>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>ROBC000414MZC</td>
                                    <td>Av. Miguel Alemán</td>
                                    <td>10%</td>
                                    <td>ROB000414MSL</td>
                                    <td>
                                      <button className="btn btn-danger" data-toggle="tooltip" data-placement="top" title="Eliminar">
                                        <i className="fas fa-trash-alt"></i>
                                      </button>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>ROBC000414MZC</td>
                                    <td>Av. Miguel Alemán</td>
                                    <td>10%</td>
                                    <td>ROB000414MSL</td>
                                    <td>
                                      <button className="btn btn-danger" data-toggle="tooltip" data-placement="top" title="Eliminar">
                                        <i className="fas fa-trash-alt"></i>
                                      </button>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                      </div>
                </div>
            </div>
        );
    }
}

export default ComplementoNotarioPublico;