import React, { Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Content from './components/Content';
import './components/styles.css';
import AlertaState from './context/alertas/alertaState';
import AuthState from './context/autenticacion/authState';
import FacturaState from './context/facturas/facturaState';
import ProductoState from './context/productos/productoState';
import ClienteState from './context/clientes/clienteState';
import tokenAuth from './config/token';

  // Revisar si tenemos un token
  const token = localStorage.getItem('token');
  if(token) {
    tokenAuth(token);
  }

// class App extends Component {
function App() {  
  
  // render() {
    return (
      <Fragment>
        <AlertaState>
          <AuthState>
          <FacturaState>
          <ProductoState>
          <ClienteState>
        <Content/>
        </ClienteState>
      </ProductoState>
      </FacturaState>
      </AuthState>
      </AlertaState>
      </Fragment>
    );
  }
// }

export default App;
