import React from 'react';

function ModalSerie() {
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-6">
                    <label>Nombre: </label>
                    <div className="form-group">
                        <input className="form-control">
                        </input>
                    </div>
                </div>
                <div className="col-md-6">
                    <label>Serie: </label>
                    <div className="form-group">
                        <input className="form-control" disabled>
                        </input>
                    </div>
                </div>
                <div className="col-md-6">
                    <label>Folio Inicial: </label>
                    <div className="form-group">
                        <input className="form-control">
                        </input>
                    </div>
                </div>
                <div className="col-md-6">
                    <label>Folio Final: </label>
                    <div className="form-group">
                        <input className="form-control">
                        </input>
                    </div>
                </div>
                <div className="col-md-6">
                    <label>Predeteminado: </label>
                        <div className="form-group">
                            <div className="col-sm-12">
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="gridRadios" id="gridRadios1" defaultValue="option1" defaultChecked />
                                <label className="form-check-label" htmlFor="gridRadios1">
                                Si
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name="gridRadios" id="gridRadios2" defaultValue="option2" />
                                <label className="form-check-label" htmlFor="gridRadios2">
                                No
                                </label>
                            </div>
                            </div>
                        </div>
                </div>
                <div className="col-md-6">
                    <label>Tipo: </label>
                        <div className="form-group">
                            <select className="form-control" disabled>
                                <option select>Factura</option>
                                <option>Nota de Crédito</option>
                                <option>Pago</option>
                                <option>Traslado</option>
                            </select>
                        </div>
                </div>
                <div className="col-md-6">
                    <label>Formato: </label>
                        <div className="form-group">
                            <select className="form-control">
                                <option select>Seleccione</option>
                                <option>Genérico 1</option>
                                <option>Genérico 2</option>
                                <option>Genérico 3</option>
                                <option>Genérico 4</option>
                                <option>Básico 1</option>
                                <option>Recibo Honorarios</option>
                                <option>Recibo Donativos</option>
                                <option>Recibo Arrendamiento</option>
                                <option>Recibo Genérico</option>
                                <option>Carta Porte (Traslado)</option>
                                <option>Formato Tipo SAT</option>
                                <option>Formato SAIT v2</option>
                                <option>Moderno</option>
                            </select>
                        </div>
                </div>
                <div className="col-md-6">
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" id="inlineCheckbox1" defaultValue="option1" data-toggle="collapse" href="#RegFiscal"/>
                        <label className="form-check-label" htmlFor="inlineCheckbox1">Definir Régimen Fiscal para esta Serie (opcional)</label>
                    </div>
                    <div className="form-group collapse" id="RegFiscal">
                        <select className="form-control">
                            <option></option>
                            <option>General de Ley Personas Morales - 601</option>
                            <option>Personas Morales con Fines no Lucrativos - 603</option>
                            <option>Consolidación - 609</option>
                            <option>Sociedades Cooperativas de Producción que optan por diferir sus ingresos - 620</option>
                            <option>Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras - 622</option>
                            <option>Opcional para Grupos de Sociedades - 623</option>
                            <option>Coordinados - 624</option>
                            <option>Hidrocarburos - 628</option>
                            <option>Régimen de Enajenación o Adquisición de Bienes - 607</option>
                        </select>
                    </div>
                </div>
                <div className="col-md-12">
                <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" id="inlineCheckbox1" defaultValue="option1" data-toggle="collapse" href="#DomExpedicion"/>
                        <label className="form-check-label" htmlFor="inlineCheckbox1">Domicilio de Expedición Diferente al Fiscal</label>
                </div>
                <div className="form-group collapse" id="DomExpedicion">
                    <span className="text-danger">Solo el Código Postal es obligatorio</span>
                        <div className="row">
                            <div className="col-md-6">
                                <label>Calle:</label>
                                <div className="form-group">
                                    <input className="form-control"></input>
                                </div>
                                </div>
                                <div className="col-md-3">
                                <label>No. Ext:</label>
                                <div className="form-group">
                                    <input className="form-control"></input>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <label>No. Interior:</label>
                                <div className="form-group">
                                    <input className="form-control"></input>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <label>Colonia:</label>
                                <div className="form-group">
                                    <input className="form-control"></input>
                                </div>
                            </div>
                            <div className="col-md-2">
                                <label>Código Postal:</label>
                                <div className="form-group">
                                    <input className="form-control" placeholder="Obligatorio"></input>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <label>Ciudad:</label>
                                <div className="form-group">
                                    <input className="form-control"></input>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <label>Estado:</label>
                                <div className="form-group">
                                    <input className="form-control"></input>
                                </div>
                            </div>
                            <div className="col-md-6">
                            <label>País:</label>
                                <div className="form-group">
                                    <input className="form-control"></input>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" id="inlineCheckbox1" defaultValue="option1"/>
                        <label className="form-check-label" htmlFor="inlineCheckbox1">Activa</label>
                </div>
                </div>
            </div>
        </div>
    );
}

export default ModalSerie;