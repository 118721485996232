import React, { Component } from "react";

class NuevoCliente extends Component {
  render() {
    return (
      <div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="card-title">Registro de nuevo Cliente</h3>
                  <button type="submit" className="btn btn-primary float">
                    <i className="fas fa-save"></i>Guardar
                  </button>
                  <button type="submit" className="btn btn-secondary">
                    <i className="fas fa-arrow-left"></i>Atrás
                  </button>
                </div>
                  <div className="card-body">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                      <li className="nav-item">
                        <a className="nav-link active" id="new-cliente" data-toggle="tab" href="#nuevo-cliente" role="tab" aria-controls="nuevo-cliente" aria-selected="true">Nuevo Cliente</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" id="accound-bank" data-toggle="tab" href="#cuentas-bancarias" role="tab" aria-controls="cuentas-bancarias" aria-selected="false">Cuentas Bancarias</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" id="complements" data-toggle="tab" href="#complementos" role="tab" aria-controls="complementos" aria-selected="false">Complementos</a>
                      </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                      <div className="tab-pane fade show active" id="nuevo-cliente" role="tabpanel" aria-labelledby="new-cliente">
                        <div className="row mt-2">
                        <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="razon_social">
                            Cliente / Nombre / Razón Social*:
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="razon_social"
                            id="razon_social"
                            placeholder="Buscar cliente por Razón Social O RFC"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="rfc">R.F.C.*: </label>
                          <input
                            type="text"
                            className="form-control"
                            name="rfc"
                            id="rfc"
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="residencia_fiscal">
                            Residencia Fiscal(Extranjero):
                          </label>
                          <select className="form-control" name="residencia_fiscal">
                            <option>NO APLICA</option>
                            <option>AFG Afganistan</option>
                            <option>ALB Albania</option>
                            <option>AND Andorra</option>
                            <option>AGO Angola</option>
                            <option>AIA Anguila</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="num_reg_id_trib">
                            Num Reg Id Trib(Extranjero):
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="num_reg_id_trib"
                            name="num_reg_id_trib"
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label htmlFor="uso-cfdi">Uso CFDI*: </label>
                          <select className="form-control" name="uso_cfdi">
                            <option>G01 Adquisición de mercancias</option>
                            <option>
                              G02 Devoluciones, descuentos o bonificaciones
                            </option>
                            <option>G03 Gastos en general</option>
                            <option>I01 Construcciones</option>
                            <option>
                              I02 Mobiliario y equipo de oficina por inversiones
                            </option>
                            <option>I03 Equipo de transporte</option>
                            <option>I04 Equipo de computo y accesorios</option>
                            <option>
                              I05 Dados, troqueles, moldes, matrices y
                              herramental
                            </option>
                            <option>I06 Comunicaciones telefónicas</option>
                            <option>I07 Comunicaciones satelitales</option>
                            <option>I08 Otra maquinaria y equipo</option>
                            <option>
                              D01 Honorarios médicos, dentales y gastos
                              hospitalarios
                            </option>
                            <option>
                              D02 Gastos médicos por incapacidad o discapacidad
                            </option>
                            <option>D03 Gastos funerales</option>
                            <option>D04 Donativos</option>
                            <option>
                              D05 Intereses reales efectivamente pagados por
                              créditos hipotecarios (casa habitaciób)
                            </option>
                            <option>D06 Aportaciones voluntarias al SAR</option>
                            <option>
                              D07 Primas por seguros de gastos médicos
                            </option>
                            <option>
                              D08 Gastos de transportación escolar obligatoria
                            </option>
                            <option>
                              D09 Depósitos en cuentas para el ahorro, primas
                              que tengan combase planes de pensiones
                            </option>
                            <option>
                              D10 Pagos por servicios educativos (colegiaturas)
                            </option>
                            <option>P01 Por definir</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="calle">Calle: </label>
                          <input
                            type="text"
                            className="form-control"
                            name="calle"
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group">
                          <label htmlFor="num_ext">Número Exterior: </label>
                          <input
                            type="text"
                            className="form-control"
                            name="num_ext"
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group">
                          <label htmlFor="num_int">Número Interior: </label>
                          <input
                            type="text"
                            className="form-control"
                            name="num_int"
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="cp">Código Postal: </label>
                          <input
                            type="text"
                            className="form-control"
                            name="cp"
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="colonia">Colonia: </label>
                          <input
                            type="text"
                            className="form-control"
                            name="colonia"
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="localidad">Localidad: </label>
                          <input
                            type="text"
                            className="form-control"
                            name="localidad"
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="referencia">Referencia: </label>
                          <input
                            type="text"
                            className="form-control"
                            name="referencia"
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="delegacion">
                            Delegación/Municipio:
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="delegacion"
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="estado">Estado </label>
                          <input
                            type="text"
                            className="form-control"
                            name="estado"
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="pais">País: </label>
                          <input
                            type="text"
                            className="form-control"
                            name="pais"
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="email">
                            E-mail(separar con comas varios):
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            name="email"
                            placeholder="tucorreo@tudominio.com"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="telefonos">Teléfonos: </label>
                          <input
                            type="text"
                            className="form-control"
                            name="telefonos"
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label htmlFor="forma_pago">Forma de Pago*: </label>
                          <select
                            className="form-control"
                            name="forma_pago"
                            id="forma_pago"
                          >
                            <option>01 Efectivo</option>
                            <option>02 Cheque nominativo</option>
                            <option>
                              03 Transferencia electrónica de fondos
                            </option>
                            <option>04 Tarjeta de crédito</option>
                            <option>05 Monedero electrónico</option>
                            <option>06 Dinero electrónico</option>
                            <option>08 Vales de despensa</option>
                            <option>12 Dación en pago</option>
                            <option>13 Pago por subrogación</option>
                            <option>14 Pago por consignación</option>
                            <option>15 Condonación</option>
                            <option>17 Compensación</option>
                            <option>23 Novación</option>
                            <option>24 Confusión</option>
                            <option>25 Remisión de deuda</option>
                            <option>26 Prescripción o caducidad</option>
                            <option>27 A satisfacción del acreedor</option>
                            <option>28 Tarjeta de débito</option>
                            <option>29 Tarjeta de servicios</option>
                            <option>30 Aplicación de anticipos</option>
                            <option>99 Por Definir</option>
                          </select>
                        </div>
                      </div>
                        </div>

                      </div>

                      <div className="tab-pane fade" id="cuentas-bancarias" role="tabpanel" aria-labelledby="accound-bank">
                          <div className="col-md-12 mt-2">
                          <div className="card">
                            <h2 className="card-header card-titulo">Cuentas Bancarias</h2>
                          
                          <div className="card-body">
                            <div className="row">
                            <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="descripcion">Banco: </label>
                              <input
                                type="text"
                                className="form-control"
                                id="banco_nombre"
                                name="banco_nombre"
                                placeholder="Busque el banco"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="descripcion">RFC: </label>
                              <input
                                type="text"
                                className="form-control"
                                id="banco_rfc"
                                name="banco_rfc"
                                placeholder=""
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label htmlFor="descripcion">Numero Cuenta: </label>
                              <input
                                type="text"
                                className="form-control"
                                id="numero_cuenta_ord"
                                name="numero_cuenta_ord"
                                placeholder="Teclee el número de cuenta de su cliente"
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <span>
                                *Los campos marcados con asterisco son obligatorios
                              </span>
                            </div>
                          </div>
                        </div>
                        </div>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="complementos" role="tabpanel" aria-labelledby="complements">
                        <div className="row mt-2">
                          <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="inlineCheckbox1"
                                defaultValue="option1"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineCheckbox1"
                              >
                                Complemento IEDU
                              </label>
                            </div>
                          </div>
                        
                          <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="inlineCheckbox1"
                                defaultValue="option1"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineCheckbox1"
                              >
                                Complemento Venta de Vehículo
                              </label>
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="inlineCheckbox1"
                                defaultValue="option1"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineCheckbox1"
                              >
                                Complemento Terceros
                              </label>
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="inlineCheckbox1"
                                defaultValue="option1"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineCheckbox1"
                              >
                                Complemento Acreditamiento del IEPS
                              </label>
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="inlineCheckbox1"
                                defaultValue="option1"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineCheckbox1"
                              >
                                Complemento Aerolineas
                              </label>
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="inlineCheckbox1"
                                defaultValue="option1"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineCheckbox1"
                              >
                                Complemento Comercio Exterior
                              </label>
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="inlineCheckbox1"
                                defaultValue="option1"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineCheckbox1"
                              >
                                Complemento Detallista
                              </label>
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="inlineCheckbox1"
                                defaultValue="option1"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineCheckbox1"
                              >
                                Complemento Divisa
                              </label>
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="inlineCheckbox1"
                                defaultValue="option1"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineCheckbox1"
                              >
                                Complemento Donatarias
                              </label>
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="inlineCheckbox1"
                                defaultValue="option1"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineCheckbox1"
                              >
                                Complemento INE
                              </label>
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="inlineCheckbox1"
                                defaultValue="option1"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineCheckbox1"
                              >
                                Complemento Leyendas
                              </label>
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="inlineCheckbox1"
                                defaultValue="option1"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineCheckbox1"
                              >
                                Complemento Notarios Publicos
                              </label>
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-4 col-xs-12">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="inlineCheckbox1"
                                defaultValue="option1"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineCheckbox1"
                              >
                                Complemento Servicios Parciales Construcción
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                     
                      
                      
                  </div>
                <div className="card-footer">
                      <div className="float-right">
                      <button type="submit" className="btn btn-primary">
                        <i className="fas fa-save"></i>Guardar
                      </button>
                      </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NuevoCliente;
