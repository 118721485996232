import React, { Component } from 'react';

class ComplementoIne extends Component {
    render() {
        return (
            <div className="container-fluid">
                    <div className="col-md-12">
                              <h4>Complemento INE</h4>
                              <hr></hr>
                                <div className="row">
                                  <div className="col-md-4 col-sm-6 col-xs-6">
                                    <div className="form-group">
                                      <label>Tipo de Proceso*:</label>
                                      <input
                                        type="email"
                                        className="form-control"
                                        id="inputEmail4"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-4 col-sm-6">
                                    <div className="form-group">
                                      <label>Tipo de Comité*:</label>
                                      <input
                                        type="email"
                                        className="form-control"
                                        id="inputEmail4"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-4 col-sm-12">
                                    <div className="form-group">
                                      <label>ID Contabilidad*:</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                      />
                                    </div>
                                  </div>
                                </div>
                      </div>
            </div>
        );
    }
}

export default ComplementoIne;