import React from 'react';
import { Link } from 'react-router-dom'

function Soporte() {
    return (
        <div className="container-fluid">
                <div className="row">
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                            <div className="card card-soporte">
                            <h4 className="text-bold">Consigue ayuda ahora</h4>
                                <div className="card-body">
                                    <p className="card-text">Chatea con uno de nuestros agentes en tiempo real.</p>
                                </div>
                            <div className="card-footer">
                                    <button type="button" className="btn btn-primary"><i className="fas fa-comments"></i>Chat</button>
                                </div>
                        </div>
                    </div>
                
                
                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                        <div className="card card-soporte">
                        <h4 className="text-bold">Si aún tienes dudas</h4>
                            <div className="card-body">
                                    <p className="card-text">Checa nuestras preguntas frecuentes</p>
                            </div>
                            <div className="card-footer">
                                <Link to="preguntas-frecuentes" type="button" className="btn btn-primary"><i className="far fa-eye"></i>Ver más</Link>
                            </div>
                    </div>
                </div>
                    
                 
                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                        <div className="card card-soporte">
                        <h4 className="text-bold">Video manuales</h4>
                            <div className="card-body">
                                    <p className="card-text">Contamos con video manuales de nuestro sistema para facilitarte su uso</p>
                            </div>
                            <div className="card-footer">
                                <button type="button" className="btn btn-primary"><i className="fas fa-mouse-pointer"></i>Checalos Aquí</button>
                            </div>
                    </div>
                </div>
                    
                
                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                        <div className="card card-soporte">
                            <h4 className="text-bold">Horario de atención al cliente</h4>
                            <div className="card-body">
                            <p className="card-text"><strong>Lunes a Viernes</strong> 9:00 am a 6:00 pm Hora del Pacífico<br></br>
                            <strong>Sábados</strong> 9:00 am a 1:00 pm Hora del Pacífico</p>
                        </div>
                    </div>
                </div>

                
                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                        <div className="card card-soporte">
                        <h4 className="text-bold">Contáctanos</h4>
                            <div className="card-body">
                                        <p className="card-text"><strong>Teléfonos</strong><br></br>
                                        <i className="fas fa-phone"></i>DF: (55) 4163 8997<br></br>
                                        <i className="fas fa-phone"></i>MZT: (669) 9853733 - (669) 2008625<br></br>
                                        <i className="fab fa-whatsapp"></i>+52 6692 552 4999</p>
                                </div>
                        </div>
                    </div>

                
                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                            <div className="card card-soporte">
                            <h4 className="text-bold">Ticket de Soporte</h4>
                            <div className="card-body">
                               <p className="card-text">¿Presentas dudas en nuestro sistema de facuración? Escribemos un ticket</p>
                            </div>
                            <div className="card-footer">
                                <Link to="ticket-soporte" type="button" className="btn btn-primary"><i className="fas fa-arrow-right"></i>Entrar</Link>
                            </div>
                        </div>
                    </div>
                </div>    
        </div>
    );
}

export default Soporte;