import React from 'react';

function DisenoFactura() {
    return (
        <div className="container-fluid">
            <div className="d-factura">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <h3 className="card-header card-title">Diseño de Factura</h3>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6">
                                            <div className="form-group">
                                            <label>Encabezado:</label>
                                                <div className="input-group cabecera">
                                                    <input type="text" className="form-control" placeholder="Seleccionar Color"/>
                                                    <div className="input-group-append">
                                                        <span className="input-group-text"><i className="fas fa-square" /></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                            <label>Texto Encabezado:</label>
                                                <div className="input-group texto-cabecera">
                                                    <input type="text" className="form-control" placeholder="Seleccionar Color"/>
                                                    <div className="input-group-append">
                                                        <span className="input-group-text"><i className="fas fa-square" /></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                            <label>Texto Interior:</label>
                                                <div className="input-group texto-interior">
                                                    <input type="text" className="form-control" placeholder="Seleccionar Color"/>
                                                    <div className="input-group-append">
                                                        <span className="input-group-text"><i className="fas fa-square" /></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>Tamaño letra encabezado</label>
                                                        <select className="form-control">
                                                            <option>10</option>
                                                            <option>11</option>
                                                            <option>12</option>
                                                            <option>13</option>
                                                            <option>14</option>
                                                            <option>15</option>
                                                            <option>16</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>Tamaño letra texto interior</label>
                                                        <select className="form-control">
                                                            <option>10</option>
                                                            <option>11</option>
                                                            <option>12</option>
                                                            <option>13</option>
                                                            <option>14</option>
                                                            <option>15</option>
                                                            <option>16</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="exampleFormControlFile1">Subir logo:</label>
                                                <input type="file" className="form-control-file" id="exampleFormControlFile1" />
                                            </div>
                                            <div className="logotipos">
                                                <iframe src="dist/img/logo3.png"></iframe>
                                                <button className="btn btn-danger"><i className="fa fas-times"></i>Eliminar</button>
                                            </div>
                                    </div>

                                <div className="col-md-6">
                                    <div className="card">
                                        <h5 className="card-header card-titulo">Vista Previa</h5>
                                        <div className="card-body">
                                            <iframe src="3AD3FDDA-174C-4BBB-B324-2CDF90602F09.pdf"></iframe>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="card-footer">
                            <div className="float-left">
                                <button type="button" className="btn btn-primary"><i className="fas fa-save"></i>Guardar</button>
                                <button type="button" className="btn btn-outline-dark ml-2"><i className="fas fa-eye"></i>Previsualizar</button>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DisenoFactura;