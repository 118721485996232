import React, { useReducer, useState } from 'react';
import AuthContext from './authContext';
import AuthReducer from './authReducer';

import clienteAxios from '../../config/axios';
import tokenAuth from '../../config/token';

import { 
    REGISTRO_EXITOSO,
    REGISTRO_ERROR,
    OBTENER_USUARIO,
    LOGIN_EXITOSO,
    LOGIN_ERROR,
    // LOGOUT_ERROR,
    CERRAR_SESION
} from '../../types';

const AuthState = props => {


    const initialState = {
        token: localStorage.getItem('token'),
        autenticado: false,
        usuario: null, 
        mensaje: null, 
        cargando: true
    }

    const [ state, dispatch ] = useReducer(AuthReducer, initialState);


    // Cuando el usuario inicia sesión
    const iniciarSesion = async datos => {
        try {
            const respuesta = await clienteAxios.post('/token', datos);
            // return;
            // console.log(respuesta);
            dispatch({
                type: LOGIN_EXITOSO,
                payload: respuesta.data
            });
            // Obtener el usuario
            usuarioAutenticado();
        } catch (error) {
            // console.log(error.response.data.msg);
            const alerta = {
                // msg: error.response.data.msg,
                msg: 'error',
                categoria: 'alerta-error'
            }

            dispatch({
                type: LOGIN_ERROR,
                payload: alerta
            })
        }
    }

    const usuarioAutenticado = async () => {
        const token = localStorage.getItem('token');
        if(token) {
            tokenAuth(token);
        }

        try {
            const respuesta = await clienteAxios.get('/v3/sucursal-info');
            console.log('la sucursal es '+respuesta.data);
            dispatch({
                type: OBTENER_USUARIO,
                payload: respuesta.data
            });

        } catch (error) {
            // console.log(error.response);
            dispatch({
                type: LOGIN_ERROR
            })
        }
    }

    // Cierra la sesión del usuario
    const cerrarSesion = async () => {
        try {
            const respuesta = await clienteAxios.post('/v3/log-out-all');
            console.log('Respuesta api logout');
            console.log(respuesta);
           
        } catch (error){
            console.log(error.response);
            // dispatch({
            //     type: LOGOUT_ERROR
            // })
        }
        dispatch({
            type: CERRAR_SESION
        })
        
        
    }

    return(
        <AuthContext.Provider
            value={{
                token: state.token,
                autenticado: state.autenticado,
                usuario: state.usuario,
                mensaje: state.mensaje,
                cargando: state.cargando,
                iniciarSesion,
                usuarioAutenticado,
                cerrarSesion
            }}
        >{props.children}

        </AuthContext.Provider>
    )
}
export default AuthState;