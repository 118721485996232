import React, { useState, useContext, useEffect, Fragment } from 'react';
import AgregarCorreos from './AgregarCorreos';
import facturaContext from '../context/facturas/facturaContext';
// import AlertaContext from '../context/alertas/alertaContext';
import AuthContext from '../context/autenticacion/authContext';

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import es from 'date-fns/locale/es';

import Factura from './facturas/Factura';
import Paginacion from './facturas/Paginacion';

import clienteAxios from '../config/axios';

// import { Button } from 'react-bootstrap';

// function Cfdi () {
const Cfdi = () => {

    const authContext = useContext(AuthContext);
    const { usuario  } = authContext;

    const facturasContext = useContext(facturaContext);
    // const { cargando_facturas, mensaje, facturas, paginacion, obtenerFacturas, pagina, setPagina } = facturasContext;
    const { cargando_facturas, mensaje, facturas, paginacion, obtenerFacturas, actualizarEnviada } = facturasContext;

    // const alertaContext = useContext(AlertaContext);
    // const { alerta, mostrarAlerta } = alertaContext;

    const [parametros, setParametros] = useState({
        // pagina: 1,
        desde: '',
        hasta: '',
        serie: '',
        folio: '',
        estado: '',
        rfc: '',
        razon_social: ''
    });
    
    const [enviando, setEnviado] = useState(1);

    const [pagina, setPagina] = useState(1);

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    registerLocale('es', es);

    // Modal enviar email
    const [correos, setCorreos] = useState();
    const [idFactura, setIdFactura] = useState();
    // const [asunto, setAsunto] = useState(usuario?.sucursal_email_asunto);
    // const [emailMensaje, setEmailMensaje] = useState(usuario?.sucursal_email_mensaje);
    const [paramFormulario, setParamFormulario] = useState({
        // emails: correos,
        emailAsunto: usuario?.sucursal_email_asunto,
        emailMensaje1: usuario?.sucursal_email_mensaje,
    });

    // Obtener facturas cuando carga el componente
    useEffect(() => {
        // si hay un error
        // if(mensaje) {
        //     mostrarAlerta(mensaje.msg, mensaje.categoria);
        // }
        // console.log(startDate);
        obtenerFacturas(pagina, parametros);
        // setAsunto(usuario.sucursal_email_asunto);
        // setEmailMensaje(usuario.sucursal_email_mensaje);
        // eslint-disable-next-line
    }, []);
    // }, [startDate]);

    // función que coloca los elementos en el state
    const handleChange = e => {
        // actualizar el state
        setParametros({
            ...parametros,
            [e.target.name] : e.target.value
        });
    }

    const handleChangeIni = (date) => {
        setStartDate(date)
        // actualizar el state
        // let fechaIni = date.getDate()+'/'+("0" + (date.getMonth() + 1)).slice(-2)+'/'+date.getFullYear();
        let fechaIni = ("0" + date.getDate()).slice(-2)+'/'+("0" + (date.getMonth() + 1)).slice(-2)+'/'+date.getFullYear();
        setParametros({
            ...parametros,
            desde: fechaIni,
        });

        console.log('FECHA INI '+ fechaIni);
        // console.log('FECHA FIN '+endDate);
        
    }

    const handleChangeFin = (date) => {
        setEndDate(date)
        // actualizar el state
        let fechaFin = ("0" + date.getDate()).slice(-2)+'/'+("0" + (date.getMonth() + 1)).slice(-2)+'/'+date.getFullYear();
        setParametros({
            ...parametros,
            hasta: fechaFin,
        });

        console.log('FECHA FIN '+fechaFin);
        // console.log('FECHA FIN '+endDate);
        
    }

    

    const handleChangeFormSendEmail = e => {
        // actualizar el state
        // if(e.target.value.length <= 0){
        //     return;
            
        // }
        setParamFormulario({
            ...paramFormulario,
            [e.target.name] : e.target.value
        });
        console.log(paramFormulario);
    }

    const handleChangeFormSendEmailCorreos = e => {
        // actualizar el state
        setCorreos(e.target.value);
        
    }
    
   
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
          console.log('Oprimio tecla enter para buscar');
          handleSubmit(e);
        }
    }

    const handleSubmit = e => {
        e.preventDefault();
       
        setPagina(1);
        // pagina = 1;
        setParametros({
            ...parametros,
            desde: startDate,
            hasta: endDate
        });

        console.log(parametros )
        console.log('LA PAGINA QUE SE BUSCARA ES ' + parametros.pagina )
        //obtenerFacturas(1, parametros.desde, parametros.hasta, parametros.serie, parametros.folio, parametros.estado, parametros.rfc, parametros.razon_social);
        obtenerFacturas(pagina, parametros);
    }

    // Cambia en icono del sobre a color verde
    const cambiarEnviada = id => {
        const nuevasFacturas = facturas.filter(factura => factura.factura_identificador !== id );
        console.log("LISTADO DE FACTURAS ");
        console.log(nuevasFacturas);
        const facturaEncontrada = facturas.find(factura => factura.factura_identificador == id );
        // if(facturaEncontrada.length > 0){
        //     nuevasFacturas.push(facturaEncontrada);
        //     console.log("NUEVO ARRAY CON ELEMENTOS ");
        //     console.log(nuevasFacturas);
        // }else{
        //     console.log("No se encontro factura");
        // }
        // console.log("FACTURA ENCONTRADA ");
        // console.log(facturaEncontrada);
        facturaEncontrada.factura_enviada = 1;
        nuevasFacturas.push(facturaEncontrada);
        console.log("NUEVO ARRAY CON ELEMENTOS ");
        
        console.log(nuevasFacturas);
        actualizarEnviada(nuevasFacturas)
        // guardarCitas(nuevasCitas);
    }

    async function enviarFactura(){
        setEnviado(2);
        console.log('Se enviara a los correo '+correos);
        console.log('Asunto '+emailAsunto);
        console.log('Mensaje '+emailMensaje1);
        try {
            const resultado = await clienteAxios.post(`/v3/facturas-reenviar/${idFactura}`,  {
                factura_emails: correos,
                factura_email_cuerpo: emailMensaje1,
                factura_email_asunto: emailAsunto
            });
            console.log(resultado.data);
            setEnviado(3);
            cambiarEnviada(idFactura);
  
        } catch (error) { 
            setEnviado(4);
            console.log('Hubo un error '+error);
        }


    }
    console.log(facturas);
    // const facturas2 = facturas.data;
    // const paginacion = facturas.meta;
    // console.log(facturas2)
    console.log(paginacion)
    // paginacion.map(pagina => (
    //     console.log(pagina)
    // ))
    // const paginas;
    // var paginas = '';
    // for (var i = 1; i < paginacion.total_pages; i++ ){
    //     // var paginas = paginas + 'uno' + i + ' ';   
    //     var paginas =  `${paginas} <li className="paginate_button page-item previous disabled" id="example1_previous">
    //         <a href="#" aria-controls="example1" data-dt-idx="0" tabindex="0" className="page-link"> <i className="fas fa-chevron-left"></i> </a></li><li className="paginate_button page-item active"><a href="#" aria-controls="example1" data-dt-idx="${i}" tabindex="0" className="page-link">${i}</a>
    //     </li>`                                        
    // }

    // <li className="paginate_button page-item previous disabled" id="example1_previous">
    //     <a href="#" aria-controls="example1" data-dt-idx="0" tabindex="0" className="page-link"> <i className="fas fa-chevron-left"></i> </a></li><li className="paginate_button page-item active"><a href="#" aria-controls="example1" data-dt-idx="1" tabindex="0" className="page-link">1</a>
    // </li>
    // <li className="paginate_button page-item ">
    //     <a href="#" aria-controls="example1" data-dt-idx="2" tabindex="0" className="page-link">2</a>
    // </li>
        const {emailAsunto, emailMensaje1} = paramFormulario;

        let formEnviar = (enviando === 1) ?
             (
                <Fragment>
                <div className="form-group">
                                            <label>Correo</label>
                                            <input 
                                                type="email" 
                                                className="form-control"
                                                name="emails"
                                                value={correos}
                                                onChange={handleChangeFormSendEmailCorreos}
                                            ></input>
                                        </div>
                                        <div className="form-group">
                                            <label>Asunto</label>
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                name="emailAsunto"
                                                value={emailAsunto}
                                                onChange={handleChangeFormSendEmail}
                                            ></input>
                                        </div>
                                        <div className="form-group">
                                            <label>Mensaje</label>
                                            <textarea 
                                                className="form-control"
                                                name="emailMensaje1"
                                                value={emailMensaje1}
                                                onChange={handleChangeFormSendEmail}
                                            >
                                            </textarea>
                                        </div>
                                        </Fragment>
            )
        : (enviando === 2) ?
             'Enviando factura por favor espere'
        : (enviando === 3) ?
            `La factura fue enviada a los correo ${correos}`
        :  'No se pudo enviar la factura por correo'
        

        // let formEnviar = (enviando === false) ? (
        //     <Fragment>
        //     <div className="form-group">
        //                                 <label>Correo</label>
        //                                 <input 
        //                                     type="email" 
        //                                     className="form-control"
        //                                     name="emails"
        //                                     value={correos}
        //                                     onChange={handleChangeFormSendEmailCorreos}
        //                                 ></input>
        //                             </div>
        //                             <div className="form-group">
        //                                 <label>Asunto</label>
        //                                 <input 
        //                                     type="text" 
        //                                     className="form-control" 
        //                                     name="emailAsunto"
        //                                     value={emailAsunto}
        //                                     onChange={handleChangeFormSendEmail}
        //                                 ></input>
        //                             </div>
        //                             <div className="form-group">
        //                                 <label>Mensaje</label>
        //                                 <textarea 
        //                                     className="form-control"
        //                                     name="emailMensaje1"
        //                                     value={emailMensaje1}
        //                                     onChange={handleChangeFormSendEmail}
        //                                 >
        //                                 </textarea>
        //                             </div>
        //                             </Fragment>
        // )
        //  : 'Enviando';


        return (
           <div>
                 <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">Consulta de Facturas 3.3</h3>
                                <div className="float-right">
                                <button to="importar-productos" data-toggle="modal" data-target="#importar-factura" type="button" className="btn btn-primary ml-1"><i className="fas fa-file-import"></i>Importar</button>
                                </div>
                            </div>
                            <div className="card-body">
                            <div className="row">
                                <div className="col-md-3 col-sm-3">
                                    <label>Desde:</label>
                                    <div className="form-group">
                                            <DatePicker
                                            selected={startDate}
                                            // onChange={date => setStartDate(date)}
                                            onChange={date => handleChangeIni(date)}
                                            selectsStart
                                            className="form-control"
                                            locale="es"
                                            // maxDate={new Date()}
                                            dateFormat="dd/MM/yyyy"
                                            name="desde"
                                            startDate={startDate}
                                            endDate={endDate}
                                            />
                                    </div>
                                </div>
                            <div className="col-md-3 col-sm-3">
                                <label>Hasta:</label>
                                <div className="form-group">
                                    <DatePicker
                                        selected={endDate}
                                        onChange={date => handleChangeFin(date)}
                                        selectsEnd
                                        className="form-control"
                                        locale="es"
                                        name="hasta"
                                        dateFormat="dd/MM/yyyy"
                                        startDate={startDate}
                                        endDate={endDate}
                                        minDate={startDate}
                                        />
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-3">
                                <label>Razon Social</label>
                                <div className="form-group">
                                    <input type="text" 
                                        className="form-control" 
                                        name="razon_social" 
                                        onKeyDown={handleKeyDown}
                                        onChange={handleChange} 
                                    />
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-3">
                                <label>RFC</label>
                                <div className="form-group">
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        name="rfc" 
                                        onKeyDown={handleKeyDown}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-3">
                                <label>Folio</label>
                                <div className="form-group">
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        name="folio"
                                        onKeyDown={handleKeyDown}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-3">
                                <label>Serie</label>
                                <div className="form-group">
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        name="serie"
                                        onKeyDown={handleKeyDown}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                           
                            <div className="col-md-3 col-sm-3">
                                <label>Estatus:</label>
                                <div className="form-group">
                                    <select 
                                        className="form-control" 
                                        name="estado" 
                                        onChange={handleChange} 
                                    >
                                        <option value="">Todas</option>
                                        <option value="sin-timbrar">Sin timbrar</option>
                                        <option value="pagadas">Pagadas</option>
                                        <option value="canceladas">Canceladas</option>
                                        <option value="timbradas">Timbrado sin cancelar</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-3 mt-4">
                                <button 
                                    type="button" 
                                    className="btn btn-primary"
                                    onClick={handleSubmit}
                                ><i className="fa fa-search"></i>Buscar</button>
                            </div>
                            </div>
                            {/* fin filtro busqueda*/}
                                <div className="table-responsive">
                                <table id="example1" className="table table-bordered table-striped">
                                <thead>
                                    <tr>
                                    <th></th>
                                    <th>Folio</th>
                                    <th>Fecha</th>
                                    <th>Cliente</th>
                                    <th>Bruto</th>
                                    <th>I.V.A</th>
                                    <th>Desc.</th>
                                    <th>Total</th>
                                    <th>Pagado</th>
                                    <th>Saldo</th>
                                    <th>Notas</th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    
                                    {/* { cargando_facturas ? ( <tr><td>CARGANDO...........</td></tr>  ) : ( <tr><td>YA SE CARGO</td></tr>  )} */}
                                    {
                                        (!facturas?.length) ? <label>NO SE ENCONTRARON RESULTADOS......</label> :
                                        facturas.map(factura => (    
                                            <Factura 
                                                key={factura.factura_identificador}
                                                factura={factura}
                                                correos={correos}
                                                setCorreos={setCorreos}
                                                setIdFactura={setIdFactura}
                                                email_asunto={usuario?.sucursal_email_asunto}
                                                email_mensaje={usuario?.sucursal_email_mensaje}
                                                setParamFormulario={setParamFormulario}
                                                setEnviado={setEnviado}
                                                
                                            />
                                        ))
                                    }
                                </tbody>
                                </table>
                               
                                
                                </div>
                            </div>
                            <div className="float-right">
                                <div className="dataTables_paginate paging_simple_numbers" id="example1_paginate">
                                        <Paginacion    
                                            parametros={parametros}
                                            setParametros={setParametros}
                                            pagina={pagina}
                                            key={`paginacion-${paginacion.current_page}`}
                                            
                                        />
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                {/* inicia modal enviar correo */}
                <div className="modal fade addcorreos" tabIndex={-1} role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalCenterTitle">Enviar Correo</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    {formEnviar}
                                    {/* <div className="form-group">
                                        <label>Correo</label>
                                        <input 
                                            type="email" 
                                            className="form-control"
                                            name="emails"
                                            value={correos}
                                            onChange={handleChangeFormSendEmailCorreos}
                                        ></input>
                                    </div>
                                    <div className="form-group">
                                        <label>Asunto</label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            name="emailAsunto"
                                            value={emailAsunto}
                                            onChange={handleChangeFormSendEmail}
                                        ></input>
                                    </div>
                                    <div className="form-group">
                                        <label>Mensaje</label>
                                        <textarea 
                                            className="form-control"
                                            name="emailMensaje1"
                                            value={emailMensaje1}
                                            onChange={handleChangeFormSendEmail}
                                        >
                                        </textarea>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                            {/* <AgregarCorreos 
                                correos={correos}
                                asunto={asunto}
                                emailMensaje={emailMensaje}
                            /> */}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" data-dismiss="modal"><i className="fas fa-times"></i>Cancelar</button>
                            <button onClick={enviarFactura} type="button" className="btn btn-primary"><i className="fas fa-envelope"></i>Enviar</button>
                        </div>
                    </div>
                </div>
                </div>
                {/* fin modal enviar correo */}


          

                    <div className="modal fade" id="importar-factura" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="importar-factura">Importar Factura</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                        <div className="form-group">
                            <input type="file" className="form-control-file" id="exampleFormControlFile1" />
                        </div>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" data-dismiss="modal"><i className="fas fa-times"></i>Cancelar</button>
                            <button type="button" className="btn btn-primary"><i className="fas fa-file-import"></i>Importar</button>
                        </div>
                        </div>
                    </div>
                    </div>
              </div>
                



            </div>

        );
    }

export default Cfdi;