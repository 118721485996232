import React from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const optionsClientes = [
    { value: 'rfc', label: 'RFC (Requerido)' },
    { value: 'razon_social', label: 'Nombre / Razón Social (Requerido)' },
    { value: 'nombre_comercial', label: 'Nombre Comercial' },
    { value: 'telefono', label: 'Teléfono' },
    { value: 'email', label: 'Correo Electrónico' },
    { value: 'calle', label: 'Calle' },
    { value: 'no_ext', label: 'No. Ext' },
    { value: 'no_int', label: 'No. Int' },
    { value: 'colonia', label: 'Colonia' },
    { value: 'municipio', label: 'Municipio' },
    { value: 'estado', label: 'Estado' }
  ];

function ImportarClientes() {
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <h3 className="card-header">Importar clientes
                        <button type="button" className="btn btn-primary">Probar importación</button></h3>
                            <div className="card-body">
                            <div class="alert alert-danger" role="alert">
                            No se han encontrado registros coincidentes para nombre 'EJEMPLO' en el campo 'Compañia' en la fila 2
                            </div>
                            <div className="card card-titulo">
                                    <p><strong>Instrucciones:</strong><br></br>
                                        1. Seleccione y suba archivo Excel (*.xls,*.xlsx,*.csv)<br></br>
                                        2. Asocie la columna del catálogo de clientes con la columna del documento a importar<br></br>
                                        3. Hacer click en "Importar Excel"</p>
                                </div>
                                <div className="form-group">
                                <span className="badge badge-primary mr-1">1</span>
                                    <label>Seleccione Archivo a Importar:</label>
                                    <input type="file" className="form-control-file" id="exampleFormControlFile1" />
                                </div>
                                <span className="badge badge-primary mr-1">2</span>
                                <label>Asociar columnas:</label>
                                   <div className="col-md-12">
                                   <table className="table-bordered table-responsive">
                                        <thead>
                                            <tr>
                                                <th>RFC (Requerido)</th>
                                                <th>Nombre / Razón Social (Requerido)</th>
                                                <th>Nombre Comercial</th>
                                                <th>Teléfono</th>
                                                <th>Correo Electrónico</th>
                                                <th>Calle</th>
                                                <th>No. Ext</th>
                                                <th>No. Int</th>
                                                <th>Colonia</th>
                                                <th>Municipio</th>
                                                <th>Estado</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                <Select 
                                                options={optionsClientes} 
                                                isSearchable
                                                autoFocus
                                                />
                                                </td>
                                                <td>
                                                    <Select 
                                                options={optionsClientes} 
                                                isSearchable
                                                autoFocus
                                                />
                                                </td>
                                                <td>
                                                    <Select 
                                                options={optionsClientes} 
                                                isSearchable
                                                autoFocus
                                                />
                                                </td>
                                                <td>
                                                    <Select 
                                                options={optionsClientes} 
                                                isSearchable
                                                autoFocus
                                                />
                                                </td>
                                                <td>
                                                    <Select 
                                                options={optionsClientes} 
                                                isSearchable
                                                autoFocus
                                                />
                                                </td>
                                                <td>
                                                    <Select 
                                                options={optionsClientes} 
                                                isSearchable
                                                autoFocus
                                                />
                                                </td>
                                                <td>
                                                    <Select 
                                                options={optionsClientes} 
                                                isSearchable
                                                autoFocus
                                                />
                                                </td>
                                                <td>
                                                    <Select 
                                                options={optionsClientes} 
                                                isSearchable
                                                autoFocus
                                                />
                                                </td>
                                                <td>
                                                    <Select 
                                                options={optionsClientes} 
                                                isSearchable
                                                autoFocus
                                                />
                                                </td>
                                                <td>
                                                    <Select 
                                                options={optionsClientes} 
                                                isSearchable
                                                autoFocus
                                                />
                                                </td>
                                                <td>
                                                    <Select 
                                                options={optionsClientes} 
                                                isSearchable
                                                autoFocus
                                                />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>XEXX010101000</td>
                                                <td>EMPRESA DE TRANSPORTES SA DE CV</td>
                                                <td>EMPRESA DE TRANSPORTES SA DE CV</td>
                                                <td>9865757</td>
                                                <td>EJEMPLO@EJEMPLO.COM</td>
                                                <td>AV. VENUSTIANO CARRANZA</td>
                                                <td>342</td>
                                                <td></td>
                                                <td>CENTRO</td>
                                                <td>VERACRUZ</td>
                                                <td>VERACRUZ</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                   </div>
                            </div>
                            <div className="card-footer">
                                <button type="button" className="btn btn-primary"><i className="fas fa-share"></i>Importar</button>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ImportarClientes;