import React from 'react';

function PreguntasFrecuentes() {
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">Preguntas Frecuentes</h3>
                        </div>
                        <div className="card-body">
                        <div className="accordion" id="accordionExample">
                            <div className="card">
                                <div className="card-header card-titulo" id="headingOne">
                                <h2 className="mb-0">
                                    <button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    ¿Cómo cancelo un CFDI?
                                    </button>
                                </h2>
                                </div>

                                <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                <div className="card-body">
                                Al ingresar al sistema, Dé clic al apartado de <strong>Facturación</strong>, después dé clic en <strong>Consultar Facturas</strong>. Se mostrará los comprobantes que ha emitido, buscamos el comprobante que queramos cancelar y dé clic en el <strong>ícono del bote de basura</strong>. Con esto quedará cancelado con nosotros y ante el SAT.
                                </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header card-titulo" id="headingTwo">
                                <h2 className="mb-0">
                                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    ¿Cómo genero un comprobante de Recepción de Pagos?
                                    </button>
                                </h2>
                                </div>
                                <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                <div className="card-body">
                                Al ingresar al sistema, nos vamos al apartado de Factura de clic en <strong>CFDI de pagos</strong>.  
                                </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header card-titulo" id="headingThree">
                                <h2 className="mb-0">
                                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    ¿Cómo cargar logo?
                                    </button>
                                </h2>
                                </div>
                                <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                <div className="card-body">
                                Al ingresar al sistema, nos vamos al apartado de <strong>Configuración </strong>y dé clic en <strong>Editar Factura</strong>, podrá agregar su logo incluso modificar su factura.  
                                </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header card-titulo" id="headingFour">
                                <h2 className="mb-0">
                                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    ¿Cómo instalo el Certificado de Sello Digital (CSD)? 
                                    </button>
                                </h2>
                                </div>
                                <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                                <div className="card-body">
                                Al ingresar al sistema, en el apartado <strong>Configuración</strong> dé clic en <strong>Certificado</strong>. Dé clic en el botón <strong>Agregar</strong>, se mostrará una ventana con los campos correspondientes para cargar el CSD, dé clic en <strong>Subir archivo del apartado Archivo de certificado digital (.cer)</strong> y busque el archivo con extensión <strong>“.cer”</strong>. Después, dé clic en <strong>Subir archivo del apartado Archivo de clave privada (.key)</strong> y busque el archivo con extensión <strong>“.key”</strong>. Por último, escribe la contraseña de la clave privada, misma que generó el CSD en la aplicación Certifica del SAT. Dé clic en <strong>Agregar</strong> y con esto se habrá cargado correctamente los archivos.
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PreguntasFrecuentes;