// import React, { useContext, useEffect } from 'react';
import React from 'react';

import { Route, Redirect } from 'react-router-dom';
// import AuthContext from '../../context/autenticacion/authContext';

const RutaPrivada = ({ autenticado, cargando, component: Component, ...props  }) => {

    // const authContext = useContext(AuthContext);
    // const { autenticado, cargando, usuarioAutenticado } = authContext;
    console.log('AUTENTICADO');
    console.log(autenticado);

    return (
        // <Route { ...props } render={ props => !autenticado && !cargando ?  (
        <Route { ...props } render={ props => !autenticado ?  (    
            <Redirect to="/" />
        )  : (
            <Component {...props} />
        ) } />

     );
}
 
export default RutaPrivada;

