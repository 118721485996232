import React, { Component } from 'react';

class RecuperarContrasena extends Component {
    render() {
        return (
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                        <div className="card card-primary">
                        <div className="card-header">
                            <h3 className="card-title">Registro de CFDI 3.3</h3>
                            <button type="submit" className="btn btn-primary float">Siguiente</button>
                        </div>
                            <div className="card-body">
                                <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="usuario">Usuario: </label>
                                        <input type="text" className="form-control" id="usuario" placeholder="Usuario" />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="rfc">R.F.C.: </label>
                                        <input type="text" className="form-control" id="rfc" placeholder="" />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="clave">E-mail: </label>
                                        <input type="text" className="form-control" id="email" placeholder="ejemplo@dominio.com" />
                                    </div>
                                </div>
                            
                            </div>
                           
                            <div className="card-footer">
                            <button type="submit" className="btn btn-primary">Enviar Contraseña</button>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
        );
    }
}

export default RecuperarContrasena;