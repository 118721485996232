import React, { useReducer, useState, useContext } from 'react';

import clienteContext from './clienteContext';
import clienteReducer from './clienteReducer';
import AuthContext from '../autenticacion/authContext';

import { 
    FORMULARIO_CLIENTE, 
    OBTENER_CLIENTES,
    AGREGAR_CLIENTE,
    CLIENTE_ERROR,
    VALIDAR_FORMULARIO_CLIENTE,
    CLIENTE_ACTUAL,
    ELIMINAR_CLIENTE,
    ACTUALIZAR_ENVIADA
} from '../../types';

import clienteAxios from '../../config/axios';


const ClienteState = props => {

    const authContext = useContext(AuthContext);
    const { cerrarSesion } = authContext;
    
    // const [pagina, setPagina] = useState(1);
    const [buscar, setBuscar] = useState("");

    const initialState = {
        clientes : [],
        paginacion : [],
        formulario : false,
        errorformulario: false,
        proyecto: null, 
        mensaje: null,
        cargando_clientes: true,
    }

    // Dispatch para ejecutar las acciones
    const [state, dispatch] = useReducer(clienteReducer, initialState)

    // Serie de funciones para el CRUD
    const mostrarFormulario = () => {
        dispatch({
            type: FORMULARIO_CLIENTE
        })
    }

   
    async function obtenerClientes (pagina, buscar) {
        
        //console.log('ESTOS SON LOS PARAMETROS '+folio);
        try {
            //const resultado = await clienteAxios.get(`/v3/facturas?page=${pagina}`,{params: {parametros}});
            const resultado = await clienteAxios.get('/v3/clientes',{params: {
                page: pagina,
                busqueda: buscar,
            }});
            dispatch({
                type: OBTENER_CLIENTES,
                payload: resultado.data
            })
        } catch (error) {
            if(error.response.status===401){
                cerrarSesion();
            }
            
            const alerta = {
                msg: 'Hubo un error',
                categoria: 'alerta-error'
            }
            console.log(error);
            dispatch({
                type: CLIENTE_ERROR,
                payload: alerta
            })
        }
    }

    // Agregar nuevo producto
    const agregarCliente = async cliente => {

        try {
            const resultado = await clienteAxios.post('/v3/clientes', cliente);
            console.log(resultado);
            // Insertar el producto en el state
            dispatch({
                type: AGREGAR_CLIENTE,
                payload: resultado.data
            })
        } catch (error) {
            if(error.response.status===401){
                cerrarSesion();
            }
            const alerta = {
                msg: 'Hubo un error',
                categoria: 'alerta-error'
            }
            
            dispatch({
                type: CLIENTE_ERROR,
                payload: alerta
            })
        }
    }

    // Valida el formulario por errores
    const mostrarError = () => {
        dispatch({
            type: VALIDAR_FORMULARIO_CLIENTE
        })
    } 

    // Selecciona el producto que el usuario dio click
    const clienteActual = clienteId => {
        dispatch({
            type: CLIENTE_ACTUAL,
            payload: clienteId
        })
    }

    // Elimina un proyecto
    const eliminarCliente = async clienteId => {
        try {
            await clienteAxios.delete(`/v3/clientes/${clienteId}`);
            dispatch({
                type: ELIMINAR_CLIENTE,
                payload: clienteId
            })
        } catch (error) {
            if(error.response.status===401){
                cerrarSesion();
            }
            const alerta = {
                msg: 'Hubo un error',
                categoria: 'alerta-error'
            }
            
            dispatch({
                type: CLIENTE_ERROR,
                payload: alerta
            })
        }
    }


    return (
        <clienteContext.Provider
            value={{
                clientes: state.clientes,
                paginacion: state.paginacion,
                formulario: state.formulario,
                errorformulario: state.errorformulario,
                cliente: state.cliente,
                mensaje: state.mensaje,
                cargando_clientes: state.cargando_clientes,
                mostrarFormulario,
                obtenerClientes,
                agregarCliente,
                mostrarError,
                clienteActual,
                eliminarCliente,
                buscar,
                setBuscar
                // actualizarEnviada
                // pagina, 
                // setPagina
                
            }}
        >
            {props.children}
        </clienteContext.Provider>
        
    )
}

export default ClienteState;